/* eslint-disable import/prefer-default-export */
/* eslint-disable no-nested-ternary */

/**
 * Modifys the tooltip label display values
 * @param {Array} tooltipItem the selected slice of data on the graph
 * @param {Array} dataArray the data we pass into the chart
 * @return {string} new fraction value to display the difference over the total
 */
export const handleToolTip = (tooltipItem, dataArray) => {
  const totalValues = dataArray[0];

  if (tooltipItem.dataIndex > 1) return null;

  const { total, missing } = dataArray[tooltipItem.dataIndex];

  return (total - missing) < 1
    ? '100 / 100'
    : tooltipItem.dataIndex === 0
      ? `${((totalValues.total - totalValues.missing)).toLocaleString()} / ${(totalValues.total).toLocaleString()}`
      : `${(totalValues.missing).toLocaleString()} / ${(totalValues.total).toLocaleString()}`;
};

/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
import React, { createContext, useState, useContext } from 'react';
import { node, object } from 'prop-types';
import { TranslationsContext } from './Translations.provider';
import { LoginContext, getAccessToken } from './Login.provider';
import { StoreViewsContext } from './StoreViews.provider';
import { StoreConfigContext } from './StoreConfig.provider';
import { SimDateTime } from '../utils/datetime';

export const SimWebContext = createContext({});
export const isIOS = () => sessionStorage.getItem('source') === 'iOS';

const SimWebProvider = ({ children, mockedValue }) => {
  const { Provider } = SimWebContext;

  const { storeConfig, configRetrieved } = useContext(StoreConfigContext);
  const { getMessage, formatMessage, isReady, fetchTranslations } = useContext(TranslationsContext);
  const {
    appId, athleteId, nikeStoreRoles, userDetails, isLoggedIn, logout, setStoreNumber, setCountry, country, storeNumber, setTrueOrIOSLogin, setIsLoggedIn, isAdmin, setOidcAccessToken,
  } = useContext(LoginContext);
  const { storeId, storeInfo, store, storeName, flag } = useContext(StoreViewsContext);

  const [isInitialCall, setInitialCall] = useState(true);

  const userAgent = navigator?.userAgent;
  const isMobile = userAgent.includes('iPad') || userAgent.includes('iPhone');
  const offsiteType = storeConfig?.offsiteType?.value;
  const isOffsiteEnabled = storeConfig?.rfidEnabled?.value && offsiteType?.toLowerCase() !== 'none';
  const isOffsiteInactive = offsiteType === 'inactive';

  // ASN Tracking
  const asnTrackingInitialStates = {
    startDateState: SimDateTime.startOfDay(),
    endDateState: SimDateTime.endOfDay(),
  };
  const [asnTrackingStates, setAsnTrackingStates] = useState(asnTrackingInitialStates);

  // Inbound Visibility
  const [inboundSearchDate, setInboundSearchDate] = useState(null);

  const getCurrencySymbol = (locale = 'en-US', currencyCode = 'USD') => {
    const nullPrice = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currencyCode,
    }).format(0);

    const currencySymbol = nullPrice.replace(/[\d,.\s]/g, '');
    return currencySymbol;
  };
  // printing is always enabled - for NSP/KOR doors we're checking the config
  const isPrintingEnabled = true;// isIOS() ? storeConfig?.isIpadPrintEnabled?.value : true;

  return (
    <Provider
      value={mockedValue ?? {
        appId,
        athleteId,
        configRetrieved,
        country,
        csvFormat: !isIOS() ? '"="' : '', // forces csv value to string
        currencyCode: storeInfo?.currencies?.[0],
        currencySymbol: getCurrencySymbol(storeInfo?.locale?.replace('_', '-') || 'en-US', storeInfo?.currencies?.[0]),
        flag,
        inboundSearchDate,
        isAdmin,
        isInitialCall,
        isIOS: isIOS(),
        isLoggedIn,
        isMobile,
        iso2Country: storeInfo?.address?.iso2Country,
        isOffsiteEnabled,
        isOffsiteInactive,
        isPrintingEnabled,
        isReady,
        isTrue: window.location !== window.parent.location,
        locale: storeInfo?.locale || 'en-US',
        nikeStoreRoles,
        region: global.SIM_REGION,
        storeInfo,
        storeId,
        storeNumber,
        store,
        storeName,
        storeConfig,
        userDetails,
        asnTracking: {
          asnTrackingStates,
          asnTrackingInitialStates,
          setAsnTrackingStates,
        },
        fetchTranslations,
        formatMessage,
        getMessage,
        getAccessToken,
        logout,
        setCountry,
        setInitialCall,
        setIsLoggedIn,
        setOidcAccessToken,
        setTrueOrIOSLogin,
        setInboundSearchDate,
        setStoreNumber,
        SimDateTime,
      }}
    >
      {children}
    </Provider>
  );
};

SimWebProvider.defaultProps = {
  children: {},
  mockedValue: null,
};

SimWebProvider.propTypes = {
  children: node,
  mockedValue: object,
};

export default SimWebProvider;

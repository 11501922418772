import React, { useContext } from 'react';
import { createTheme } from '@mui/material';
import BaseViewPage from '../../baseView/BaseViewPage';
import CustomSpinner from '../../../components/CustomSpinner';
import { ScanReportSummaryContext } from './ScanReportSummary.provider';
import { baseMuiTheme } from '../../baseView/BaseViewPageUtils';

import './ScanReportSummaryTable.css';

const ScanReportSummary = () => {
  const {
    id, data, loading, error, tableOptions, datePicker, columns,
  } = useContext(ScanReportSummaryContext);

  const theme = createTheme({
    ...baseMuiTheme,
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            '&:first-of-type': {
              paddingLeft: '70px',
            },
          },
        },
      },
    },
  });

  const ellipses = (
    <div id="scan-summary-ellipses" data-testid="scan-summary-ellipses">
      <CustomSpinner size="10px" color="#5CDAA8" loading margin="25px" />
    </div>
  );

  return (
    <BaseViewPage
      id={id}
      title={id}
      loading={false}
      storeConfigValue="isRfidScanReportEnabled"
      error={error}
      theme={theme}
      datepicker={datePicker}
      details={{
        title: loading ? ellipses : '',
        options: tableOptions,
        columns,
        rows: loading ? [] : data,
      }}
    />
  );
};

export default ScanReportSummary;

/* eslint-disable max-len */
import React, {
  useMemo, useContext, useState,
} from 'react';
import {
  array, number, string, func, bool,
} from 'prop-types';
import _ from 'lodash';
import mockStyleColor from '../../__test__/__mocks__/cpa/cpa-merged.json';
import ProductDetailCard from './ProductDetailCard';
import DialogHeader from '../dialogHeader/DialogHeader';

import { CPAContext } from '../../context/cpa/CPA.provider';
import { SimWebContext } from '../../context/SimWeb.provider';
import { tutorialContext } from '../../context/Tutorial.provider';

import './ProductDetails.css';

const sortBySOH = (sizes, rfid = true) => {
  if (!sizes) return 0;
  return sizes?.map(size => (
    rfid
      ? _.get(size, 'quantity.physicalStock.quantity', 0)
      : _.get(size, 'quantity.fiscalStock.quantity', 0)
  )).reduce((acc, curr) => (curr ? acc + curr : acc), 0);
};
/**
   * scan to the  users in the summary table
   * @param {number} index - index of the item in view in modal mode
   * @param {function} setIsOpen - (for modals) -handles open/close of modal
   * @param {number} rowCount - (for modals) - checks the row length for the navigation
   * @param {function} setIndex (for modals) - handles updating the index
   * @param {string} styleColor (for modals) - style color the user is looking at
   * @param {boolean} modal - (for modals) - if modal we add navigation options to the cards (see style management)
   * @param {array} optionalColumns - a list of extra columns to display in the gtin table other than the default gtin, size and soh columns
   * @param {boolean} isUnitCountEnabled - show Inbound Units column if true
   * @param {boolean} isCreatingPicklist - bool indicating if the user is creating a picklist
   * @param {array} selectedStyles - array of styles to be added to the picklist
   *  @param {function} setSelectedPicklistItems - function to set selected picklist items
   *  @param {array} picklistItems - styles selected for the picklist creation
   *  @param {function} setFormOpen - func that controls the open/closing of the picklist form
*/
const ProductDetailContainer = ({
  index, setIsOpen, rowCount, setIndex, styleColor, modal, optionalColumns, isUnitCountEnabled,
  isCreatingPicklist, selectedStyles, setSelectedPicklistItems, picklistItems, setFormOpen,
}) => {
  const { startTutorial } = useContext(tutorialContext);
  const { data, loading } = useContext(CPAContext);
  const { storeConfig } = useContext(SimWebContext);

  const [picklistIndex, setPicklistIndex] = useState(0);
  const rfidEnabled = storeConfig?.rfidEnabled?.value;

  const productsCards = useMemo(() => {
    const products = startTutorial ? mockStyleColor : data;

    const modalHeader = modal && (
      <DialogHeader
        loading={loading}
        index={index}
        setIsOpen={setIsOpen}
        rowCount={rowCount}
        setIndex={setIndex}
        nodeTitle={styleColor}
      />
    );

    const renderProductDetailCard = (product, key) => (
      <ProductDetailCard
        product={product}
        isModal={modalHeader}
        key={key}
        optionalColumns={optionalColumns}
        isUnitCountEnabled={isUnitCountEnabled}
        picklistIndex={picklistIndex}
        setPicklistIndex={setPicklistIndex}
        selectedStyles={selectedStyles}
        isCreatingPicklist={isCreatingPicklist}
        setSelectedPicklistItems={setSelectedPicklistItems}
        picklistItems={picklistItems}
        setIsOpen={setIsOpen}
        setFormOpen={setFormOpen}
      />
    );

    if (loading) {
      return renderProductDetailCard(products, styleColor);
    }

    if (products) {
      if (!isCreatingPicklist) {
        if (products.length >= 1) {
          products.sort((a, b) => (sortBySOH(a?.sizes, rfidEnabled) > sortBySOH(b?.sizes, rfidEnabled) ? -1 : 1));
        }
        return products.map(p => renderProductDetailCard(p, p?.globalProductId || p?.productCode || p?.styleColor));
      }

      if (isCreatingPicklist) {
        return renderProductDetailCard(products?.[picklistIndex], styleColor);
      }
    }

    return null;
  }, [index, rowCount, styleColor, data, loading, rfidEnabled, startTutorial,
    picklistIndex, isCreatingPicklist, selectedStyles, picklistItems]);

  return productsCards;
};

ProductDetailContainer.propTypes = {
  data: array,
  index: number,
  rowCount: number,
  setIndex: func,
  styleColor: string,
  modal: bool,
  optionalColumns: array,
  isUnitCountEnabled: bool,
  isCreatingPicklist: bool,
  selectedStyles: array,
  setSelectedPicklistItems: func,
  picklistItems: array,
  setIsOpen: func,
  setFormOpen: func,
};

ProductDetailContainer.defaultProps = {
  data: [],
  index: 0,
  rowCount: null,
  setIndex: null,
  styleColor: null,
  modal: false,
  optionalColumns: [],
  isUnitCountEnabled: false,
  isCreatingPicklist: false,
  selectedStyles: [],
  setSelectedPicklistItems: () => {},
  picklistItems: [],
  setIsOpen: () => {},
  setFormOpen: () => {},
};

export default ProductDetailContainer;

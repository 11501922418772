import _ from 'lodash';

export default (sizes, rfid = true) => {
  if (!sizes) return 0;

  return sizes?.map(size => (
    rfid
      ? _.get(size, 'quantity.physicalStock.quantity', 0)
      : _.get(size, 'quantity.fiscalStock.quantity', 0)
  )).reduce((acc, curr) => (curr ? acc + curr : acc), 0);
};

/* eslint-disable no-console */
/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import { baseUrl, endpoints, routerUrls } from '../../axios/endpoints';
import { generalAxiosRequest, getRandomInteger } from '../../axios/axiosFunctions';
import { logErrorAction, newRelicAction } from '../../utils/newRelicPageActions';
import PIUploadStatus from './PIUploadStatus';
import { getPaginatedVarianceData } from './physicalInventoryUpload.utils';

/**
  * axios call to either submit or canceled the upload files and its session
  * @param {string} storeId storeId
  * @param {string} sessionId current sessionId
  * @param {string} status either FILES_SUBMITTED or SESSION_CANCELED
  * @param {function} onSuccess optional function executed onSuccess
  * @param {function} onFail optional function executed onFail
  * @param {function} onFinally optional function executed onFinally
  * @return {object}  object containing the status fo the session
*/

export const updateSession = async (storeId, sessionId, status, onSuccess, onFail, onFinally) => {
  const url = `${baseUrl(routerUrls.SIMWEB_BFF)}${endpoints.PI_UPLOAD.url}?storeId=${storeId}&sessionId=${sessionId}`;
  const result = await generalAxiosRequest('PATCH', url, endpoints.PI_UPLOAD, true, { appId: 'simweb' }, { status })
    .then((session) => {
      if (onSuccess) onSuccess(session);
      return {
        status: session.status,
        data: null,
      };
    })
    .catch(async (error) => {
      logErrorAction('PIUploadError', error, 'error upload status', { message: error?.message, sessionId });
      if (error?.message === 'File aleady uploaded') {
        return {
          status: PIUploadStatus.DUPLICATE_FILE_UPLOAD.name,
          data: null,
        };
      }

      if (onFail) onFail(error);
      return {
        status: PIUploadStatus.STOCK_UPDATE_ERROR.name,
        data: null,
        error,
      };
    })
    .finally(() => {
      if (onFinally) onFinally();
    });

  return result;
};

/**
  * axios call returns the status for the provided storeId and session
  * @param {string} storeId storeId
  * @param {string} sessionId current sessionId, optional if null returns all sessionIds
  * @return {object}  object containing the session information
*/
export const getUploadStatus = async (storeId, sessionId) => {
  const sessionFilter = sessionId ? `&sessionId=${sessionId}` : '';
  const url = `${baseUrl(routerUrls.SIMWEB_BFF)}${endpoints.PI_UPLOAD.url}?storeId=${storeId}${sessionFilter}&${getRandomInteger()}`;

  const result = await generalAxiosRequest('GET', url, endpoints.PI_UPLOAD, true, { appId: 'simweb' })
    .then((data) => data)
    .catch(error => {
      logErrorAction('PIUploadError', error, 'error upload status', { message: error?.message, sessionId });
      return error;
    });

  return result;
};

/**
  * axios call returns the variance report data for storeId and sessionId
  * @param {string} storeId storeId
  * @param {string} sessionId current sessionId
  * @return {array}  array containing delta variances
*/
export const getPhysicalInventoryUploadResults = async (storeId, sessionId, setPaginatedVarianceData, setTotalCounts) => {
  const results = [];
  let anchor = null;
  let isFirstPage = true;
  const startTime = Date.now();

  const filters = `storeId=${storeId}&sessionId=${sessionId}&count=1000`;
  let url = `${baseUrl(routerUrls.SIMWEB_BFF)}${endpoints.PI_UPLOAD_REPORT.url}?${filters}`;

  do {
    if (anchor) url = `${baseUrl(routerUrls.SIMWEB_BFF)}${endpoints.PI_UPLOAD_REPORT.url}?storeId=${storeId}&sessionId=${sessionId}&${anchor}&count=1000`;
    // eslint-disable-next-line no-await-in-loop
    const resultsResponse = await generalAxiosRequest('GET', url, endpoints.PI_UPLOAD_REPORT, true, { appId: 'simweb' })
      .then((data) => data)
      .catch(error => {
        logErrorAction('PIUploadError', error, 'error retreiving results', { message: error?.message, sessionId });
        return { error, data: {} };
      });

    anchor = resultsResponse?.pages?.next?.split('&')[2];

    if (resultsResponse?.error) {
      return resultsResponse;
    }

    if (resultsResponse?.objects) {
      results.push(resultsResponse.objects);
    }

    if (isFirstPage) {
      const firstPage = getPaginatedVarianceData(results.flat(1), 0, 100);
      setPaginatedVarianceData(firstPage);
      setTotalCounts(firstPage?.length);
      isFirstPage = false;
    }
  }

  while (anchor);

  const duration = Math.ceil(Date.now() - startTime);
  newRelicAction('PIUploadView-Success', {
    report: 'PIUpload',
    sessionId,
    duration,
  });

  return results.flat(1);
};

/**
  * helper function to read the file into a buffer
  * @param {File} file uploaded file
  * @return {buffer}  file stream of the uploaded file
*/
export const readFileAsync = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();

  reader.onload = () => {
    resolve(reader.result);
  };

  reader.onerror = reject;
  reader.readAsText(file);
});

/**
  * axios call to upload a file
  * @param {string} storeId storeId
  * @param {File} file uploaded file
  * @return {object}}  session information object
*/
export const uploadFile = async (storeId, file) => {
  const fileNameSplit = file.name.split('.');
  const fileName = fileNameSplit[0].concat('.', fileNameSplit[1].toLowerCase());

  const buffer = await readFileAsync(file);

  const headers = {
    appId: 'simweb',
    'Content-Type': 'application/octet-stream',
  };

  const url = `${baseUrl(routerUrls.SIMWEB_BFF)}${endpoints.PI_UPLOAD_FILES.url}?storeId=${storeId}&filename=${fileName}`;

  return generalAxiosRequest('POST', url, endpoints.PI_UPLOAD_FILES, true, headers, buffer)
    .then((data) => data)
    .catch(error => {
      const errorResponse = {
        storeId,
        status: 'FILE_UPLOAD_FAILED',
        files: 0,
        error,
        errorCode: '',
        fileName,
        size: file?.size,
      };

      if (error.response) {
        errorResponse.errorCode = error.response.status;
        errorResponse.error = error.response.data;
      } else if (error.request) {
        errorResponse.errorCode = 'Unknown';
        errorResponse.error = error.request;
      } else {
        errorResponse.errorCode = 'Unknown';
        errorResponse.error = error?.message ?? 'Unknown';
      }

      logErrorAction('PIUploadError', error, `error uploading ${fileName}`, errorResponse);

      return errorResponse;
    });
};

/* eslint-disable max-len */
const DAY_IN_MILLISECONDS = 1000 * 60 * 60 * 24;
const HOUR_IN_MILLISECONDS = 1000 * 60 * 60;
const HALF_HOUR_IN_MILLISECONDS = 1000 * 30 * 60;
const TEN_MINUTES_IN_MILLISECONDS = 1000 * 10 * 60;
const MINUTE_IN_MILLISECONDS = 1000 * 60;

/**
 * a function to format milliseconds to the nears whole hour, minute, and second
 * @param {*} secondsRemaining - time remaining in milliseconds of the timer
 * @param {*} setRemainingTime - use state function to update time object
 */
const formatTime = (secondsRemaining, setRemainingTime) => {
  const hours = Math.floor((secondsRemaining % DAY_IN_MILLISECONDS) / HOUR_IN_MILLISECONDS);
  const minutes = Math.floor((secondsRemaining % HOUR_IN_MILLISECONDS) / MINUTE_IN_MILLISECONDS);
  const seconds = Math.floor((secondsRemaining % MINUTE_IN_MILLISECONDS) / 1000);
  setRemainingTime({ hours, minutes, seconds });
};

/**
 * a funtion to create timer counting down from the start of a scan start date
 * formatting the remaining time in hours minutes, and seconds.
 * @param {*} startTime - time stamp in milliseconds of initial scan
 * @param {*} timeLength - duration of the timer from the start time
 * @param {*} setRemainingTime - use state function to update remaining time object
 * @param {*} setScanTimedOut - use state function to update when the timer has expired
 * @param {*} setSendNotification - use state function to trigger scan report notifications
 */
// eslint-disable-next-line import/prefer-default-export
export const countDownTimer = (startTime, timeLength, currentNotification, setRemainingTime, setScanTimedOut, setSendNotification) => {
  const scanExpirationTime = startTime + timeLength;
  const currentDate = new Date(Date.now());
  const currentTime = currentDate.getTime();
  const secondsRemaining = (scanExpirationTime - currentTime);

  const withinFiveHours = (secondsRemaining) => secondsRemaining > HOUR_IN_MILLISECONDS;
  const withinOneHour = (secondsRemaining) => (HALF_HOUR_IN_MILLISECONDS + 1000) < secondsRemaining && secondsRemaining <= HOUR_IN_MILLISECONDS;
  const withinHalfHour = (secondsRemaining) => (TEN_MINUTES_IN_MILLISECONDS + 1000) < secondsRemaining && secondsRemaining <= HALF_HOUR_IN_MILLISECONDS;
  const withinTenMinutes = (secondsRemaining) => (MINUTE_IN_MILLISECONDS + 1000) < secondsRemaining && secondsRemaining <= TEN_MINUTES_IN_MILLISECONDS;

  if (withinFiveHours(secondsRemaining) && currentNotification !== 'FIVE_HOUR_MARK') {
    setSendNotification('FIVE_HOUR_MARK');
  }

  if (withinOneHour(secondsRemaining) && currentNotification !== 'ONE_HOUR_MARK') {
    setSendNotification('ONE_HOUR_MARK');
  }

  if (withinHalfHour(secondsRemaining) && currentNotification !== 'HALF_HOUR_MARK') {
    setSendNotification('HALF_HOUR_MARK');
  }

  if (withinTenMinutes(secondsRemaining) && currentNotification !== 'TEN_MINUTES_MARK') {
    setSendNotification('TEN_MINUTES_MARK');
  }

  if (secondsRemaining > 0) {
    formatTime(secondsRemaining, setRemainingTime);
  } else if (secondsRemaining > -1000 && secondsRemaining <= 0) {
    setSendNotification('TIMEDOUT');
  } else {
    setScanTimedOut(true);
  }
};

import { camelCase } from 'lodash';

export const createGradient = (ctx, colors) => {
  const gradients = [];

  colors?.map(color => {
    const gradient = ctx.createLinearGradient(0, 0, 0, 200);
    gradient.addColorStop(0, color.start || '#000000');
    gradient.addColorStop(1, color.end || '#ffffff');
    gradients.push(gradient);
  });

  gradients.push('#434244');

  return gradients;
};

export const updateData = (data) => {
  const dataArray = [];
  const labelArray = [];
  const colorArray = [];

  if (data) {
    data.map(val => {
      if (val.value || val.value === 0) dataArray.push(val.value);
      if (val.label) labelArray.push(val.label);
      if (val.colors) {
        colorArray.push(val.colors);
      }
    });
  }

  return {
    dataArray, labelArray, colorArray,
  };
};

export const getLegendData = (transform, aggregatedBy) => {
  if (!transform?.[aggregatedBy]) return [];

  const divisions = Object.keys(transform[aggregatedBy]);
  const labels = [];
  const legend = [];

  divisions?.map(division => {
    transform[aggregatedBy][division]?.items?.map(aggregation => {
      if (labels.includes(aggregation.label)) return;
      labels.push(aggregation.label);
      legend.push({
        label: aggregation.label,
        colors: aggregation.colors,
      });
    });
  });

  return legend;
};

export const buildTransformObject = (
  categories = ['genderAge', 'retailCategory'],
  divisions = ['apparel', 'equipment', 'footwear'],
) => {
  const transform = {};

  categories.map(category => {
    if (!transform?.[category]) transform[category] = {};

    divisions.map(division => {
      if (!transform[category]?.[division]) {
        transform[category][division] = {
          centerValue: 0,
          items: [],
        };
      }
    });
  });

  return transform;
};

export const chartTransform = (summary, categories, divisions, getMessage) => {
  const titleCase = str => `${str[0].toLowerCase()}${str.slice(1)}`;

  const transform = buildTransformObject(categories, divisions);

  summary?.map(item => {
    item.subAggregations.map(sub => {
      const category = titleCase(sub.name);
      const division = titleCase(item.name);

      transform[category][division] = {
        centerValue: item.centerValue,
        items: [],
      };

      sub.subAggregations.map(subAggregation => {
        const name = subAggregation.name.replace('/', '').replace(' ', '');
        const colorKey = `--${sub.name}_${name}`;

        transform[category][division].items.push({
          label: getMessage(camelCase(subAggregation.name)),
          value: subAggregation.count,
          colors: {
            start: getComputedStyle(document.documentElement).getPropertyValue(colorKey),
            end: getComputedStyle(document.documentElement).getPropertyValue(`${colorKey}2`),
          },
        });
      });
    });
  });

  return transform;
};

import React from 'react';
import InboundVisibilityProvider from '../../../containers/inboundVisibility/InboundVisibility.provider';
import InboundVisibilityWidget from './InboundVisibilityWidget';

const InboundVisibilityWidgetContainer = () => (
  <InboundVisibilityProvider>
    <InboundVisibilityWidget />
  </InboundVisibilityProvider>
);

export default InboundVisibilityWidgetContainer;

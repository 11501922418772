import React, {
  createContext, useContext, useEffect, useState, useMemo,
} from 'react';
import { node, object } from 'prop-types';
import { cloneDeep } from 'lodash';
import { SimWebContext } from '../../context/SimWeb.provider';
import { setSizeComplianceTutorialSteps } from './utils/sizeComplianceProvider.utils';
import { tutorialContext } from '../../context/Tutorial.provider';
import { fetchSizeComplianceData } from './sizeCompliance.axios';
import DIVISIONS from '../../constants/divisions';

export const SizeComplianceContext = createContext();

const SizeComplianceProvider = ({ children, mockedValue }) => {
  const { Provider } = SizeComplianceContext;
  const { storeId, storeInfoById } = useContext(SimWebContext);
  const {
    stepIndex, setStepIndex, startTutorial, isbackClicked, restartTutorial,
  } = useContext(tutorialContext);

  const { facilityType: nikeDoor, businessConcept: storeType } = { ...storeInfoById };

  const [isLoading, setIsLoading] = useState(false);
  const [isBinned, setIsBinned] = useState(true);
  const [isInitialCall, setIsInitialCall] = useState(true);
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [pageSearchText, setPageSearchText] = useState('');
  const [error, setError] = useState(null);
  const [supportedFilters, setSupportedFilters] = useState([]);
  const [filters, setFilters] = useState([
    {
      name: 'binned',
      operator: 'EQUALS',
      values: ['true'],
    },
  ]);

  const updateTabData = (productList) => {
    let filteredProduct = null;

    if (!isBinned) {
      filteredProduct = productList?.filter(product => (product?.binInfo?.length === 0));
    } else {
      filteredProduct = productList?.filter(product => (product?.binInfo?.length > 0));
    }
    return filteredProduct;
  };

  const handleResetFacets = () => {
    setSearchText('');
    setPageSearchText('');
    setFilters(filters.slice(0, 1));
  };

  const onToggleTabChange = (selectedValue) => {
    setSearchText('');
    setPageSearchText('');

    const binStatus = selectedValue.toLowerCase() === 'bin';
    setIsBinned(binStatus);

    const filterCopy = filters.slice(0, 1);
    if (filterCopy[0]) filterCopy[0].values = [(binStatus ? 'true' : 'false')];
    setFilters(filterCopy);
  };

  const sizeComplianceVariables = useMemo(() => {
    const filtersCopy = cloneDeep(filters);
    const count = 100;

    if (nikeDoor === 'NIKE_OWNED_STORE' && storeType === 'BEACON') {
      const divisionFilters = filtersCopy.filter(filter => filter?.name === 'division');
      if (divisionFilters.length === 0) {
        filtersCopy.push({
          name: 'division',
          operator: 'EQUALS',
          values: DIVISIONS.apparel.aliases,
        });
      }
    }

    return {
      storeId,
      textQuery: pageSearchText,
      filters: filtersCopy,
      count,
    };
  }, [storeId, filters, pageSearchText]);

  useEffect(() => {
    setSizeComplianceTutorialSteps(startTutorial, stepIndex, restartTutorial, isbackClicked, setStepIndex);
  }, [stepIndex, startTutorial, isbackClicked, restartTutorial]);

  const fetchData = async () => {
    setIsLoading(true);
    setError(null);
    setData([]);

    try {
      const sizeCompData = await fetchSizeComplianceData(sizeComplianceVariables);

      if (sizeCompData) {
        setIsLoading(true);
        const filters = sizeCompData?.supportedFilters || [];
        const updatedProductList = updateTabData(sizeCompData?.product);
        setData(updatedProductList);
        setSupportedFilters(filters);

        if (isInitialCall) {
          setIsInitialCall(false);
        }
      }

      setIsLoading(false);
    } catch (error) {
      setError(error?.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (sizeComplianceVariables && storeId) {
      fetchData();
    }
  }, [sizeComplianceVariables, storeId]);

  useEffect(() => {
    setError(error);
  }, [error]);

  return (
    <Provider
      value={mockedValue ?? {
        loading: isLoading,
        error,
        data,
        filters,
        handleResetFacets,
        onToggleTabChange,
        isInitialCall,
        isBinned,
        pageSearchText,
        refetch: fetchData,
        searchText,
        supportedFilters,
        setIsBinned,
        setFilters,
        setPageSearchText,
        setSearchText,
      }}
    >
      {children}
    </Provider>
  );
};

SizeComplianceProvider.defaultProps = {
  children: {},
  mockedValue: null,
};

SizeComplianceProvider.propTypes = {
  children: node,
  mockedValue: object,
};

export default SizeComplianceProvider;

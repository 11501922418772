import { func, string } from 'prop-types';
import React from 'react';
import Button from './Button';

import './ClearFacetsButton.css';

/**
 * When clicked will deselect all facets
 * @param {function} clearFacets - when called will clear selected state of all facets
 * @param {string} buttonText - text of the button
 * @param {string} name - name of the component
 */
const ClearFacetsButton = ({
  clearFacets, buttonText, name,
}) => {
  const handleClick = () => {
    clearFacets();
  };

  return (
    <Button
      className="clear-facets-button"
      buttonText={buttonText}
      dataTestId={`${name}-clear-facets-button`}
      onClick={handleClick}
    />
  );
};

ClearFacetsButton.propTypes = {
  clearFacets: func.isRequired,
  buttonText: string.isRequired,
  name: string.isRequired,
};

export default (ClearFacetsButton);

import React from 'react';
import { any, string, bool } from 'prop-types';
import { BeatLoader } from 'react-spinners';

import './CustomSpinner.css';

const CustomSpinner = ({
  loading,
  color,
  size,
  margin,
  ...rest
}) => (
  <div
    className="custom-spinner"
    data-testid="custom-spinner"
    style={{
      paddingRight: margin,
      paddingLeft: margin,
    }}
  >
    <BeatLoader
      size={size}
      color={color}
      loading={loading}
      rest={rest}
    />
  </div>
);

CustomSpinner.propTypes = {
  loading: bool,
  color: string,
  size: string,
  rest: any,
  margin: string,
};

CustomSpinner.defaultProps = {
  loading: false,
  color: '#123abc',
  size: '150',
  margin: '2px',
  rest: null,
};

export default CustomSpinner;

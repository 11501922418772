import React, { useContext } from 'react';
import AgedOrdersProvider from '../../../containers/agedOrders/AgedOrders.provider';
import AgedOrdersWidget from './AgedOrdersWidget';
import { SimWebContext } from '../../../context/SimWeb.provider';

const AgedOrdersWidgetContainer = () => {
  const { isAdmin, storeConfig } = useContext(SimWebContext);
  const isAgedOrdersEnabled = isAdmin || storeConfig?.isAgedOrdersEnabled?.value;
  return isAgedOrdersEnabled && (
    <AgedOrdersProvider>
      <AgedOrdersWidget />
    </AgedOrdersProvider>
  );
};

export default AgedOrdersWidgetContainer;

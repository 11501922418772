/* eslint-disable import/prefer-default-export */
import { isEmpty } from 'lodash';

/**
 * A helper function to map the bins data by style-color
 * @param {array} binsData a list of bins assignment data
 */
export const buildStyleColorsBinsMapping = (binsData) => {
  const styleColorMap = new Map();

  if (!isEmpty(binsData)) {
    binsData.map(bins => {
      if (bins.productCode) {
        const styleColor = bins.productCode;
        const currentBins = styleColorMap.get(styleColor);
        const updatedBins = !currentBins ? new Array(bins.name) : Array.from(new Set(currentBins.concat(bins.name)));
        styleColorMap.set(styleColor, updatedBins);
      }
    });
  }
  return styleColorMap;
};

/**
 * Util that takes state/utils/configs & handles setting/changing steps in the turorial when clicking through the steps
 * @param {boolean} startTutorial - checks if we're in tutorial mode
 * @param {number} stepIndex - the current step index we are on for the tutorial
 * @param {boolean} restartTutorial - a value used to cancel out any state since the tutorial is no longer active
 * @param {boolean} isbackClicked - a value that handles back clicks within the steps in the tooltip
 * @param {func} setStepIndex - a function that handles setting the step index
 */
export const setSizeComplianceTutorialSteps = (
  startTutorial, stepIndex, restartTutorial, isbackClicked, setStepIndex,
) => {
  const mainCheckbox = document.querySelectorAll('table thead tr input')[0];
  const listOfCheckboxes = document.querySelectorAll('table td input');
  const picklist = document.querySelector('[data-testid="size-compliance-picklist-button"]');
  const cancelButton = document.querySelector('.cancel-button');

  if (startTutorial) {
    if (stepIndex === 5 && isbackClicked && mainCheckbox?.checked) {
      mainCheckbox?.click(); // deselect all
    }
    if (stepIndex === 6) {
      if (isbackClicked) {
        mainCheckbox?.click(); // deselect all - make sure no single checkboxes are clicked from the previous step
        setTimeout(() => {
          mainCheckbox?.click(); // select all once again
        }, 400);
      } else {
        mainCheckbox?.click(); // select all
        setTimeout(() => {
          setStepIndex(6);
        }, 400);
      }
    }
    if (stepIndex === 7) {
      // handle unqiued case where user is clicking in the highlighted section and changing the default behavior
      if (!mainCheckbox?.checked) {
        mainCheckbox?.click(); // select all
        listOfCheckboxes?.[0].click(); // select single checkbox
      }
      mainCheckbox?.click(); // deselect all
      setTimeout(() => {
        listOfCheckboxes?.[0]?.click(); // select single checkbox
        setStepIndex(7);
      }, 600);
    }
    // handle unqiued case where user is clicking in the highlighted section and changing the default behavior
    // eslint-disable-next-line max-len
    if ((stepIndex === 8 && isbackClicked && !listOfCheckboxes?.[0]?.checked) || (stepIndex === 8 && !listOfCheckboxes?.[0]?.checked)) {
      cancelButton?.click();
      setTimeout(() => {
        listOfCheckboxes?.[0].click(); // select single checkbox
        setStepIndex(8);
      }, 400);
    }

    if (stepIndex === 9) {
      picklist?.click();
      setTimeout(() => {
        setStepIndex(9);
      }, 500);
    }
  }
  if (restartTutorial) {
    if (mainCheckbox?.checked) mainCheckbox.click();
    if (listOfCheckboxes?.[0]?.checked && !mainCheckbox?.checked) listOfCheckboxes?.[0]?.click();
    if (stepIndex === 0 && cancelButton) cancelButton?.click();
  }
};

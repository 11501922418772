import React, {
  useMemo, useContext, useEffect, useState,
} from 'react';
import { bool, func, number } from 'prop-types';
import Joyride from 'react-joyride';
import Switch from '@mui/material/Switch';
import { LoadingIndicator } from '../../assets/Svgs/index';
import { SimWebContext } from '../../context/SimWeb.provider';
import { tutorialContext } from '../../context/Tutorial.provider';
/**
 * This component is used as a step by step guide on how to use features/reports
 * @param {function} setTutorialState - sets the bool to trigger activating the tutorial
 * @param {bool} startTutorial - the value that activates the tutorial
 * @param {function} setStepIndex - sets the index of the step
 * @param {string} stepIndex - the active index of the step in view
 * @param {bool} disabled - disable the tutorial if no data is present
 */
const Tutorial = ({
  setTutorialState, startTutorial, setStepIndex, stepIndex, disabled,
}) => {
  const { getMessage } = useContext(SimWebContext);
  const {
    handleJoyrideCallback, steps, activateTutorials, loadingTutorial, dashboardWidget, isStyleManagementTutorialEnabled,
  } = useContext(tutorialContext);

  const [page, setPage] = useState(null);

  useEffect(() => {
    setPage(window.location.href.split('/')[3]);
  }, [window.location]);

  // reposition joyride spotlight to target component after scrolling the screen
  useEffect(() => {
    const { target } = steps?.[stepIndex] || {};
    if (dashboardWidget && target) {
      const targetEl = document.querySelector(target);
      if (targetEl) {
        window.setTimeout(() => {
          const spotlight = document.querySelector('.react-joyride__spotlight');
          if (spotlight) {
            const spotlightTop = spotlight.offsetTop;
            const targetTop = targetEl.offsetTop;
            const diff = spotlightTop > targetTop
              ? spotlightTop - targetTop
              : targetTop - spotlightTop;
            if (diff > 1) {
              spotlight.style.transform = `translateY(${diff}px)`;
            }
          }
        }, 0);
      }
    }
  }, [stepIndex, startTutorial]);

  // eslint-disable-next-line arrow-body-style
  const tutorial = useMemo(() => {
    const enableTutorialForPilot = isStyleManagementTutorialEnabled && page === 'styleManagement';
    // eslint-disable-next-line no-console
    // console.log('tutorial:', startTutorial, stepIndex, activateTutorials, steps); // keep temporarily
    return (activateTutorials || enableTutorialForPilot) ? (
      <div data-testid={`${page}-tutorial-container`}>
        <Joyride
          callback={handleJoyrideCallback}
          continuous
          run={startTutorial}
          scrollToSteps
          setStepIndex={setStepIndex}
          showProgress
          showSkipButton
          stepIndex={stepIndex}
          steps={steps}
          disableScrolling
          locale={{
            back: getMessage('back'),
            close: getMessage('close'),
            last: getMessage('last'),
            next: getMessage('next'),
            skip: getMessage('skip'),
          }}
          styles={{
            options: {
              primaryColor: '#000',
              zIndex: '2000',
            },
          }}
        />
        <label htmlFor="reportTutorial">{getMessage('tutorial')}</label>
        <Switch
          checked={startTutorial}
          onChange={setTutorialState}
          name="reportTutorial"
          color="primary"
          data-testid="custom-tutorial-switch"
          disabled={disabled}
        />
        <p className="tutorial-disabled" data-testid="disabledTutorial">{disabled ? 'The tutorial is disabled due to no data present for this report' : null}</p>
        {loadingTutorial && <LoadingIndicator width="45px" height="45px" />}
      </div>
    ) : null;
  }, [startTutorial, stepIndex, steps, activateTutorials, loadingTutorial, disabled, isStyleManagementTutorialEnabled, page]);

  return (
    <div data-testid="custom-tutorial" className="tutorial-joyride-wrapper">
      {tutorial}
    </div>
  );
};

Tutorial.propTypes = {
  disabled: bool,
  setTutorialState: func,
  startTutorial: bool,
  setStepIndex: func,
  stepIndex: number,
};

Tutorial.defaultProps = {
  disabled: false,
  setTutorialState: null,
  startTutorial: false,
  setStepIndex: null,
  stepIndex: 0,
};

export default Tutorial;

import React, {
  createContext, useContext, useEffect, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { node, object } from 'prop-types';
import { fetchScanDetailsData } from '../scanReport.axios';
import useSessionStorage from '../../../hooks/useSessionStorage';
import { SimWebContext } from '../../../context/SimWeb.provider';

export const ScanDetailsContext = createContext({});

const ScanDetailsProvider = ({ children, mockedValue }) => {
  const { sessionId, endDate } = useParams();
  const { Provider } = ScanDetailsContext;
  const { isOffsiteEnabled } = useContext(SimWebContext);

  const [scanDetails, setScanDetails] = useState(null);
  const [scanItems, setScanItems] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [sohLoading, setSOHLoading] = useState(false);
  const [scanProductDetailData, setScanProductDetailData] = useState(null);

  const id = 'scanDetailsReport';

  const [getCurrentSort, setCurrentSort] = useSessionStorage('currentSort', null);
  const [getPage, setPage] = useSessionStorage('page', -1);
  const [getDisplayedRowCount, setDisplayedRowCount] = useSessionStorage('displayedRowCount', 0);

  const fetchScan = async () => {
    setLoading(true);

    try {
      const response = await fetchScanDetailsData(sessionId, endDate, isOffsiteEnabled);
      const { items, ...details } = response;
      setScanDetails(details);
      setScanItems(items);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchScan(sessionId);
  }, []);

  return (
    <Provider value={mockedValue ?? {
      id,
      data: scanDetails,
      items: scanItems, // this should always be array of objects
      error,
      loading,
      sohLoading,
      setScanItems,
      setSOHLoading,
      scanProductDetailData,
      setScanProductDetailData,
      getCurrentSort,
      setCurrentSort,
      getPage,
      setPage,
      getDisplayedRowCount,
      setDisplayedRowCount,
    }}
    >
      {children}
    </Provider>
  );
};

ScanDetailsProvider.propTypes = {
  children: node,
  mockedValue: object,
};

ScanDetailsProvider.defaultProps = {
  children: {},
  mockedValue: null,
};

export default ScanDetailsProvider;

import React, { useContext, useMemo } from 'react';
import { isEmpty } from 'lodash';
import { newRelicAction } from '../../../../utils/newRelicPageActions';
import Facet from '../../../../components/facets/Facet';
import FacetGroup from '../../../../components/facets/FacetGroup';
import InfoTooltip from '../../../../components/InfoTooltip';
import TooltipContent from './TooltipContent';

import {
  buildFacetsData, getAggregationComponents, updateFilters, getFacetGroupsToShow,
} from './scanReportFacets.utils';

import { SimWebContext } from '../../../../context/SimWeb.provider';
import { ScanDetailsContext } from '../ScanDetails.provider';

import './ScanReportFacets.css';

const ScanReportFacets = () => {
  const { getMessage } = useContext(SimWebContext);
  const { data, filters, setFilters } = useContext(ScanDetailsContext);

  const handleFacetChange = (facet, groupName) => {
    const updatedFilters = updateFilters(facet, groupName, filters);
    const isFacetChecked = !facet.selected;
    newRelicAction('rfid-scan-report-facet', { facet: groupName, item: facet.name, checked: isFacetChecked });
    setFilters(updatedFilters);
  };

  const facetGroupComponent = useMemo(() => {
    const facetData = buildFacetsData(data?.supportedFilters);
    const facetGroups = getFacetGroupsToShow(facetData);

    const tooltip = (
      <InfoTooltip
        placement="top"
        title={getMessage('aggregations')}
        content={<TooltipContent scanType={data?.scanType} />}
      />
    );

    return facetGroups.map(groupName => {
      const facetComponent = facetData[groupName]?.values.map(facet => ((facet) ? (
        <Facet
          key={facet.name}
          nameKey={facet.name}
          name={`${groupName}.${facet.name}`}
          selected={facet.selected}
          handleChange={() => handleFacetChange(facet, groupName)}
          hidden={false}
          group={groupName}
          aggregation={getAggregationComponents(facet.additionalText)}
          translatedName={facet.label}
        />
      ) : null));

      return (
        (!isEmpty(facetComponent)) ? (
          <FacetGroup
            key={groupName}
            name={`scan-report-facet-group-${groupName}`}
            title={getMessage(facetData[groupName].label)}
            hidden={false}
            tooltip={groupName === 'division' ? tooltip : null}
          >
            <div className="facets-container" data-testid={`${groupName}-facet-container`}>
              {facetComponent}
            </div>
          </FacetGroup>
        ) : null
      );
    });
  }, [data?.supportedFilters]);

  return (
    <div data-testid="scan-report-facets">
      {facetGroupComponent}
    </div>
  );
};

export default ScanReportFacets;

import React from 'react';
import { func, string } from 'prop-types';

import './svgs.css';

// eslint-disable-next-line import/prefer-default-export
export const Plus = ({
  dataTestId,
  onClick,
  text,
  stroke,
  customClass,
}) => (
  <div className={`plus_container ${text ? 'plus_container-withText' : null} ${customClass}`} onClick={onClick} data-testid={dataTestId}>
    <svg className="svg-circle-plus" stroke={stroke} viewBox="0 0 100 100">
      <line x1="32.5" y1="50" x2="67.5" y2="50" strokeWidth="5" />
      <line x1="50" y1="32.5" x2="50" y2="67.5" strokeWidth="5" />
    </svg>
    <p>{text}</p>
  </div>
);

Plus.propTypes = {
  dataTestId: string,
  onClick: func,
  text: string,
  stroke: string,
  customClass: string,
};

Plus.defaultProps = {
  dataTestId: '',
  onClick: null,
  text: '',
  stroke: 'black',
  customClass: '',
};

export default Plus;

/* eslint-disable no-unused-expressions */
/* eslint-disable max-len */
import React, {
  useContext, useMemo, useState, useEffect,
} from 'react';
import {
  Dialog,
  Tabs,
  Tab,
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
  DialogContent,
} from '@mui/material';
import { DateTime } from 'luxon';
import MUIDataTable from 'mui-datatables';
import { isEmpty } from 'lodash';
import { getRfidTableHeader } from '../../components/RFIDTableHeader';
import DialogHeader from '../../components/dialogHeader/DialogHeader';
import DonutChartWithLegend from '../../components/visualSummaries/donutChart/DonutChartWithLegend';
import GenderCategoryToggle, { getToggles, getAggregatedBy } from '../../components/toggle/GenderCategoryToggle';
import { Refresh } from '../../assets/Svgs/Refresh';
import BaseViewPage from '../baseView/BaseViewPage';
import { buildMarkdownsSummaryObject, productStatusSort } from './priceChanges.utils';
import { defaultSort } from '../../utils/sort/defaultSort';
import { baseDetailsTableOptions } from '../baseView/BaseViewPageUtils';
import { DATE_SHORT_WITH_APPENDED_ZEROS } from '../../constants/LocaleFormats';
import getFormattedPrice from '../../utils/getFormattedPrice';
import getLocalizedPercent from '../../utils/getLocalizedPercent';

import { PriceChangesContext } from './PriceChanges.provider';
import { SimWebContext } from '../../context/SimWeb.provider';

import './PriceChangesPage.css';

const theme = createTheme(({
  palette: {
    secondary: {
      main: '#000000',
    },
  },
}));

const PriceChangesPage = () => {
  const {
    data, currentEffectiveDate, error, fixedHeader, id, lastUpdatedTimestamp, loading, isOffsiteEnabled, isOffsiteInactive, rfidEnabled,
    summaryData, tabValue, title, toggleSelected, visualData, stopAnimation,
    setRefetch, setTabValue, setToggleSelected, dialogData, setDialogData, openModal, setOpenModal,
  } = useContext(PriceChangesContext);

  const {
    currencyCode, formatMessage, getMessage, locale,
  } = useContext(SimWebContext);

  const [scIndex, setSCIndex] = useState(0);
  const [rows, setRows] = useState([]);
  const [sortDirection, setDirection] = useState('asc');

  useEffect(() => {
    if (dialogData?.items) {
      setRows(dialogData?.items);
    }
  }, [dialogData?.items]);

  useEffect(() => {
    if (data?.[scIndex]) setDialogData(data?.[scIndex]);
  }, [data, scIndex]);

  // eslint-disable-next-line max-len
  const summaryObject = useMemo(() => buildMarkdownsSummaryObject(summaryData, currentEffectiveDate, locale, getMessage), [summaryData, currentEffectiveDate, getMessage]);

  const tableTitle = useMemo(() => (
    <div className={`${id}-last-updated-date`}>
      <span>{formatMessage('lastUpdated', { timestamp: lastUpdatedTimestamp })}</span>
      <Refresh
        testId={`${id}-refresh`}
        onClick={() => setRefetch(true)}
      />
    </div>
  ), [lastUpdatedTimestamp, formatMessage]);

  // main table options
  const dataTableOptions = useMemo(() => ({
    ...(baseDetailsTableOptions(getMessage)),
    download: true,
    sortOrder: {
      name: 'effectiveDates',
      direction: 'asc',
    },
    onRowClick: (rowData, { dataIndex }) => {
      setSCIndex(dataIndex);
      setOpenModal(true);
    },
  }), [getMessage]);

  const rfidColumn = getRfidTableHeader('totalRfidSoh', getMessage('rfidSoh'), 'productCard-summary', rfidEnabled, isOffsiteEnabled, isOffsiteInactive, !rfidEnabled, fixedHeader);

  // main table columns
  const columns = useMemo(() => (
    [
      {
        name: 'effectiveDate',
        label: getMessage('effectiveDateOrMarkdownTask'),
        options: {
          filter: true,
          customBodyRender: value => (
            <>{DateTime.fromISO(value).toLocaleString(DATE_SHORT_WITH_APPENDED_ZEROS)}</>
          ),
        },
      },
      {
        name: 'progressPercentage',
        label: getMessage('progressPercentage'),
        options: {
          filter: true,
          customBodyRender: value => (
            <>{getLocalizedPercent(locale, value)}</>
          ),
        },
      },
      {
        name: 'status',
        label: getMessage('status'),
        options: { filter: true },
      },
      {
        name: 'styleColorSoh',
        label: getMessage('totalStyleColors'),
        options: {
          filter: true,
          setCellHeaderProps: () => ({
            'data-testid': 'columnStep',
          }),
        },
      },
      {
        name: 'totalSoh',
        label: getMessage('soh'),
      },
      rfidColumn,
    ]
  ), []);

  // donut charts and toggling
  const visuals = useMemo(() => {
    const getChart = (aggregatedBy, data, toggle) => (
      <DonutChartWithLegend
        id={`${id}-${aggregatedBy}`}
        data={data}
        aggregatedBy={aggregatedBy}
        toggle={toggle}
        subDescriptionTitle={`${getMessage('currentMarkdown')} - ${currentEffectiveDate}`}
        isLive
        stopAnimation={stopAnimation}
      />
    );

    const toggle = <GenderCategoryToggle selected={toggleSelected} setSelected={setToggleSelected} />;
    const aggregatedBy = getAggregatedBy(getToggles(getMessage), toggleSelected);
    const chart = getChart(aggregatedBy, visualData, toggle);

    return visualData ? (
      <div>
        <div data-testid={`${id}-toggleGroup`}>
          {chart}
        </div>
      </div>
    ) : null;
  }, [visualData, toggleSelected, currentEffectiveDate, getMessage]);

  // main table tabs
  const tabs = useMemo(() => (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Tabs
          value={tabValue}
          onChange={(_, val) => setTabValue(val)}
          TabIndicatorProps={{ style: { backgroundColor: 'black' } }}
        >
          <Tab sx={{ '&.Mui-selected': { color: '#666' } }} label={getMessage('markdowns')} data-testid="PriceChangesMarkdownsTab" />
        </Tabs>
      </ThemeProvider>
    </StyledEngineProvider>
  ), [tabValue]);

  // markdown dialog table columns
  const markdownDialogColumns = useMemo(() => {
    const rfidSohColumn = getRfidTableHeader('rfidSoh', getMessage('rfidSoh'), 'markdown-table', rfidEnabled, isOffsiteEnabled, isOffsiteInactive, false, fixedHeader);
    return [
      {
        name: 'styleColor',
        label: getMessage('style-color'),
      },
      {
        name: 'productStatus',
        label: getMessage('productStatus'),
      },
      {
        name: 'division',
        label: getMessage('division'),
      },
      {
        name: 'category',
        label: getMessage('retailCategory'),
      },
      {
        name: 'currentPrice',
        label: getMessage('currentPrice'),
        options: {
          filter: true,
          customBodyRender: value => (
            <>{getFormattedPrice(value, locale, currencyCode)}</>
          ),
        },
      },
      {
        name: 'markDownPrice',
        label: getMessage('markdownPrice'),
        options: {
          filter: true,
          customBodyRender: value => (
            <>{getFormattedPrice(value, locale, currencyCode)}</>
          ),
        },
      },
      {
        name: 'soh',
        label: getMessage('soh'),
      },
      rfidSohColumn,
      {
        name: 'bin',
        label: getMessage('bin'),
      },
    ];
  }, []);

  // markdown dialog table options
  const getOptions = (rows, sortDirection, setDirection, getMessage) => ({
    ...(baseDetailsTableOptions(getMessage)),
    download: true,
    sortOrder: { name: 'productStatus', direction: sortDirection },
    customSort: (data, dataIndex, rowIndex) => ((dataIndex === 1)
      ? productStatusSort(data, dataIndex, sortDirection)
      : defaultSort(data, dataIndex, rowIndex)),
    onColumnSortChange: (changedColumn, direction) => {
      if (changedColumn === 'productStatus') {
        const sortedRows = productStatusSort(rows, changedColumn, direction);
        setRows(sortedRows);
        setDirection(direction);
      } else {
        setRows(rows);
      }
    },
  });

  const options = useMemo(() => getOptions(rows, sortDirection, setDirection, getMessage), [rows, sortDirection, setDirection, getMessage]);

  // dialog
  const dialog = useMemo(() => {
    const dialogTitle = (
      <strong>{`${getMessage('effectiveDate')}: ${dialogData?.effectiveDate && DateTime.fromISO(dialogData.effectiveDate).toLocaleString(DATE_SHORT_WITH_APPENDED_ZEROS)}`}</strong>
    );
    return (
      <div data-testid="price-changes-dialog-data-table">
        <DialogHeader
          index={scIndex}
          setIsOpen={setOpenModal}
          rowCount={data?.length}
          setIndex={setSCIndex}
          nodeTitle={dialogTitle}
        />
        <DialogContent>
          <h3><strong className="mardown-progress-percentage">{`${getMessage('markdownProgressPercentage')}: ${getLocalizedPercent(locale, dialogData?.progressPercentage || 0)}`}</strong></h3>
          <MUIDataTable
            data={rows}
            columns={markdownDialogColumns} // TODO: use different columns on tab changes
            options={options}
          />
        </DialogContent>
      </div>
    );
  }, [dialogData, rows, data, sortDirection]);

  const summary = !isEmpty(summaryObject) ? { ...summaryObject, isLive: true } : null;
  return (
    <>
      <BaseViewPage
        id={id}
        title={title}
        storeConfigValue="isPriceChangesEnabled"
        visuals={visuals}
        summary={summary}
        details={{
          title: tableTitle, options: dataTableOptions, columns, rows: data,
        }}
        error={error}
        loading={loading}
        tabs={tabs}
      />
      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        fullScreen={false}
        maxWidth="xl"
        fullWidth
      >
        {dialog}
      </Dialog>
    </>
  );
};

export default PriceChangesPage;

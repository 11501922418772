import React, { useContext } from 'react';
import { array, number, string } from 'prop-types';
import {
  Table, TableHead, TableContainer, Paper, TableRow, TableCell, TableBody,
} from '@mui/material';

import { TranslationsContext } from '../../context/Translations.provider';
import getFormattedPrice from '../../utils/getFormattedPrice';
import './TransfersPage.css';

const PrinterPage = ({
  columns, columnSpan, currencyCode, data, locale,
}) => {
  const { getMessage } = useContext(TranslationsContext);

  const tableHeaders = columns.map(column => (
    (column.options?.display !== 'excluded' && column.options?.display !== false) ? <TableCell className="bold" key={column.label}>{column.label}</TableCell> : null
  ));

  const tableBodies = (item) => (
    columns.map(column => (
      (column.options?.display !== 'excluded' && column.options?.display !== false) ? <TableCell key={`${column.name}-${item[column.name]}`}>{item[column.name]}</TableCell> : null
    ))
  );

  const cartonTableDetailItem = (carton) => {
    const rows = [];

    let firstCartonRow = true;
    let totalItems = 0;
    let totalPrice = 0;
    carton?.styleColors?.forEach((styleColor) => {
      let firstStyleColorRow = true;
      styleColor?.items.forEach((item) => {
        rows.push(
          <TableRow
            key={`${item.gtin}-${styleColor.styleColor}-${carton.cartonNumber}`}
            data-testid="transfers-carton-details-table-row"
            className="nested-rows"
          >
            <TableCell className={!firstCartonRow ? 'no-border' : ''}>
              {firstCartonRow ? carton.cartonNumber : null}
            </TableCell>
            <TableCell className={!firstStyleColorRow ? 'no-border' : ''}>
              {firstStyleColorRow ? styleColor.styleColor : null}
            </TableCell>
            <TableCell className={!firstStyleColorRow ? 'no-border' : ''}>
              {firstStyleColorRow ? styleColor.class : null}
            </TableCell>
            <TableCell className={!firstStyleColorRow ? 'no-border' : ''}>
              {firstStyleColorRow ? styleColor.description : null}
            </TableCell>
            <TableCell>
              {item.size}
            </TableCell>
            <TableCell>
              {item.gtin}
            </TableCell>
            <TableCell>
              {item.quantity}
            </TableCell>
            <TableCell>
              {getFormattedPrice(item?.price, locale, currencyCode)}
            </TableCell>
          </TableRow>,
        );

        totalItems += item.quantity;
        totalPrice += item.price;

        firstCartonRow = false;
        firstStyleColorRow = false;
      });
    });

    rows.push(
      <TableRow className="bottom-row" key={`${carton.cartonNumber}-totals`}>
        <TableCell className="bold">
          {getMessage('totals')}
        </TableCell>
        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell className="bold">
          {totalItems}
        </TableCell>
        <TableCell className="bold">
          {getFormattedPrice(totalPrice, locale, currencyCode)}
        </TableCell>
      </TableRow>,
    );

    return rows;
  };

  const tables = data.map(item => (
    <TableContainer
      component={Paper}
      className="print-table"
      data-testid="transfers-print-table"
      key={`${item.transferId}-container`}
    >
      {item.cartons.map(carton => (
        <Table
          className="print-carton-table"
          key={`print-carton-table-${carton.cartonNumber}`}
        >
          <TableBody>
            <TableRow>
              {tableHeaders}
            </TableRow>
            <tr>
              {tableBodies(item)}
            </tr>
            <tr>
              <td colSpan={columnSpan}>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>{getMessage('cartonID')}</TableCell>
                        <TableCell>{`${getMessage('style')}\xA0\xA0\xA0${getMessage('clr')}`}</TableCell>
                        <TableCell>{getMessage('class')}</TableCell>
                        <TableCell>{getMessage('description')}</TableCell>
                        <TableCell>{getMessage('size')}</TableCell>
                        <TableCell>{getMessage('upc')}</TableCell>
                        <TableCell>{getMessage('units')}</TableCell>
                        <TableCell>{getMessage('price')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {cartonTableDetailItem(carton)}
                    </TableBody>
                  </Table>
                </TableContainer>
              </td>
            </tr>
          </TableBody>
        </Table>
      ))}
    </TableContainer>
  ));

  return (
    <div className="print-source">
      {tables}
    </div>
  );
};

PrinterPage.propTypes = {
  columns: array.isRequired,
  columnSpan: number.isRequired,
  currencyCode: string,
  data: array.isRequired,
  locale: string,
};

PrinterPage.defaultProps = {
  currencyCode: 'USD',
  locale: 'en-US',
};

export default PrinterPage;

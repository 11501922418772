/* eslint-disable no-param-reassign */
import React, { useMemo } from 'react';
import {
  any, array, func, number,
} from 'prop-types';
import {
  FormGroup, FormLabel, TextField, Slider,
} from '@mui/material';

const CustomDisplayComponent = ({
  column, filterList, getMessage, index, onChange, dataList,
}) => {
  const { name } = column;
  const component = useMemo(() => (
    <>
      <FormLabel>{getMessage(name)}</FormLabel>
      <FormGroup style={{ justifyContent: 'center' }} row>
        <TextField
          label="min"
          value={filterList[index][0] || 0}
          onChange={event => {
            filterList[index][0] = event.target.value;
            onChange(filterList[index], index, column);
          }}
          style={{ width: '20%', marginRight: '5%' }}
        />
        <Slider
          value={[+filterList[index][0] || 0, +filterList[index][1] || dataList[dataList.length - 1]]}
          onChange={(event, newValue) => {
            let valueToUse = newValue;
            if (newValue[0] === 0 && newValue[1] === dataList[dataList.length - 1]) valueToUse = [];
            filterList[index] = valueToUse;
            onChange(filterList[index], index, column);
          }}
          min={0}
          max={dataList[dataList.length - 1]}
          valueLabelDisplay="off"
          style={{ width: '50%' }}
        />
        <TextField
          label="max"
          value={filterList[index][1] || dataList[dataList.length - 1]}
          onChange={event => {
            filterList[index][1] = event.target.value;
            onChange(filterList[index], index, column);
          }}
          style={{ width: '20%', marginLeft: '5%', textAlign: 'right' }}
        />
      </FormGroup>
    </>
  ), [filterList]);

  return component;
};

CustomDisplayComponent.propTypes = {
  column: any.isRequired,
  filterList: array.isRequired,
  getMessage: func.isRequired,
  index: number.isRequired,
  onChange: func.isRequired,
  dataList: array.isRequired,
};

const getSumOfRfidSOH = (rfidSoh) => {
  if (rfidSoh === '-') return 0;
  return rfidSoh?.split('/')?.reduce((acc, curr) => (curr ? +acc + +curr : +acc), 0);
};

export default ({ getMessage, sohRange, filterType }) => {
  const name = (filterType === 'rfidSoh') ? 'rfidStock' : 'soh';
  return {
    customFilterListOptions: {
      render: (v) => `${getMessage(name)}: ${v?.[0] || 0} - ${v?.[1] || sohRange[sohRange.length - 1]}`,
      update: (filterList, filterPos, index) => {
        if (filterPos === 0) filterList[index].splice(filterPos, 1, 0);
        else if (filterPos === 1) filterList[index].splice(filterPos, 1, sohRange[sohRange.length - 1]);
        else if (filterPos === -1) filterList[index] = [];
        return filterList;
      },
    },
    filterOptions: {
      logic: (data, filters) => {
        const soh = (filterType === 'rfidSoh') ? getSumOfRfidSOH(data) : data;
        if (filters[0] && filters[1]) return soh < filters[0] || soh > filters[1];
        if (filters[0]) return soh < filters[0];
        if (filters[1]) return soh > filters[1];
        return false;
      },
      display: (filterList, onChange, index, column) => (
        <CustomDisplayComponent
          filterList={filterList}
          onChange={onChange}
          index={index}
          column={column}
          dataList={sohRange}
          getMessage={getMessage}
        />
      ),
    },
  };
};

import React, {
  createContext, useContext, useState, useEffect,
} from 'react';
import { node, object } from 'prop-types';
import { isOutsideDateRange } from '../../utils/isOutsideDateRange';
import { buildBinAuditData } from './binAudits.utils';
import { fetchBinAuditsData } from './binAudits.axios';

import { SimWebContext } from '../../context/SimWeb.provider';

export const binAuditsContext = createContext({});

const BinAuditsProvider = ({ children, mockedValue }) => {
  const { Provider } = binAuditsContext;

  const { store, getMessage, SimDateTime } = useContext(SimWebContext);
  const [isOpen, setOpen] = useState(false);
  const [dialogData, setDialogData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [auditIndex, setAuditIndex] = useState(0);

  const [startDate, setStartDate] = useState(SimDateTime.startOfDay());
  const [endDate, setEndDate] = useState(SimDateTime.endOfDay());

  const maxDate = SimDateTime.endOfDay();
  const isOutsideRange = date => isOutsideDateRange(date, null, maxDate);

  const refreshData = (options) => {
    const { startDate: currentStartDate, endDate: currentEndDate } = options;
    setStartDate(SimDateTime.toDateTime(currentStartDate));
    setEndDate(SimDateTime.toDateTime(currentEndDate));
  };

  const onGoClick = (options) => {
    refreshData(options);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      setData(null);

      try {
        const oosAuditsData = await fetchBinAuditsData(store, startDate, endDate);
        const preparedData = buildBinAuditData(oosAuditsData);

        setData(preparedData);
        setLoading(false);
      } catch (error) {
        setError(error?.message);
        setLoading(false);
      }
    };

    if (store && startDate && endDate) {
      fetchData();
    }
  }, [startDate, endDate, store]);

  return (
    <Provider
      value={mockedValue ?? {
        store,
        startDate,
        endDate,
        isOpen,
        data,
        dialogData,
        auditIndex,
        error,
        getMessage,
        isLoading,
        isOutsideRange,
        setStartDate,
        setEndDate,
        setOpen,
        setDialogData,
        setAuditIndex,
        onGoClick,
        refreshData,
      }}

    >
      {children}
    </Provider>
  );
};

BinAuditsProvider.defaultProps = {
  children: {},
  mockedValue: null,
};

BinAuditsProvider.propTypes = {
  children: node,
  mockedValue: object,
};

export default BinAuditsProvider;

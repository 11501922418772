/* eslint-disable max-len */
import React, { useContext, useRef, useMemo } from 'react';
import {
  array, bool, func, number,
} from 'prop-types';
import { DateTime } from 'luxon';
import { DialogContent, DialogTitle, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PrintIcon from '@mui/icons-material/Print';

import { CSVLink } from 'react-csv';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import IconButton from '@mui/material/IconButton';
// Local Imports
import CustomTable from '../../components/customTable/CustomTable';
import DialogHeader from '../../components/dialogHeader/DialogHeader';
import { DATE_SHORT_WITH_APPENDED_ZEROS } from '../../constants/LocaleFormats';
import { buildDownloadData } from './binAudits.utils';
// context
import { SimWebContext } from '../../context/SimWeb.provider';

import './BinAuditsPage.css';

const useStyles = makeStyles({
  icon: {
    position: 'absolute',
    right: '23px',
    top: '7px',
    fontSize: '37px',
    color: '#757575',
    cursor: 'pointer',
    '&:hover': {
      borderRadius: '50%',
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      color: '#1976d2',
    },
  },
  dialogHeader: {
    fontSize: '1.25rem',
    color: 'black',
    padding: '16px 24px 0px',
    fontWeight: 'bolder',
  },
});

const BinAuditsDialogDetails = ({
  dialogData, setOpen, auditIndex, setAuditIndex, parentRowCount, loading,
}) => {
  const { isIOS, getMessage } = useContext(SimWebContext);
  const classes = useStyles();
  const csvLink = useRef();

  const downloadData = useMemo(() => buildDownloadData(dialogData), [dialogData]);

  if (!dialogData || !dialogData.length) {
    return null;
  }

  const downloadIcon = (
    <>
      <IconButton
        onClick={() => csvLink?.current?.link.click()}
        className={classes.icon}
        size="large"
        data-testid="bin-audits-muiDataTable-download"
      >
        <CloudDownloadIcon />
      </IconButton>
      <CSVLink
        data={downloadData}
        filename="Stockroom Bin Audits"
        ref={csvLink}
        target="_blank"
      />
    </>
  );

  const printIcon = (
    <PrintIcon
      className={classes.icon}
      onClick={() => {
        window.print();
      }}
      style={{ width: '25px' }}
      data-testid="bin-audits-muiDataTable-print"
    />
  );

  const getToolbarIcons = () => (isIOS ? downloadIcon : printIcon);

  return (
    <>
      <DialogHeader
        loading={loading}
        index={auditIndex}
        setIsOpen={setOpen}
        rowCount={parentRowCount}
        setIndex={setAuditIndex}
        nodeTitle={(
          <div className={classes.dialogHeader}>
            {`${DateTime.fromISO(dialogData[0]?.date).toLocaleString(DATE_SHORT_WITH_APPENDED_ZEROS)} 
              - ${dialogData[0].score}%`}
          </div>
        )}
      />
      <Paper elevation={6} style={{ margin: 30 }}>
        <div data-testid="bin-audit-dialog-muiDataTable" style={{ position: 'relative' }}>
          <DialogTitle>
            {getToolbarIcons()}
          </DialogTitle>
          <DialogContent>
            {dialogData?.map(bins => (
              <div className="dropdown-table-detail" key={bins?.key}>
                <div className="tables">
                  {bins?.binsData?.map(bin => (
                    <CustomTable
                      key={bin?.key}
                      columns={[
                        {
                          key: 'bin', class: 'left tight', dataType: 'string', label: getMessage('bin'),
                        },
                        {
                          key: 'passFail', class: 'passFail', dataType: 'passFail', label: getMessage('passFail'),
                        },
                        {
                          key: 'styleColor', class: '', dataType: 'string', label: getMessage('failedStyle'),
                        },
                        {
                          key: 'updated', class: '', dataType: isIOS ? '' : 'node', label: getMessage('updated'),
                        },
                        {
                          key: 'buffer', class: 'last', dataType: 'string', label: '',
                        },
                      ]}
                      data={bin?.styleColors}
                    />
                  ))}
                </div>
              </div>
            ))}
          </DialogContent>
        </div>
      </Paper>
    </>
  );
};

BinAuditsDialogDetails.propTypes = {
  dialogData: array.isRequired,
  setOpen: func.isRequired,
  auditIndex: number.isRequired,
  setAuditIndex: func.isRequired,
  parentRowCount: number,
  loading: bool,
};

BinAuditsDialogDetails.defaultProps = {
  parentRowCount: 0,
  loading: false,
};

export default BinAuditsDialogDetails;

export const sortBySeasonAndYear = (data, index, direction) => {
  if (!data) return [];

  const seasonList = ['FA', 'HO', 'SP', 'SU'];

  const regexp = /(.+)(\d{4})/;

  const sortedValue = [...data];
  return sortedValue.sort((a, b) => {
    if (a?.data?.[index] === '-' && b?.data?.[index] === '-') return 0;
    if (a?.data?.[index] === '-' || b?.data?.[index] === '-') {
      if (direction === 'asc') return (a?.data?.[index] !== '-' && b?.data?.[index] === '-') ? -1 : 1;
      return (a?.data?.[index] === '-' && b?.data?.[index] !== '-') ? 1 : -1;
    }

    const [seasonFirst, yearFirst] = regexp.exec(a?.data?.[index]).slice(1);
    const [seasonSecond, yearSecond] = regexp.exec(b?.data?.[index]).slice(1);

    if (direction === 'asc') return +yearFirst - +yearSecond || seasonList.indexOf(seasonFirst) - seasonList.indexOf(seasonSecond);
    return +yearSecond - +yearFirst || seasonList.indexOf(seasonSecond) - seasonList.indexOf(seasonFirst);
  });
};

export const sortBySeason = (data, index, direction) => {
  if (!data) return [];

  const seasonOrder = ['FA', 'HO', 'SP', 'SU'];

  const sortedValue = [...data];
  return sortedValue.sort((a, b) => {
    if (a?.data?.[index] === '-' && b?.data?.[index] === '-') return 0;
    if (a?.data?.[index] === '-' || b?.data?.[index] === '-') {
      if (direction === 'asc') return (a?.data?.[index] !== '-' && b?.data?.[index] === '-') ? -1 : 1;
      return (a?.data?.[index] === '-' && b?.data?.[index] !== '-') ? 1 : -1;
    }

    if (direction === 'asc') return seasonOrder.indexOf(a?.data?.[index]) - seasonOrder.indexOf(b?.data?.[index]);
    return seasonOrder.indexOf(b?.data?.[index]) - seasonOrder.indexOf(a?.data?.[index]);
  });
};

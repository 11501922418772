import React, {
  useState, useEffect, createContext, useContext,
} from 'react';
import { node, object } from 'prop-types';
import { baseUrl, routerUrls, endpoints } from '../axios/endpoints';
import { LoginContext } from './Login.provider';
import { TranslationsContext } from './Translations.provider';
import { getLanguageFlag } from '../assets/flags/index';
import regionMap from '../constants/region-map.json';
import geoMap from '../constants/geo-map.json';
import { generalAxiosRequest } from '../axios/axiosFunctions';
import { newRelicAttribute, logErrorAction } from '../utils/newRelicPageActions';

export const StoreViewsContext = createContext({});

const StoreViewsProvider = ({ children, mockedValue }) => {
  const { Provider } = StoreViewsContext;

  const { isLoggedIn, storeNumber, country } = useContext(LoginContext);
  const { setLanguage } = useContext(TranslationsContext);

  const [store, setStore] = useState('');
  const [storeInfo, setStoreInfo] = useState(null);
  const [isStoreInfoLoaded, setIsStoreInfoLoaded] = useState(false);
  const [flag, setFlag] = useState('');
  const currentPath = window.location.href;

  const resetState = () => {
    setStore('');
    setStoreInfo(null);
    setFlag('');
  };

  // used for new relic logging - need an additioanl call by id in order to obtain the district data point
  useEffect(() => {
    const getStoreInfo = async () => {
      const url = `${baseUrl(routerUrls.NIKE_API)}${endpoints.STORE_VIEWS.url}/${storeInfo?.id}`;
      const headers = { 'nike-api-caller-id': 'com.nike:retail.sim' };
      const storeData = await generalAxiosRequest('GET', url, endpoints.STORE_VIEWS, false, headers);
      if (storeData) {
        if (storeData?.businessConcept) newRelicAttribute('store-concept', storeData.businessConcept);
        if (storeData?.name) newRelicAttribute('store-name', storeData.name);
        if (storeData?.storeNumber) newRelicAttribute('store-number', storeData?.storeNumber);
        if (storeData?.address?.city) newRelicAttribute('store-city', storeData.address?.city);
        if (storeData?.address?.country) newRelicAttribute('store-country', storeData.address?.country);
        if (storeData?.region) newRelicAttribute('store-region', storeData.region);
        if (storeData?.district) newRelicAttribute('store-district', storeData.district.code);
        if (storeData.address?.country && storeData?.storeNumber) newRelicAttribute('store-country-number', `${storeData.address?.country}-${storeData?.storeNumber}`);
        if (storeData?.region) newRelicAttribute('geo', geoMap[storeData?.region]);
      }
      return storeData;
    };
    if (storeInfo?.id) {
      getStoreInfo();
    }
  }, [storeInfo?.id]);

  useEffect(() => {
    setIsStoreInfoLoaded(false);
    let region;

    const fetchStoreInfo = async () => {
      const url = `${baseUrl(routerUrls.NIKE_API)}${endpoints.STORE_VIEWS.url}`
        + `?search=(storeNumber==(${storeNumber}) and (storeStatus==OPEN or storeStatus==UNOPENED`
        + ` or storeStatus==CLOSED) and address.country==(${country}))`;
      const headers = { 'nike-api-caller-id': 'com.nike:retail.sim' };

      try {
        const storeInfoResponse = await generalAxiosRequest('GET', url, endpoints.STORE_VIEWS, false, headers);
        const storeInfo = storeInfoResponse?.objects?.[0];

        region = storeInfo?.region;
        storeInfo.isNorthAmerica = !region ? true : region === 'NORTH_AMERICA'; // defaults to NA if region is missing in store views.
        storeInfo.isEMEA = region && region === 'EUROPE_MIDDLE_EAST_AFRICA';
        storeInfo.isGreaterChina = region && region === 'GREATER_CHINA';
        storeInfo.isAPLA = region && region === 'ASIA_PACIFIC_LATIN_AMERICA';

        setStoreInfo(storeInfo);
        setStore(`${country}-${storeNumber}`);

        global.SIM_REGION = regionMap[country] || 'us-east-1';
        const flagImage = getLanguageFlag(country);
        setFlag(flagImage);
        setIsStoreInfoLoaded(true);
      } catch (error) {
        logErrorAction('storeViews', error, { operationName: url });
      }
    };

    if (storeNumber && country) {
      fetchStoreInfo();
    }
  }, [storeNumber, country]);

  // This useEffect is to set the language based on the store the user is trying to log in as
  // that way, if a different language is selected in the dropdown after login, it won't be overwritten.
  useEffect(() => {
    if (currentPath.includes('/login') && storeInfo?.defaultLanguage) {
      setLanguage(storeInfo.defaultLanguage);
      sessionStorage.setItem('language', storeInfo.defaultLanguage);
    }
  }, [storeInfo, storeNumber]);

  useEffect(() => {
    if (!isLoggedIn) resetState();
  }, [isLoggedIn]);

  return (
    <Provider
      value={mockedValue ?? {
        flag,
        store,
        country,
        storeId: storeInfo?.id,
        storeNumber,
        storeName: storeInfo?.name,
        storeInfo,
        isStoreInfoLoaded,
        setStoreInfo,
      }}
    >
      {children}
    </Provider>
  );
};

StoreViewsProvider.defaultProps = {
  children: {},
  mockedValue: null,
};

StoreViewsProvider.propTypes = {
  children: node,
  mockedValue: object,
};

export default StoreViewsProvider;

import React, { useContext } from 'react';
import {
  func, string, object, bool, node,
} from 'prop-types';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import InfoTooltip from '../InfoTooltip';
import SearchTooltipContent from './SearchTooltipContent';

import { TranslationsContext } from '../../context/Translations.provider';
import { newRelicAction } from '../../utils/newRelicPageActions';

import './Search.css';
/**
 * Search input for filtering the Size Compliance report.
 * handles loading state, making the gql query and reseting the page when unmounted
 * @param {string} type - the name of the parent search screen, i.e. scan sizeCompliance
 * @param {function} searchText - gets the state's input textQuery value for input only
 * @param {function} setSearchTextValue - sets the state's input' textQuery value
 * @param {function} setPageSearchTextValue - sets the states' textQuery value used in gql calls
 */

export const Search = ({
  type,
  searchTooltipContents,
  searchText,
  setSearchTextValue,
  setPageSearchTextValue,
  showTooltip,
  style,
  customTooltip,
  resetFacets,
}) => {
  const { getMessage } = useContext(TranslationsContext);

  const getPlaceholder = {
    'inbound-visibility': getMessage('inboundVisibilitySearchPlaceholder'),
    'scan-report': getMessage('searchStyleColorUpc'),
    'size-compliance': getMessage('searchStyleColorUpc'),
  };

  const onClick = () => {
    newRelicAction(`${type}-search`, { query: searchText });
    setPageSearchTextValue(searchText);
    if (typeof resetFacets === 'function') {
      resetFacets({});
    }
  };

  const clear = () => {
    setSearchTextValue('');
    setPageSearchTextValue('');
    if (typeof resetFacets === 'function') {
      resetFacets({});
    }
  };

  const searchOnEnter = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      onClick();
    }
  };

  return (
    <div data-testid={`${type}-dynamic-search`} className="dynamic-search-container" style={style}>
      {!showTooltip && <div style={{ height: '43px' }} />}
      {(showTooltip && !customTooltip) && (
        <div className="label" data-testid={`${type}-dynamic-search-tooltip`}>
          {type !== 'inbound-visibility' ? getMessage('styleColorOrUpc') : ''}
          <InfoTooltip
            title={`${getMessage('searchExamples')}:`}
            content={<SearchTooltipContent contents={searchTooltipContents} />}
            disableFocusListener
            disableTouchListener
            placement="right"
          />
        </div>
      )}
      {showTooltip && customTooltip}
      <Input
        placeholder={getPlaceholder[type]}
        endAdornment={(
          <InputAdornment position="end">
            <IconButton
              data-testid={`${type}-dynamic-search-clear-button`}
              style={{ visibility: searchText ? 'visible' : 'hidden', height: '30px', width: '30px' }}
              onClick={() => clear()}
              size="large"
            >
              <ClearIcon />
            </IconButton>
            <IconButton
              data-testid={`${type}-dynamic-search-button`}
              style={{ height: '30px', width: '30px' }}
              onClick={() => onClick()}
              size="large"
            >
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        )}
        onChange={(e) => { setSearchTextValue(e.target.value); }}
        onKeyDown={(e) => { searchOnEnter(e); }}
        value={searchText}
      />
    </div>
  );
};

Search.defaultProps = {
  type: '',
  searchText: '',
  showTooltip: true,
  style: null,
  customTooltip: null,
  resetFacets: null,
};

Search.propTypes = {
  type: string,
  searchTooltipContents: object.isRequired,
  searchText: string,
  setSearchTextValue: func.isRequired,
  setPageSearchTextValue: func.isRequired,
  showTooltip: bool,
  style: object,
  customTooltip: node,
  resetFacets: func,
};

export default Search;

/* eslint-disable import/no-named-default */
import React from 'react';
import { camelCase } from 'lodash';

// Components
import AdjustmentsProvider from './containers/adjustments/Adjustments.provider';
import AdjustmentsPage from './containers/adjustments/AdjustmentsPage';
import AgedOrdersPage from './containers/agedOrders/AgedOrders';
import AgedOrdersProvider from './containers/agedOrders/AgedOrders.provider';
import AdminPage from './containers/admin/AdminPage';
import AdminProvider from './containers/admin/Admin.provider';
import ASNTrackingProvider from './containers/asnReport/ASNTracking.provider';
import ASNTrackingReport from './containers/asnReport/ASNTrackingReport';
import BinAuditsPage from './containers/binAudits/BinAuditsPage';
import BinAuditsProvider from './containers/binAudits/BinAudits.provider';
import DashboardPage from './containers/dashboardPage/DashboardPage';
import DashboardProvider from './containers/dashboardPage/Dashboard.provider';

import InboundVisibilityProvider from './containers/inboundVisibility/InboundVisibility.provider';
import InboundVisibilityTableProvider from './containers/inboundVisibility/InboundVisiblityTable.provider';
import InboundVisibilityPurchaseOrderDialogProvider from './containers/inboundVisibility/dialog/InboundVisibilityPurchaseOrderDialog.provider';
import InboundVisibilityPage from './containers/inboundVisibility/InboundVisibilityPage';
import ItemActivityProvider from './containers/itemActivity/ItemActivity.provider';
import ItemActivityPage from './containers/itemActivity/ItemActivityPage';
import MissingStylesProvider from './containers/missingStyles/MissingStyles.provider';
import MissingStylesPage from './containers/missingStyles/MissingStylesPage';
import OOSAuditProvider from './containers/oosAuditReport/OOSAudits.provider';
import OOSAuditsPage from './containers/oosAuditReport/OOSAuditsPage';
import PhysicalInventoryUploadProvider from './containers/physicalInventoryUpload/PhysicalInventoryUpload.provider';
import PhysicalInventoryUploadPage from './containers/physicalInventoryUpload/PhysicalInventoryUploadPage';
import PriceChangesProvider from './containers/priceChanges/PriceChanges.provider';
import PriceChangesPage from './containers/priceChanges/PriceChangesPage';
import { default as ProductMovementPage } from './containers/productMovement/ProductMovementPage';
import { default as ProductMovementProvider } from './containers/productMovement/ProductMovement.provider';

import ProductRefillProvider from './containers/productRefill/ProductRefill.provider';
import ProductRefillPage from './containers/productRefill/ProductRefillPage';
import ReceivingLogProvider from './containers/receivingLog/ReceivingLog.provider';
import ReceivingLogPage from './containers/receivingLog/ReceivingLogPage';

import ScanReportSummaryProvider from './containers/scans/summary/ScanReportSummary.provider';
import ScanReportSummary from './containers/scans/summary/ScanReportSummary';

import DisplayScan from './containers/scans/display/DisplayScan';
import DisplayScanProvider from './containers/scans/display/DisplayScan.provider';

import ScanDetails from './containers/scans/details/ScanDetails';
import ScanDetailsProvider from './containers/scans/details/ScanDetails.provider';
import ScanDetailsV2 from './containers/scans/detailsV2/ScanDetailsPage';
import ScanDetailsV2Provider from './containers/scans/detailsV2/ScanDetails.provider';

import SizeComplianceProvider from './containers/sizeCompliance/SizeCompliance.provider';
import SizeCompliancePage from './containers/sizeCompliance/SizeCompliancePage';
import StorePerformanceProvider from './containers/storePerformance/StorePerformance.provider';
import StorePerformancePage from './containers/storePerformance/StorePerformancePage';
import StockOnHandProvider from './containers/stockOnHand/StockOnHand.provider';
import StockOnHandPage from './containers/stockOnHand/StockOnHandPage';
import StockroomOrganizationPage from './containers/stockroomOrganization/StockroomOrganizationPage';
import StockroomOrganizationProvider from './containers/stockroomOrganization/StockroomOrganization.provider';
import TransfersProvider from './containers/transfers/Transfers.provider';
import TransfersPage from './containers/transfers/TransfersPage';
import StyleManagementProvider from './containers/styleManagement/StyleManagement.provider';
import StyleManagementPage from './containers/styleManagement/StyleManagementPage';
import StyleManagementDataProvider from './containers/styleManagement/StyleManagementData.provider';
import CPAProvider from './context/cpa/CPA.provider';

/**
 * This file is to wrap all pages with their required providers.
 * If an order needs to be forced, be sure to add an object to the providers
 * array with the provider and the order it needs to be in.
 */

const pageAndProviderList = {
  adjustments: {
    providers: [AdjustmentsProvider],
    page: AdjustmentsPage,
  },
  admin: {
    providers: [AdminProvider],
    page: AdminPage,
  },
  agedOrders: {
    providers: [AgedOrdersProvider],
    page: AgedOrdersPage,
  },
  asnTracking: {
    providers: [ASNTrackingProvider],
    page: ASNTrackingReport,
  },
  binAudit: {
    providers: [BinAuditsProvider],
    page: BinAuditsPage,
  },
  dashboard: {
    providers: [DashboardProvider, PriceChangesProvider],
    page: DashboardPage,
  },
  displayScan: {
    providers: [{
      provider: CPAProvider,
      order: 2,
    }, {
      provider: DisplayScanProvider,
      order: 1,
    }],
    page: DisplayScan,
  },
  inboundVisibility: {
    providers: [{
      provider: InboundVisibilityProvider,
      order: 3,
    }, {
      provider: InboundVisibilityTableProvider,
      order: 2,
    }, {
      provider: InboundVisibilityPurchaseOrderDialogProvider,
      order: 1,
    }],
    page: InboundVisibilityPage,
  },
  itemActivity: {
    providers: [ItemActivityProvider],
    page: ItemActivityPage,
  },
  missingStyles: {
    providers: [MissingStylesProvider],
    page: MissingStylesPage,
  },
  oosAudit: {
    providers: [OOSAuditProvider],
    page: OOSAuditsPage,
  },
  physicalInventoryUpload: {
    providers: [StockOnHandProvider, PhysicalInventoryUploadProvider],
    page: PhysicalInventoryUploadPage,
  },
  priceChanges: {
    providers: [PriceChangesProvider],
    page: PriceChangesPage,
  },
  productMovement: {
    providers: [
      {
        provider: ProductMovementProvider,
        order: 1,
      },
      {
        provider: CPAProvider,
        order: 2,
      },
    ],
    page: ProductMovementPage,
  },

  productRefill: {
    providers: [ProductRefillProvider],
    page: ProductRefillPage,
  },
  receivingLog: {
    providers: [ReceivingLogProvider],
    page: ReceivingLogPage,
  },
  scan: {
    providers: [{
      provider: CPAProvider,
      order: 2,
    }, {
      provider: ScanDetailsProvider,
      order: 1,
    }],
    page: ScanDetails,
  },
  scanDetails: {
    providers: [{
      provider: CPAProvider,
      order: 2,
    }, {
      provider: ScanDetailsV2Provider,
      order: 1,
    }],
    page: ScanDetailsV2,
  },
  scanReportSummary: {
    providers: [ScanReportSummaryProvider],
    page: ScanReportSummary,
  },
  sizeCompliance: {
    providers: [SizeComplianceProvider],
    page: SizeCompliancePage,
  },
  stockOnHand: {
    providers: [StockOnHandProvider],
    page: StockOnHandPage,
  },
  storePerformance: {
    providers: [StorePerformanceProvider],
    page: StorePerformancePage,
  },
  stockroomOrganization: {
    providers: [{
      provider: CPAProvider,
      order: 2,
    }, {
      provider: StockroomOrganizationProvider,
      order: 1,
    }],
    page: StockroomOrganizationPage,
  },
  styleManagement: {
    providers: [{
      provider: CPAProvider,
      order: 3,
    }, {
      provider: StyleManagementDataProvider,
      order: 2,
    }, {
      provider: StyleManagementProvider,
      order: 1,
    }],
    page: StyleManagementPage,
  },
  transfers: {
    providers: [TransfersProvider],
    page: TransfersPage,
  },
};

/**
 * Wraps multiple providers around a child component.
 * @param {function} commponents: array of providers
 * @returns wrapped providers
 */
export const PageProviders = ({ components = [], children = <></> }) => {
  if (components[0].order) {
    // eslint-disable-next-line no-param-reassign
    components = components.sort((a, b) => b.order - a.order).map(comp => comp.provider);
  }
  // eslint-disable-next-line arrow-body-style
  return components.reverse().reduce((child, Component) => {
    return <Component>{child}</Component>;
  }, children);
};

/**
 * Takes the name of a given page and renders it with proper providers.
 * @param {string} pageName name of the page to be wrapped with its providers
 * @returns a wrapped page with all of its proper providers in order to operate
 */
const wrappedPages = (pageName) => {
  const component = pageAndProviderList[camelCase(pageName)];

  return (
    <PageProviders components={component.providers}>
      <component.page />
    </PageProviders>
  );
};

export default wrappedPages;

/* eslint-disable max-len */
import React, { useContext } from 'react';
import DeviceAssignmentAdapter from './deviceAssignment.axios';
import AthletesDevices from './AthletesDevices';
import { getAccessToken } from '../../context/Login.provider';
import { SimWebContext } from '../../context/SimWeb.provider';
import useAuthorization from '../../components/authorizer/Authorizer';

const daAdapter = new DeviceAssignmentAdapter();

const SelfCheckoutDeviceAssignment = () => {
  const {
    country, storeId, storeName, storeNumber, getMessage,
  } = useContext(SimWebContext);

  const title = 'selfCheckoutTitle';
  const { isAuthorized, unauthorizedView: UnauthorizedView } = useAuthorization(title, 'selfCheckoutEnabled', 40);

  if (!isAuthorized) {
    return UnauthorizedView;
  }

  return (
    <div className="flex-column" data-testid="selfcheckoutdeviceassignment">
      <div>
        <p className="feature-title">{getMessage('selfCheckoutTitle')}</p>
        <AthletesDevices
          auth={getAccessToken()}
          country={country}
          daAdapter={daAdapter}
          storeId={storeId}
          storeName={storeName}
          storeNumber={storeNumber}
        />
      </div>
    </div>
  );
};

export default SelfCheckoutDeviceAssignment;

/* eslint-disable max-len */
import React, { useContext } from 'react';
import BaseViewPage from '../baseView/BaseViewPage';
import { AgedOrdersContext } from './AgedOrders.provider';
import './AgedOrders.css';
import '../../components/dashboardWidgets/agedOrdersWidget/AgedOrdersWidget.css';
import AgedOrdersWidget from '../../components/dashboardWidgets/agedOrdersWidget/AgedOrdersWidget';

/**
 * Page component for aged orders report
 */
const AgedOrdersPage = () => {
  const {
    id, title, loading, options, columns, rows, dialog,
  } = useContext(AgedOrdersContext);

  return (
    <BaseViewPage
      id={id}
      title={title}
      storeConfigValue="isAgedOrdersEnabled"
      loading={loading}
      visuals={<AgedOrdersWidget addTitle={false} isReportWidget />}
      dialog={dialog}
      details={{ options, columns, rows }}
    />
  );
};

export default AgedOrdersPage;

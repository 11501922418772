/**
  * Helper func that assigns the accurate search type and param
  * depending on the value pased in when the user selects the GO btn
  * @param {string | object} searchValue input value scanned/entered in input field
  * @param {function} getProducts handles setting variables for CPA endpoint
*/
// eslint-disable-next-line import/prefer-default-export
export const onGoClick = (
  searchValue,
  getProducts,
  startTutorial,
) => {
  if (!startTutorial) {
    const val = typeof searchValue === 'string' ? searchValue : searchValue.text;
    getProducts(val, true);
  }
};
/**
  * Helper func for onGoClick function on enter keyboard events
  * depending on the value pased in when the user selects the GO btn
  * @param {event} e event obj
  * @param {string | object} searchValue input value scanned/entered in input field
  * @param {function} getProducts handles setting variables for CPA endpoint
*/
// eslint-disable-next-line import/prefer-default-export
export const onEnterPressed = (
  e,
  searchValue,
  getProducts,
) => {
  if (e.charCode === 13) {
    onGoClick(
      searchValue,
      getProducts,
    );
  }
};

/* eslint-disable max-len */
import React, {
  useMemo, useContext,
} from 'react';

import { func, string } from 'prop-types';
import Toggle from './Toggle';
import { SimWebContext } from '../../context/SimWeb.provider';

export const getToggles = (getMessage) => ({
  gender: {
    name: 'genderAge',
    label: getMessage('gender'),
    aggregatedBy: 'genderAge',
  },
  category: {
    name: 'retailCategory',
    label: getMessage('category'),
    aggregatedBy: 'retailCategory',
  },
});

export const getAggregatedBy = (toggles, selected) => Object.values(toggles).find(_ => _.aggregatedBy === selected)?.aggregatedBy ?? 'genderAge';

const GenderCategoryToggle = ({ selected, setSelected }) => {
  const { getMessage } = useContext(SimWebContext);
  const toggles = getToggles(getMessage);

  const getToggle = useMemo(() => (
    <Toggle
      hasSeveralToggles
      toggleLabelsArray={Object.values(toggles)}
      selected={selected}
      setSelected={value => { setSelected(value); }}
      toggleByLabel
      toggleDirection="vertical"
    />
  ), [selected, toggles]);

  return (
    <>{getToggle}</>
  );
};

GenderCategoryToggle.propTypes = {
  selected: string.isRequired,
  setSelected: func.isRequired,
};

export default GenderCategoryToggle;

/* eslint-disable max-len */
import React, {
  useState, createContext, useContext, useEffect,
} from 'react';
import { node, object } from 'prop-types';
import { SimWebContext } from '../../context/SimWeb.provider';
import { getItemActivityData } from './itemActivity.axios';
import { setSearchValueAndType } from '../../context/cpa/cpa.utils';
import { isOutsideDateRange } from '../../utils/isOutsideDateRange';

export const itemActivityContext = createContext({});

const ItemActivityProvider = ({ children, mockedValue }) => {
  const { Provider } = itemActivityContext;

  const {
    store, storeId, country, SimDateTime,
  } = useContext(SimWebContext);

  const [modalStyles, setModalStyles] = useState('');
  const [searchStyle, setSearchStyle] = useState('');

  const startOfDay = SimDateTime.startOfDay();
  const endOfDay = SimDateTime.endOfDay();

  const [startDateCurrent, setStartDate] = useState(startOfDay);
  const [endDateCurrent, setEndDate] = useState(endOfDay);

  const [isOpen, setOpen] = useState(false);
  const [index, setIndex] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const [hiddenColumns, setHiddenColumns] = useState({
    postVoidSale: true,
    postVoidReturn: true,
  });
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    setData(null);
    setError(null);
    setLoading(true);

    const getProductData = async () => {
      const originDateAfterEq = SimDateTime.startOfDay(startDateCurrent).toISO();
      const originDateBeforeEq = SimDateTime.endOfDay(endDateCurrent).toISO();
      const { searchValue, searchType } = setSearchValueAndType(searchStyle);

      try {
        const itemActivityData = await getItemActivityData(storeId, searchValue, searchType, originDateAfterEq, originDateBeforeEq);

        setData(itemActivityData);
      } catch (error) {
        setError(error?.message);
      }

      setLoading(false);
    };

    if (store && country) getProductData();
  }, [store, country, startDateCurrent, endDateCurrent, storeId, searchStyle]);

  const onSearchChange = (val) => {
    if (!val) return;

    setSearchStyle(val);
  };

  const onEnterSearch = (val) => {
    onSearchChange(val);
  };

  const onClickPressed = (gtinSearch, options) => {
    onSearchChange(gtinSearch?.current?.value);
    setStartDate(SimDateTime.toDateTime(options.startDate));
    setEndDate(SimDateTime.toDateTime(options.endDate));
  };

  const handleModalOpen = () => {
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  const maxDate = SimDateTime.endOfDay();
  const isOutsideRange = date => isOutsideDateRange(date, null, maxDate);

  return (
    <Provider
      value={mockedValue ?? {
        startDateCurrent,
        endDateCurrent,
        loading,
        data,
        error,
        hiddenColumns,
        index,
        isOpen,
        modalStyles,
        rowCount,
        searchStyle,
        store,
        handleModalClose,
        handleModalOpen,
        isOutsideRange,
        onClickPressed,
        onEnterSearch,
        onSearchChange,
        setHiddenColumns,
        setIndex,
        setModalStyles,
        setRowCount,
      }}
    >
      {children}
    </Provider>
  );
};

ItemActivityProvider.propTypes = {
  children: node,
  mockedValue: object,
};

ItemActivityProvider.defaultProps = {
  children: {},
  mockedValue: null,
};

export default ItemActivityProvider;

import { isEmpty } from 'lodash';
import { routerUrls, endpoints, baseUrl } from '../../axios/endpoints';
import { generalAxiosRequest } from '../../axios/axiosFunctions';
import { logErrorAction } from '../../utils/newRelicPageActions';

/**
 * Fetch Inbound Visibility Product Shipments data
 * @param {object} payload - the data payload
 */
export const fetchInboundVisibilityProductShipmentsData = async (payload) => {
  if (!payload || isEmpty(payload)) return null;

  const url = `${baseUrl(routerUrls.SIMWEB_BFF)}${endpoints.INBOUND_VISIBILITY.url}product_shipments`;

  const data = await generalAxiosRequest('POST', url, endpoints.INBOUND_VISIBILITY, true, null, payload)
    .then(resp => resp)
    .catch(error => {
      logErrorAction('InboundVisibilityProductShipmentsError', error, 'error retrieving data', {
        message: error?.message, payload,
      });
      throw error;
    });

  return data;
};

/**
 * Fetch Inbound Visibility Sales Orders data
 * @param {object} payload - the data payload
 */
export const fetchInboundVisibilityOnOrderData = async (payload) => {
  if (!payload || isEmpty(payload)) return null;

  const url = `${baseUrl(routerUrls.SIMWEB_BFF)}${endpoints.INBOUND_VISIBILITY.url}sales_orders`;

  const data = await generalAxiosRequest('POST', url, endpoints.INBOUND_VISIBILITY, true, null, payload)
    .then(resp => resp)
    .catch(error => {
      logErrorAction('InboundVisibilitySalesOrdersError', error, 'error retrieving data', {
        message: error?.message, payload,
      });
      throw error;
    });

  return data;
};

import React, { useContext, useMemo } from 'react';
import {
  array, string, bool, object,
} from 'prop-types';
import { ErrorOutline } from '@mui/icons-material';
import { LoadingIndicator } from '../../../assets/Svgs';
import DonutChart from './DonutChart';
import { LiveBadge } from '../../liveBadge/LiveBadge';
import Legend from '../legend/Legend';
import { getLegendData, chartTransform } from '../visualSummaries.utils';

import { SimWebContext } from '../../../context/SimWeb.provider';

import './DonutChartWithLegend.css';

const DonutChartWithLegend = ({
  aggregatedBy, categories, centerTextLabel, data, divisions, error, id,
  isLive, loading, stopAnimation, subDescriptionTitle, toggle,
}) => {
  const { getMessage } = useContext(SimWebContext);

  const transform = chartTransform(data, categories, divisions, getMessage);

  const buildDivisionGraph = (divisions) => divisions.map(division => {
    const data = transform[aggregatedBy][division].items;
    const { centerValue } = transform[aggregatedBy][division];

    return (
      <>
        <DonutChart
          key={`${id}-visuals-${division}-${Math.random()}`}
          chartType="visibility"
          data={data}
          title={getMessage(division)}
          legendEnabled={false}
          centerTextLabel={centerTextLabel}
          centerValue={centerValue}
          displayCenterText
          width="200px"
          stopAnimation={stopAnimation}
          selectedToggle={aggregatedBy}
        />
      </>
    );
  });

  const legend = useMemo(() => {
    const legend = getLegendData(transform, aggregatedBy);
    return <Legend legend={legend} />;
  }, [transform, aggregatedBy]);

  const chart = useMemo(() => {
    if (loading) {
      return (
        <div className="visual-summary-loading" data-testid="visual-summary-loading">
          <LoadingIndicator width="65px" height="65px" fill="#FFFFFF" />
        </div>
      );
    }

    if (error) {
      return (
        <div className="visual-summary-no-data" data-testid="visual-summary-no-data">
          <ErrorOutline color="error" fontSize="large" />
          <br />
          <p>{getMessage('noUnitError')}</p>
        </div>
      );
    }

    if (!transform?.[aggregatedBy]) return {};

    const divisions = Object.keys(transform[aggregatedBy]);

    return (
      <>
        {isLive && <LiveBadge />}
        {subDescriptionTitle && (
          <div className="header-graph-sub-decription-title" data-testid={`${id}-sub-title`}>
            {subDescriptionTitle}
          </div>
        )}
        <div className="header-data-graphs" data-testid={`${id}-visual`}>
          {buildDivisionGraph(divisions)}
        </div>
        <div className="header-data-graphs" data-testid={`${id}-legend`}>
          {legend}
        </div>

      </>
    );
  }, [id, loading, error, transform, aggregatedBy]);

  return (
    <div className="header-visuals" data-testid="donut-chart-legend-container">
      <div className="header-graph">
        <div className="header-graph-table">
          {chart}
        </div>
      </div>
      <div className="header-toggle">
        {toggle}
      </div>
    </div>
  );
};

DonutChartWithLegend.propTypes = {
  aggregatedBy: string.isRequired,
  categories: array,
  centerTextLabel: string,
  data: array.isRequired,
  divisions: array,
  error: bool,
  id: string.isRequired,
  isLive: bool,
  loading: bool,
  stopAnimation: bool,
  subDescriptionTitle: string,
  toggle: object,
};

DonutChartWithLegend.defaultProps = {
  categories: ['genderAge', 'retailCategory'],
  centerTextLabel: '',
  divisions: ['apparel', 'equipment', 'footwear'],
  error: false,
  isLive: false,
  loading: false,
  stopAnimation: false,
  subDescriptionTitle: '',
  toggle: null,
};

export default DonutChartWithLegend;

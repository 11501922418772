import React from 'react';

// eslint-disable-next-line import/prefer-default-export
export const SalesFloor = (
  <svg version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink" width="25" height="25" viewBox="0 0 484.145 484.145" xmlSpace="preserve">
    <g>
      <path d="M315.765,197.28h-68.534c-5.799,0-10.5,4.701-10.5,10.5c0,5.799,4.701,10.5,10.5,10.5h68.534c5.799,0,10.5-4.701,10.5-10.5
        C326.265,201.981,321.564,197.28,315.765,197.28z"
      />
      <path d="M328.337,86.911h-43.293c-5.799,0-10.5,4.701-10.5,10.5c0,1.602-0.083,3.185-0.212,4.754h-48.384V88.53
        c19.388-4.727,33.821-22.242,33.821-43.065c0-24.439-19.882-44.322-44.321-44.322c-24.439,0-44.322,19.883-44.322,44.322
        c0,5.799,4.701,10.5,10.5,10.5c5.799,0,10.5-4.701,10.5-10.5c0-12.86,10.462-23.322,23.322-23.322
        c12.859,0,23.321,10.462,23.321,23.322c0,12.859-10.462,23.321-23.321,23.321c-5.799,0-10.5,4.701-10.5,10.5v22.879h-45.288
        c-0.129-1.569-0.212-3.152-0.212-4.754c0-5.799-4.701-10.5-10.5-10.5H30.936C13.878,86.911,0,100.789,0,117.848v80.724
        c0,17.058,13.878,30.936,30.936,30.936H63.16v181.6c0,16.434,13.37,29.805,29.805,29.806h29.708v12.282
        c0,16.434,13.37,29.805,29.806,29.806h247.23c16.435,0,29.806-13.371,29.806-29.806v-181.6h23.694
        c17.058,0,30.936-13.878,30.936-30.936v-80.724c0-17.059-13.878-30.936-30.936-30.936H435.68v-11.152
        c0-17.059-13.878-30.937-30.936-30.937h-46.69c3.687-6.521,5.737-13.991,5.737-21.84c0-24.44-19.882-44.322-44.321-44.322
        c-24.439,0-44.322,19.883-44.322,44.322c0,5.799,4.701,10.5,10.5,10.5s10.5-4.701,10.5-10.5c0-12.86,10.462-23.322,23.322-23.322
        c12.859,0,23.321,10.462,23.321,23.322C342.79,73.957,338.043,83.43,328.337,86.911z M268.436,123.165
        c-9.468,18.833-28.964,31.794-51.441,31.794c-22.477,0-41.973-12.96-51.441-31.794H268.436z M84.16,411.107v-192.1
        c0-5.799-4.701-10.5-10.5-10.5H30.936c-5.479,0-9.936-4.458-9.936-9.936v-80.724c0-5.479,4.458-9.937,9.936-9.937h108.209
        c5.149,38.364,38.099,68.048,77.85,68.048c39.751,0,72.7-29.685,77.85-68.048h109.898c5.479,0,9.936,4.458,9.936,9.937v80.724
        c0,5.479-4.457,9.936-9.936,9.936h-44.412c-5.799,0-10.5,4.701-10.5,10.5v192.1c0,4.855-3.951,8.806-8.806,8.806H92.966
        C88.11,419.913,84.16,415.963,84.16,411.107z M463.145,240.66c0,5.479-4.457,9.936-9.936,9.936h-34.194
        c-5.799,0-10.5,4.701-10.5,10.5v192.1c0,4.855-3.95,8.806-8.806,8.806H152.48c-4.856-0.001-8.807-3.951-8.807-8.806v-12.282
        h197.352c16.435,0,29.806-13.371,29.806-29.806v-181.6h33.912c17.059,0,30.936-13.878,30.936-30.936v-48.572h17.529
        c5.479,0,9.936,4.458,9.936,9.936V240.66z"
      />
    </g>
  </svg>
);

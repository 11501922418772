import _ from 'lodash';

export function getSegmentedSOH(segmentation, isOffsiteEnabled) {
  if (!segmentation) return '';

  const { salesFloorQuantity, stockRoomQuantity, offSiteQuantity } = { ...segmentation };
  const segmeents = [salesFloorQuantity, stockRoomQuantity];
  if (isOffsiteEnabled) segmeents.push(offSiteQuantity);
  return segmeents.join(' / ');
}

/**
 * Given a stock object return either the physical or fiscal stock quantity depending on if
 * rfid is enabled, returns - if unable to get quantity
 * @param {object} stockOnHand - the object containing the stock info
 * @param {boolean} rfid - if true return physical stock, else return fiscal stock
 */
export default (stockOnHand, rfid = true, isOffsiteEnabled) => {
  if (!stockOnHand) return '-';
  const segmentation = stockOnHand?.physicalStock?.segmentation;

  if (rfid && !segmentation) {
    return stockOnHand?.physicalStock?.quantity?.toString() ?? '-';
  }

  if (rfid) return getSegmentedSOH(segmentation, isOffsiteEnabled);
  return _.get(stockOnHand, 'fiscalStock.quantity', '-');
};

/* eslint-disable */
import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
/**
  * A Function that forces a scroll to the top of the page when the route/history changes
  * @param {Object} history the history object
  * @return nothing
  * its listening to the history object and if the path changes it calls
  * the widow object to force a scroll to the top of the page
*/

function ScrollToTop({ history }) {
  useEffect(() => {
    const unlisten = history?.listen(() => {
      window?.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, [history]);

  return null;
}

export default withRouter(ScrollToTop);

import React, { useContext } from 'react';
import { Alert, Typography } from '@mui/material';
import { SimWebContext } from '../../context/SimWeb.provider';
import env from '../../environment';

export const isAuthorizedToView = (storeConfig, storeConfigValue, isAdmin, nikeStoreRoles, minRole) => {
  if (env.isTest()) {
    return true;
  }

  if (storeConfig?.[storeConfigValue]?.value && (nikeStoreRoles && minRole ? nikeStoreRoles?.maxValue >= minRole : true)) {
    return true;
  }

  return isAdmin;
};
/**
 * Custom hook for authorization.
 *
 * @param {string} title - The title of the component.
 * @param {string} storeConfigValue - The value used for store configuration.
 * @returns {Object} - An object containing the authorization status and unauthorized view.
 */
const useAuthorization = (title, storeConfigValue, minRole) => {
  const {
    isAdmin, storeConfig, getMessage, formatMessage, nikeStoreRoles,
  } = useContext(SimWebContext);

  const isAuthorized = isAuthorizedToView(storeConfig, storeConfigValue, isAdmin, nikeStoreRoles, minRole);

  const unauthorizedView = isAuthorized ? null : (
    <Alert severity="warning" data-testid={`${title?.replace(' ', '-')?.toLocaleLowerCase()}-unauthorized`}>
      <Typography variant="h6" align="center">
        {formatMessage('unauthorizedToViewErrorMessage', { page: getMessage(title) })}
        { minRole && <span>{` Roles ${minRole} and above are required for this page.`}</span>}
      </Typography>
    </Alert>
  );

  return { isAuthorized, unauthorizedView };
};

export default useAuthorization;

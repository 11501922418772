import React from 'react';
import {
  bool, element, func, string,
} from 'prop-types';
import classNames from 'classnames';

import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';

/**
 * A search facet, that has a checkbox, a label for the checkbox and an
 * optional aggregation component can be provided
 * @param {function} handleChange - function to handle when a checkbox is clicked,
 *                                  needs to take a facet and group as parameters
 * @param {string} name - will be the label for the checkbox
 * @param {selected} bool - the checked state of the checkbox
 * @param {Component} aggregation - a react component can be provided to give the user additional info
 * @param {boolean} hidden - if true, then facet will not be visable
 * @param {string} group - the facet group this facet belongs to
 * @param {string} nameKey - the unlocalized version of the name
 * @param {string} translatedName - the translated header for the facet
 */
const Facet = ({
  handleChange, name, selected, aggregation, hidden, group, nameKey, translatedName, onClick,
}) => {
  const onChange = () => {
    handleChange(nameKey, group);
  };
  const facetClasses = classNames('facet', { hide: hidden });
  const labelClasses = classNames('facet-label', { 'facet-label-selected': selected });
  return (
    <div className={facetClasses} data-testid={`facet-${name}`}>
      <div className="facet-checkbox-wrapper">
        {group === 'edd' ? (
          <Radio
            data-testid={`facet-input-${name}`}
            value={translatedName}
            checked={selected}
            onChange={onChange}
            color="default"
            onClick={onClick}
            style={{ padding: 0, color: '#000000' }}
          />
        ) : (
          <Checkbox
            className="facet-checkbox"
            data-testid={`facet-input-${name}`}
            type="checkbox"
            checked={selected}
            onChange={onChange}
            color="default"
            style={{ padding: 0, color: '#000000' }}
          />
        )}
        <label className={labelClasses}>{translatedName}</label>
      </div>
      {aggregation}
    </div>
  );
};

Facet.defaultProps = {
  aggregation: null,
  hidden: true,
  onClick: () => {},
};

Facet.propTypes = {
  handleChange: func.isRequired,
  name: string.isRequired,
  selected: bool.isRequired,
  aggregation: element,
  hidden: bool,
  group: string.isRequired,
  nameKey: string.isRequired,
  translatedName: string.isRequired,
  onClick: func,
};

export default Facet;

import env from '../environment';

const REQUEST_ACCESS_TOKEN = 'requestAccessToken';
const SET_TITLE = 'setTitle';
const LOGOUT = 'logout';
const CLOSE = 'close';
const GET_URL = 'getUrl';
const ADDRESS_BAR = 'addressbar';
const SEND_STORE = 'sendStore';

const RETAIL_AUTH = 'retail';
const OKTA_AUTH = 'okta';

export const Messages = {
  RequestAccessToken: REQUEST_ACCESS_TOKEN,
  SetTitle: SET_TITLE,
  Logout: LOGOUT,
  Close: CLOSE,
  GetURL: GET_URL,
  Addressbar: ADDRESS_BAR,
  SendStore: SEND_STORE,
};

export const AuthTypes = {
  RetailAuth: RETAIL_AUTH,
  OktaAuth: OKTA_AUTH,
};

export const getTargetOrigin = () => {
  // run simweb first, then the retail launchpad
  // the url of the Retail Launchpad
  let targetOrigin = ['http://localhost:3000', 'http://localhost:3001'];

  const buildMode = process.env.REACT_APP_BUILD_MODE || 'none';

  if (env.isLocalHost()) {
    return targetOrigin;
  }

  if (buildMode === 'test') {
    targetOrigin = ['https://true-test.nike.com', 'https://rtl-lchpad-2.rbh-retail-launchpad-test.nikecloud.com'];
  }

  if (buildMode === 'prod') {
    targetOrigin = ['https://true.nike.com', 'https://rtl-lchpad-2.rbh-retail-launchpad-prod.nikecloud.com'];
  }

  return targetOrigin;
};

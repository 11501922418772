/* eslint-disable */
export enum EItemInputTypes {
  InputTypeStyle,
  InputTypeStyleColor,
  InputTypeGtin,
  InputTypeInvalid,
  InputTypeNone,
}

export default (input: string) => {
  if (typeof input !== 'string') {
    return EItemInputTypes.InputTypeInvalid;
  }

  if (!input.trim().length) {
    return EItemInputTypes.InputTypeNone;
  }

  // see https://regex101.com/r/QTMxvk/1 for a breakdown of what this regex does
  if (/^[0-9]{10,}$/g.test(input)) {
    return EItemInputTypes.InputTypeGtin;
  }

  if (input.includes('-')) {
    return EItemInputTypes.InputTypeStyleColor;
  }

  return EItemInputTypes.InputTypeStyle;
};

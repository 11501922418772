import React, { useContext } from 'react';
import { bool, string } from 'prop-types';
import { SimWebContext } from '../../../context/SimWeb.provider';
import DashboardWidgetContainer from '../../../components/dashboardWidgets/DashboardWidgetContainer';
import {
  ScanAcuracy, ScanTimer, SizeCompliance, StyleCompliance, ScanCompliance,
} from './index';

const RfidWidget = ({ isRfidDataEnabled, rfidFullStoreScanDate }) => {
  const { getMessage } = useContext(SimWebContext);

  return (isRfidDataEnabled
    && (
      <DashboardWidgetContainer title={getMessage('rfid')} testid="dashboard_rfidWidget">
        <ScanTimer />
        <ScanAcuracy />
        <ScanCompliance rfidFullStoreScanDate={rfidFullStoreScanDate} />
        <div className="rfid-flex-complianceWidgets">
          <StyleCompliance />
          <SizeCompliance />
        </div>
      </DashboardWidgetContainer>
    )
  );
};

RfidWidget.propTypes = {
  isRfidDataEnabled: bool,
  rfidFullStoreScanDate: string,
};

RfidWidget.defaultProps = {
  isRfidDataEnabled: false,
  rfidFullStoreScanDate: '',
};

export default RfidWidget;

/* eslint-disable max-len */

// add isNavItemEnabled, if other checks are needed other than just storeConfig
// example isNavItemEnabled: (storeConfig, storeInfo, child) => (storeConfig?.selfCheckoutEnabled?.value && !storeInfo.isNorthAmerica ? child : null),

const navFeatures = {
  stockRoom: {
    i18nKey: 'stockRoom',
    childItems: [
      {
        i18nKey: 'itemInquiry',
        slug: '/itemInquiry',
        featureSet: 'mvp',
        storeConfigKey: 'isItemInquiryEnabled',
      },
      {
        i18nKey: 'itemActivity',
        slug: '/itemActivity',
        featureSet: 'mvp',
        storeConfigKey: 'isItemActivityEnabled',
      },
      {
        i18nKey: 'stockOnHand',
        slug: '/StockOnHand',
        featureSet: 'mvpPlus',
        storeConfigKey: 'sohEnabled',
      },
      {
        i18nKey: 'stockroomOrganization',
        slug: '/stockroomOrganization',
        featureSet: 'mvp',
      },
    ],
  },
  shipping: {
    i18nKey: 'shipping',
    featureSet: 'phaseTwo',
    childItems: [
      {
        i18nKey: 'inboundVisibility',
        slug: '/inboundVisibility',
        storeConfigKey: 'isInboundVisibilityEnabled',
      },
      {
        i18nKey: 'asnTrackingReport',
        slug: '/asnTracking',
        storeConfigKey: 'isASNTrackingEnabled',
      },
      {
        i18nKey: 'receivingLog',
        slug: '/ReceivingLog',
      },
      {
        i18nKey: 'transfers',
        slug: '/transfers',
      },
      {
        i18nKey: 'agedOrders',
        slug: '/agedOrders',
        badge: 'New!',
        badgeColor: 'success',
        storeConfigKey: 'isAgedOrdersEnabled',
      },
    ],
  },
  tasks: {
    i18nKey: 'audits',
    featureSet: 'mvpPlus',
    childItems: [
      {
        i18nKey: 'productRefill',
        slug: '/productRefill',
        featureSet: 'mvp',
      },
      {
        i18nKey: 'stockroomBinAudits',
        slug: '/stockroomBin',
      },
      {
        i18nKey: 'oosAudits',
        slug: '/OOSAudits',
      },
      {
        i18nKey: 'adjustments',
        slug: '/adjustments',
        minimumRoleLevel: 30,
        featureSet: 'phaseTwo',
      },
      {
        i18nKey: 'storePerformance',
        slug: '/UPHReport',
        featureSet: 'mvp',
        minimumRoleLevel: 40,
        storeConfigKey: 'isStorePerformanceEnabled',
      },
      {
        i18nKey: 'deviceAssignment',
        slug: '/selfCheckoutDeviceAssignment',
        minimumRoleLevel: 40,
        storeConfigKey: 'selfCheckoutEnabled',
      },
      {
        i18nKey: 'piUpload',
        slug: '/physicalInventoryUpload',
        minimumRoleLevel: 40,
        storeConfigKey: 'isPIUploadEnabled',
      },
      {
        i18nKey: 'priceChange',
        slug: '/priceChanges',
        storeConfigKey: 'isPriceChangesEnabled',
      },
    ],
  },
  rfid: {
    i18nKey: 'rfidReports',
    storeConfigKey: 'rfidEnabled',
    childItems: [
      {
        i18nKey: 'scanReport',
        slug: '/scanSummary',
      },
      {
        i18nKey: 'productMovement',
        slug: '/ProductMovement',
      },
      {
        i18nKey: 'missingStylesReport',
        slug: '/RFIDMissingScan',
      },
      {
        i18nKey: 'sizeCompliance',
        slug: '/sizeCompliance',
        storeConfigKey: 'sizeComplianceEnabled',
      },
      {
        i18nKey: 'styleManagement',
        slug: '/styleManagement',
        badge: 'New!',
        badgeColor: 'success',
        storeConfigKey: 'isStyleManagementEnabled',
      },
    ],
  },
};

export default navFeatures;

/**
 * Get percentage in localized string
 * @param {string} locale the locale for a given user
 * @param {number} numberValue the value in number
 */
export default (locale, numberValue) => {
  if (numberValue === null || numberValue === undefined) return '-';
  if (numberValue === '-') return numberValue;

  let value = numberValue ? numberValue / 100 : 0;

  // eslint-disable-next-line no-restricted-globals
  if (isNaN(value)) value = numberValue;

  return value.toLocaleString(locale, { style: 'percent', minimumFractionDigits: 0 });
};

import React, { useContext, useMemo } from 'react';
import Error from '../../components/alerts/Error';
import Tutorial from '../../components/tutorial/Tutorial';
import SizeComplianceFacets from './facets/SizeComplianceFacets';
import SizeComplianceTable from './summaryTable/SizeComplianceTable';
import { LoadingIndicator } from '../../assets/Svgs';
import useAuthorization from '../../components/authorizer/Authorizer';

import { SimWebContext } from '../../context/SimWeb.provider';
import { SizeComplianceContext } from './SizeCompliance.provider';
import { tutorialContext } from '../../context/Tutorial.provider';

import './SizeComplianceReport.css';

/**
 * The main page for Size Compliance Report
 */
const SizeCompliancePage = () => {
  const { getMessage } = useContext(SimWebContext);
  const {
    loading, error, refetch, data, isInitialCall,
  } = useContext(SizeComplianceContext);

  const {
    stepIndex, setStepIndex, startTutorial, handleStartTutorial,
  } = useContext(tutorialContext);

  const title = 'sizeCompliance';
  const { isAuthorized, unauthorizedView: UnauthorizedView } = useAuthorization(title, 'sizeComplianceEnabled');

  const content = useMemo(() => {
    if (loading && isInitialCall) return <div data-testid="loadingSizeComplianceData" className="sizeCompliance-loading-spinner"><LoadingIndicator /></div>;

    if (error) {
      return (
        <Error
          apiName="Size Compliance API"
          errorObject={error}
          pageCode=""
        />
      );
    }

    return <SizeComplianceTable />;
  }, [loading, refetch, error, data]);

  if (!isAuthorized) {
    return UnauthorizedView;
  }

  return (
    <div className="size-compliance-container" data-testid="sizeComplianceReport">
      <div className="size-compliance-body">
        <div className="size-compliance-facets">
          <div className="feature-title" id="scan-report-title">
            {getMessage(title)}
            <Tutorial
              setTutorialState={handleStartTutorial}
              startTutorial={startTutorial}
              setStepIndex={setStepIndex}
              stepIndex={stepIndex}
              disabled={loading && isInitialCall}
            />
          </div>
          <SizeComplianceFacets />
        </div>
        <div className="size-compliance-table">
          {content}
        </div>
      </div>
    </div>
  );
};

export default SizeCompliancePage;

import React, { useState, createContext } from 'react';
import { node, object } from 'prop-types';

export const ErrorContext = createContext({});

/**
  * An Error Provider that handles errors within the application and displaying them on
  * the appropriate page with the accurate messaging
  *
  * @param {node} children - data that we iterate on and create data visuals
*/
const ErrorProvider = ({ children, mockedValue }) => {
  const { Provider } = ErrorContext;

  const [redirectError, setRedirectError] = useState(null);
  const [versionError, setVersionError] = useState(null);

  return (
    <Provider
      value={mockedValue ?? {
        versionError,
        redirectError,
        setVersionError,
        setRedirectError,
      }}
    >
      {children}
    </Provider>
  );
};

ErrorProvider.defaultProps = {
  children: {},
  mockedValue: null,
};

ErrorProvider.propTypes = {
  children: node,
  mockedValue: object,
};

export default ErrorProvider;

import React, { useState } from 'react';
import { string, any, element } from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { Info } from '../assets/Svgs/index';

const HtmlTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#292929',
    color: '#DEDEDE',
    maxWidth: 600,
    lineHeight: '16px',
    fontWeight: 500,
    fontSize: 12,
    border: '1px solid black',
    padding: '20px',
    whiteSpace: 'pre-line',
  },
}))(Tooltip);

const InfoTooltip = ({
  title,
  content,
  placement,
  extraClasses,
  customIcon,
}) => {
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  return (
    <div className={extraClasses} data-testid="info-tooltip">
      <HtmlTooltip
        open={tooltipIsOpen}
        onOpen={() => setTooltipIsOpen(true)}
        onClose={() => setTooltipIsOpen(false)}
        onClick={() => setTooltipIsOpen(!tooltipIsOpen)}
        placement={placement}
        title={(
          <>
            <Typography color="inherit" sx={{ paddingBottom: '13px', fontSize: '1.2em' }}>
              {title}
            </Typography>
            {content}
          </>
        )}
      >
        <div>
          {customIcon}
        </div>
      </HtmlTooltip>
    </div>
  );
};

InfoTooltip.propTypes = {
  title: string,
  content: any,
  placement: string,
  extraClasses: string,
  customIcon: element,
};

InfoTooltip.defaultProps = {
  title: '',
  content: null,
  placement: 'top',
  extraClasses: '',
  customIcon: (<Info width="15" height="15" />),
};

export default InfoTooltip;

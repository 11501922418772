/* eslint-disable object-curly-newline */
/* eslint-disable indent */
/* eslint-disable max-len */
const PIUploadStatus = {
    PAGE_LOAD: { name: 'PAGE_LOAD', severity: 'success', bodegaKey: 'fileUploadStatus' },
    DELTA_CALC_COMPLETED: { name: 'DELTA_CALC_COMPLETED', title: 'Variance Calculation Completed', severity: 'success', bodegaKey: 'deltaCompleted' },
    DELTA_CALC_FAILED: { name: 'DELTA_CALC_FAILED', title: 'Variance Calculation Failed', severity: 'error', bodegaKey: 'deltaFailed' },
    DELTA_CALC_IN_PROGRESS: { name: 'DELTA_CALC_IN_PROGRESS', title: 'Variance Calculation in Progress', severity: 'warning', bodegaKey: 'calcInProgress' },
    // Duplicate error message from server
    DUPLICATE_FILE_UPLOAD: { name: 'DUPLICATE_FILE_UPLOAD', title: 'It looks like you have already uploaded this file. Fetching last successful variance report.', severity: 'warning', bodegaKey: 'duplicateFile' },
    // Duplicate error message from UI, when doing multiple file uploads
    DUPLICATE_FILE_UPLOADING: { name: 'DUPLICATE_FILE_UPLOADING', title: 'This file has already been uploaded. Upload a new inventory file or click Submit or Cancel.', severity: 'warning', bodegaKey: 'duplicateFileUploading' },
    ERROR: { name: 'ERROR', title: 'Error', severity: 'error', bodegaKey: 'fatalError' },
    FETCHING_LAST_VARIANCE: { name: 'FETCHING_LAST_VARIANCE', title: 'Fetching Variance Report', severity: 'warning', bodegaKey: 'getVarianceReport' },
    FETCHING_UPLOAD_STATUS: { name: 'FETCHING_UPLOAD_STATUS', title: 'Fetching Current File Upload Status', severity: 'warning', bodegaKey: 'fileUploadStatus' },
    FILE_UPLOAD_COMPLETED: { name: 'FILE_UPLOAD_COMPLETED', title: 'File Upload Completed', severity: 'success', bodegaKey: 'uploadCompleted' },
    FILE_UPLOAD_FAILED: { name: 'FILE_UPLOAD_FAILED', title: 'File Upload Failed', severity: 'error', bodegaKey: 'uploadFailed' },
    FILE_UPLOAD_IN_PROGRESS: { name: 'FILE_UPLOAD_IN_PROGRESS', title: 'File Upload in Progress', severity: 'warning', bodegaKey: 'fileUploadInProgress' },
    FILES_SUBMITTED: { name: 'FILES_SUBMITTED', title: 'Files Submitted', severity: 'warning', bodegaKey: 'filesSubmitted' },
    HISTORICAL_REPORT: { name: 'HISTORICAL_REPORT', title: 'Fetching Historical Variance Report', severity: 'warning', bodegaKey: 'historicalVariance' },
    HISTORICAL_REPORT_LOADED: { name: 'HISTORICAL_REPORT_LOADED', title: 'Historical Variance Report Loaded', severity: 'success', bodegaKey: 'historicalVarianceLoaded' },
    SESSION_CANCELED: { name: 'SESSION_CANCELED', title: 'Upload Canceled', severity: 'warning', bodegaKey: 'uploadCanceled' },
    SESSION_CANCELING: { name: 'SESSION_CANCELING', title: 'Session Canceling', severity: 'warning', bodegaKey: 'sessionCanceling' },
    SOH_IN_PROGRESS: { name: 'SOH_IN_PROGRESS', title: 'Generating Stock On Hand Report', severity: 'warning', bodegaKey: 'sohInProgress' },
    SOH_SUMMARY_COMPLETED: { name: 'SOH_SUMMARY_COMPLETED', title: 'Stock On Hand Summary Completed', severity: 'success', bodegaKey: 'sohSummaryCompleted' },
    SOH_DETAILS_COMPLETED: { name: 'SOH_DETAILS_COMPLETED', title: 'Stock On Hand Details Completed', severity: 'success', bodegaKey: 'sohDetailsCompleted' },
    STOCK_UPDATED: { name: 'STOCK_UPDATED', title: 'PI Upload Completed: Stock Updated', severity: 'success', bodegaKey: 'submitCompleted' },
    STOCK_UPDATE_ERROR: { name: 'STOCK_UPDATE_ERROR', title: 'Submit or Canceled Failed. Please re-try your last action.', severity: 'warning', bodegaKey: 'stockUpdateError' },
    SUCCESS: { name: 'SUCCESS', title: 'Success!', severity: 'success', bodegaKey: 'submitCompleted' },
    UNKNOWN: { name: 'UNKNOWN', title: 'Unknown', severity: 'warning', bodegaKey: 'unknown' },
    isFileUploadComplete: (uploadStatus) => uploadStatus === PIUploadStatus.FILE_UPLOAD_COMPLETED.name,
    isFileUploadInProgress: (uploadStatus) => uploadStatus === PIUploadStatus.FILE_UPLOAD_IN_PROGRESS.name,
    isFileUploadFailed: (uploadStatus) => uploadStatus === PIUploadStatus.FILE_UPLOAD_FAILED.name,
    isDuplicateFile: (uploadStatus) => uploadStatus === PIUploadStatus.DUPLICATE_FILE_UPLOAD.name,
    isSessionCanceled: (uploadStatus) => uploadStatus === PIUploadStatus.SESSION_CANCELED.name,
    isFetchingHistoricalReport: (uploadStatus) => uploadStatus === PIUploadStatus.HISTORICAL_REPORT.name,
    isFileSubmitted: (uploadStatus) => uploadStatus === PIUploadStatus.FILES_SUBMITTED.name,
    isDeltaCalcInProgress: (uploadStatus) => uploadStatus === PIUploadStatus.DELTA_CALC_IN_PROGRESS.name,
    isStockUpdateFailed: (uploadStatus) => uploadStatus === PIUploadStatus.STOCK_UPDATE_ERROR.name,
    isDeltaCalculationFailed: (uploadStatus) => uploadStatus === PIUploadStatus.DELTA_CALC_FAILED.name,
    isFileCalculated: (uploadStatus) => uploadStatus === PIUploadStatus.DELTA_CALC_COMPLETED.name || uploadStatus === PIUploadStatus.STOCK_UPDATED.name,
    isInProgress: (uploadStatus) => PIUploadStatus.isFileSubmitted(uploadStatus) || PIUploadStatus.isDeltaCalcInProgress(uploadStatus),
    isSuccessful: (uploadStatus) => uploadStatus === PIUploadStatus.SUCCESS.name,
    isPageLoad: (uploadStatus) => uploadStatus === PIUploadStatus.PAGE_LOAD.name,
    isFetchingUploadStatus: (uploadStatus) => uploadStatus === PIUploadStatus.FETCHING_UPLOAD_STATUS.name,
    isFetchingVarianceReport: (uploadStatus) => uploadStatus === PIUploadStatus.FETCHING_LAST_VARIANCE.name,
    getUploadStatusKeyString: (uploadStatus) => PIUploadStatus[uploadStatus]?.bodegaKey ?? uploadStatus,
  };

  export default PIUploadStatus;

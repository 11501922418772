import React from 'react';
import { string } from 'prop-types';

// eslint-disable-next-line import/prefer-default-export
export const NewStyle = ({ extraClasses }) => (
  <div className={`${extraClasses}`}>
    <svg width="24" height="24" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(0,48) scale(0.100000,-0.100000)" fill="currentColor" stroke="currentColor">
        <path d="M205 420 c-23 -24 -37 -30 -72 -30 l-43 0 0 -42 c0 -34 -6 -49 -32 -75 l-32 -33 32 -33 c26 -26 32 -41 32 -75 l0 -42 42 0 c34 0 49 -6 75 -32 l33 -32 33 32 c26 26 41 32 75 32 l42 0 0 42 c0 34 6 49 32 75 l32 33 -32 33 c-26 26 -32 41 -32 75 l0 42 -43 0 c-35 0 -49 6 -72 30 -16 17 -32 30 -35 30 -3 0 -19 -13 -35 -30z m59 -35 c13 -20 25 -25 56 -25 40 0 40 0 40 -39 0 -31 6 -44 28 -60 l27 -21 -27 -21 c-22 -16 -28 -29 -28 -60 0 -39 0 -39 -39 -39 -31 0 -44 -6 -60 -28 l-21 -27 -21 27 c-16 22 -29 28 -60 28 -39 0 -39 0 -39 39 0 31 -6 44 -27 60 l-28 21 28 21 c21 16 27 29 27 60 0 39 0 39 40 39 31 0 43 5 56 25 9 14 20 25 24 25 4 0 15 -11 24 -25z" />
        <path d="M255 260 l-39 -40 -23 22 c-18 17 -26 19 -35 10 -10 -10 -5 -19 20 -44 l32 -32 54 53 c49 48 61 71 37 71 -4 0 -24 -18 -46 -40z" />
      </g>
    </svg>
  </div>
);

NewStyle.propTypes = {
  extraClasses: string,
};

NewStyle.defaultProps = {
  extraClasses: '',
};

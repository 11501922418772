/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
import React from 'react';
import { SimDateTime } from '../../../../../utils/datetime';
import { getLocalizedNumber } from '../../../utils';
import { scanAggregationColors } from '../../../scanReportConstants';

/**
 * Returns aggregation values into an object with color codes
 * @param {object} values an object of scan aggregation values
 */
export const getAggregationText = (values) => (
  Object.keys(values).map(key => {
    let name = '';

    switch (key) {
      case 'scanned':
      case 'salesFloor':
        name = `${values[key]} / `;
        break;
      case 'expected':
        name = `${values[key]}`;
        break;
      case 'stockRoom':
        name = `${values[key]}`;
        break;
      case 'offsite':
        name = ` / ${values[key]}`;
        break;
      default:
        break;
    }

    if (name) {
      return {
        name,
        color: scanAggregationColors[key.toUpperCase()],
      };
    }
  }).filter(values => values !== undefined)
);

/**
 * Get the aggregation values and return with color coldes
 * @param {array} aggregations a list of scan aggregations
 * @param {string} locale the language tag
 */
export const getAggregations = (aggregations, locale) => {
  const values = {
    scanned: getLocalizedNumber(locale, aggregations?.scanned),
    expected: getLocalizedNumber(locale, aggregations?.expected),
  };

  return getAggregationText(values);
};

/**
 * A helper function to returns the scan aggregations
 * @param {array} aggregations the scan aggregations data
 * @param {string} locale the language tag
 */
export const getScanAggregations = (aggregations, locale) => {
  const additionalText = getAggregations(aggregations, locale);

  return (
    additionalText.map(aggregate => (
      <span style={{ color: aggregate.color }} key={aggregate.color}>
        {aggregate.name}
      </span>
    ))
  );
};

/**
 * Get the aggregation values by scan location and return with color codes
 * @param {array} aggregations a list of scan aggregations by location
 * @param {string} locale the language tag
 * @param {boolean} isOffsiteEnabled is offsite enabled in the store
 */
export const getLocationAggregations = (aggregations, locale, isOffsiteEnabled) => {
  const salesFloor = aggregations?.salesFloor;
  const stockRoom = aggregations?.stockRoom;
  const offsite = aggregations?.offsite;

  const values = {
    salesFloor: salesFloor ? getLocalizedNumber(locale, salesFloor) : '-',
    stockRoom: stockRoom ? getLocalizedNumber(locale, stockRoom) : '-',
    ...(isOffsiteEnabled && {
      offsite: offsite ? getLocalizedNumber(locale, offsite) : '-',
    }),
  };

  return getAggregationText(values);
};

/**
 * A helper function to returns the scan aggregations by locations
 * @param {object} scanData the selected scan session data
 * @param {string} locale the language tag
 * @param {boolean} isOffsiteEnabled is offsite enabled in the store
 */
export const getScanLocationAggregations = (scanData, locale, isOffsiteEnabled) => {
  const additionalText = getLocationAggregations(scanData, locale, isOffsiteEnabled);

  return (
    additionalText.map(aggregate => (
      <span style={{ color: aggregate.color }} key={aggregate.color}>
        {aggregate.name}
      </span>
    ))
  );
};

/**
 * A helper function to get the scan time range from scan history data
 * @param {string} scanStartDate the scan start datetime
 * @param {string} scanEndDate the scan end datetime
 */
export const getScanTimeRange = (scanStartDate, scanEndDate) => {
  if (!scanStartDate) return '-';

  const startTime = SimDateTime.toLocaleString(SimDateTime.toDateTime(scanStartDate), SimDateTime.TIME_WITH_SECONDS);

  if (!scanEndDate) {
    const now = SimDateTime.toLocaleString(SimDateTime.now(), SimDateTime.TIME_WITH_SECONDS);
    return `${startTime} - ${now}`;
  }

  const submittedTime = SimDateTime.toLocaleString(SimDateTime.toDateTime(scanEndDate), SimDateTime.TIME_WITH_SECONDS);
  return `${startTime} - ${submittedTime}`;
};

/**
 * A helper function to get the scan duration time from session data and return in translated string
 * @param {number} duration the scan duration time in minutes
 * @param {function} getMessage a function to get translated message
 */
export const getScanDuration = (duration, getMessage) => `${duration || '-'} ${getMessage('minutes')}`;

import React from 'react';
import {
  bool, func, object, oneOfType, string,
} from 'prop-types';
import { Chip, Grid, Typography } from '@mui/material';
import classNames from 'classnames';
import ColorChips from '../colorChips/ColorChips';
import ProductDetailRfidToggle from './ProductDetailsRfidToggle';
import ProductImageCarousel from '../ProductImageCarousel';
import getFormattedPrice from '../../utils/getFormattedPrice';

const ProductInformation = ({
  rfidEnabled, sohTypeSelected, product, productPrice, locale, currencyCode, startTutorial, setSohTypeSelected, getMessage,
}) => {
  const {
    sizes, bins, productCode, content, prices,
  } = product || {};
  return (
    <Grid item xs={4} data-testid="productDetails.rfidToggle">
      <div className="flex-column" style={{ justifyContent: 'space-between' }}>
        <div style={{ margin: '10px 0' }}>
          <ProductDetailRfidToggle
            rfidEnabled={rfidEnabled}
            sizes={sizes}
            sohTypeSelected={sohTypeSelected}
            setSohTypeSelected={setSohTypeSelected}
          />
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{ margin: '10px 20px 10px 0' }}>
            <Typography gutterBottom variant="h5">
              {getMessage('bins')}
            </Typography>
            {bins?.length >= 1 ? bins.map(
              bin => (
                <Chip
                  key={bin}
                  label={bin}
                  style={{ margin: 2 }}
                />
              ),
            ) : getMessage('none')}
          </div>
          <ColorChips productContent={content || {}} />
        </div>
        <Grid item xs={8} style={{ margin: '20px' }}>
          <ProductImageCarousel
            imageSet={content?.imageSet}
            noImageLabel={getMessage('noImage')}
          />
        </Grid>
        <Typography component="div" variant="h5">
          <div>
            {`${getMessage('style-color')}: ${productCode ?? ''}`}
          </div>
          {(typeof productPrice === 'object')
            ? (
              <div className="productCard_itemPrice">
                {getMessage('priceHistory:')}
                {productPrice}
              </div>
            ) : (
              <>
                <p>{getMessage('priceHistory:')}</p>
                <div className={classNames('flex-row', 'productCard_item_price_details')}>
                  <span>
                    <p className="productCard_current-price">
                      {`${productPrice}`}
                    </p>
                    <p>{getMessage('currentPrice')}</p>
                  </span>
                  <span>
                    <p className="productCard_msrp-price">
                      {`${getFormattedPrice(prices?.msrp, locale, currencyCode, startTutorial)}`}
                    </p>
                    <p>{getMessage('msrp')}</p>
                  </span>
                </div>
              </>
            )}
        </Typography>
      </div>
    </Grid>
  );
};

ProductInformation.propTypes = {
  rfidEnabled: bool,
  sohTypeSelected: string.isRequired,
  product: object,
  productPrice: oneOfType([object, string]).isRequired,
  locale: string,
  currencyCode: string,
  startTutorial: bool,
  setSohTypeSelected: func.isRequired,
  getMessage: func.isRequired,
};

ProductInformation.defaultProps = {
  rfidEnabled: false,
  product: {},
  locale: 'en-US',
  currencyCode: 'USD',
  startTutorial: false,
};

export default ProductInformation;

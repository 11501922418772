import React, { useContext } from 'react';
import { object, string } from 'prop-types';
import { DateTime } from 'luxon';
import { TableRow, TableCell } from '@mui/material';
import { DATE_TIME_SHORT_WITH_APPENDED_ZEROS } from '../../constants/LocaleFormats';
import { TranslationsContext } from '../../context/Translations.provider';
import { SimWebContext } from '../../context/SimWeb.provider';

const ReceivingLogTableDetailItem = ({ data, reportType }) => {
  const { getMessage } = useContext(TranslationsContext);
  const { storeInfo } = useContext(SimWebContext);
  const rows = [];

  let totalCartons = 0;
  let totalFailedCartons = 0;
  let totalUnitsExpected = 0;
  let totalUnitsReceived = 0;
  let totalVariance = 0;

  data?.purchaseOrders?.forEach((purchaseOrder) => {
    let firstPORow = true;
    purchaseOrder?.cartons?.forEach((carton) => {
      let firstCartonRow = true;
      totalCartons += 1;
      let failed = false;
      carton?.cartonItems.forEach((item) => {
        if (item.variance < 0) {
          failed = true;
        }

        rows.push(
          <TableRow
            key={`${item.gtin}${carton.cartonNumber}${purchaseOrder.purchaseOrderNumber}-${Math.random()}`}
            className="nested-rows"
          >
            <TableCell>
              { firstPORow ? purchaseOrder.purchaseOrderNumber : null }
            </TableCell>
            <TableCell>
              { storeInfo?.isNorthAmerica && firstCartonRow ? carton?.deliveryNumbers?.join(',') : null }
            </TableCell>
            <TableCell className={`${failed ? 'bold fail' : ''}`}>
              { firstCartonRow ? `${carton.cartonNumber}${failed ? '*' : ''}` : null }
            </TableCell>
            {reportType === 'FULL' ? (
              <TableCell>
                {
                  firstCartonRow
                  // if this is the first row of the carton, show the receiveDate, if it exists. Show "-" otherwise.
                    ? (carton.receiveDate && DateTime.fromISO(carton.receiveDate).toLocaleString(DATE_TIME_SHORT_WITH_APPENDED_ZEROS)) || '-'
                  // if this is not the first row of the carton, this <TableCell> should be empty.
                    : ''
                }
              </TableCell>
            ) : null}
            <TableCell>
              { item.gtin }
            </TableCell>
            <TableCell>
              { item.unitsExpected }
            </TableCell>
            <TableCell>
              { item.unitsReceived }
            </TableCell>
            <TableCell className={`${item.variance < 0 ? 'number fail' : ''}`}>
              { Math.abs(item.variance) }
            </TableCell>
            <TableCell>
              { item.stockOnHand }
            </TableCell>
          </TableRow>,
        );

        totalUnitsExpected += item.unitsExpected;
        totalUnitsReceived += item.unitsReceived;
        totalVariance += item.variance;

        firstPORow = false;
        firstCartonRow = false;
      });

      if (failed) {
        totalFailedCartons += 1;
      }
    });
  });

  rows.push(
    <TableRow className="bottom-row" key={`${data.proNumber || data.deliveryNumber}totals`}>
      <TableCell>
        {getMessage('totals')}
      </TableCell>
      <TableCell className={`${totalFailedCartons > 0 && totalFailedCartons === totalCartons ? 'fail' : ''} bold`}>
        {
          totalFailedCartons === totalCartons
            ? totalFailedCartons
            : `${totalCartons - totalFailedCartons} / ${totalCartons}`
        }
      </TableCell>
      {reportType === 'FULL' ? <TableCell /> : null}
      <TableCell />
      <TableCell className="bold">
        { totalUnitsExpected }
      </TableCell>
      <TableCell className="bold">
        { totalUnitsReceived }
      </TableCell>
      <TableCell className={`${totalVariance < 0 ? 'fail' : ''} bold`}>
        { Math.abs(totalVariance) }
      </TableCell>
      <TableCell />
    </TableRow>,
  );

  return rows;
};

ReceivingLogTableDetailItem.propTypes = {
  data: object.isRequired,
  reportType: string,
};

ReceivingLogTableDetailItem.defaultProps = {
  reportType: 'MISSING',
};

export default ReceivingLogTableDetailItem;

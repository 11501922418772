import React from 'react';
import { string } from 'prop-types';

// eslint-disable-next-line import/prefer-default-export
export const Requested = ({ extraClasses }) => (
  <div className={`${extraClasses}`}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.16463 12.4096H6.12V10.365L14.485 2L16.5296 4.04463L8.16463 12.4096Z" stroke="#111111" strokeWidth="1.25" strokeMiterlimit="10" />
      <path d="M11.6265 3.73494L13.7952 5.90361" stroke="#111111" strokeWidth="1.25" strokeMiterlimit="10" />
      <path d="M9.66667 3.08434H3.16667C1.97067 3.08434 1 4.06179 1 5.26615V16.9025C1 18.1069 1.97067 19.0843 3.16667 19.0843H11.8333C13.0293 19.0843 14 18.1069 14 16.9025V6.77664" stroke="#111111" strokeWidth="1.25" />
    </svg>
  </div>
);

Requested.propTypes = {
  extraClasses: string,
};

Requested.defaultProps = {
  extraClasses: '',
};

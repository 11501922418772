import { camelCase } from 'lodash';
/**
   * sets chrome tab title based on the report that is open
   * @param {string} page - report url title
   * @param {func} getMessage - fetches report title translation
   */
// eslint-disable-next-line import/prefer-default-export
export const updateChromeTab = (page, getMessage, country, storeNumber, isAdmin) => {
  let pageTranslationKey = null;
  if (page === 'login') {
    pageTranslationKey = 'signInLabel';
  } else if (page === 'asnTracking') {
    pageTranslationKey = 'asnTrackingReport';
  } else if (page === 'stockroomBin') {
    pageTranslationKey = 'binAudits';
  } else if (page === 'UPHReport') {
    pageTranslationKey = 'storePerformance';
  } else if (page === 'scanSummary') {
    pageTranslationKey = 'scanReport';
  } else if (page === 'RFIDMissingScan') {
    pageTranslationKey = 'missingStyles';
  } else if (page === 'stylemanagement') {
    pageTranslationKey = 'styleManagement';
  } else if (page === 'priceChanges') {
    pageTranslationKey = 'priceChange';
  } else {
    pageTranslationKey = page;
  }

  document.title = `${ isAdmin ? `${country}-${storeNumber}` : 'Nike SIM' } | ${getMessage(camelCase(pageTranslationKey))}`;
};

/**
 * Changes the favicon of the current page.
 * @param {string} src - The source URL of the new favicon.
 */
export const changeFavicon = (src) => {
  const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
  link.type = 'image/x-icon';
  link.rel = 'shortcut icon';
  link.href = src;
  document.getElementsByTagName('head')[0].appendChild(link);
};

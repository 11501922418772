import React, { useContext } from 'react';
import { ReceivingLogWidget } from '../../../components/dashboardWidgets';
import ReceivingLogProvider from '../../receivingLog/ReceivingLog.provider';
import { SimWebContext } from '../../../context/SimWeb.provider';
import parseFeatureSet, { EStoreFeatureSet } from '../../../utils/storeFeatureSet.ts';

const ReceivingLog = () => {
  const { store, storeConfig } = useContext(SimWebContext);
  const featureSet = storeConfig?.featureSet?.value;
  const isPhase2 = featureSet && parseFeatureSet(featureSet) >= EStoreFeatureSet.FeatureSetPhaseTwo;

  return (isPhase2
&& (
  <ReceivingLogProvider fromDashboard>
    <ReceivingLogWidget store={store} />
  </ReceivingLogProvider>
));
};

export default ReceivingLog;

import React from 'react';
import {
  bool, string,
} from 'prop-types';
import Stack from '@mui/material/Stack';

import AppUpdateNotification from './AppUpdateNotification';
import SeamlessLoginNotification from './SeamlessLoginNotification';
import ScanNotification from './ScanNotification';

const StackedNotifications = ({ page, isScanTimerEnabled, isAdmin }) => (
  <Stack spacing={1} sx={{ maxWidth: '500px' }}>
    <AppUpdateNotification />
    { isAdmin && <SeamlessLoginNotification />}
    { page !== 'login' && isScanTimerEnabled && <ScanNotification /> }
  </Stack>
);

StackedNotifications.propTypes = {
  page: string,
  isScanTimerEnabled: bool,
  isAdmin: bool,
};

StackedNotifications.defaultProps = {
  page: '',
  isScanTimerEnabled: false,
  isAdmin: false,
};

export default StackedNotifications;

/* eslint-disable max-len */
import React, {
  createContext, useState, useContext, useEffect,
} from 'react';
import { node, object } from 'prop-types';
import { DateTime } from 'luxon';
import { getSummaryAndVisualData, setPriceChangesTutorialSteps } from './priceChanges.utils';
import { fetchPriceChangesData, fetchLiveUpdates } from './priceChanges.axios';

import { SimWebContext } from '../../context/SimWeb.provider';
import { tutorialContext } from '../../context/Tutorial.provider';

export const PriceChangesContext = createContext({});

const PriceChangesProvider = ({ children, mockedContext }) => {
  const { Provider } = PriceChangesContext;
  const {
    locale, isOffsiteEnabled, isOffsiteInactive, storeConfig, storeId,
  } = useContext(SimWebContext);

  const {
    stepIndex, setStepIndex, startTutorial, isbackClicked, restartTutorial,
  } = useContext(tutorialContext);

  const [data, setData] = useState([]);
  const [unalteredEffectiveDate, setUnalteredEffectiveDate] = useState(null);
  const [currentEffectiveDate, setCurrentEffectiveDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [lastUpdatedTimestamp, setLastUpdatedTimestamp] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const [summaryData, setSummaryData] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [toggleSelected, setToggleSelected] = useState('genderAge');
  const [visualData, setVisualData] = useState(null);
  const [dialogData, setDialogData] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [stopAnimation, setStopAnimation] = useState(false);

  const id = 'priceChanges';
  const title = 'priceChange';
  const rfidEnabled = storeConfig?.rfidEnabled?.value;
  const MINUTE_MS = 60000;
  const fixedHeader = true;

  const fetchData = async () => {
    setLoading(true);
    setError(null);
    setData(null);

    try {
      const { details, summary: summaryResponse } = await fetchPriceChangesData(storeId, rfidEnabled, isOffsiteEnabled);
      const { summary, visual, effectiveDate } = getSummaryAndVisualData(summaryResponse, locale);

      setData(details);
      setSummaryData(summary);
      setVisualData(visual);
      setUnalteredEffectiveDate(summaryResponse?.effectiveDate);
      setCurrentEffectiveDate(effectiveDate);
      setLoading(false);
    } catch (e) {
      setError(e);
      setLoading(false);
    }
    setLastUpdatedTimestamp(DateTime.now().toLocaleString(DateTime.DATETIME_MED));
    setRefetch(false);
  };

  // If there is a currently active markdown, poll for updates every minute.
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (unalteredEffectiveDate) {
      setStopAnimation(true);
      const interval = setInterval(async () => {
        const summaryResponse = await fetchLiveUpdates(storeId, rfidEnabled, isOffsiteEnabled, unalteredEffectiveDate);
        const { summary, visual } = getSummaryAndVisualData(summaryResponse, locale);

        setSummaryData(summary);
        setVisualData(visual);
      }, MINUTE_MS);
      return () => clearInterval(interval);
    }
  }, [unalteredEffectiveDate]);

  useEffect(() => {
    if (storeId && !loading) fetchData();
  }, [storeId]);

  useEffect(() => {
    if (refetch) fetchData();
  }, [refetch]);

  useEffect(() => {
    setPriceChangesTutorialSteps(startTutorial, stepIndex, restartTutorial, isbackClicked, openModal, setOpenModal, setStepIndex);
  }, [stepIndex, startTutorial, openModal, isbackClicked, restartTutorial]);

  return (
    <Provider
      value={mockedContext ?? {
        data,
        currentEffectiveDate,
        error,
        fixedHeader,
        id,
        lastUpdatedTimestamp,
        loading,
        isOffsiteEnabled,
        isOffsiteInactive,
        rfidEnabled,
        summaryData,
        tabValue,
        title,
        toggleSelected,
        visualData,
        openModal,
        dialogData,
        stopAnimation,
        setRefetch,
        setTabValue,
        setToggleSelected,
        setDialogData,
        setOpenModal,
      }}
    >
      {children}
    </Provider>
  );
};

PriceChangesProvider.defaultProps = {
  children: {},
  mockedContext: null,
};

PriceChangesProvider.propTypes = {
  children: node,
  mockedContext: object,
};

export default PriceChangesProvider;

import React, { useContext } from 'react';

import Tutorial from '../components/tutorial/Tutorial';
import { tutorialContext } from '../context/Tutorial.provider';

const useTutorial = (widgetName) => {
  const {
    activateTutorials,
    stepIndex,
    setStepIndex,
    startTutorial,
    dashboardWidget,
    handleStartTutorial,
    setDashboardWidget,
  } = useContext(tutorialContext);

  const isWidget = !dashboardWidget || dashboardWidget === widgetName;

  const onTutorialStart = (widgetName) => {
    setDashboardWidget(widgetName);
    handleStartTutorial();
  };

  const TutorialComponent = () => (
    (activateTutorials && isWidget) && (
      <Tutorial
        setTutorialState={() => onTutorialStart(widgetName)}
        startTutorial={startTutorial}
        setStepIndex={setStepIndex}
        stepIndex={stepIndex}
      />
    ));

  return {
    activateTutorials,
    stepIndex,
    setStepIndex,
    startTutorial,
    isWidget,
    Tutorial: TutorialComponent,
  };
};

export default useTutorial;

/* eslint-disable no-console */
/* eslint-disable max-len */
import writeXlsxFile from 'write-excel-file';
import React, {
  useContext, useCallback, createContext, useState, useEffect, useMemo, useRef,
} from 'react';
import { object, node } from 'prop-types';
import { CSVLink } from 'react-csv';
import { Tooltip } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { orderBy } from 'lodash';
import { makeStyles } from '@mui/styles';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import fileIcon from '../../assets/images/file-icon.png';
import { LoadingIndicator } from '../../assets/Svgs/index';
import { newRelicAction } from '../../utils/newRelicPageActions';
import {
  removeExtraColumnFromDownload, removeExtraDataFromDownload, tableSort, buildDropZonesListCSVData, getRowData,
  constructTableData, getCSVDownloadData, setInboundTutorialSteps,
} from './inboundVisibility.utils';

import { SimWebContext } from '../../context/SimWeb.provider';
import { tutorialContext } from '../../context/Tutorial.provider';
import { InboundVisibilityContext } from './InboundVisibility.provider';

const useStyles = makeStyles({
  downloadIcon: {
    color: '#757575',
    cursor: 'pointer',
    '&:hover': {
      borderRadius: '50%',
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      color: '#3f51b5',
    },
  },
  dropZonesIcon: {
    cursor: 'pointer',
    margin: '10px 25px 10px 0',
  },
});

const theme = createTheme({
  components: {
    MuiTable: {
      styleOverrides: {
        root: {
          responsiveStacked: {
            maxHeight: 'none',
            overflowX: 'auto',
          },
        },
      },
    },
    MuiDataTable: {
      styleOverrides: {
        responsiveStacked: {
          maxHeight: 'none',
          overflowX: 'auto',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
          '&.Mui-selected': {
            height: '40px',
            width: '30px',
          },
        },
        head: {
          height: '40px',
        },
      },
    },
    MUIDataTableSelectCell: {
      styleOverrides: {
        headerCell: {
          zIndex: '20001',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: 0,
        },
        body: {
          padding: 0,
        },
      },
    },
    MUIDataTableHeadCell: {
      styleOverrides: {
        fixedHeader: {
          zIndex: 'auto',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MUIDataTableBodyCell: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
  },
});

export const InboundVisibilityTableContext = createContext({});

const InboundVisibilityTableProvider = ({ children, mockedValue }) => {
  const { Provider } = InboundVisibilityTableContext;
  const {
    getMessage, isIOS, locale, currencyCode, isPrintingEnabled, storeId, store, country, SimDateTime, isAdmin, storeInfo,
  } = useContext(SimWebContext);
  const {
    stepIndex, setStepIndex, startTutorial, isbackClicked, setBackWasClicked, restartTutorial, setTimeOutOnTutorial,
  } = useContext(tutorialContext);
  const {
    eddEnabled, selectedItems, setSelectedItems, setDialogData, isPRO, webCarrierAndTrackingID, isDropZonesEnabled,
    data, hasFacetsSelected, rfidEnabled, excelDownloadsEnabled,
  } = useContext(InboundVisibilityContext);

  // tutorial magic
  const mountedDialog = document.querySelector('[data-testid="purchase-order-dialog-table"]');
  const dropzoneModal = document.querySelector('[data-testid="printDropZoneDialog"]');

  const [rows, setRows] = useState([]);
  const [docProNumber, setDocProNumber] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [printDialogOpen, setPrintDialogOpen] = useState(false);
  const [printDropZoneData, setPrintDropZoneData] = useState([]);
  const [poIndex, setPOIndex] = useState(0);
  const [selectedStyleColorSOH, setSelectedStyleColorSOH] = useState(null);
  const [isDropZonesDataLoading, setIsDropZonesDataLoading] = useState(false);
  const csvLink = useRef();
  const classes = useStyles();

  const handleGetRowData = (rows, selectableRows) => {
    setPrintDropZoneData(getRowData(rows, selectableRows));
  };

  const docNumberColumn = webCarrierAndTrackingID
    ? [
      {
        name: 'carrierCode',
        label: getMessage('carrierCode'),
        options: { filter: false },
      },
      {
        name: 'trackingNumber',
        label: 'PRO Number',
        options: { filter: false },
      },
    ] : [
      {
        name: 'docNumber',
        label: (isPRO) ? 'PRO Number' : 'DOC Number',
        options: {
          filter: false,
        },
      },
    ];

  const getColumns = (getMessage) => [
    {
      name: 'edd',
      label: getMessage('eddDate'),
      options: {
        filter: false,
        display: (eddEnabled || (isAdmin && storeInfo?.isNorthAmerica)) || 'excluded',
      },
    },
    {
      name: 'am_pm',
      label: 'AM/PM',
      options: {
        filter: false,
        display: (eddEnabled || (isAdmin && storeInfo?.isNorthAmerica)) || 'excluded',
      },
    },
    {
      name: 'shipDate',
      label: getMessage('shipDate'),
      options: {
        filter: false,
      },
    },
    ...docNumberColumn,
    {
      name: 'deliveryNumber',
      label: 'Delivery Number',
      options: {
        filter: false,
        display: country === 'USA' || 'excluded',
      },
    },
    {
      name: 'poNumber',
      label: country === 'USA' ? getMessage('stoPoNumber') : getMessage('poNumber'),
      options: {
        filter: false,
      },
    },
    {
      name: 'carton',
      label: getMessage('carton'),
      options: {
        display: !isIOS,
        filter: false,
      },
    },
    {
      name: 'division',
      label: getMessage('division'),
      options: {
        display: !isIOS,
        filter: true,
        filterType: 'multiselect',
      },
    },
    {
      name: 'gender',
      label: 'Gender',
      options: {
        display: !isIOS,
        filter: false,
      },
    },
    {
      name: 'units',
      label: getMessage('units'),
      options: {
        filter: false,
        setCellHeaderProps: (val) => ({
          className: val.index === 7 && 'columnStep',
          'data-testid': 'columnStep',
        }),
      },
    },
    {
      name: 'shipmentTypeFilter',
      label: getMessage('shipmentType'),
      options: {
        filter: true,
        display: 'excluded',
      },
    },
    {
      name: 'shipmentType',
      label: getMessage('shipmentType'),
      options: {
        display: !isIOS,
        filter: false,
      },
    },
    {
      name: 'status',
      label: getMessage('status'),
      options: {
        display: !isIOS,
        filter: true,
      },
    },
    {
      name: 'lirp',
      label: 'LI/RP',
      options: {
        display: storeInfo?.isEMEA || 'excluded',
        filter: false,
      },
    },
  ];

  const customToolBar = useCallback((rows, selectedRows, selectedStyleColorSOH) => {
    const fileName = `InboundVisibility-PO-List-${store}-${SimDateTime.toUtcISO()}`;

    return (
      <div style={{ display: 'flex', alignItems: 'baseline', minHeight: '44px' }} data-testid="inbound-visibility-custom-tool-bar">
        {!isIOS && selectedStyleColorSOH && !isDropZonesDataLoading && (
          <>
            <Tooltip title="Download PO List">
              <CloudDownloadIcon
                className={classes.downloadIcon}
                onClick={async () => {
                  let isDownloaded = false;
                  if (excelDownloadsEnabled) {
                    const csvData = buildDropZonesListCSVData(rows, selectedRows, selectedStyleColorSOH, isPRO, webCarrierAndTrackingID, storeInfo?.isEMEA, country === 'USA');
                    const dataRows = csvData.map(row => row.map(value => ({ value: value.toString(), type: String })));
                    try {
                      writeXlsxFile(dataRows, { fileName: `${fileName}.xlsx` });
                      isDownloaded = true;
                    } catch (error) {
                      console.error(error);
                      isDownloaded = false;
                    }
                  } else {
                    csvLink.current.link.click();
                    isDownloaded = true;
                  }

                  newRelicAction('inbound-visibility-dropzone-list-download', { isDownloaded });
                }}
                data-testid="inbound-visibility-dropzone-list-download-button"
                style={{ marginRight: 13 }}
              />
            </Tooltip>
            {!excelDownloadsEnabled && (
              <CSVLink
                data={buildDropZonesListCSVData(rows, selectedRows, selectedStyleColorSOH, isPRO, webCarrierAndTrackingID, false, country === 'USA')}
                filename={`${fileName}.csv`}
                className="hidden"
                ref={csvLink}
                target="_blank"
              />
            )}
          </>
        )}
        {!isIOS && isDropZonesEnabled && !isDropZonesDataLoading && (
          <Tooltip title="Create Dropzones">
            <img
              src={fileIcon}
              alt="printDropZoneFileIcon"
              height={24}
              className={classes.dropZonesIcon}
              onClick={() => {
                handleGetRowData(rows, selectedRows);
                setPrintDialogOpen(true);
              }}
            />
          </Tooltip>
        )}
        {isDropZonesDataLoading && (
          <LoadingIndicator height="100px" width="100px" extraClass="dropzone-icons-loading" />
        )}
      </div>
    );
  }, [excelDownloadsEnabled, isDropZonesDataLoading]);

  const customColumnSort = (changedColumn, direction) => {
    let sortedRows;
    if (changedColumn === 'shipmentType') {
      sortedRows = tableSort(rows, 'shipmentTypeFilter', direction);
    } else {
      sortedRows = orderBy(rows, changedColumn, [`${direction}`]);
    }

    setRows(sortedRows);
  };

  const muiDataTableOptions = useMemo(() => {
    const fileName = `InboundVisibility-${store}-${SimDateTime.toUtcISO()}`;

    const baseOptions = {
      fixedHeader: true,
      tableBodyHeight: hasFacetsSelected ? '64vh' : '108vh',
      selectableRows: 'multiple',
      rowsPerPage: 100,
      rowsPerPageOptions: [15, 25, 50, 100, 1000],
      textLabels: {
        toolbar: {
          downloadCsv: excelDownloadsEnabled ? 'Download XLS' : 'Download CSV',
        },
        body: { noMatch: getMessage('noResults') },
      },
      print: isPrintingEnabled,
      responsive: 'standard',
      // sortOrder: { name: eddEnabled ? 'edd' : 'shipDate' },
      onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
        setSelectedItems(rowsSelected);
      },
      rowsSelected: selectedItems,
      customToolbarSelect: (selectedRows) => customToolBar(rows, selectedRows, selectedStyleColorSOH),
      filter: true,
      onRowClick: (_rowData, { dataIndex }) => {
        setPOIndex(dataIndex);
        setDocProNumber(rows?.[dataIndex]?.docNumber);
        setDialogData(rows?.[dataIndex]?.details);
        setIsOpen(true);
      },
      onColumnSortChange: (changedColumn, direction) => customColumnSort(changedColumn, direction),
    };

    const csvDownloads = {
      ...baseOptions,
      onDownload: (buildHead, buildBody, newColumns, newData) => {
        newRelicAction('inbound-visibility-report-download', { isDownloaded: true });
        newColumns.splice(newColumns.findIndex(column => column.name === 'poNumber') + 1 || 4, 0, { name: 'cartonIds', label: getMessage('cartonID') });
        const headers = buildHead(removeExtraColumnFromDownload(eddEnabled, newColumns, country, storeInfo?.isEMEA));
        const dataRows = buildBody(removeExtraDataFromDownload(eddEnabled, newData, rows, newColumns, country, storeInfo?.isEMEA, webCarrierAndTrackingID));
        return `\uFEFF${headers}${dataRows}`;
      },
      downloadOptions: {
        filename: `${fileName}.csv`,
        separator: ',',
        filterOptions: {
          userDisplayedColumnsOnly: true,
          useDisplayedRowsOnly: true,
        },
      },
    };

    const excelDownloads = {
      ...baseOptions,
      onDownload: (buildHead, buildBody, newColumns, newData) => {
        newRelicAction('inbound-visibility-report-download', { isDownloaded: true });
        newColumns.splice(newColumns.findIndex(column => column.name === 'poNumber') + 1 || 4, 0, { name: 'cartonIds', label: getMessage('cartonID') });
        const headerColumns = removeExtraColumnFromDownload(eddEnabled, newColumns, country, storeInfo?.isEMEA);
        const data = removeExtraDataFromDownload(eddEnabled, newData, rows, newColumns, country, storeInfo?.isEMEA, webCarrierAndTrackingID);
        const headerRow = headerColumns.map(column => ({ value: column.label.toUpperCase(), fontWeight: 'bold' }));
        const dataRows = data.map(row => row.data.map(value => ({ value: value?.toString() ?? '', type: String })));

        try {
          writeXlsxFile([headerRow, ...dataRows], { fileName: `${fileName}.xlsx` });
          newRelicAction('purchase-order-download', { isDownloaded: true });
        } catch (error) {
          console.error(error);
          newRelicAction('purchase-order-download', { isDownloaded: true });
        }

        return false; // cancels default datatable download logic
      },
      downloadOptions: {
        filterOptions: {
          useDisplayedColumnsOnly: true,
          useDisplayedRowsOnly: true,
        },
      },
    };

    return excelDownloadsEnabled ? excelDownloads : csvDownloads;
  }, [country, eddEnabled, excelDownloadsEnabled, getMessage, hasFacetsSelected, isPrintingEnabled, rows, selectedItems,
    selectedStyleColorSOH, store, storeInfo]);

  useEffect(() => {
    if (data) {
      const rows = constructTableData(data, locale, currencyCode, eddEnabled);
      setRows(rows);
    }
  }, [data, locale, currencyCode]);

  useEffect(() => {
    const getSelectedSohData = async (selectedItems) => {
      setIsDropZonesDataLoading(true);
      const selectedRowData = selectedItems?.map(index => rows[index]);
      const soh = await getCSVDownloadData(storeId, selectedRowData, rfidEnabled);
      setSelectedStyleColorSOH(soh);
      setIsDropZonesDataLoading(false);
    };

    if (selectedItems?.length > 0) {
      getSelectedSohData(selectedItems);
    } else {
      setSelectedStyleColorSOH(null);
    }
  }, [selectedItems]);

  useEffect(() => {
    const allShipDatesSelected = document.querySelector('table input')?.checked;
    const singleShipDateSelected = document.querySelector('table td input')?.checked;

    setInboundTutorialSteps(startTutorial, stepIndex, restartTutorial, allShipDatesSelected, singleShipDateSelected,
      printDialogOpen, isbackClicked, isOpen, isDropZonesEnabled, setIsOpen,
      setBackWasClicked, setPrintDialogOpen, setStepIndex, setTimeOutOnTutorial);
  }, [
    startTutorial, stepIndex, isOpen, isDropZonesEnabled, printDialogOpen,
    mountedDialog, isbackClicked, dropzoneModal, restartTutorial, setTimeOutOnTutorial,
  ]);

  return (
    <Provider
      value={mockedValue ?? {
        options: muiDataTableOptions,
        rows,
        columns: getColumns(getMessage),
        theme,
        docProNumber,
        setDocProNumber,
        isOpen,
        setIsOpen,
        printDropZoneData,
        printDialogOpen,
        setPrintDialogOpen,
        poIndex,
        setPOIndex,
        useDeliveryNumber: country === 'USA',
      }}
    >
      {children}
    </Provider>
  );
};

InboundVisibilityTableProvider.defaultProps = {
  children: {},
  mockedValue: null,
};

InboundVisibilityTableProvider.propTypes = {
  children: node,
  mockedValue: object,
};

export default InboundVisibilityTableProvider;

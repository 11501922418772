/**
 * @fileoverview This file contains the division constants used in the SIM web UI.
 * @module divisions
 */

/**
 * @constant
 * @type {Object}
 * @property {string[]} aliases - The aliases for the apparel division.
 * @property {string} label - The label for the apparel division.
 * @property {function} isApparel - Checks if a given division is an apparel division.
 * @memberof module:divisions
 */
const divisions = {
  apparel: {
    name: 'apparel',
    aliases: ['Apparel', 'APPAREL DIVISION', 'APPAREL'],
    label: 'AP',
    bodegaKey: 'apparel',
  },
  equipment: {
    name: 'equipment',
    aliases: ['Equipment', 'EQUIPMENT DIVISION', 'EQUIPMENT'],
    label: 'EQ',
    bodegaKey: 'equipment',
  },
  footwear: {
    name: 'footwear',
    aliases: ['Footwear', 'FOOTWEAR DIVISION', 'FOOTWEAR'],
    label: 'FW',
    bodegaKey: 'footwear',
  },
  isApparel(division) { return this.apparel.aliases.map(alias => alias.toLowerCase()).includes(division?.toLowerCase()); },
  isEquipment(division) { return this.equipment.aliases.map(alias => alias.toLowerCase()).includes(division?.toLowerCase()); },
  isFootwear(division) { return this.footwear.aliases.map(alias => alias.toLowerCase()).includes(division?.toLowerCase()); },
  getTranslation(division) {
    if (!division) return '-';
    if (this.isApparel(division)) return this.apparel.bodegaKey;
    if (this.isFootwear(division)) return this.footwear.bodegaKey;
    if (this.isEquipment(division)) return this.equipment.bodegaKey;
    return '-';
  },
  getMappedValue(division) {
    if (!division) return '-';
    if (this.isApparel(division)) return this.apparel.name;
    if (this.isFootwear(division)) return this.footwear.name;
    if (this.isEquipment(division)) return this.equipment.name;
    return '-';
  },
  getLabel(division) {
    if (!division) return '-';
    if (this.isApparel(division)) return this.apparel.label;
    if (this.isFootwear(division)) return this.footwear.label;
    if (this.isEquipment(division)) return this.equipment.label;
    return '-';
  },
  getFilterValues() { return [...this.apparel.aliases, ...this.equipment.aliases, ...this.footwear.aliases]; },
};

export default divisions;

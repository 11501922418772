import React, { useContext, useMemo } from 'react';
import _, { isEmpty } from 'lodash';

import FacetGroup from '../../../components/facets/FacetGroup';
import Facet from '../../../components/facets/Facet';
import InfoTooltip from '../../../components/InfoTooltip';
import ClearFacetsButton from '../../../components/ClearFacetsButton';
import TooltipContent from './TooltipContent';
import SizeComplianceToggle from './SizeComplianceToggle';

import { newRelicAction } from '../../../utils/newRelicPageActions';
import { SimWebContext } from '../../../context/SimWeb.provider';
import { SizeComplianceContext } from '../SizeCompliance.provider';
import {
  buildFacetsData, getAggregationValue, getFacetGroups, updateFilters, getAggregationComponent,
} from '../utils/sizeComplianceFacet.util';

/**
 * Container holding facets for Size Compliance Report
 */
const SizeComplianceFacets = () => {
  const { getMessage } = useContext(SimWebContext);
  const {
    filters, supportedFilters, setFilters, handleResetFacets,
  } = useContext(SizeComplianceContext);

  const onFacetChange = (facet, groupName) => {
    const updatedFilters = updateFilters(facet, groupName, filters);
    const isFacetChecked = !facet.selected;
    newRelicAction('size-compliance-report-facet', { facet: groupName, item: facet.name, checked: isFacetChecked });
    setFilters(updatedFilters);
  };

  const sizeComplianceFacets = useMemo(() => {
    const facets = buildFacetsData(supportedFilters);
    const facetGroups = getFacetGroups(facets);

    return facetGroups?.map(groupName => {
      const facetComponent = facets[groupName]?.values.map(facet => ((facet)
        ? (
          <Facet
            key={facet.name}
            nameKey={facet.name}
            name={`${groupName}.${facet.name}`}
            selected={facet.selected}
            group={groupName}
            handleChange={() => onFacetChange(facet, groupName)}
            hidden={false}
            aggregation={(
              getAggregationComponent(getAggregationValue(facet?.aggregations, 'accuracy'), getAggregationValue(facet?.aggregations, 'unrepresented'))
            )}
            translatedName={getMessage(_.camelCase(facet.label))}
          />
        ) : null));

      let toolTip = null;
      if (groupName === 'division') {
        toolTip = (
          <InfoTooltip
            content={<TooltipContent />}
            title={getMessage('aggregations').toUpperCase()}
          />
        );
      }

      return (
        (!isEmpty(facetComponent)) ? (
          <FacetGroup
            key={groupName}
            name={groupName}
            title={getMessage(facets[groupName].label)}
            hidden={false}
            tooltip={toolTip}
          >
            {facetComponent}
          </FacetGroup>
        ) : null
      );
    });
  }, [supportedFilters, filters]);

  return (
    <div data-testid="size-compliance-facets">
      <SizeComplianceToggle />
      { sizeComplianceFacets }
      <ClearFacetsButton
        buttonText={getMessage('clearFacets')}
        name="size-compliance"
        clearFacets={handleResetFacets}
      />
    </div>
  );
};

export default SizeComplianceFacets;

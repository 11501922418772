/* eslint-disable import/prefer-default-export */
export const dashboardArray = [
  {
    content: 'View the Inbound Visiblity report summary. Default to today.',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'right-end',
    spotlightClicks: false,
    spotlightPadding: 0,
    target: '[data-testid="inbound-visibility-widget"]',
    title: 'Inbound Visibility Summary',
    widget: 'inboundVisibility',
  },
  {
    content: 'Use the left arrow to see yesterday summary.',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'top',
    spotlightClicks: false,
    spotlightPadding: 0,
    target: '[data-testid="inbound-visibility-widget-previous-date"]',
    title: 'Yesterday Inbound Visibility Summary',
    widget: 'inboundVisibility',
  },
  {
    content: 'Use the right arrow return to today summary.',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'top',
    spotlightClicks: false,
    spotlightPadding: 0,
    target: '[data-testid="inbound-visibility-widget-next-date"]',
    title: 'Today Inbound Visibility Summary',
    widget: 'inboundVisibility',
  },
  {
    content: 'Click on the summary to navigate to the Inbound Visibility report.',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'right-bottom',
    spotlightClicks: false,
    spotlightPadding: 0,
    target: '[data-testid="inbound-visibility-widget-data"]',
    title: 'Inbound Visibility Report',
    widget: 'inboundVisibility',
  },
  {
    content: 'View the current Markdown completion summary. Number of styles and percent Markdowns completed, and number of styles and percent Markdowns not found. Click on the widget to navigate to the Markdowns report.',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'right-end',
    spotlightClicks: false,
    spotlightPadding: 0,
    target: '[data-testid="markdowns-widget-totals-table-tutorial"]',
    title: 'Current Markdown Completion Summary',
    widget: 'markdowns',
  },
  {
    content: 'View the current Receiving Log summary. The number of expected, received and missing cartons and items. Default to today.',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'right-end',
    spotlightClicks: false,
    spotlightPadding: 0,
    target: '[data-testid="receivingLogWidget-tutorial"]',
    title: 'Receiving Log Summary',
    widget: 'receivingLog',
  },
  {
    content: 'Use the left arrow to see yesterday summary.',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'top',
    spotlightClicks: false,
    spotlightPadding: 0,
    target: '[data-testid="receiving-log-widget-previous-date"]',
    title: 'Yesterday Receiving Log Summary',
    widget: 'receivingLog',
  },
  {
    content: 'Use the right arrow return to today summary.',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'top',
    spotlightClicks: false,
    spotlightPadding: 0,
    target: '[data-testid="receiving-log-widget-next-date"]',
    title: 'Today Receiving Log Summary',
    widget: 'receivingLog',
  },
  {
    content: 'Click on the summary to navigate to the Receiving Log report.',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'right-bottom',
    spotlightClicks: false,
    spotlightPadding: 0,
    target: '[data-testid="receiving-log-widget-data-container"]',
    title: 'Receiving Log Report',
    widget: 'receivingLog',
  },
  {
    content: 'View the current Product Refill summary. Click on the widget to navigate to the Product Refill report.',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'right-end',
    spotlightClicks: false,
    spotlightPadding: 0,
    target: '[data-testid="productRefillWidget-tutorial"]',
    title: 'Product Refill Summary',
    widget: 'productRefill',
  },
  {
    content: 'Enter a style-color or UPC into the search field',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'bottom',
    spotlightClicks: false,
    spotlightPadding: 0,
    target: '[data-testid="styleInput"]',
    title: 'itemInquiryS1Title',
    widget: 'itemInquiry',
  },
  {
    content: 'itemInquiryS2',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'bottom-end',
    spotlightClicks: true,
    spotlightPadding: 0,
    target: '[data-testid="inquiryGoButton"]',
    title: 'itemInquiryS2Title',
    widget: 'itemInquiry',
  },
  {
    content: 'itemInquiryS5',
    disableBeacon: true,
    hideCloseButton: true,
    disableOverlayClose: true,
    placement: 'top',
    spotlightClicks: true,
    spotlightPadding: 0,
    target: '[data-testid="toggle-SOH"]',
    title: 'itemInquiryS5Title',
    config: true, // only for rfid doors
    widget: 'itemInquiry',
  },
  {
    content: 'Click on the "View In Item Inquiry" button to view search in Item Inquiry Report.',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'bottom',
    spotlightClicks: false,
    spotlightPadding: 0,
    target: '[data-testid="product-detail-to-report-button"]',
    title: 'To Item Inquiry Report',
    widget: 'itemInquiry',
  },
  {
    content: 'View the list of BOPIS orders.',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'bottom',
    spotlightClicks: false,
    spotlightPadding: 0,
    target: '[data-testid="agedOrders-order-details-bopis"]',
    title: 'BOPIS Orders',
    widget: 'agedOrders',
  },
  {
    content: 'View the list of ship from store orders.',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'bottom',
    spotlightClicks: false,
    spotlightPadding: 0,
    target: '[data-testid="agedOrders-order-details-sfs"]',
    title: 'Ship From Store Orders',
    widget: 'agedOrders',
  },
  {
    content: 'View the latest or current RFID Scan status. Click on the widget to navigate to the Scan report.',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'bottom',
    spotlightClicks: false,
    spotlightPadding: 0,
    target: '[data-testid="scanTimerWidget"]',
    title: 'RFID Scan Timer Widget',
    widget: 'scanTimer',
  },
  {
    content: 'The timer will appear and start counting down once the scan is complete and ready for review.',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'bottom',
    spotlightClicks: false,
    spotlightPadding: 0,
    target: '[data-testid="timerWidget_timer_container"]',
    title: 'Countdown Timer',
    widget: 'scanTimer',
  },
  {
    content: 'The countdown clock changes to red once the remaining time reaches below one hour.',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'bottom',
    spotlightClicks: false,
    spotlightPadding: 0,
    target: '[data-testid="timerWidget_timer_container"]',
    title: 'Timing Out',
    widget: 'scanTimer',
  },
  {
    content: 'The countdown clock becomes unavailable after the timer has reached zero time remaining and the scan has been timed out.',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'bottom',
    spotlightClicks: false,
    spotlightPadding: 0,
    target: '[data-testid="timerWidget_timer_container"]',
    title: 'Timed Out',
    widget: 'scanTimer',
  },
  {
    content: 'If the scan is completed, and the scan is submitted, it will show that there is no longer a pending manager review needed.',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'bottom',
    spotlightClicks: false,
    spotlightPadding: 0,
    target: '[data-testid="timerWidget_timer_container"]',
    title: 'Scan Submitted',
    widget: 'scanTimer',
  },
  {
    content: 'The Scan Timer widget will remain visible for 6 hours from the scan began.',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'bottom',
    spotlightClicks: false,
    spotlightPadding: 0,
    target: '[data-testid="scanTimerWidget"]',
    title: 'Scan Timer Available Time',
    widget: 'scanTimer',
  },
  {
    content: 'View the last full store scan completed within 29 days. Click on the widget to navigate to Scan Report for more details.',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'bottom',
    spotlightClicks: false,
    spotlightPadding: 0,
    target: '[data-testid="fs-scanAccuracy-container"]',
    title: 'Last FullStore Scan',
    widget: 'scanAccuracy',
  },
  {
    content: 'View the last sale floor or cycle count scan completed within 29 days. Click on the widget to navigate to Scan Report for more details.',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'bottom',
    spotlightClicks: false,
    spotlightPadding: 0,
    target: '[data-testid="sf-cc-scanAccuracy-container"]',
    title: 'Last SaleFloor or CycleCount Scan',
    widget: 'scanAccuracy',
  },
  {
    content: 'View the scans that has completed in the last 28 days.',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'bottom',
    spotlightClicks: false,
    spotlightPadding: 0,
    target: '[data-testid="scanComplianceWidget"]',
    title: 'Scan Compliance Widget',
    widget: 'scanCompliance',
  },
  {
    content: 'View the date, day of the week, time spent on completing a scan and the scan accuracy of the completed scan.',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'bottom',
    spotlightClicks: false,
    spotlightPadding: 0,
    target: '[data-testid="scanComplianceWidget_weekly-scan-single-dates-clickable-71fb76cb-b5b4-45d8-ad03-4d0fa16d114b"]',
    title: 'Day With Scan Completed',
    widget: 'scanCompliance',
  },
  {
    content: 'The + sign indicates there were multiple scans completed on that date.',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'bottom',
    spotlightClicks: false,
    spotlightPadding: 0,
    target: '[data-testid="scan-single-dates-accuracy-71fb76cb-b5b4-45d8-ad03-4d0fa16d114b"]',
    title: 'Multiple Scan Completed',
    widget: 'scanCompliance',
  },
  {
    content: 'The date without a completed scan will have an X marked.',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'bottom',
    spotlightClicks: false,
    spotlightPadding: 0,
    target: '[data-testid="scanComplianceWidget_weekly-scan-single-dates"]',
    title: 'Day With No Scan Completed',
    widget: 'scanCompliance',
  },
  {
    content: 'The color indicates the type of scan completed.',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'bottom',
    spotlightClicks: false,
    spotlightPadding: 0,
    target: '[data-testid="scanComplianceWidget_legend"]',
    title: 'Types Of Scan',
    widget: 'scanCompliance',
  },
  {
    content: 'Use the arrow buttons to view scans in previous weeks.',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'bottom',
    spotlightClicks: false,
    spotlightPadding: 0,
    target: '[data-testid="scanComplianceWidget-previous-week"]',
    title: 'Previous Scans',
    widget: 'scanCompliance',
  },
  {
    content: 'Click on the date with a scan completed to view more details of the scan.',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'bottom',
    spotlightClicks: false,
    spotlightPadding: 0,
    target: '[data-testid="scanComplianceWidget_weekly-scan-single-dates-clickable-d25fa1f9-f727-473d-a860-0f5869d59a29"]',
    title: 'Navigate to Scan Report',
    widget: 'scanCompliance',
  },
  {
    content: 'View the number of styles represented on the sale floor. Click on the widget to navigate to Missing Styles Report for more details.',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'bottom',
    spotlightClicks: false,
    spotlightPadding: 0,
    target: '[data-testid="styleComplianceWidget_dashboard"]',
    title: 'Style Compliance Summary',
    widget: 'styleCompliance',
  },
  {
    content: 'Hover over a given chart to see the breakdown of the represented and total styles for each division.',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'bottom',
    spotlightClicks: false,
    spotlightPadding: 0,
    target: '[data-testid="styleComplianceWidget_data_visuals"]',
    title: 'Summary Breakdowns',
    widget: 'styleCompliance',
  },
  {
    content: 'View the number of sizes represented on the sale floor. Click on the widget to navigate to Size Compliance Report for more details.',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'bottom',
    spotlightClicks: false,
    spotlightPadding: 0,
    target: '[data-testid="sizeComplianceWidget_dashboard"]',
    title: 'Size Compliance Summary',
    widget: 'sizeCompliance',
  },
  {
    content: 'Hover over a given chart to see the breakdown of the represented and total sizes for each division.',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'bottom',
    spotlightClicks: false,
    spotlightPadding: 0,
    target: '[data-testid="sizeComplianceWidget_data_visuals"]',
    title: 'Summary Breakdowns',
    widget: 'sizeCompliance',
  },
];

export const dashboardSteps = (widget, translatedSteps, rfidEnabled) => {
  let newStep = [...translatedSteps];

  newStep = newStep?.filter(value => value?.widget === widget);

  if (!rfidEnabled) {
    newStep = newStep?.filter(value => value?.config !== true);
  }

  return newStep;
};

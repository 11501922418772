import React, {
  useState, createContext, useContext, useEffect, useMemo,
} from 'react';
import { useParams } from 'react-router-dom';

import { node, object } from 'prop-types';
import { getRfidTableHeader } from '../../../components/RFIDTableHeader';
import ProductDetailContainer from '../../../components/productDetails/ProductDetailContainer';
import { baseDialogWrapper as Dialog } from '../../baseView/BaseViewPageUtils';
import { fetchDisplayScan } from '../scanReport.axios';
import CustomSpinner from '../../../components/CustomSpinner';
import getFormattedPrice from '../../../utils/getFormattedPrice';

import { CPAContext } from '../../../context/cpa/CPA.provider';
import { SimWebContext } from '../../../context/SimWeb.provider';

export const DisplayScanContext = createContext({});

/*
* ScanReportSummaryProvider provides:
*     - Columns and rows for both summary and detailed views
*     - Base table options that can be extended
*     - Product info modal declaration
*     - Data
*     - OnGoClick function for triggering data fetch
*
* @param {node} children - data that we iterate on and create data visuals
* @param {object} mockedValue - mock values for testing
*/
const DisplayScanProvider = ({ children, mockedValue }) => {
  const { Provider } = DisplayScanContext;
  const {
    storeId, store, getMessage, isOffsiteEnabled, isOffsiteInactive, isPrintingEnabled, storeConfig, locale, SimDateTime,
    storeInfo, currencyCode,
  } = useContext(SimWebContext);

  const isEMEA = storeInfo?.isEMEA;
  const rfidEnabled = storeConfig?.rfidEnabled?.value;
  const eddEnabled = storeConfig?.isEDDEnabled?.value;

  const { getProducts } = useContext(CPAContext);

  const [isOpen, setIsOpen] = useState(false);
  const [modalStyleColor, setModalStyleColor] = useState('');
  const [tableIndex, setTableIndex] = useState(0);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  const id = 'displayScan';
  const { sessionId } = useParams();

  useEffect(() => {
    const getProductDataForStyleColor = () => getProducts(modalStyleColor);
    if (modalStyleColor) getProductDataForStyleColor();
  }, [modalStyleColor]);

  useEffect(() => {
    const fetchDisplay = async (storeId, selectedScanId) => {
      setLoading(true);

      const today = SimDateTime.now();
      const endDateInclusive = SimDateTime.endOfDay(today).toISO();

      try {
        const response = await fetchDisplayScan(storeId, store, selectedScanId, endDateInclusive, isEMEA, eddEnabled);
        if (response?.error) {
          setError(response.error);
        } else {
          setData(response.data);
        }
      } catch (error) {
        setError(error?.message);
      } finally {
        setLoading(false);
      }
    };

    if (!sessionId) return;

    fetchDisplay(storeId, sessionId);
  }, [storeId, store, sessionId, isEMEA, eddEnabled]);

  const onRowClick = (rowData, dataIndex) => {
    const styleColor = rowData[0];

    setIsOpen(true);
    setTableIndex(dataIndex);
    setModalStyleColor(styleColor);
  };

  const productModal = useMemo(() => {
    const content = (
      <ProductDetailContainer
        index={tableIndex}
        setIsOpen={setIsOpen}
        rowCount={data?.items?.length}
        setIndex={setTableIndex}
        styleColor={modalStyleColor}
        modal
        isUnitCountEnabled
      />
    );
    return <Dialog id={id} content={content} isOpen={isOpen} setIsOpen={setIsOpen} />;
  }, [isOpen, tableIndex, modalStyleColor, data?.items?.length]);

  const rfidColumn = getRfidTableHeader('rfidSoh', getMessage('rfidStock'), id, rfidEnabled, isOffsiteEnabled, isOffsiteInactive);

  const columns = [
    {
      name: 'productCode',
      label: getMessage('styleColor'),
    },
    {
      name: 'description',
      label: getMessage('description'),
    },
    {
      name: 'genderAge',
      label: getMessage('gender'),
    },
    {
      name: 'retailCategory',
      label: getMessage('retailCategory'),
    },
    {
      name: 'price',
      label: getMessage('price'),
      options: {
        customBodyRender: value => (
          <>{getFormattedPrice(value, locale, currencyCode)}</>
        ),
      },
    },
    {
      name: 'soh',
      label: getMessage('soh'),
    },
    rfidColumn,
    {
      name: 'inboundUnits',
      label: getMessage('inboundUnits'),
    },
  ];

  const ellipses = (
    <div id="responsive-dialog-title">
      <CustomSpinner size="10px" color="#5CDAA8" loading margin="25px" />
    </div>
  );

  const tableTitle = useMemo(() => (
    <div className="display-scan-title-container">
      <h2>
        {getMessage('displayScan')}
      </h2>
      {loading ? ellipses
        : (
          <>
            <div className="display-scan-title-date">
              {`${getMessage('date')}: ${data?.eventDateTime ? new Date(data?.eventDateTime).toLocaleString(locale) : '-'}`}
            </div>
            <div data-testid="display-scan-title-unit">
              {`${getMessage('unitsScanned')}: ${data?.units ?? '-'}`}
            </div>
            <div data-testid="display-scan-title-division">
              {`${getMessage('division')}: ${data?.division ?? '-'}`}
            </div>
          </>
        )}
    </div>
  ), [data, loading]);

  const tableOptions = {
    onRowClick: (rowData, { dataIndex }) => onRowClick(rowData, dataIndex),
    selectableRows: 'none',
    print: isPrintingEnabled,
    responsive: 'standard',
    filter: true,
    search: false,
    download: true,
    // eslint-disable-next-line prefer-template
    onDownload: (buildHead, buildBody, newColumns, newData) => '\uFEFF' + buildHead(newColumns) + buildBody(newData),
    downloadOptions: {
      filterOptions: {
        useDisplayedRowsOnly: true,
      },
    },
    viewColumns: false,
    textLabels: {
      body: {
        noMatch: getMessage('noData'),
      },
    },
  };

  return (
    <Provider
      value={mockedValue ?? {
        id,
        tableTitle,
        data,
        columns,
        tableOptions,
        productModal,
        error,
        loading,
        setModalStyleColor,
        setTableIndex,
        setIsOpen,
      }}
    >
      {children}
    </Provider>
  );
};

DisplayScanProvider.defaultProps = {
  children: {},
  mockedValue: null,
};

DisplayScanProvider.propTypes = {
  children: node,
  mockedValue: object,
};

export default DisplayScanProvider;

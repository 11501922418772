import React, {
  useEffect, useContext, useRef,
} from 'react';
import Dialog from '@mui/material/Dialog';
// Local Imports
import DateRangeWithOptions from '../../components/date-range/DateRangeWithOptions';
import { LoadingIndicator } from '../../assets/Svgs/index';
import ErrorAlert from '../../components/alerts/Error';
import BinAuditsTable from './BinAuditsTable';
import BinAuditsDialogDetails from './BinAuditsDialogDetails';
import { binAuditsContext } from './BinAudits.provider';

import './BinAuditsPage.css';

export const BinAuditsPage = () => {
  const {
    startDate,
    endDate,
    isOpen,
    setOpen,
    dialogData,
    setDialogData,
    getMessage,
    isLoading,
    data,
    auditIndex,
    setAuditIndex,
    error,
    onGoClick,
    isOutsideRange,
  } = useContext(binAuditsContext);

  const detailsRef = useRef();

  useEffect(() => {
    onGoClick({ startDate, endDate });
  }, []);

  const dataForRender = data || { totalAverage: '...', audits: [] };

  return (
    <div className="bin-audits-page flex-column">
      <div className="flex-row header">
        <div className="flex-column">
          <div className="feature-title">
            {getMessage('stockroomBinAudits')}
          </div>
          <div className="feature-subtitle">
            {getMessage('totalAverage')}
            {`: ${dataForRender?.totalAverage}`}
            {'%'}
          </div>
        </div>
        <div className="page-controls flex-row">
          <div className="date-picker-block">
            <DateRangeWithOptions
              start={startDate}
              end={endDate}
              onClick={onGoClick}
              disabled={isLoading}
              isOutsideRange={isOutsideRange}
            />
          </div>
        </div>
      </div>
      {error && (
        <ErrorAlert
          errorObject={error}
          apiName="Bin Audits API"
          pageCode=""
        />
      )}
      <div className="details" ref={detailsRef}>
        {!isLoading && (
          <BinAuditsTable
            dataForRender={dataForRender}
            setDialogData={setDialogData}
            setOpen={setOpen}
            loading={isLoading}
            setAuditIndex={setAuditIndex}
            auditIndex={auditIndex}
          />
        )}
        <Dialog
          open={isOpen}
          onClose={() => setOpen(false)}
          PaperProps={{
            style: {
              minHeight: '80%',
              maxHeight: '80%',
              minWidth: '90%',
              maxWidth: '90%',
            },
          }}
        >
          <BinAuditsDialogDetails
            loading={isLoading}
            parentRowCount={dataForRender?.audits?.length}
            dialogData={dialogData}
            data={dataForRender}
            setOpen={setOpen}
            setAuditIndex={setAuditIndex}
            auditIndex={auditIndex}
          />
        </Dialog>
        {isLoading && <LoadingIndicator /> }
      </div>
    </div>
  );
};

export default BinAuditsPage;

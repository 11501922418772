/* eslint-disable no-useless-catch */
import { chunk } from 'lodash';
import { generalAxiosRequest } from '../../axios/axiosFunctions';
import {
  baseUrl, chooseTaskService, endpoints, routerUrls,
} from '../../axios/endpoints';
import { newRelicAction } from '../../utils/newRelicPageActions';
import { SimDateTime } from '../../utils/datetime';
import {
  generateBinName, getPicklistName, getBinNames, getUnits, getDescription,
} from './productRefill.utils';

export const getProductRefillTasks = async (storeId, headers, taskService, getMessage) => {
  const fetchedTasks = [];
  const isTaskV3Service = taskService?.toLowerCase() === 'v3';
  let keepFetching = true;
  const endpoint = chooseTaskService(taskService, true);
  let url = `${baseUrl(routerUrls.NIKE_API)}`
  + `${endpoint.url}?`
  + `&filter=storeId(${storeId})`
  + `&filter=${isTaskV3Service ? 'fulfillmentType' : 'type'}(REFILL,UNBINNED_REFILL,PICK_LIST)`
  + '&filter=status(NOT_STARTED,STARTED)&count=75'
  + `&filter=modificationDateAfterEq(${SimDateTime.now().minus({ hours: 24 })})`;

  try {
    while (keepFetching) {
      // eslint-disable-next-line no-await-in-loop
      const taskPage = await generalAxiosRequest('GET', url, endpoint, true, headers);
      if (taskPage?.objects?.length > 0) {
        taskPage.objects.map(task => {
          const taskToPush = {
            id: task?.id,
            bin: generateBinName(task, getMessage),
            picklistName: getPicklistName(task, isTaskV3Service),
            type: isTaskV3Service ? task?.fulfillmentType : task?.classification?.type,
            binNames: getBinNames(task?.items),
            units: getUnits(task?.items),
            description: getDescription(task?.items),
            createdDateUtc: task?.creationDate,
            date: SimDateTime.toLocaleString(task?.creationDate, SimDateTime.DATETIME_SHORT),
            items: task?.items,
            key: task?.id,
          };
          fetchedTasks.push(taskToPush);
        });
      }
      if (taskPage?.pages?.next) {
        url = `${baseUrl(routerUrls.NIKE_API)}${taskPage.pages.next}`;
      } else {
        keepFetching = false;
      }
    }
    return fetchedTasks;
  } catch (e) {
    throw e;
  }
};

export const getRefillCountsData = async (storeId, footwearRefill, hideUnbinnedStatus) => {
  const binned = hideUnbinnedStatus ? '&filter=binned(true)' : '';
  const aggregateBy = footwearRefill ? 'aggregateBy=binnedStatus' : 'aggregateBy=division';
  const url = `${baseUrl(routerUrls.NIKE_API)}${endpoints.REFILL_COUNTS.url}`
      + `?${aggregateBy}&filter=storeId(${storeId})${binned}`;
  try {
    let count = 0;
    const refillCountFetch = await generalAxiosRequest('GET', url, endpoints.REFILL_COUNTS);
    if (!footwearRefill) {
      count = refillCountFetch?.objects.filter(({ label }) => label !== 'FOOTWEAR').reduce((accum, obj) => accum + obj.count, 0);
    } else {
      count = refillCountFetch?.objects.reduce((accum, obj) => accum + obj.count, 0);
    }
    return count;
  } catch (e) {
    throw e;
  }
};

export const markTaskAsFilled = async (task, user, headers, taskService, store, allTasksAsFilled = false) => {
  const body = [];

  // task parent update
  body.push({
    op: 'add',
    path: '/assignedAthleteId',
    value: user,
  });

  if (taskService?.toLowerCase() !== 'v3') {
    body.push({
      op: 'replace',
      path: '/status',
      value: 'COMPLETED_SUCCESSFULLY',
    });
  }

  // task item updates
  task.items.map((item, i) => {
    if (taskService?.toLowerCase() === 'v3') {
      body.push({
        op: 'replace',
        path: `/items/${JSON.stringify(i)}/fulfillments`,
        value: [{ quantity: item.quantityRequested, status: 'FULFILLED' }],
      });
    } else {
      body.push({
        op: 'replace',
        path: `/items/${JSON.stringify(i)}/status`,
        value: 'FULFILLED',
      }, {
        op: 'add',
        path: `/items/${JSON.stringify(i)}/fulfillment`,
        value: { quantityFulfilled: item.quantityRequested },
      });
    }
  });

  const endpoint = chooseTaskService(taskService);
  const url = `${baseUrl(routerUrls.NIKE_API)}${endpoint.url}`
    + `/${task.id}`;

  // There's a restriction on how many transactions can be ran. Each item produces 2 items in array so we can send 49
  // items in the first array (this is because the parent update is included in the first call), then the rest can be
  // sent after.  The rest is safe to send since there is a hard limit of 100 items per picklist.
  if (body.length > 100) {
    const chunkedArray = chunk(body, 100);
    chunkedArray.map(async (array, i) => generalAxiosRequest('PATCH', url, endpoint, true, headers, chunkedArray[i]));
  } else {
    if (!allTasksAsFilled) {
      newRelicAction('sim-mark-task-as-filled', { taskId: task.id, store });
    }
    await generalAxiosRequest('PATCH', url, endpoint, true, headers, body);
  }
};

export const markAllTasksAsFilled = async (tasks, user, headers, taskService, store) => {
  newRelicAction('sim-mark-all-as-filled', { store, numberOfTasks: tasks?.length });
  tasks.map(async task => {
    await markTaskAsFilled(task, user, headers, taskService, store, true);
  });
};

/* eslint-disable import/prefer-default-export */
export const adjustmentsArray = [
  {
    content: 'Select a start and end date to search for specific adjustment details between a range of dates',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'bottom',
    spotlightClicks: true,
    spotlightPadding: 0,
    target: '[data-testid="date-pickers-container"]',
    title: 'inboundVisibilityS1Title',
  },
  {
    content: 'Click on the "Go" button to search the adjustment details for the selected dates',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'bottom-end',
    spotlightClicks: true,
    target: '[data-testid="date-range-submit-button"]',
    title: 'inboundVisibilityS2Title',
  },
  {
    content: 'inboundVisibilityS9',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'top',
    spotlightClicks: true,
    spotlightPadding: 0,
    target: '[data-testid="columnStep"]',
    title: 'stockRoomOrgS2Title',
  },
  {
    content: 'Use the options in the header to search, download, print, or search the adjustment data',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'top',
    spotlightClicks: true,
    spotlightPadding: 0,
    target: 'div[role^="toolbar"] > div:nth-child(2)',
    title: 'inboundVisibilityS8Title',
  },
  {
    content: 'Click the checkbox on a given date to select a single or multiple adjustments as needed. You can print or download the adjustments details by clicking the options in the header row',
    disableBeacon: true, // 4
    disableOverlayClose: true,
    hideCloseButton: true,
    placement: 'auto',
    spotlightClicks: true,
    spotlightPadding: 0,
    target: '[data-testid="MUIDataTableBodyRow-0"]',
    title: 'Selecting a single adjustment',
  },
  {
    content: 'inboundVisibilityS10',
    disableBeacon: true, // 5
    disableOverlayClose: true,
    hideCloseButton: true,
    placement: 'auto',
    spotlightClicks: true,
    spotlightPadding: 0,
    target: 'thead tr th', // select all checkboxes
    title: 'inboundVisibilityS10Title',
  },
  {
    content: 'Click on a single row/date to see additional adjustment details',
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    placement: 'auto',
    spotlightClicks: true,
    target: '.MuiTableBody-root > tr',
    title: 'Selecting a Single Row',
  },
  {
    content: 'Select a document type from the dropdown to choose from on of the options', // only for both RUS and GRC
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    placement: 'auto',
    spotlightClicks: true,
    target: '[data-testid="print-option-block"]',
    title: 'Document Type Selector',
    config: true, // only if printOptions is true
  },
  {
    content: 'Select an address to print the adjustment details. You can keep the default address selected or enter a new address in the form', // only for both RUS and GRC
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    placement: 'bottom',
    spotlightClicks: true,
    spotlightPadding: 0,
    target: '[data-testid="address-modal-content"]',
    title: 'Select an Address',
    config: true, // only if printOptions is true
    styles: {
      overlay: {
        zIndex: '20001',
      },
      options: {
        zIndex: '20001',
      },
    },
  },
];

export const adjustmentSteps = (translatedSteps, printOptions) => {
  let newStep = [...translatedSteps];

  if (!printOptions) {
    newStep = newStep?.filter(value => value?.config !== true);
  }

  return newStep;
};

import React, { useContext, useEffect, useRef } from 'react';
import {
  func, object, string, array, bool,
} from 'prop-types';
import { DateTime } from 'luxon';
import Card from '@mui/material/Card';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { CSVLink } from 'react-csv';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';
import MUIDataTable from 'mui-datatables';
import DialogHeader from '../dialogHeader/DialogHeader';

import { SimWebContext } from '../../context/SimWeb.provider';
import { itemActivityContext } from '../../containers/itemActivity/ItemActivity.provider';

import './ItemActivityModal.css';

const useStyles = makeStyles({
  downloadIcon: {
    color: '#757575',
    cursor: 'pointer',
    '&:hover': {
      borderRadius: '50%',
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      color: '#1976d2',
    },
  },
});

const ItemActivityModal = ({
  styleInput, startDate, endDate, productData, closeCall, hiddenColumns, loading, rows,
}) => {
  const {
    index, rowCount, setIndex, setModalStyles,
  } = useContext(itemActivityContext);

  const { csvFormat, getMessage, storeConfig } = useContext(SimWebContext);
  const rfidEnabled = storeConfig?.rfidEnabled?.value;
  const classes = useStyles();
  const csvLink = useRef();

  useEffect(() => {
    setModalStyles(rows?.[index]?.styleColor);
  }, [index]);

  let columns = [{
    name: 'date',
    label: getMessage('date'),
  }, {
    name: 'gtin',
    label: getMessage('upc'),
  }, {
    name: 'size',
    label: getMessage('size'),
  }, {
    name: 'sale',
    label: getMessage('unitsSold'),
  }, {
    name: 'onlineSale',
    label: getMessage('onlineSales'),
  }, {
    name: 'shipFromStore',
    label: getMessage('shipFromStore'),
  }, {
    name: 'returned',
    label: getMessage('returns'),
  }, {
    name: 'received',
    label: getMessage('received'),
  }, {
    name: 'postVoidSale',
    label: getMessage('postVoidSales'),
  }, {
    name: 'postVoidReturn',
    label: getMessage('postVoidReturns'),
  }, {
    name: 'adjustment',
    label: getMessage('inventoryAdjustment'),
  }, {
    name: 'transfer',
    label: getMessage('transferOut'),
  }, {
    name: 'soh',
    label: getMessage('soh'),
  }];

  if (rfidEnabled) {
    columns = [...columns, {
      name: 'rfidSoh',
      label: getMessage('rfidSoh'),
    }];
  }

  const columnsToShow = columns.map((column) => {
    if (hiddenColumns[column.name]) {
      return {
        ...column,
        options: {
          ...column.options,
          display: false,
        },
      };
    }

    return column;
  });

  const aggRows = {};
  const event = productData?.find(product => product?.styleColor === styleInput);
  if (event && Array.isArray(event?.itemEvents)) {
    event?.itemEvents?.forEach((_) => {
      const date = DateTime.fromISO(_?.originDate).toString().split('T')[0];
      const gtin = _?.productId;
      if (!aggRows[date]) {
        aggRows[date] = {};
      }

      if (!aggRows[date][gtin]) {
        aggRows[date][gtin] = {
          SALE: 0,
          RETURN: 0,
          RECEIVED: 0,
          POST_VOID_SALE: 0,
          POST_VOID_RETURN: 0,
          ADJUSTMENT: 0,
          TRANSFER: 0,
          INITIAL_BALANCE: 0,
          PHYSICAL_INVENTORY: 0,
          ONLINE_SALE: 0,
          SHIP_FROM_STORE: 0,
          SIZE: (_?.product?.sizeName) || '-',
          SOH: (_?.soh?.fiscalStock?.quantity) || 0,
          RFIDSOH: (_?.soh?.physicalStock?.quantity) || 0,
        };
      }
      if (_?.originType === 'WECHAT_MOBILE_SALE') aggRows[date][gtin].SALE += Math.abs(Number(_?.delta));
      aggRows[date][gtin][_?.originType] += Math.abs(Number(_?.delta));
    });
  }
  const activityRows = [];

  Object.keys(aggRows).forEach((day) => {
    Object.keys(aggRows[day]).forEach((key) => {
      const row = aggRows[day][key];
      activityRows.push({
        date: day,
        gtin: key,
        size: row.SIZE,
        sale: row.SALE,
        onlineSale: row.ONLINE_SALE,
        shipFromStore: row.SHIP_FROM_STORE,
        returned: row.RETURN,
        received: row.RECEIVED,
        postVoidSale: row.POST_VOID_SALE,
        postVoidReturn: row.POST_VOID_RETURN,
        adjustment: row.ADJUSTMENT,
        transfer: row.TRANSFER,
        soh: row.SOH,
        rfidSoh: row.RFIDSOH,
      });
    });
  });
  const buildDownloadData = () => {
    const toDownload = [];
    activityRows?.map(r => toDownload.push({
      ...r,
      gtin: `${csvFormat}${r?.gtin}`,
    }));
    return toDownload;
  };

  const HeaderElements = () => (
    <>
      <IconButton
        onClick={() => csvLink.current.link.click()}
        className={classes.downloadIcon}
        size="large"
      >
        <CloudDownloadIcon />
      </IconButton>
      <CSVLink
        data={buildDownloadData()}
        filename="Item Activity Report Details"
        ref={csvLink}
        target="_blank"
      />
    </>
  );

  return (
    <div className="item-activity-modal" data-testid="item-activity-modal">
      <DialogHeader
        loading={loading}
        index={index}
        setIsOpen={closeCall}
        rowCount={rowCount}
        setIndex={setIndex}
      />
      <div className="feature-title">
        <DialogTitle>{getMessage('detailedActivity')}</DialogTitle>
      </div>
      <DialogContent>
        <div className="feature-header">
          <Card className="summary-box">
            <div className="style-box">
              <span className="key">{`${getMessage('style')}: `}</span>
              <span className="value" data-testid="styleSummary">{styleInput}</span>
            </div>
            <div className="date-box">
              <span className="key">{`${getMessage('dates')}: `}</span>
              <span className="value" data-testid="dateRangeSummary">{`${startDate}  -  ${endDate}`}</span>
            </div>
          </Card>
        </div>
        <div className="table" data-testid="itemActivityModalTable">
          <MUIDataTable
            data={activityRows}
            columns={columnsToShow}
            options={{
              selectableRows: 'none',
              print: true,
              filter: false,
              elevation: 0,
              download: false,
              customToolbar: () => (<HeaderElements />),
            }}
            className="MUIDataTable"
          />
        </div>
      </DialogContent>
    </div>
  );
};

ItemActivityModal.propTypes = {
  styleInput: string.isRequired,
  startDate: string.isRequired,
  endDate: string.isRequired,
  productData: array.isRequired,
  closeCall: func,
  hiddenColumns: object.isRequired,
  loading: bool,
  rows: array,
};

ItemActivityModal.defaultProps = {
  closeCall: null,
  loading: false,
  rows: [],
};

export default ItemActivityModal;

const supportedLanguages = [
  {
    name: 'Chinese (Simplified)',
    tag: 'zh-Hans',
    bodega: 'zh-Hans-CN',
  },
  {
    name: 'Chinese (Traditional)',
    tag: 'zh-Hant',
    bodega: 'zh-Hant-TW',
  },
  {
    name: 'Croatian',
    tag: 'hr',
    bodega: 'hr',
  },
  {
    name: 'Czech',
    tag: 'cs',
    bodega: 'cs-CZ',
  },
  {
    name: 'Dutch',
    tag: 'nl',
    bodega: 'nl-NL',
  },
  {
    name: 'English (US)',
    tag: 'en',
    bodega: 'en-US',
  },
  {
    name: 'English (GB)',
    tag: 'en-GB',
    bodega: 'en-GB',
  },
  {
    name: 'French',
    tag: 'fr',
    bodega: 'fr-FR',
  },
  {
    name: 'German',
    tag: 'de',
    bodega: 'de-DE',
  },
  {
    name: 'Greek',
    tag: 'el',
    bodega: 'el-GR',
  },
  {
    name: 'Hebrew',
    tag: 'he',
    bodega: 'he',
  },
  {
    name: 'Hungarian',
    tag: 'hu',
    bodega: 'hu-HU',
  },
  {
    name: 'Italian',
    tag: 'it',
    bodega: 'it-IT',
  },
  {
    name: 'Japanese',
    tag: 'ja',
    bodega: 'ja-JP',
  },
  {
    name: 'Korean',
    tag: 'ko',
    bodega: 'ko-KR',
  },
  {
    name: 'Portuguese (Brazilian)',
    tag: 'pt-BR',
    bodega: 'pt-BR',
  },
  {
    name: 'Portuguese (European)',
    tag: 'pt-PT',
    bodega: 'pt-PT',
  },
  {
    name: 'Russian',
    tag: 'ru',
    bodega: 'ru-RU',
  },
  {
    name: 'Spanish (Mexico)',
    tag: 'es-MX',
    bodega: 'es-MX',
  },
  {
    name: 'Spanish (Spain)',
    tag: 'es-ES',
    bodega: 'es-ES',
  },
  {
    name: 'Turkish',
    tag: 'tr',
    bodega: 'tr-TR',
  },
];

export default supportedLanguages;

import React from 'react';
import { string } from 'prop-types';

// eslint-disable-next-line import/prefer-default-export
export const Shoe = ({ extraClasses }) => (
  <div className={`${extraClasses}`}>
    <svg aria-hidden="false" focusable="false" viewBox="0 0 24 24" role="img" width="24px" height="24px" fill="none" id="Shoe-m-icon" xmlns="http://www.w3.org/2000/svg">
      <path fill="currentColor" fillRule="evenodd" d="M9.872 13.976c-.789-.087-.836-1.146-.826-1.475-.099.454-.197.908-.22 1.354-.037.699.143 1.339.573 1.567.775.413 1.567-.442 2.032-1.056l4.315-5.701-4.777 4.744c-.402.396-.79.598-1.097.567z" clipRule="evenodd" />
      <path stroke="currentColor" strokeWidth="1.5" d="M19.222 4.556l2.032 3.52a3.75 3.75 0 01-1.372 5.123L9.3 19.308a7.039 7.039 0 01-3.517.942H3.742c-1.494 0-2.057-1.952-.793-2.747a5.74 5.74 0 001.913-1.987l5.506-9.54a6.258 6.258 0 007.665-4.424" />
    </svg>
  </div>
);

Shoe.propTypes = {
  extraClasses: string,
};

Shoe.defaultProps = {
  extraClasses: '',
};

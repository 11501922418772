import React, { useState } from 'react';
import { bool, func } from 'prop-types';
import {
  FormControl, RadioGroup, FormControlLabel, Radio,
} from '@mui/material';

/**
 * Renders a group of radio buttons to switch between admin and store views.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.isAdmin - Indicates whether the current view is admin or store.
 * @param {Function} props.setIsAdmin - Callback function to update the view mode.
 * @returns {JSX.Element} The rendered ViewAsGroup component.
 */
const ViewAsGroup = ({ isAdmin, setIsAdmin }) => {
  const [value, setValue] = useState(isAdmin ? 'admin' : 'store');

  return (
    <FormControl>
      <RadioGroup
        row
        aria-labelledby="view-as-admin-label"
        name="row-radio-buttons-group"
        value={value}
        onChange={(e) => { setValue(e?.target?.value); setIsAdmin(e?.target?.value === 'admin'); }}
      >
        <div style={{ display: 'flex', flexDirection: 'row', paddingTop: '15px' }}>
          <FormControlLabel value="admin" control={<Radio size="small" />} label="Admin" />
          <FormControlLabel value="store" control={<Radio size="small" />} label="Store" />
        </div>
      </RadioGroup>
    </FormControl>
  );
};

export default ViewAsGroup;

ViewAsGroup.propTypes = {
  isAdmin: bool.isRequired,
  setIsAdmin: func.isRequired,
};

import {
  bool, func, number, string,
} from 'prop-types';
import React, { useContext, useState } from 'react';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import Button from '../../../components/Button';

import './PicklistFormModal.css';
import { TranslationsContext } from '../../../context/Translations.provider';
import { StyleManagementContext } from '../../styleManagement/StyleManagement.provider';
/**
 * UI for athletes to enter product to refill on salesfloor
 * @param {function} createPiclist - service call to create a picklist
 * @param {boolean} isOpen - indicator to display modal
 * @param {boolean} loading - indicator to display loading indicator
 * @param {function} setIsOpen - function to update whether the modal should be displayed
 * @param {number} units - total product included in piclist
 */
const PicklistFormModal = ({
  createPicklist,
  isOpen,
  loading,
  setIsOpen,
  units,
  report,
}) => {
  const { getMessage } = useContext(TranslationsContext);
  const {
    setCreatingPicklist, setCreated, setPicklistComplete,
  } = useContext(StyleManagementContext);

  const [picklistName, setPicklistName] = useState('');

  const handleSubmit = (picklistName) => {
    createPicklist(picklistName);
    setIsOpen(false);
    setCreatingPicklist(false);
  };

  const handleCancel = () => {
    setIsOpen(false);
    setCreatingPicklist(false);
    setIsOpen(false);
    setPicklistComplete(true); // unselect rows
    setCreated(false);
  };

  const className = 'report-picklist-form';
  const dataTestId = `${report}-picklist-form`;
  return (
    <Dialog
      open={isOpen}
      fullScreen={false}
      maxWidth="md"
    >
      <div data-testid={`${dataTestId}-container`} className={`${className}-container`}>
        <DialogTitle>
          <div className={`${className}-title`}>{getMessage('namePickList')}</div>
        </DialogTitle>
        <DialogContent>
          <input
            type="text"
            className={`${className}-input`}
            data-testid={`${dataTestId}-input`}
            name="picklist-name"
            onChange={e => setPicklistName(e.target.value)}
          />
          <div className={`${className}-units`} data-testid={`${dataTestId}-units`}>
            {`${getMessage('pickListUnits')} ${units}`}
          </div>
          <div className={`${className}-buttons`}>
            <Button
              className="sim-button cancel-button"
              dataTestId={`${dataTestId}-cancel`}
              buttonText={getMessage('cancel')}
              onClick={() => (report === 'style-management' ? handleCancel() : setIsOpen(false))}
            />
            <Button
              className="submit-button sim-button"
              dataTestId={`${dataTestId}-submit`}
              buttonText={getMessage('submit')}
              isLoading={loading}
              onClick={() => (report === 'style-management' ? handleSubmit(picklistName) : createPicklist(picklistName))}
            />
          </div>
        </DialogContent>
      </div>
    </Dialog>
  );
};

PicklistFormModal.defaultProps = {
  createPicklist: null,
  isOpen: false,
  loading: false,
  units: 0,
  report: 'size-compliance',
};

PicklistFormModal.propTypes = {
  createPicklist: func,
  isOpen: bool,
  loading: bool,
  setIsOpen: func.isRequired,
  units: number,
  report: string,
};

export default PicklistFormModal;

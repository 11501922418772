/**
 * Either translates a single string or parses and translates an array into a comma separated string.
 * @param {Array.<string>|string} value - either a string or an array
 * @param {func} getMessage - function to translate a string
 * @returns {string}
 */
const parseArrayOrStringForTable = (value, getMessage) => {
  if (Array.isArray(value)) {
    return value.map(string => getMessage(string)).join(', ');
  }
  return getMessage(value);
};

export default parseArrayOrStringForTable;

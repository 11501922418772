import React from 'react';
import { useParams } from 'react-router-dom';

import ItemInquiryPage from './ItemInquiryPage';
import CPAProvider from '../../context/cpa/CPA.provider';

const ItemInquiry = () => {
  const { searchValue } = useParams();
  return (
    <CPAProvider>
      <ItemInquiryPage itemInquirySearchValue={searchValue} />
    </CPAProvider>
  );
};

export default ItemInquiry;

import React, { useContext, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import DonutChartWithLegend from '../../../components/visualSummaries/donutChart/DonutChartWithLegend';
import VisualSummariesContainer from '../../../components/visualSummaries/VisualSummariesContainer';
import { getAggregatedBy } from '../../../components/toggle/GenderCategoryToggle';
import SummaryWidget from '../../../components/visualSummaries/summaryWidget/SummaryWidget';
import { getCountsObject } from './inboundVisibilityVisuals.utils';
import { getVisualSummaryData, getSubAggregationList } from '../../../utils/getVisualSummaryData';

import { SimWebContext } from '../../../context/SimWeb.provider';
import { InboundVisibilityContext } from '../InboundVisibility.provider';
import Toggle from '../../../components/toggle/Toggle';

const id = 'inbound-visibility';

const InboundVisibilityVisualsTable = () => {
  const { getMessage, locale } = useContext(SimWebContext);
  const {
    data, loading, error, supportedFilters, eddEnabled,
  } = useContext(InboundVisibilityContext);

  const [selectedToggle, setSelectedToggle] = useState('genderAge');

  const toggles = {
    gender: {
      name: 'genderAge',
      label: getMessage('gender'),
      aggregatedBy: 'genderAge',
    },
    category: {
      name: 'retailCategory',
      label: getMessage('category'),
      aggregatedBy: 'retailCategory',
    },
    // TODO Temporarily disabled: GOLINV-10756
    // liRp: {
    //   label: 'LI/RP',
    //   name: 'LI/RP',
    //   aggregatedBy: 'liRp',
    // },
  };

  const subAggregations = getSubAggregationList(toggles);

  const summaryWidget = useMemo(() => {
    const aggregateObject = supportedFilters.find(item => (item.name === 'poNumber'));

    const dataObj = getCountsObject(aggregateObject, getMessage);

    return (
      <>
        <SummaryWidget loading={loading} data={dataObj} locale={locale} title={getMessage('inboundSummary')} />
      </>
    );
  }, [
    loading,
    supportedFilters,
    eddEnabled,
  ]);

  const visuals = useMemo(() => {
    const getChart = (aggregatedBy, data, toggle) => (
      <DonutChartWithLegend
        id={`${id}-${aggregatedBy}`}
        data={data}
        loading={loading}
        error={error || isEmpty(data)}
        aggregatedBy={aggregatedBy}
        toggle={toggle}
        centerTextLabel={getMessage('units')}
        categories={subAggregations}
      />
    );

    const toggle = (
      <Toggle
        hasSeveralToggles
        toggleLabelsArray={Object.values(toggles)}
        selected={selectedToggle}
        setSelected={value => { setSelectedToggle(value); }}
        toggleByLabel
        toggleDirection="vertical"
      />
    );

    const aggregatedBy = getAggregatedBy(toggles, selectedToggle);
    const graphData = getVisualSummaryData(data, ['Apparel', 'Footwear', 'Equipment'], subAggregations, 'inbound');
    const chart = getChart(aggregatedBy, graphData, toggle);

    return (
      <div>
        <div data-testid={`${id}-toggleGroup`}>
          <VisualSummariesContainer chart={chart} summaryWidget={summaryWidget} />
        </div>
      </div>
    );
  }, [data, selectedToggle, getMessage]);

  return (
    <>{visuals}</>
  );
};

export default InboundVisibilityVisualsTable;

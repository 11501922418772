// eslint-disable-next-line import/prefer-default-export
export const styleManagementStepsArray = [
  {
    content: 'styleManagementS1',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'auto',
    spotlightClicks: true,
    spotlightPadding: 15,
    target: '[data-testid="style-management-binnedButton"]',
    title: 'styleManagementS1Title',
  },
  {
    content: 'styleManagementS2',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'auto',
    spotlightClicks: true,
    spotlightPadding: 15,
    target: '[data-testid="style-management-unbinnedButton"]',
    title: 'styleManagementS2Title',
  },
  {
    content: 'styleManagementS3',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'auto',
    spotlightClicks: true,
    spotlightPadding: 15,
    target: '[data-testid="style-management-unbinnedButton"]',
    title: 'styleManagementS3Title',
  },
  {
    content: 'styleManagementS4',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'auto',
    spotlightClicks: true,
    spotlightPadding: 0,
    target: '[data-testid="style-management-genderAge-visual"]',
    title: 'styleManagementS4Title',
  },
  {
    content: 'styleManagementS5',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'bottom',
    spotlightClicks: true,
    spotlightPadding: 15,
    target: '.toggle-vertical',
    title: 'styleManagementS5Title',
  },
  {
    content: 'styleManagementS6',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'top',
    spotlightClicks: true,
    spotlightPadding: 0,
    target: 'div[role^="toolbar"] > div:nth-child(2)',
    title: 'styleManagementS6Title',
  },
  {
    content: 'styleManagementS7',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'top',
    spotlightClicks: true,
    spotlightPadding: 0,
    target: '[data-testid="columnStep"]',
    title: 'styleManagementS7Title',
  },
  {
    content: 'styleManagementS8',
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    placement: 'auto',
    spotlightClicks: true,
    spotlightPadding: 0,
    target: '[data-testid="MUIDataTableBodyRow-0"]',
    title: 'styleManagementS8Title',
    styles: {
      overlay: {
        zIndex: '20001',
      },
      options: {
        zIndex: '20001',
      },
    },
  },
  // {
  //   content: 'styleManagementS9',
  //   disableBeacon: true,
  //   hideCloseButton: true,
  //   disableOverlayClose: true,
  //   placement: 'auto',
  //   spotlightClicks: true,
  //   spotlightPadding: 10,
  //   target: '[data-testid="toggle-SOH"]',
  //   title: 'itemInquiryS5Title',
  //   styles: {
  //     overlay: {
  //       zIndex: '20001',
  //     },
  //     options: {
  //       zIndex: '20001',
  //     },
  //   },
  //   config: true, // only if isRFIDEnabled is true
  // },
  // {
  //   content: 'itemInquiryS6',
  //   disableBeacon: true,
  //   hideCloseButton: true,
  //   disableOverlayClose: true,
  //   placement: 'bottom',
  //   spotlightClicks: true,
  //   spotlightPadding: 0,
  //   target: '[data-testid="productDetailsInfo"]',
  //   title: 'styleManagementS10Title',
  // },
  // {
  //   content: 'inboundVisibilityS17',
  //   disableBeacon: true,
  //   disableOverlayClose: true,
  //   hideCloseButton: true,
  //   placement: 'auto',
  //   spotlightClicks: true,
  //   spotlightPadding: 10,
  //   target: '[data-testid="modal-next-button"]',
  //   title: 'inboundVisibilityS17Title',
  //   styles: {
  //     overlay: {
  //       zIndex: '20001',
  //     },
  //     options: {
  //       zIndex: '20001',
  //     },
  //   },
  // },
  // {
  //   content: 'inboundVisibilityS18',
  //   disableBeacon: true,
  //   disableOverlayClose: true,
  //   hideCloseButton: true,
  //   placement: 'auto',
  //   spotlightClicks: true,
  //   spotlightPadding: 0,
  //   target: '[data-testid="modalCloseButton"]',
  //   styles: {
  //     overlay: {
  //       zIndex: '20001',
  //     },
  //     options: {
  //       zIndex: '20001',
  //     },
  //   },
  //   title: 'inboundVisibilityS18Title',
  // },
  {
    content: 'styleManagementS13',
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    placement: 'auto',
    spotlightClicks: true,
    spotlightPadding: 0,
    target: '[data-testid="style-management-refresh"]',
    title: 'styleManagementS13Title',
  },
];
// eslint-disable-next-line import/prefer-default-export
export const styleManagementSteps = (rfidEnabled, translatedSteps) => {
  let newSteps = translatedSteps;

  if (!rfidEnabled) {
    newSteps = newSteps.filter(value => value.config !== true);
  }

  return newSteps;
};

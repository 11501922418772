import React, { useState } from 'react';
import {
  shape, string, any, bool, number, arrayOf, oneOfType,
} from 'prop-types';

import './DropdownTable.css';

const DropdownTableSummaryCell = ({ data }) => {
  let { value } = data;
  let noValue = false;

  if (value === undefined || value === null || value === '') {
    value = '-';
    noValue = true;
  }

  return (
    <div className={`dropdown-table-summary-cell ${data.pullRight ? 'right' : ''} ${data.className || 'plain'} ${noValue ? 'noValue' : ''}`}>
      <div className="label">
        {data.key || '\xA0' /* &nbsp; */}
      </div>
      <div className="value">
        {data.url ? <a href={data.url} target="_blank" rel="noopener noreferrer">{value.toString()}</a> : value.toString()}
      </div>
    </div>
  );
};

DropdownTableSummaryCell.propTypes = {
  data: shape({
    key: string,
    value: any,
    url: string,
    pullRight: bool,
    className: string,
  }).isRequired,
};

export const DropdownTable = ({
  className,
  children,
  maxHeight,
  summaryCells,
}) => {
  const [collapsed, setCollapsed] = useState(true);

  const onClick = () => setCollapsed(!collapsed);

  // eslint-disable-next-line no-unused-vars
  let pulledRight = false;

  const filteredSummaryCells = summaryCells.filter((cell) => cell); // filter out null values

  return (
    <div className={`dropdown-table-container ${collapsed ? 'collapsed' : ''} ${className}`}>
      <div className="dropdown-summary-table" onClick={onClick}>
        <div className="dropdown-table-summary-cell arrow">
          <svg>
            <polygon points="0,0 4,7 8,0" />
          </svg>
        </div>
        {filteredSummaryCells.map((summary) => {
          if (summary.pullRight) { pulledRight = true; }
          return <DropdownTableSummaryCell data={summary} key={JSON.stringify(summary)} />;
        })}
      </div>
      <div className="dropdown-table-detail" style={{ maxHeight }}>
        {children}
      </div>
    </div>
  );
};

DropdownTable.propTypes = {
  className: string,
  children: any,
  maxHeight: oneOfType([number, string]),
  summaryCells: arrayOf(shape({
    key: string,
    value: any,
    pullRight: bool,
    className: string,
  })),
};

DropdownTable.defaultProps = {
  className: '',
  children: null,
  maxHeight: '100%',
  summaryCells: [],
};

export default DropdownTable;

/* eslint-disable react/jsx-curly-brace-presence */
import React from 'react';
import { number, oneOfType, string } from 'prop-types';

/**
 * Provides the user with the PO number, Carton, and Unit count data on a particualar facet
 * @param {number || string} poNumber - the PO number count for the facet
 * @param {number || string} carton - the Carton count for the facet
 * @param {number || string} unit - the Unit count for the facet
 */
// eslint-disable-next-line arrow-body-style
const InboundVisibilityFacetAggregation = ({ poNumber, carton, unit }) => {
  return (
    <div className="inbound-visibility-aggregation">
      <span data-testid="inbound-visibility-po-number" className="inbound-visibility-po-number">{poNumber}</span>
      <span className="aggregation-seperator">{'/'}</span>
      <span data-testid="inbound-visibility-carton" className="inbound-visibility-carton">{carton}</span>
      <span className="aggregation-seperator">{'/'}</span>
      <span data-testid="inbound-visibility-unit" className="inbound-visibility-unit">{unit}</span>
    </div>
  );
};

InboundVisibilityFacetAggregation.defaultProps = {
  poNumber: null,
  carton: null,
  unit: null,
};

InboundVisibilityFacetAggregation.propTypes = {
  poNumber: oneOfType([string, number]),
  carton: oneOfType([string, number]),
  unit: oneOfType([string, number]),
};

export default InboundVisibilityFacetAggregation;

import { isNaN } from 'lodash';
import { sizeOrder } from './sortSizes';

/**
 * A helper function to compare two sizes
 * @param {string} aSize - first value to compare
 * @param {string} bSize - second value to compare
 * @param {string} direction - the sort direction
 */
export const compareSizes = (aSize, bSize, direction) => {
  if (!aSize || !bSize || !direction || (aSize === '-' && bSize === '-')) return 0;

  const aMatchingPattern = sizeOrder.find(pattern => pattern.test(aSize));
  const bMatchingPattern = sizeOrder.find(pattern => pattern.test(bSize));

  if (direction === 'asc') {
    if (sizeOrder.indexOf(aMatchingPattern) === sizeOrder.indexOf(bMatchingPattern)) {
      if (isNaN(+aSize) && isNaN(+bSize)) {
        return aSize > bSize ? 1 : -1;
      }
      return +aSize - +bSize;
    }
    return sizeOrder.indexOf(aMatchingPattern) - sizeOrder.indexOf(bMatchingPattern);
  }
  if (sizeOrder.indexOf(aMatchingPattern) === sizeOrder.indexOf(bMatchingPattern)) {
    if (isNaN(+aSize) && isNaN(+bSize)) {
      return bSize > aSize ? 1 : -1;
    }
    return bSize - aSize;
  }
  return sizeOrder.indexOf(bMatchingPattern) - sizeOrder.indexOf(aMatchingPattern);
};

/**
 * A heler function to sort a list of object by style-color/productCode first then by size
 * @param {array} data - the data to be sorted
 * @param {string} direction - the sort direction - asc or desc
 */
export const sortByStyleColorAndSize = (data, direction) => {
  if (!data || data.length === 0) return [];

  return [...data].sort((a, b) => {
    const { size: aSize, productCode: aPC, styleColor: aSC } = a;
    const { size: bSize, productCode: bPC, styleColor: bSC } = b;
    const aProductCode = aPC || aSC;
    const bProductCode = bPC || bSC;

    if (aProductCode === '-' && bProductCode === '-') return 0;
    if (aProductCode === '-' || bProductCode === '-') {
      if (direction === 'asc') return (aProductCode !== '-' && bProductCode === '-') ? -1 : 1;
      return (aProductCode === '-' && bProductCode !== '-') ? 1 : -1;
    }

    if (direction === 'asc') return aProductCode.localeCompare(bProductCode) || compareSizes(aSize, bSize, direction);
    return bProductCode.localeCompare(aProductCode) || compareSizes(aSize, bSize, direction);
  });
};

/* eslint-disable no-console */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-useless-catch */
/* eslint-disable no-param-reassign */
import { isArray, isEmpty } from 'lodash';
import InfoTooltip from '../../components/InfoTooltip';
import { baseUrl, endpoints, routerUrls } from '../../axios/endpoints';
import { generalAxiosRequest } from '../../axios/axiosFunctions';
import { SimDateTime } from '../../utils/datetime';
import { agedOrderValues, tooltips } from './agedOrders.constants';

// Util Functions

/**
 * Returns an object containing priority classname styling and
 * bodega string translation
 * @param {String} bucket string value of time range of data
 */
export const getStatusPriority = (bucket) => {
  if (!agedOrderValues?.[bucket]) return '';

  return {
    className: agedOrderValues?.[bucket]?.className,
    translationKey: agedOrderValues?.[bucket]?.translationKey,
  };
};

/**
 * Returns age string and status svg for an order
 * @param {String} status tasks view aged order status
 * @param {String} bucket string value of time range of data
 */
export const getAgedOrderStatusDetails = (status, bucket) => {
  if (!agedOrderValues?.[status]) console.error(`New status was returned: ${status}`);
  if (!agedOrderValues?.[status] || !agedOrderValues?.[bucket]) return (<></>);

  const className = status === agedOrderValues.REJECTED_BY_STORE.key ? '-high-priority' : agedOrderValues?.[bucket]?.className;

  return (
    <div className={`agedOrders-grid-items${className}`}>
      <InfoTooltip
        customIcon={agedOrderValues?.[status]?.icon}
        content={`${agedOrderValues?.[bucket]?.translationKey} Days in ${agedOrderValues?.[status]?.text} Queue`}
      />
    </div>
  );
};

/**
 * Builds aged orders icon details tooltip content
 */
export const agedOrdersStatusToolTip = () => {
  const toolTipContent = [];
  tooltips.forEach(tooltip => {
    const status = tooltip?.text?.toUpperCase();
    const icon = tooltip?.icon;
    const value = tooltip?.value?.toUpperCase();

    toolTipContent.push((
      <div className="agedOrders-tooltip-icons">
        {icon ? (<> {icon}<p>{`: ${status}`}</p></>)
          : (
            <>
              <p className={tooltip?.key?.toLowerCase()}>{`${status}: `}</p>
              <p>{value}</p>
            </>
          )}
      </div>
    ));
  });
  return (
    <div className="agedOrders-tooltip">
      {toolTipContent}
    </div>
  );
};

/**
 * Fetch task data from Task Views V3 via fulfillment type, and status
 * NOTE: TEST DATA ONLY AVAILABLE IN US-201
 * @param {String} storeId Unique identifier for a store
 */
export const fetchTaskViewsData = async (storeId) => {
  const current = SimDateTime.now();
  const sevenDaysAgo = current.minus({ day: 7 }).toISO();

  if (!storeId) return null;

  const filters = `?count=1000&language=en-US&filter=storeId(${storeId})`
  + '&filter=fulfillmentType(SHIP_FROM_STORE,BOPIS)'
  + '&filter=status(STARTED,NOT_STARTED)'
  // + '&filter=taskType(PICK_UP)'
  + `&filter=modificationDateAfterEq(${sevenDaysAgo})`
  + `&filter=modificationDateBeforeEq(${current.toISO()})`;

  try {
    const tasksUrl = `${baseUrl(routerUrls.NIKE_API)}${endpoints.TASK_VIEWS_V3.url}${filters}`;
    const tasksData = await generalAxiosRequest('GET', tasksUrl, endpoints.TASKS_V3);
    return tasksData?.objects;
  } catch (err) {
    throw err;
  }
};
/**
 * Groups Aged Order data into orders from 1, 2, and 7 days ago.
 * @param {Array} agedOrders filtered condensed aged orders data
 */
export const sortAgedOrdersDataBydate = (agedOrders) => {
  const local = SimDateTime.now();

  const sevenDaysAgo = local.minus({ day: 7 }).valueOf();
  const twoDaysAgo = local.minus({ day: 2 }).valueOf();
  const oneDayAgo = local.minus({ day: 1 }).valueOf();

  const lowPriority = [];
  const mediumPriority = [];
  const highPriority = [];

  if (!agedOrders || !isArray(agedOrders)) {
    return [];
  }

  agedOrders.forEach(order => {
    const creationDate = SimDateTime.toDateTime(order.creationDate).valueOf();

    if (creationDate >= oneDayAgo) {
      lowPriority.push({
        ...order,
        timeRange: agedOrderValues.LOW_PRIORITY.key,
      });
    }

    if (oneDayAgo > creationDate && creationDate >= twoDaysAgo) {
      mediumPriority.push({
        ...order,
        timeRange: agedOrderValues.MEDIUM_PRIORITY.key,
      });
    }

    if (twoDaysAgo > creationDate && creationDate >= sevenDaysAgo) {
      highPriority.push({
        ...order,
        timeRange: agedOrderValues.HIGH_PRIORITY.key,
      });
    }
  });

  return [...highPriority, ...mediumPriority, ...lowPriority];
};

/**
 * Splits aged order data into consumable filtered groups
 * for UI
 * @param {Array} agedOrders Condensed aged order data
 */
export const filterAgedOrdersData = (agedOrders) => {
  const result = [];
  let bopisData = {};
  let sfsData = {};

  if (!agedOrders || !isArray(agedOrders) || isEmpty(agedOrders)) return result;
  const unfilteredBopisOrders = agedOrders.filter(order => order.journeyType === agedOrderValues.BOPIS.key);
  const unfilteredSfsOrders = agedOrders.filter(order => order.journeyType === agedOrderValues.SHIP_FROM_STORE.key);

  bopisData = sortAgedOrdersDataBydate(unfilteredBopisOrders);
  sfsData = sortAgedOrdersDataBydate(unfilteredSfsOrders);

  if (unfilteredBopisOrders[0]?.journeyType) {
    result.push({
      journeyType: unfilteredBopisOrders[0]?.journeyType,
      orders: bopisData,
      numOfOrders: unfilteredBopisOrders?.length,
    });
  }

  if (unfilteredSfsOrders[0]?.journeyType) {
    result.push({
      journeyType: unfilteredSfsOrders[0]?.journeyType,
      orders: sfsData,
      numOfOrders: unfilteredSfsOrders?.length,
    });
  }

  return result;
};

/**
 * Pull Aged Order Widget data from payload
 * @param {object} tasksData raw payload of tasksviews aged orders data
 */
export const getAgedOrdersData = (tasksData) => {
  const agedOrderData = [];

  if (!tasksData || !isArray(tasksData) || tasksData?.length === 0) return agedOrderData;

  tasksData.forEach((task) => {
    const agedOrderItem = {
      journeyType: task?.fulfillmentType,
      orderId: task?.taskOrigin?.orderNumber,
      creationDate: task?.creationDate,
      status: task?.status,
      fulfillmentStatus: task?.items?.[0]?.status,
      items: task?.items,
      athleteId: task?.requestingAthleteId?.split(';')?.[2],
      description: task?.description,
      taskType: task?.taskType,
    };
    agedOrderData.push(agedOrderItem);
  });

  const modifiedAgedOrders = filterAgedOrdersData(agedOrderData);
  return modifiedAgedOrders;
};

/* eslint-disable import/prefer-default-export */
export const sizeComplianceStepsArray = [
  {
    content: 'Filter by Bin or no Bin to refiner your search results',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'auto',
    spotlightClicks: false,
    spotlightPadding: 10,
    target: '[data-testid="toggle-noBin"]',
    title: 'Filter Size Compliance By Bin',
  },
  {
    content: 'Filter data by clicking a facet checkbox to change results in the data table. Select/deselect multiple items in a facet group section for a flexible search result',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'top',
    spotlightClicks: true,
    spotlightPadding: 10,
    target: '[data-testid="facet-division.Apparel"] > div:nth-child(1)',
    title: 'Size Compliance Facets',
  },
  {
    content: 'Narrow down your search by using the search box to modify to filter your specific item in the report. Hover over the info icon for additional details on how to customize your search. Key in your search details and click the magnifying glass to complete the search',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'auto',
    spotlightClicks: true,
    spotlightPadding: 10,
    target: '[data-testid="size-compliance-dynamic-search"]',
    title: 'Quick Search Tool',
  },
  {
    content: 'inboundVisibilityS7',
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    placement: 'auto',
    spotlightClicks: true,
    spotlightPadding: 10,
    target: '[data-testid="size-compliance-clear-facets-button"]',
    title: 'inboundVisibilityS7Title',
  },
  {
    content: 'inboundVisibilityS8',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'top',
    spotlightClicks: true,
    spotlightPadding: 0,
    target: 'div[role^="toolbar"] > div:nth-child(2)',
    title: 'inboundVisibilityS8Title',
  },
  {
    content: 'inboundVisibilityS9',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'top',
    spotlightClicks: true,
    spotlightPadding: 0,
    target: '[data-testid="columnStep"]',
    title: 'stockRoomOrgS2Title',
  },
  {
    content: 'inboundVisibilityS10',
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    placement: 'auto',
    spotlightClicks: true,
    spotlightPadding: 0,
    target: 'thead tr th', // select all checkboxes
    title: 'inboundVisibilityS10Title',
  },
  {
    content: 'Click on a single checkbox for hand selecting row items from the list below',
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    placement: 'auto',
    spotlightClicks: true,
    target: '.MuiTableBody-root > tr',
    title: 'Selecting a Single Row Item',
  },
  {
    content: 'Click on the list icon to create a picklist for all the selected items in the size compliance data table',
    disableBeacon: true,
    hideCloseButton: true,
    placement: 'top',
    spotlightClicks: true,
    spotlightPadding: 0,
    target: '[data-testid="size-compliance-picklist-button"]',
    title: 'Create a Picklist',
  },
  {
    content: 'To create a picklist enter a name in the the input feild below. Click submit or cancel to complete the task.',
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    placement: 'auto',
    spotlightClicks: true,
    spotlightPadding: 0,
    target: '[data-testid="size-compliance-picklist-form-container"]',
    title: 'Submit or Cancel a Picklist',
    styles: {
      overlay: {
        zIndex: '20001',
      },
      options: {
        zIndex: '20001',
      },
    },
  },
];

// eslint-disable-next-line import/prefer-default-export
export const sizeComplianceSteps = (isSizeComplianceRefillsEnabled, translatedSteps) => {
  const newSteps = translatedSteps;

  if (!isSizeComplianceRefillsEnabled) {
    newSteps.splice(6, 4);
  }

  return newSteps;
};

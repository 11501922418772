import axios from 'axios';
import { baseUrl, routerUrls, endpoints } from '../../axios/endpoints';

export default class DeviceAssignmentAdapter {
  getDevices({ auth, storeId }) {
    return axios({
      method: 'GET',
      url: `${baseUrl(routerUrls.SIMWEB_BFF)}${endpoints.DEVICE_ASSIGNMENT.url}?storeId=${storeId}`,
      headers: {
        Authorization: `Bearer ${auth}`,
        'Content-Type': 'application/json',
      },
    }).then(({ data }) => data.objects);
  }

  getProfiles({ auth, country, storeNumber }) {
    return axios({
      method: 'GET',
      url: `${baseUrl(routerUrls.SIMWEB_BFF)}${endpoints.DEVICE_ASSIGNMENT.url}`
            + `${endpoints.DEVICE_ASSIGNMENT.actions.profiles}?store=${country},${storeNumber}`,
      headers: {
        Authorization: `Bearer ${auth}`,
        'Content-Type': 'application/json',
      },
    }).then(({ data }) => data.objects);
  }

  getEmployeeNumber({ auth, athleteId }) {
    return axios({
      method: 'GET',
      url: `${baseUrl(routerUrls.SIMWEB_BFF)}${endpoints.DEVICE_ASSIGNMENT.url}`
      + `${endpoints.DEVICE_ASSIGNMENT.actions.profiles}/${athleteId}`,
      headers: {
        Authorization: `Bearer ${auth}`,
        'Content-Type': 'application/json',
      },
    }).then(({ data }) => data.employeeNumber);
  }

  getSessions({ auth, storeId }) {
    return axios({
      method: 'GET',
      url: `${baseUrl(routerUrls.SIMWEB_BFF)}${endpoints.DEVICE_ASSIGNMENT.url}`
      + `${endpoints.DEVICE_ASSIGNMENT.actions.sessions}?storeId=${storeId}`,
      headers: {
        Authorization: `Bearer ${auth}`,
        'Content-Type': 'application/json',
      },
    }).then(({ data }) => data.objects);
  }

  postSession({ auth, postBody }) {
    return axios({
      method: 'POST',
      url: `${baseUrl(routerUrls.SIMWEB_BFF)}${endpoints.DEVICE_ASSIGNMENT.url}`
      + `${endpoints.DEVICE_ASSIGNMENT.actions.sessions}`,
      headers: {
        Authorization: `Bearer ${auth}`,
        'Content-Type': 'application/json',
      },
      data: postBody,
    }).then(({ data }) => data);
  }

  putSession({ auth, sessionId, putBody }) {
    return axios({
      method: 'PUT',
      url: `${baseUrl(routerUrls.SIMWEB_BFF)}${endpoints.DEVICE_ASSIGNMENT.url}`
      + `${endpoints.DEVICE_ASSIGNMENT.actions.sessions}/${sessionId}`,
      headers: {
        Authorization: `Bearer ${auth}`,
        'Content-Type': 'application/json',
      },
      data: putBody,
    }).then(({ data }) => data);
  }

  deleteSession({ auth, sessionId }) {
    return axios({
      method: 'DELETE',
      url: `${baseUrl(routerUrls.SIMWEB_BFF)}${endpoints.DEVICE_ASSIGNMENT.url}`
      + `${endpoints.DEVICE_ASSIGNMENT.actions.sessions}/${sessionId}`,
      headers: {
        Authorization: `Bearer ${auth}`,
        'Content-Type': 'application/json',
      },
    }).then(({ status }) => status);
  }
}

import React, {
  createContext, useContext, useState, useEffect,
} from 'react';
import { node, object } from 'prop-types';

import { StoreConfigContext } from '../../context/StoreConfig.provider';
import { SimWebContext } from '../../context/SimWeb.provider';

export const AdminContext = createContext({});

const AdminProvider = ({ children, mockedValue }) => {
  const { Provider } = AdminContext;

  const { storeConfig, getMessage } = useContext(SimWebContext);
  const {
    updateStoreConfig, setDisplayStatus, displayStatusMessage,
  } = useContext(StoreConfigContext);

  const configsArray = [
    {
      value: storeConfig?.footwearRefill?.value,
      key: 'sim.footwearRefill',
      label: getMessage('footwearRefill'),
      update: false,
    },
    {
      value: storeConfig?.equipmentHashRefill?.value,
      key: 'sim.equipmentHashRefill',
      label: getMessage('equipmentHashRefill'),

      update: false,
    },
    {
      value: storeConfig?.isTutorialEnabled?.value,
      key: 'sim.isTutorialEnabled',
      label: getMessage('enableTutorials'),
      update: false,
    },
  ];

  const [loadingConfigs, setLoading] = useState(false);
  const [handleSubmit, setHandleSubmit] = useState(false);
  const [updatedConfigs, setUpdatedConfigs] = useState(configsArray);

  const updateFieldChanged = (config, index) => {
    const newArr = [...updatedConfigs];
    newArr[index].value = !config.value;
    newArr[index].update = !config.update;

    setUpdatedConfigs(newArr);
  };

  useEffect(() => {
    if (handleSubmit) {
      setLoading(true);
      updatedConfigs.map(config => {
        if (config.update === true) {
          updateStoreConfig(config.key, config.value);
          setDisplayStatus(true);
        }
      });
      setHandleSubmit(false);
      setTimeout(() => {
        setLoading(false);
      }, 500);
      setTimeout(() => {
        setDisplayStatus(false);
      }, 10000);
    }
  }, [handleSubmit, updatedConfigs]);

  return (
    <Provider
      value={mockedValue ?? {
        configsArray,
        updatedConfigs,
        loadingConfigs,
        handleSubmit,
        setHandleSubmit,
        updateFieldChanged,
        displayStatusMessage,
      }}
    >
      {children}
    </Provider>
  );
};

AdminProvider.propTypes = {
  children: node,
  mockedValue: object,
};

AdminProvider.defaultProps = {
  children: {},
  mockedValue: null,
};

export default AdminProvider;

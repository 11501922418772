/* eslint-disable object-curly-newline */
/* eslint-disable max-len */
import React, {
  useContext, useEffect, useMemo,
} from 'react';
import { DialogContent } from '@mui/material';
import { LoadingIndicator } from '../../../assets/Svgs/index';
import ErrorAlert from '../../../components/alerts/Error';
import { getCartonNumber } from '../inboundVisibility.utils';
import PurchaseOrderDialogTable from './PurchaseOrderDialogTable';
import DialogHeader from '../../../components/dialogHeader/DialogHeader';

import { SimWebContext } from '../../../context/SimWeb.provider';
import { tutorialContext } from '../../../context/Tutorial.provider';
import { InboundVisibilityContext } from '../InboundVisibility.provider';
import { InboundVisibilityTableContext } from '../InboundVisiblityTable.provider';
import { PurchaseOrderDialogContext } from './InboundVisibilityPurchaseOrderDialog.provider';

/**
 * Takes in Inbound Visibility purchase order details data and format it to be show in the dialog
 * that is shown on the Inbound Visibility page when and order is clicked on.
 */

const PurchaseOrderDialogDetails = () => {
  const { formatMessage } = useContext(SimWebContext);
  const { startTutorial, isbackClicked } = useContext(tutorialContext);
  const { dialogData, setDialogData, rfidEnabled } = useContext(InboundVisibilityContext);
  const { rows, setIsOpen, poIndex, setPOIndex, setDocProNumber } = useContext(InboundVisibilityTableContext);
  const { setIndex, loading, data, error, dataWithDotComAvailability } = useContext(PurchaseOrderDialogContext);

  // allows navigation through main table to details
  useEffect(() => {
    setDialogData(rows?.[poIndex]?.details);
    setDocProNumber(rows?.[poIndex]?.docNumber);
    setIndex(0);
  }, [poIndex]);

  const dialogDetails = useMemo(() => {
    const noData = !dialogData || dialogData === null || !dialogData?.length || !dialogData[0]?.poNumber;

    if (loading && !startTutorial && !data) {
      return (
        <div className="poDialog-loading-container">
          <LoadingIndicator height="100px" width="100px" extraClass="poDialog-loading" />
        </div>
      );
    }

    if (error || noData || (!dialogData && !startTutorial)) {
      return (
        <ErrorAlert
          errorObject={error}
          apiName="Product Availability API"
          pageCode=""
        />
      );
    }

    return <PurchaseOrderDialogTable dataWithDotComAvailability={dataWithDotComAvailability} />;
  }, [data, error, rfidEnabled, dialogData, startTutorial, isbackClicked, dataWithDotComAvailability]);

  const dialogTitle = (
    <>
      <strong>{formatMessage('purchaseOrderListStore', { store: '' })}</strong>
      {dialogData && ` ${dialogData[0]?.poNumber} (${getCartonNumber(dialogData)} cartons)`}
    </>
  );

  return (
    <div data-testid="inbound-visibility-dialog-muiDataTable">
      <DialogHeader
        index={poIndex}
        setIsOpen={setIsOpen}
        rowCount={rows?.length}
        setIndex={setPOIndex}
        nodeTitle={dialogTitle}
      />
      <DialogContent>
        {dialogDetails}
      </DialogContent>
    </div>
  );
};

export default PurchaseOrderDialogDetails;

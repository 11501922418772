import DIVISIONS from '../../constants/divisions';

export const XSN = 'XSN';
export const ASN = 'ASN';

export const ShipmentTypes = {
  Xsn: XSN,
  Asn: ASN,
};

const genderAge = {
  name: 'genderAge',
  operator: 'EQUALS',
  values: [
    'Mens', 'Womens', 'Kids', 'Other',
    // potentially deprecated
    'Boys Big Kid', 'Girls Big Kid', 'Adult Unisex', 'Boys Little Kid', 'Boys Infant/Toddler', 'Unisex Big Kid', 'Girls Little Kid', 'Unisex Little Kid', 'Girls Infant/Toddler', 'Unisex Infant/Toddler',
  ],
};

const category = {
  name: 'retailCategory',
  operator: 'EQUALS',
  values: [
    'Action Sports', 'American Football', 'Athletic Training', 'Baseball/Softball', 'Basketball', 'Causes', 'Cole Haan', 'Collegiate', 'Converse', 'Digital Sport', 'Football/Soccer', 'Golf', 'Hurley', 'Jordan', 'Lacrosse', 'Livestrong', 'Mens Training', 'Nike SB', 'Other', 'Performance Swim', 'Rugby', 'Running', 'Sportswear', 'Tennis', 'Umbro', 'Volleyball', 'Walking', 'Womens Training', 'Young Athletes', 'Bras',
    // potentially deprecated
    'Nike Sportswear', 'Jordan Brand', 'Nike Basketball', 'Nike Golf', 'Baseball Other Field Sports', 'All Other',
  ],
};

const processingType = {
  name: 'processingType',
  operator: 'EQUALS',
  values: [
    'Replenishment', 'LoadIn',
  ],
};

export const apparelDivisions = DIVISIONS.apparel.aliases;
export const footwearDivisions = DIVISIONS.footwear.aliases;
export const equipmentDivisions = DIVISIONS.equipment.aliases;

// using these values to iterate on
export const labelsArray = ['Gender', 'Category', 'LI/RP'];

export const inboundVisibilityfilters = {
  category,
  genderAge,
  processingType,
};

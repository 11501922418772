/* eslint-disable import/prefer-default-export */
/**
 * Builds a column name to index mapping object
 * @param {array} columns a list of table columns
 */
export const buildColumnIndexMappings = (columns) => {
  const mapping = {};

  columns?.map((column, index) => {
    if (!(column.name in mapping)) mapping[column.name] = index;
  });
  return mapping;
};

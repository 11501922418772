/**
 * Since the MUI table has default sorting on it we need to obtain that
 * defaulted sorted data for the modal navigation that way we have the accurate index
 * @param {array} rows an array of date with dates and time stamps
 * @param {number} index location of the obj in the given array
 * @param {string} direction sort directions such as desc/asc
*/
// eslint-disable-next-line import/prefer-default-export
export const sortByDefault = (rows, index, direction) => {
  if (!rows) return [];

  const sortedValue = rows.length && [...rows];
  sortedValue?.sort((a, b) => {
    const first = a?.[index];
    const second = b?.[index];
    const check = direction === 'desc' ? new Date(second) - new Date(first) : new Date(first) - new Date(second);

    if (Math.sign(check) === 1) {
      return 1;
    } if (Math.sign(check) === -1) {
      return -1;
    }
    return 0;
  });
  return sortedValue;
};

import React, {
  useState, createContext, useEffect, useContext,
} from 'react';
import { node, object } from 'prop-types';
import { generalAxiosRequest } from '../axios/axiosFunctions';
import { baseUrl, routerUrls, endpoints } from '../axios/endpoints';
import { TranslationsContext } from './Translations.provider';

import { LoginContext } from './Login.provider';

export const StoreConfigContext = createContext({});

const StoreConfigProvider = ({ children, mockedValue }) => {
  const { Provider } = StoreConfigContext;
  const { getMessage } = useContext(TranslationsContext);

  const [storeConfig, setStoreConfig] = useState({});
  const [configRetrieved, setConfigRetrieved] = useState(false);
  const [configStatusMessage, setConfigStatusMessage] = useState(null);
  const [displayStatusMessage, setDisplayStatus] = useState(false);
  const [refetchStoreConfig, setRefetch] = useState(false);

  const {
    storeNumber, store, country, isLoggedIn,
  } = useContext(LoginContext);

  useEffect(() => {
    if (!isLoggedIn) {
      setStoreConfig({});
    }
    // eslint-disable-next-line consistent-return
    const fetchStoreConfig = async () => {
      const values = {};
      const url = `${baseUrl(routerUrls.DELEDGE)}${endpoints.STORE_CONFIG.url}/${country}-${storeNumber}`;
      try {
        const response = await generalAxiosRequest('GET', url, endpoints.STORE_CONFIG);

        response?.objects?.map(configValue => {
          const splitKey = configValue?.key?.split('.');
          const groupName = splitKey[0];
          if (groupName === 'sim' || groupName === 'refill') {
            const keyName = splitKey.slice(1).join('.');
            if (!values[keyName]) { values[keyName] = {}; }
            values[keyName] = {
              value: configValue.value,
              scope: configValue.scopeType,
            };
          }
        });
        setStoreConfig(values);
        setConfigRetrieved(true);
        setRefetch(false);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('error', error);
      }
    };

    if ((storeNumber && country && isLoggedIn) || refetchStoreConfig) {
      fetchStoreConfig();
    }
  }, [isLoggedIn, storeNumber, country, refetchStoreConfig]);

  const updateStoreConfig = async (key, value) => {
    const body = {
      key, value, scopeType: 'STORE', scope: store,
    };

    const url = `${baseUrl(routerUrls.DELEDGE)}${endpoints.STORE_CONFIG_POST.url}`;

    try {
      const response = await generalAxiosRequest('POST', url, endpoints.STORE_CONFIG_POST, true, null, body);

      if (response) {
        setConfigStatusMessage(getMessage('configUpdateSuccessMessage'));
      }
    } catch (error) {
      setConfigStatusMessage(getMessage('ConfigUpdatedErrorMessage'));
      // eslint-disable-next-line no-console
      console.log('error', error);
    }
  };

  return (
    <Provider
      value={mockedValue ?? {
        configRetrieved,
        refetchStoreConfig,
        storeConfig,
        displayStatusMessage,
        configStatusMessage,
        setDisplayStatus,
        updateStoreConfig,
        setRefetch,
      }}
    >
      {children}
    </Provider>
  );
};

StoreConfigProvider.defaultProps = {
  children: {},
  mockedValue: null,
};

StoreConfigProvider.propTypes = {
  children: node,
  mockedValue: object,
};

export default StoreConfigProvider;

const n = 'numeric';
const d2 = '2-digit';

export const DATE_SHORT_WITH_APPENDED_ZEROS = {
  year: n,
  month: d2,
  day: d2,
};

export const DATE_TIME_SHORT_WITH_APPENDED_ZEROS = {
  year: n,
  month: d2,
  day: d2,
  hour: n,
  minute: d2,
};

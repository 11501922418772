/* eslint-disable no-plusplus */
import React, { useMemo, useContext } from 'react';
import { object } from 'prop-types';
import { Typography, Tooltip, Zoom } from '@mui/material';
import tinycolor from 'tinycolor2';
import { SimWebContext } from '../../context/SimWeb.provider';

import './ColorChip.css';

/**
   * Color display for product colors used in the product details component
   * @param {object} productContent - coming from CPA with details colors correlated to a product
*/
const ColorChips = ({ productContent }) => {
  const { getMessage } = useContext(SimWebContext);

  const colors = productContent?.colors?.map((color) => (color.type !== 'SIMPLE' ? color.name : null)).filter((a) => a);
  const distinctColors = Array.from(new Set(colors));

  const chips = useMemo(() => {
    let className = 'chip';
    let i = 0;

    return (
      <table>
        <tbody>
          <tr>
            <td data-testid="color-chips" className="color-chips" key="color-chips">
              {productContent?.colors?.map(
                (color) => {
                  if (color.type === 'SIMPLE') {
                    return null;
                  }

                  let styleObj = {
                    background: `#${color.hex || '000'}`,
                    color: tinycolor(`#${color.hex}`).isLight() ? '#000' : '#FFF',
                  };

                  if (color.name.toLowerCase() === 'multi-color') {
                    styleObj = {};
                    className = 'chip chip-multiColor';
                  }

                  return (
                    <div style={{ marginRight: '5px' }} key={`color.name.${i++}`}>
                      <Tooltip
                        key={`${color.type}.${color.name}.${i++}`}
                        title={`${color.type} - ${color.name}`}
                        TransitionComponent={Zoom}
                      >
                        <div className={className} style={styleObj} />
                      </Tooltip>
                    </div>
                  );
                },
              )}
            </td>
          </tr>
        </tbody>
      </table>
    );
  }, [productContent?.colors]);

  if (!distinctColors) return null;

  return (
    <div style={{ margin: '10px 0' }}>
      <Typography gutterBottom variant="h5">
        {getMessage('colors')}
      </Typography>
      <Typography variant="h6">{distinctColors.join(', ')}</Typography>
      {chips}
    </div>
  );
};

ColorChips.propTypes = {
  productContent: object.isRequired,
};

export default ColorChips;

/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import { routerUrls, endpoints, baseUrl } from '../../axios/endpoints';
import { generalAxiosRequest } from '../../axios/axiosFunctions';
import { logErrorAction } from '../../utils/newRelicPageActions';

export const fetchOOSAuditsData = async (store, dateAfter, dateBefore) => {
  if (!store || !dateBefore || !dateAfter) return null;

  try {
    const filters = `?filter=store(${store})&&filter=creationDateBeforeEq(${dateBefore.replace('+', '%2B')})&filter=creationDateAfterEq(${dateAfter.replace('+', '%2B')})`;
    const url = `${baseUrl(routerUrls.DELEDGE)}${endpoints.OOS_AUDITS.url}${filters}`;
    const oosAuditsData = await generalAxiosRequest('GET', url, endpoints.OOS_AUDITS);

    return oosAuditsData?.objects;
  } catch (error) {
    logErrorAction('OOSAuditsError', error, 'error retrieving data', {
      message: error?.message, store, dateBefore, dateAfter,
    });
    throw error;
  }
};

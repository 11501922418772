import React from 'react';
import {
  string, bool, object, node,
} from 'prop-types';
import { LoadingIndicator } from '../../../assets/Svgs';
import ErrorAlert from '../../../components/alerts/Error';

const PageContent = ({
  id, loading, error, reportDetails,
}) => {
  if (loading) {
    return (
      <LoadingIndicator
        dataTestId={`${id}-loading`}
        extraClass="loading-indicator"
      />
    );
  }

  return (
    <>
      {error ? <ErrorAlert errorObject={error} apiName={id} pageCode="" /> : null}
      {reportDetails}
    </>
  );
};

PageContent.propTypes = {
  id: string.isRequired,
  loading: bool.isRequired,
  error: object,
  reportDetails: node,
};

PageContent.defaultProps = {
  error: null,
  reportDetails: null,
};

export default PageContent;

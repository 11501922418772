import {
  routerUrls, baseUrl, chooseTaskService,
} from './endpoints';
import { generalAxiosRequest } from './axiosFunctions';
import { logErrorAction } from '../utils/newRelicPageActions';
/**
   * A function that creates a pick list from the Size Compliance Report
   * @param {obj} variables the variables obj we are passing in the body
   * @param {string} taskService config value for the version of the task service to use
   * @param {string} report which report the service is being called in
*/
// eslint-disable-next-line import/prefer-default-export
export const createProductRefillPickList = async (variables, taskService, report) => {
  const {
    storeId, name, items, athleteId,
  } = variables;
  const endpoint = chooseTaskService(taskService);

  const v2body = {
    storeId,
    name,
    items,
    classification: {
      type: 'PICK_LIST',
      agent: 'nike.retail.web',
    },
    status: 'NOT_STARTED',
    requestingAthleteId: athleteId,
  };

  const v3Body = {
    storeId,
    description: name,
    items,
    taskType: 'PICK',
    fulfillmentType: 'PICK_LIST',
    requestingAthleteId: athleteId,
  };

  const url = `${baseUrl(routerUrls.NIKE_API)}${endpoint.url}`;
  const body = taskService?.toLowerCase() === 'v3' ? v3Body : v2body;
  try {
    return await generalAxiosRequest('POST', url, endpoint, true, null, body)
      .then(resp => resp)
      .catch(error => {
        logErrorAction('createProductRefillPickList', error, `${report} Report - error retrieving data`, {
          message: error?.message, body,
        });
        throw error;
      });
  } catch (error) {
    return Promise.reject(error);
  }
};

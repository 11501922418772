/* eslint-disable no-useless-catch */
/* eslint-disable no-constant-condition */
/* eslint-disable no-loop-func */
/* eslint-disable consistent-return */
/* eslint-disable no-await-in-loop */
import {
  cloneDeep, isArray, mergeWith,
} from 'lodash';
import {
  routerUrls, endpoints, baseUrl,
} from '../../axios/endpoints';
import { generalAxiosRequest } from '../../axios/axiosFunctions';

/**
   * A function to merge assigned values
   * @param objValue the destination object
   * @param srcValue the source object
   * @param key a key on the object
*/
const mergeCustomizer = (objValue, srcValue, key) => {
  if (isArray(objValue) && key === 'product') {
    return objValue.concat(srcValue);
  }
};

/**
   * A function that gets the Size Compliance Data
   * @param {obj} variables the variables obj we're passing in the body
*/
export const getSizeCompliance = async (variables) => {
  const url = `${baseUrl(routerUrls.SIM_REPORTS)}${endpoints.SIZE_COMPLIANCE.url}`;
  try {
    return generalAxiosRequest('POST', url, endpoints.SIZE_COMPLIANCE, true, null, variables)
      .then(resp => resp);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
   * A function that gets the Size Compliance Data
   * @param {obj} variables the variables obj we're passing in the body
*/
export const fetchSizeComplianceData = async (variables) => {
  let pageNumber = 1;
  let totalCount = 0;
  const results = {};

  const copyOfVariables = cloneDeep({ ...variables, pageNumber });

  const { storeId, filters } = variables;
  if (!storeId || !filters) return null;

  while (true) {
    let responsePage = null;

    const url = `${baseUrl(routerUrls.SIM_REPORTS)}${endpoints.SIZE_COMPLIANCE.url}`;
    await generalAxiosRequest('POST', url, endpoints.SIZE_COMPLIANCE, true, null, copyOfVariables)
      .then((resp) => {
        const { pages, ...restSizeComplianceData } = resp;
        pageNumber += 1;
        totalCount += pages?.currentCount;
        responsePage = pages;
        copyOfVariables.pageNumber = pageNumber;
        mergeWith(results, restSizeComplianceData, mergeCustomizer);
      });

    if (totalCount >= responsePage?.totalCount) break;
  }
  return results;
};

import React, {
  useState, createContext, useContext, useEffect, useMemo,
} from 'react';
import { node, object } from 'prop-types';
import parseArrayOrStringForTable from '../../utils/parseArrayOrStringForTable';
import {
  baseUrl, endpoints, routerUrls, reportRegions,
} from '../../axios/endpoints';
import { SimWebContext } from '../../context/SimWeb.provider';
import { generalAxiosRequest } from '../../axios/axiosFunctions';
import ProductDetailContainer from '../../components/productDetails/ProductDetailContainer';
import { CPAContext } from '../../context/cpa/CPA.provider';
import { baseDialogWrapper as Dialog } from '../baseView/BaseViewPageUtils';

export const ProductMovementContext = createContext({});

/**
  * Product Movement Provider provides:
  *     - Columns and rows for both summary and detailed views
  *     - Base table options that can be extended
  *     - Product info modal declaration
  *     - Data
  *     - OnGoClick function for triggering data fetch
  *
  * @param {node} children - data that we iterate on and create data visuals
  * @param {object} mockedValue - mock values for testing
 */
const ProductMovementProvider = ({ children, mockedValue }) => {
  const { Provider } = ProductMovementContext;

  const { getProducts } = useContext(CPAContext);
  const {
    storeId, SimDateTime, getMessage, locale, region,
  } = useContext(SimWebContext);

  // Data handling props
  const [startDate, setStartDate] = useState(SimDateTime.startOfDay().toISO());
  const [endDate, setEndDate] = useState(SimDateTime.endOfDay().toISO());
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedScan, setSelectedScan] = useState(null);
  // modal props
  const [isOpen, setIsOpen] = useState(false);
  const [modalStyleColor, setModalStyleColor] = useState('');
  const [tableIndex, setTableIndex] = useState(0);

  // This hook is used to fetch the product details for the product details modal.  Data is then set in the modal component itself.
  useEffect(() => {
    getProducts(modalStyleColor);
  }, [modalStyleColor]);

  // Used to keep the scrolling in product modal in sync with the nested data in the details table view.
  useEffect(() => {
    setModalStyleColor(selectedScan?.products[tableIndex]?.styleColor);
  }, [tableIndex]);

  // Fetch the product movement data
  useEffect(() => {
    const variables = {
      creationDateAfterEq: SimDateTime.toUtcISO(startDate),
      creationDateBeforeEq: SimDateTime.toUtcISO(endDate),
      storeId,
    };

    const fetchProductMovementData = async () => {
      setData([]);
      setError(null);
      try {
        const url = `${baseUrl(routerUrls.SIMWEB_BFF)}${endpoints.PRODUCT_MOVEMENT_BFF.url}`;
        const response = await generalAxiosRequest('POST', url, endpoints.PRODUCT_MOVEMENT_BFF, true, null, { region: reportRegions[region], variables });
        setData(response);
      } catch (error) {
        setError(error);
      }
      setLoading(false);
    };

    if (storeId && startDate && endDate) {
      setLoading(true);
      fetchProductMovementData();
    }
  }, [startDate, endDate, storeId]);

  // Used to label and denote which report the base view components are rendering
  const id = 'productMovement';

  const summaryColumns = [
    {
      name: 'date',
      label: getMessage('date'),
      options: {
        customBodyRender: value => (
          new Date(value).toLocaleString(locale)
        ),
      },
    },
    {
      name: 'destination',
      label: getMessage('destination'),
      options: {
        customBodyRender: value => parseArrayOrStringForTable(value, getMessage),
      },
    },
    {
      name: 'gender',
      label: getMessage('gender'),
      options: {
        customBodyRender: value => parseArrayOrStringForTable(value, getMessage),
      },
    },
    {
      name: 'division',
      label: getMessage('division'),
      options: {
        customBodyRender: value => parseArrayOrStringForTable(value, getMessage).toUpperCase(),
      },
    },
    {
      name: 'category',
      label: getMessage('category'),
      options: {
        customBodyRender: value => parseArrayOrStringForTable(value, getMessage),
      },
    },
    {
      name: 'scanned',
      label: getMessage('scanned'),
    }];

  const detailsColumns = [{
    name: 'styleColor',
    label: getMessage('styleColor'),
  },
  {
    name: 'upc',
    label: getMessage('upc'),
  },
  {
    name: 'description',
    label: getMessage('description'),
  },
  {
    name: 'division',
    label: getMessage('division'),
    options: {
      customBodyRender: value => getMessage(value).toUpperCase(),
    },
  },
  {
    name: 'gender',
    label: getMessage('gender'),
    options: {
      customBodyRender: value => getMessage(value),
    },
  },
  {
    name: 'category',
    label: getMessage('category'),
    options: {
      customBodyRender: value => getMessage(value),
    },
  },
  {
    name: 'size',
    label: getMessage('size'),
  },
  {
    name: 'quantityMoved',
    label: getMessage('quantityMoved'),
  }];

  const tableOptions = {
    selectableRows: 'none',
    selectableRowsHeader: false,
    rowsPerPage: 25,
    rowsPerPageOptions: [15, 25, 50, 100, 1000],
    print: true,
    responsive: 'standard',
    download: false,
    filter: true,
    textLabels: {
      body: {
        noMatch: getMessage('noData'),
      },
    },
    sortOrder: {
      name: 'productCode',
      direction: 'desc',
    },
    fixedHeader: true,
    tableBodyHeight: '625px',
  };

  // Product Modal declaration, this is used in the secondary view of the report.
  const productModal = useMemo(() => {
    const content = (
      <ProductDetailContainer
        index={tableIndex}
        setIsOpen={setIsOpen}
        rowCount={selectedScan?.products?.length}
        setIndex={setTableIndex}
        styleColor={modalStyleColor}
        modal
      />
    );
    return <Dialog id={id} content={content} isOpen={isOpen} setIsOpen={setIsOpen} />;
  }, [isOpen, tableIndex, modalStyleColor, selectedScan?.products?.length]);

  // Used on the date range with options
  const onDateChange = (options) => {
    const { startDate, endDate } = options;
    setStartDate(SimDateTime.toUtcISO(startDate));
    setEndDate(SimDateTime.toUtcISO(endDate));
  };

  return (
    <Provider
      value={mockedValue ?? {
        data,
        summaryColumns,
        detailsColumns,
        selectedScan,
        productModal,
        tableOptions,
        error,
        loading,
        startDate,
        endDate,
        id,
        datepicker: {
          startDate,
          endDate,
          handleDateChange: onDateChange,
        },
        setSelectedScan,
        setModalStyleColor,
        setTableIndex,
        setIsOpen,
      }}
    >
      {children}
    </Provider>
  );
};

ProductMovementProvider.defaultProps = {
  children: {},
  mockedValue: null,
};

ProductMovementProvider.propTypes = {
  children: node,
  mockedValue: object,
};

export default ProductMovementProvider;

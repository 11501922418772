import React, { useContext, useEffect, useState } from 'react';
import { startCase } from 'lodash';
import { Route, Redirect, useHistory } from 'react-router-dom';
import { LoadingIndicator } from '../assets/Svgs/index';
import { ErrorContext } from '../context/Error.provider';
import { LoginContext } from '../context/Login.provider';
import { SimWebContext } from '../context/SimWeb.provider';
import { setDefaultZone, CET_TIMEZONE } from '../utils/datetime';
import { newRelicAction } from '../utils/newRelicPageActions';
/**
 * This component handles access to pages. If users try to access any of the pages and are not logged in
 * they will be redirected to login with an error to sign in. If they are logged in, but try to access
 * a page they are not authorized to view, they will be redirected to the dashboard with an error message.
 * If they are logged in, approved to view, they will be moved to the route they requested.
 *
 * @param {*} param - route props
 * @returns
 */
export const PrivateRoute = ({ ...props }) => {
  const history = useHistory();

  const { setRedirectError } = useContext(ErrorContext);
  const { isLoggedIn, setError, isAdmin } = useContext(LoginContext);
  const {
    storeConfig, configRetrieved, formatMessage, getMessage, storeInfo, setIsLoggedIn, isTrue, isIOS,
  } = useContext(SimWebContext);

  const [unauthorized, setUnauthorized] = useState(false);
  const [hasUserDetails, setHasUserDetails] = useState(false);
  const [unauthorizedToView, setUnauthorizedToView] = useState(false);
  const [previousRoute, setPreviousRoute] = useState('');

  const attemptedRoute = history.location.pathname.split('/')?.[1];

  const routeToConfigMappings = {
    dashboard: true,
    stockroomorganization: true,
    asntracking: true,
    adjustments: true,
    receivinglog: true,
    transfers: true,
    productrefill: true,
    stockroombin: true,
    oosaudits: true,
    example: true,
    pricechanges: storeConfig?.isPriceChangesEnabled?.value || false,
    iteminquiry: storeConfig?.isItemInquiryEnabled?.value || false,
    itemactivity: storeConfig?.isItemActivityEnabled?.value || false,
    stockonhand: storeConfig?.sohEnabled?.value || false,
    inboundvisibility: storeConfig?.isInboundVisibilityEnabled?.value || false,
    selfcheckoutdeviceassignment: storeConfig?.selfCheckoutEnabled?.value || false,
    physicalinventoryupload: storeConfig?.isPIUploadEnabled?.value || false,
    scansummary: storeConfig?.rfidEnabled?.value || false,
    scan: storeConfig?.rfidEnabled?.value || false,
    displayscan: storeConfig?.displayScanEnabled?.value || false,
    productmovement: storeConfig?.rfidEnabled?.value || false,
    rfidmissingscan: storeConfig?.rfidEnabled?.value || false,
    sizecompliance: storeConfig?.sizeComplianceEnabled?.value || false,
    stylemanagement: storeConfig?.isStyleManagementEnabled?.value || false,
    uphreport: storeConfig?.isStorePerformanceEnabled?.value ?? true,
  };

  useEffect(() => {
    if (isTrue || isIOS) return;

    window.addEventListener('storage', (event) => {
      const login = sessionStorage.getItem('login');

      if (event.key === 'REQUESTING_SHARED_CREDENTIALS' && login) {
        const appId = sessionStorage.getItem('appId');
        const language = sessionStorage.getItem('language');
        const source = sessionStorage.getItem('source');
        const loginSource = sessionStorage.getItem('loginSource');

        localStorage.setItem('CREDENTIALS_SHARING', JSON.stringify({
          login, appId, language, source, loginSource,
        }));
        localStorage.removeItem('CREDENTIALS_SHARING');
      }

      if (event.key === 'CREDENTIALS_SHARING' && !login) {
        const tokens = JSON.parse(event.newValue);
        sessionStorage.setItem('login', tokens.login);
        sessionStorage.setItem('appId', tokens.appId);
        sessionStorage.setItem('language', tokens.language);
        sessionStorage.setItem('source', tokens.source);
        sessionStorage.setItem('loginSource', tokens.loginSource);
        setIsLoggedIn(true);
        newRelicAction('simweb-credential-sharing', { isShared: true });

        // forces a refresh to the attempted route which is needed
        // to pull all of the newly set session variables in useEffects below.
        if (!unauthorizedToView && !unauthorized) window.location = attemptedRoute;
      }
    });
  }, []);

  useEffect(() => {
    if (isTrue || isIOS) return;

    localStorage.setItem('REQUESTING_SHARED_CREDENTIALS', Date.now().toString());
    localStorage.removeItem('REQUESTING_SHARED_CREDENTIALS');
  }, []);

  useEffect(() => {
    let isSubscribed = true;
    const checkLogin = async () => {
      const userDetails = (await JSON.parse(sessionStorage.getItem('login'))) || '';
      if (userDetails) setHasUserDetails(true);
      if (!isLoggedIn && !userDetails && isSubscribed) {
        setError(getMessage('pleaseLogIn'));
        setUnauthorized(true);
      }
    };

    checkLogin();

    return () => { isSubscribed = false; };
  }, [isLoggedIn]);

  useEffect(() => {
    if (attemptedRoute.toLowerCase() === 'inboundvisibility' && storeInfo?.isEMEA) {
      setDefaultZone(CET_TIMEZONE);
    } else {
      setDefaultZone(storeInfo?.timezone);
    }
    if (!isAdmin && configRetrieved) {
      if (attemptedRoute !== previousRoute) {
        setPreviousRoute(attemptedRoute);
      }

      if (!routeToConfigMappings[attemptedRoute.toLowerCase()]) {
        setUnauthorizedToView(true);
      } else {
        setUnauthorizedToView(false);
      }
    }
  }, [configRetrieved, attemptedRoute, storeInfo?.timezone]);

  if (configRetrieved) {
    if (unauthorizedToView && isLoggedIn) {
      setRedirectError(formatMessage('unauthorizedToViewErrorMessage', { page: startCase(previousRoute) }));
      // eslint-disable-next-line no-console
      console.error(`Unauthorized to view '${previousRoute ?? 'No Previous Route'}'. Redirecting to dashboard.`);
      return (<Redirect push to="/dashboard" />);
    }
    if (!unauthorizedToView && !unauthorized) {
      return (
        <Route {...props} />
      );
    }
  }

  if ((!configRetrieved || !hasUserDetails) && !unauthorized) {
    return (<LoadingIndicator extraClass="login_loading" />);
  }

  return (<Redirect to="/login" />);
};

export default PrivateRoute;

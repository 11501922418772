import React, { useContext } from 'react';
import { Grid, Typography, Radio } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { func, object } from 'prop-types';
import { SimWebContext } from '../../../context/SimWeb.provider';
import PrintDropZoneStyleColorTable from './PrintDropZoneStyleColorTable';
import Swoosh from '../../../assets/Svgs/Swoosh';

const styles = makeStyles(() => ({
  poStyles: {
    fontSize: '3em',
    padding: '10px',
    textAlign: 'center',
  },
  poStylesBold: {
    fontWeight: '800',
  },
  typographyStyles: {
    padding: '10px',
    textAlign: 'center',
    fontSize: '2rem',
    wordBreak: 'break-word',
  },
  lableStyles: {
    fontSize: '24px',
    textTransform: 'uppercase',
    fontFamily: 'inherit',
    fontWeight: '500',
    textAlign: 'center',
  },
  radioContainer: {
    border: '1px solid #a385c2',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    paddingLeft: '45px',
    marginTop: '12px',
  },
  radioOverride: {
    width: '16',
    height: '16',
    padding: 2,
  },
}));

const PrintDropZoneDialogContainer = ({ item, mergeOrdersBasedOnStyleColors }) => {
  const classes = styles();
  const { getMessage, country, storeInfo } = useContext(SimWebContext);
  const mergedItemDetails = mergeOrdersBasedOnStyleColors(item?.details);
  const isEMEA = storeInfo?.isEMEA;

  // eslint-disable-next-line max-len
  const detailsInChunk = mergedItemDetails?.length && Array.from({ length: Math.ceil(mergedItemDetails.length / 7) }, (v, i) => mergedItemDetails.slice(i * 7, i * 7 + 7));

  const selectDropzoneHeader = (item) => {
    if (country === 'USA' && item.deliveryNumber !== '-') {
      return 'Delivery #: ';
    }
    if (isEMEA) {
      return `${getMessage('document')} #: `;
    }
    return `${getMessage('po')} #: ${item?.poNumber?.slice(0, -3) ?? ''}`;
  };

  const selectDropzoneNumber = (item) => {
    if (country === 'USA' && item.deliveryNumber !== '-') {
      return item.deliveryNumber;
    }
    if (isEMEA) {
      return item.docNumber;
    }
    return `${item?.poNumber?.substr(item?.poNumber?.length - 3)}`;
  };

  const dropZoneContainers = detailsInChunk?.map(details => (
    <div data-testid={`${item.poNumber}-printDropZoneHeader`}>
      <Grid container item spacing={2} className="printDropZoneContainer">
        <Grid item xs={3}>
          <div>
            <Swoosh />
            <Typography className={classes.lableStyles}>
              {getMessage('dropzone')}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={9}>
          <div className="gridBorderStyles">
            <Typography className={classes.poStyles}>
              {selectDropzoneHeader(item)}
              <span className={classes.poStylesBold}>
                {selectDropzoneNumber(item)}
              </span>
            </Typography>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className="gridBorderStyles">
            <Typography className={classes.lableStyles}>
              {`# ${getMessage('of')} 
              ${getMessage('cartons')}`}
            </Typography>
            <Typography
              className={classes.typographyStyles}
              style={{ fontSize: '5em' }}
            >
              {item.carton}
            </Typography>
          </div>
          <div className="gridBorderStyles">
            <Typography className={classes.lableStyles}>
              {`${getMessage('priority')}:`}
            </Typography>
            <Typography
              style={{
                padding: '32px', textAlign: 'center', fontSize: '2rem', wordBreak: 'break-word',
              }}
            />
            <hr className="hrStyles" />
          </div>
          <div className={classes.radioContainer}>
            <Typography className={classes.lableStyles}>
              {`${getMessage('division')}:`}
            </Typography>
            <div>
              <Radio
                id="footwear"
                color="primary"
                disabled={!item.division.toLowerCase().includes('footwear')}
                checked={item.division.toLowerCase().includes('footwear')}
                size="medium"
                className={classes.radioOverride}
              />
              <label htmlFor="footwear" className="printDropZoneFooterCheckboxLabel">
                {getMessage('footwear')}
              </label>
            </div>
            <div>
              <Radio
                id="apparel"
                color="primary"
                disabled={!item.division.toLowerCase().includes('apparel')}
                checked={item.division.toLowerCase().includes('apparel')}
                className={classes.radioOverride}
              />
              <label htmlFor="apparel" className="printDropZoneFooterCheckboxLabel">
                {getMessage('apparel')}
              </label>
            </div>
            <div>
              <Radio
                id="equipment"
                color="primary"
                disabled={!item.division.toLowerCase().includes('equipment')}
                checked={item.division.toLowerCase().includes('equipment')}
                className={classes.radioOverride}
              />
              <label htmlFor="equipment" className="printDropZoneFooterCheckboxLabel">
                {getMessage('equipment')}
              </label>
            </div>
          </div>
        </Grid>
        <Grid item xs={9}>
          <div>
            <PrintDropZoneStyleColorTable
              data={details}
              poNumber={item?.poNumber}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  ));
  return dropZoneContainers;
};

PrintDropZoneDialogContainer.propTypes = {
  item: object,
  mergeOrdersBasedOnStyleColors: func.isRequired,
};

PrintDropZoneDialogContainer.defaultProps = {
  item: {},
};

export default PrintDropZoneDialogContainer;

import { getLocalizedNumber } from './getLocalizedNumber';
import getLocalizedPercent from '../../../utils/getLocalizedPercent';

/**
 * Returns aggregation values into an object with color codes
 * @param {object} values an object of scan aggregation values
 */
export const getAggregationText = (values) => [
  { name: `${values.scanned} / `, color: '#3f51b5' },
  { name: `${values.expected} / `, color: '#17a2b8' },
  { name: `${values.missing} / `, color: '#c05850' },
  { name: `${values.extra} / `, color: '#d0b487' },
  { name: `${values.accuracy}`, color: '#8aa58f' },
];

// TODO: Remove this function when move detail page to V2
/**
 * Returns location scanned aggregation values into an object with color codes
 * @param {object} values an ojbect of scan aggregation values by location
 * @param {boolean} isOffsiteEnabled is offsite enabled in the store
 */
export const getLocationAggregationText = (values, isOffsiteEnabled) => [
  { value: `${values.salesFloor} / `, color: '#3f51b5' },
  { value: `${values.stockRoom}`, color: '#17a2b8' },
  ...(isOffsiteEnabled ? [
    { value: ` / ${values.offsite}`, color: '#c05850' },
  ] : []),
];

/**
 * Get the aggregation values and return with color coldes
 * @param {array} aggregations a list of scan aggregations
 * @param {string} locale the language tag
 */
export const getAggregations = (aggregations, locale) => {
  const scanned = aggregations?.find(_ => _.field === 'scanned');
  const expected = aggregations?.find(_ => _.field === 'expected');
  const missing = aggregations?.find(_ => _.field === 'missing');
  const extra = aggregations?.find(_ => _.field === 'extra');
  const accuracy = aggregations?.find(_ => _.field === 'accuracy');

  const values = {
    scanned: scanned ? getLocalizedNumber(locale, scanned.value) : '-',
    expected: expected ? getLocalizedNumber(locale, expected.value) : '-',
    missing: missing ? getLocalizedNumber(locale, missing.value) : '-',
    extra: extra ? getLocalizedNumber(locale, extra.value) : '-',
    accuracy: accuracy ? getLocalizedPercent(locale, accuracy.value) : '-',
  };

  return getAggregationText(values);
};

// TODO: Remove this function when move detail page to V2
/**
 * Get the aggregation values by scan location and return with color codes
 * @param {array} aggregations a list of scan aggregations by location
 * @param {string} locale the language tag
 * @param {boolean} isOffsiteEnabled is offsite enabled in the store
 */
export const getLocationAggregations = (aggregations, locale, isOffsiteEnabled) => {
  const salesFloor = aggregations?.find(_ => _.value === 'salesFloor')?.aggregations?.find(_ => _.field === 'scanned');
  const stockRoom = aggregations?.find(_ => _.value === 'stockRoom')?.aggregations?.find(_ => _.field === 'scanned');
  const offsite = aggregations?.find(_ => _.value === 'offsite')?.aggregations?.find(_ => _.field === 'scanned');

  const values = {
    salesFloor: salesFloor ? getLocalizedNumber(locale, salesFloor.value) : '-',
    stockRoom: stockRoom ? getLocalizedNumber(locale, stockRoom.value) : '-',
    ...(isOffsiteEnabled && {
      offsite: offsite ? getLocalizedNumber(locale, offsite.value) : '-',
    }),
  };

  return getLocationAggregationText(values, isOffsiteEnabled);
};

import React, { useContext } from 'react';
import Toggle from '../../../components/toggle/Toggle';
import Search from '../../../components/search/Search';

import { SimWebContext } from '../../../context/SimWeb.provider';
import { SizeComplianceContext } from '../SizeCompliance.provider';

import searchTooltipContents from './search-tooltip-contents.json';

const SizeComplianceToggle = () => {
  const {
    isBinned, onToggleTabChange, searchText, setSearchText, setPageSearchText,
  } = useContext(SizeComplianceContext);
  const { getMessage } = useContext(SimWebContext);

  const binToggle = (
    <Toggle
      leftLabel={getMessage('bin')}
      leftName="bin"
      rightLabel={getMessage('noBin')}
      rightName="noBin"
      selected={isBinned}
      setSelected={onToggleTabChange}
      toggleByLabel={false}
    />
  );

  return (
    <div>
      {binToggle}
      <div style={{ marginBottom: '20px' }}>
        <Search
          type="size-compliance"
          searchText={searchText}
          setSearchTextValue={setSearchText}
          setPageSearchTextValue={setPageSearchText}
          searchTooltipContents={searchTooltipContents}
        />
      </div>
    </div>
  );
};

export default SizeComplianceToggle;

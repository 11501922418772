import { cloneDeep, isEmpty } from 'lodash';

/**
 * A helper function to replace the translated sort option labels
 * @param {object} options a list of available sortBy options
 * @param {function} getMessage a function to get translated message
 */
export const getTranslatedSortByOptions = (options, getMessage) => {
  if (isEmpty(options)) return [];

  return options.map(option => {
    if (option.label === 'Missing Count') return { ...option, label: getMessage('missingCount') };
    if (option.label === 'Extra Count') return { ...option, label: getMessage('extraCount') };
    return option;
  });
};

/**
 * A helper function to the total aggregation numbers of all gtins of a product
 * @param {object} allGtins a list of gtin data
 */
export const getAggregationTotal = (allGtins) => {
  let expectedTotal = 0;
  let extraTotal = 0;
  let missingTotal = 0;
  let scannedTotal = 0;

  allGtins?.forEach(gtin => {
    expectedTotal += gtin.expected;
    extraTotal += gtin.extra;
    missingTotal += gtin.missing;
    scannedTotal += gtin.scanned;
  });

  const totalAccuracy = Math.round((1 - (missingTotal / expectedTotal)) * 100);
  const accuracyTotal = Number.isNaN(totalAccuracy) ? 100 : totalAccuracy;

  return {
    accuracyTotal,
    expectedTotal: expectedTotal < 0 ? 0 : expectedTotal,
    extraTotal,
    missingTotal,
    scannedTotal,
  };
};

/**
 * A helper function to get the URL of the first image from the image set
 * @param {object} data the product data by style-color
 */
export const getImageURL = (data) => {
  let imageURL = '';

  const imageSet = data?.[0]?.content?.imageSet;
  const { images, defaultDomains } = imageSet || {};
  if (images && defaultDomains) {
    const domain = defaultDomains[0];
    const { company, view } = images[0];
    imageURL = `https://${domain}/${company}/${view}?scl=3&cropN=0,0,1,1`;
  } else if (images?.[0]?.url) imageURL = images?.[0]?.url;

  return imageURL;
};

/**
 * A helper function to sort the product by selected sort key and order
 * @param {array} arr a list of products
 * @param {string} key the sort key
 * @param {string} order the sort order
 */
/* eslint-disable no-nested-ternary */
export const sortDetailTableData = (arr, key, order) => {
  if (!arr) return [];
  if (!key || !order) return arr;

  return arr.sort((a, b) => {
    const newKey = (key.split(' ')[0]).toLowerCase();
    return order === 'DESC'
      ? (b[newKey] < a[newKey] ? -1 : b[newKey] > a[newKey] ? 1 : 0)
      : (a[newKey] < b[newKey] ? -1 : a[newKey] > b[newKey] ? 1 : 0);
  });
};

export const mergeSOHByGtin = (scanData, skus, isOffsiteEnabled, getStockOnHand) => {
  if (!scanData || scanData.length === 0) return [];

  const copyOfScanData = cloneDeep(scanData);
  const mergedData = copyOfScanData.map(data => {
    const gtinData = skus?.find(_ => _.gtin === data.gtin);
    // eslint-disable-next-line no-param-reassign
    data.quantity = getStockOnHand(gtinData?.quantity, true, isOffsiteEnabled);
    return data;
  });

  return mergedData;
};

import React from 'react';
import { bool } from 'prop-types';
import LoadingIndicator from '../../../components/CustomSpinner';

/**
 * The title of the size compliance table, has a loading indicator
 * @param {bool} loading true if a new page is being loaded in the table
 */
const Title = ({ loading }) => (
  <div style={{ display: 'flex' }} data-testid="size-compliance-table-title">
    <LoadingIndicator loading={loading} size="10px" color="black" />
  </div>
);

Title.propTypes = {
  loading: bool.isRequired,
};

export default Title;

import React from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Alert, Checkmark, Close } from '../../../../assets/Svgs/index';

/**
 * A helper function to return RESUMED sting if the scan status is SCANNING, else return the status
 * @param {string} status the RFID scan status to be updated - SUBMITTED, SCANNING or CANCELLED
 */
export const getRFIDScanStatus = (status) => {
  if (status === 'SCANNING') return 'RESUMED';
  return status;
};

/**
 * A helper function to extract certain information from a scan data
 * @param {object} scanData the data of selected scan
 */
export const getValuesFromScanData = (scanData) => {
  if (!scanData) return { accuracy: 0, status: '', totalResumeCount: 0 };

  const { aggregations, scanStatus: { status, totalResumeCount } } = scanData;
  const accuracy = aggregations?.find(_ => _.field === 'accuracy')?.value || 0;

  return { accuracy, status, totalResumeCount };
};

/**
 * A helper function to get the title for the Manager Approval Modal
 * @param {object} scanData the data of the selected scan
 * @param {boolean} statusUpdated an indicator if a scan to be updated
 * @param {string} action the manager approval action - SUBMITTED, SCANNING or CANCELLED
 * @param {string} rfidStatus the RFID scan status to be updated - SUBMITTED, SCANNING or CANCELLED
 * @param {number} accuracyThreshold the scan accuracy threshold of the store
 * @param {function} getMessage the function to get translated message
 * @param {function} formatMessage the function to get and format translated message
 * @param {object} error an error returned from the backend
 */
export const getTitle = (scanData, statusUpdated, action, rfidStatus, accuracyThreshold,
  getMessage, formatMessage, error) => {
  if (!scanData || error) return '';

  const { accuracy, status, totalResumeCount } = getValuesFromScanData(scanData);

  if (statusUpdated) return formatMessage('messageScanStatusUpdated', { status: getRFIDScanStatus(rfidStatus) });
  if (status !== 'IN_REVIEW') return getMessage('messageScanTimedOut');
  if (accuracy < accuracyThreshold && action === 'SUBMITTED') return formatMessage('messageScanLowAccuracy', { accuracyThreshold });
  if (action === 'SCANNING' && totalResumeCount <= 1) return getMessage('resumeScan');
  if (totalResumeCount === 2 && action === 'SCANNING') return getMessage('messageResumeOnce');

  return formatMessage('messageScanApprovalActionConfirmation', { status: action.slice(0, -3) });
};

/**
 * A helper function to get margin style for the modal title component
 * @param {boolean} statusUpdated an indicator if a scan to be updated
 * @param {string} status the latest status of the scan - SCANNING, IN_REVIEW, SUBMITTED, CANCELLED or TIMED_OUT
 */
export const getModalTitleStyles = (statusUpdated, status) => {
  if (!status) return {};
  if (statusUpdated === false && status === 'IN_REVIEW') return { margin: '50px 0px 0px 0px' };
  return { margin: '0px' };
};

/**
 * A helper function to get the content for the Manager Approval Modal
 * @param {object} scanData the data of the selected scan
 * @param {string} action the manager approval action - SUBMITTED, SCANNING or CANCELLED
 * @param {number} accuracyThreshold the scan accuracy threshold of the store
 * @param {function} getMessage the function to get translated message
 * @param {object} error an error returned from the backend
 * @param {boolean} showLowAccuracyWarning an indicator to show a low accuracy warning message
 */
export const getContent = (scanData, action, accuracyThreshold, getMessage, error, showLowAccuracyWarning = false) => {
  if (!scanData) return '';
  if (error) return getMessage('rfidManagerApprovalErrorTryAgain');
  const { accuracy, status, totalResumeCount } = getValuesFromScanData(scanData);

  if (action !== 'CANCELLED' && action !== 'SCANNING') {
    if (status !== 'IN_REVIEW') return getMessage('messageScanExpired');
    if (accuracy < accuracyThreshold && totalResumeCount > 1) return getMessage('messageScanLowAccuracySecond');
    if (accuracy < accuracyThreshold) return getMessage('messageScanLowAccuracyFirst');
  }
  if (action === 'SCANNING' && showLowAccuracyWarning) return getMessage('messageScanLowAccuracyWarning');

  return '';
};

/**
 * A helper function to get the icon for the Manager Approval Modal
 * @param {string} scanStatus the latest status of the scan - SCANNING, IN_REVIEW, SUBMITTED, CANCELLED or TIMED_OUT
 * @param {boolean} statusUpdated an indicator if a scan to be updated
 * @param {string} action the manager approval action - SUBMITTED, SCANNING or CANCELLED
 * @param {object} error an error returned from the backend
 */
export const getIcon = (scanStatus, statusUpdated, action, error) => {
  if (!scanStatus || !action) return null;

  if (error) return <ErrorOutlineIcon color="error" fontSize="large" />;

  if (statusUpdated) {
    if ((scanStatus === 'IN_REVIEW' || scanStatus === 'CANCELLED') && action === 'CANCELLED') {
      return <span><Close fill="#FF0000" width="50px" height="50px" /></span>;
    }

    if ((scanStatus === 'IN_REVIEW' || scanStatus === 'SUBMITTED' || scanStatus === 'SCANNING')) {
      return <span><Checkmark fill="#5CDAA8" width="50px" height="50px" /></span>;
    }
  }

  if (scanStatus !== 'IN_REVIEW') return <span><Alert width="50px" fill="#bb1426" /></span>;

  return null;
};

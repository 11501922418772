import React from 'react';
import { string } from 'prop-types';

// eslint-disable-next-line import/prefer-default-export
export const CameraScan = ({ stroke }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={`${stroke}`} xmlns="http://www.w3.org/2000/svg" className="cameraScanner">
    <path fillRule="evenodd" clipRule="evenodd" d="M15.75 13.5C15.75 15.571 14.071 17.25 12 17.25C9.929 17.25 8.25 15.571 8.25 13.5C8.25 11.429 9.929 9.75 12 9.75C14.071 9.75 15.75 11.429 15.75 13.5Z" strokeWidth="1.5" />
    <path d="M17.5 6.75H19.5C20.743 6.75 21.75 7.758 21.75 9V12V16.5C21.75 18.571 20.071 20.25 18 20.25H6C3.929 20.25 2.25 18.571 2.25 16.5V9C2.25 7.758 3.257 6.75 4.5 6.75H6.5H14.25C15.079 6.75 15.75 6.078 15.75 5.25C15.75 4.422 15.079 3.75 14.25 3.75H9.75C8.921 3.75 8.25 4.422 8.25 5.25V6" strokeWidth="1.5" />
    <path d="M6 9.75H4.5" strokeWidth="1.5" />
  </svg>
);

CameraScan.propTypes = {
  stroke: string,
};

CameraScan.defaultProps = {
  stroke: 'black',
};

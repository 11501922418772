import React from 'react';
import { string } from 'prop-types';

// eslint-disable-next-line import/prefer-default-export
export const Shipping = ({ extraClasses }) => (
  <div className={`${extraClasses}`}>
    <svg aria-hidden="false" focusable="false" viewBox="0 0 24 24" role="img" width="24px" height="24px" fill="none" id="Shipping-m-icon" xmlns="http://www.w3.org/2000/svg">
      <path stroke="currentColor" strokeWidth="1.5" d="M8.25 16.5a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm12 .033a2.25 2.25 0 11-4.502-.001 2.25 2.25 0 014.502.001z" clipRule="evenodd" />
      <path stroke="currentColor" strokeWidth="1.5" d="M15.75 16.565H8.5M.75 11.5V14A2.25 2.25 0 003 16.25h.25m16.25-6.5H3A2.25 2.25 0 01.75 7.5V5.25h17.189c.2 0 .39.079.531.22l4.56 4.56a.75.75 0 01.22.531v3.689A2.25 2.25 0 0121 16.5h-.75" />
    </svg>
  </div>
);

Shipping.propTypes = {
  extraClasses: string,
};

Shipping.defaultProps = {
  extraClasses: '',
};

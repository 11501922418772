import React from 'react';
import { string } from 'prop-types';

/**
 * A Silhouette Icon used in the Navigation
 * @param {string} height - the height of the svg
 * @param {string} fill - the color to fill the swoosh with
 * @param {string} width - the width of the svg
 * @param {string} className - the classname for the node
 * @param {string} testId - the id given to the comp for testing
 */
// eslint-disable-next-line import/prefer-default-export
export const User = ({
  height, fill, width, className, testId,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 18"
    height={height}
    width={width}
    className={className}
    data-testid={testId}
  >
    <path
      d="M9 0C6.51479 0 4.5 2.01479 4.5 4.5H6C6 2.84321 7.34321 1.5 9 1.5V0ZM4.5 4.5C4.5 6.98521 6.51479 9 9 9V7.5C7.34321 7.5 6 6.15679 6 4.5H4.5ZM9 9C11.4852 9 13.5 6.98521 13.5 4.5H12C12 6.15679 10.6568 7.5 9 7.5V9ZM13.5 4.5C13.5 2.01479 11.4852 0 9 0V1.5C10.6568 1.5 12 2.84321 12 4.5H13.5ZM1.5 18V15H0V18H1.5ZM1.5 15C1.5 13.3432 2.84321 12 4.5 12V10.5C2.01479 10.5 0 12.5148 0 15H1.5ZM4.5 12H13.5V10.5H4.5V12ZM13.5 12C15.1568 12 16.5 13.3432 16.5 15H18C18 12.5148 15.9852 10.5 13.5 10.5V12ZM16.5 15V18H18V15H16.5Z"
      fill={fill}
    />
  </svg>
);

User.defaultProps = {
  height: '22',
  width: '22',
  fill: 'black',
  className: '',
  testId: '',
};

User.propTypes = {
  height: string,
  fill: string,
  width: string,
  className: string,
  testId: string,
};

import React from 'react';
import { string } from 'prop-types';

// eslint-disable-next-line import/prefer-default-export
export const BinnedBox = ({ extraClasses }) => (
  <div className={`${extraClasses}`}>
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="24px"
      height="24px"
      fill="none"
      strokeWidth="1.5"
    >
      <path stroke="currentColor" width="1.5" d="M14.25 3.75C13.01 3.75 12 4.76 12 6.5v7m8.25-3.75H3.75" />
      <path stroke="currentColor" width="1.5" d="M14.25 3.75h4.39l1.61 6v10.5H3.75V9.75l1.61-6h5.14" />
    </svg>
  </div>
);

BinnedBox.propTypes = {
  extraClasses: string,
};

BinnedBox.defaultProps = {
  extraClasses: string,
};

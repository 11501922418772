/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import { SimDateTime } from './datetime';

/**
 * A helper function to check is the selected date outside of range
 * @param {date} dayValue the selected date value
 * @param {date} minDate the minimum allowed date in date range
 * @param {date} maxDate the maximum allowed date in date range
 */
export const isOutsideDateRange = (dayValue, minDate, maxDate) => {
  const day = SimDateTime.toDateTime(dayValue);
  const isBeforeMinDate = day < minDate;
  const isAfterMaxDate = day > maxDate;

  if (minDate && maxDate) return isBeforeMinDate || isAfterMaxDate;
  if (minDate) return isBeforeMinDate;
  if (maxDate) return isAfterMaxDate;

  return false;
};

/**
 * A helper function to check is the selected date within the searchable range
 * @param {date} dateValue the selected date value
 * @param {string} type the date picker type. ie. startDate or endDate
 * @param {date} startDate the selected start date
 * @param {number} maxDaysBack the number of days allows to select from the past
 * @param {number} maxDaysForward the number of days allows to select to the future
 * @param {number} searchableDays the number of days range allows to search
 * @param {boolean} eddEnabled is store has EDD enabled
 * @param {func} setTooManyDaysSelected sets the state of tooManyDays Selected to help with errorHandling.  Should return a bool.
 */
export const isWithinSearchableRange = (
  dateValue, type, startDate, maxDaysBack, maxDaysForward = 0, searchableDays, eddEnabled = false, setTooManyDaysSelected,
) => {
  const allowedEOD = !eddEnabled ? SimDateTime.endOfDay() : SimDateTime.endOfDay().plus({ days: maxDaysForward });
  const searchableEOD = SimDateTime.endOfDay(startDate).plus({ days: searchableDays });
  const maxDate = (type === 'startDate' || SimDateTime.toDateTime(allowedEOD) < SimDateTime.toDateTime(searchableEOD)) ? allowedEOD : searchableEOD;
  const isOutsideRange = isOutsideDateRange(dateValue, SimDateTime.startOfDay().minus({ days: maxDaysBack }), maxDate);

  if (isOutsideRange) {
    setTooManyDaysSelected(true);
  }
  return isOutsideRange;
};

/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable max-len */

/**
  * sorts and object of arrays with given prop
  * @param {string} prop - property to sort by
*/
export const byProperty = (prop) => (a, b) => {
  if (typeof a[prop] === 'number') {
    return (a[prop] - b[prop]);
  }
  return ((a[prop] < b[prop]) ? -1 : ((a[prop] > b[prop]) ? 1 : 0));
};

/**
 * Create sub aggregation summary object
 * @param {string} name name of the sub aggregation
 * @param {object} subAggregations computation of metrics over a set of data
 */
export const createSummary = (name, subAggregations) => ({ name, count: 0, subAggregations: subAggregations ?? [] });

/**
 * Create sub aggregation
 * @param {object} subAggregations computation of metrics over a set of data
 */
export const createSubAggregations = (subAggregations) => (subAggregations.map(aggregation => createSummary(aggregation)));

/**
 * Create one level deep of sub aggregation
 * @param {object} summary the aggregation object
 * @param {string} name the name of the aggregation
 * @param {number || string} value the sub aggregation value
 * @param {number} count product unit count. use this count if available instead of incresment count by 1
 */
export const incrementSubAggregation = (summary, name, value, count) => {
  if (!value) return;

  const group = summary.subAggregations.find(item => item?.name?.toLowerCase() === name?.toLowerCase());
  let subAggregation = group.subAggregations.find(item => item.name === value);

  if (!subAggregation) {
    subAggregation = createSummary(value);
    group.subAggregations.push(subAggregation);
  }
  if (count) {
    subAggregation.count += count;
    group.count += count;
  } else {
    subAggregation.count += 1;
    group.count += 1;
  }
};

/**
 * Gets a list of available aggregations from the toggle object
 * @param {object} aggregations an object with a list of available aggregations
 */
export const getSubAggregationList = (aggregations) => Object.values(aggregations)?.map(aggregation => aggregation?.name);

/**
  * Creates a summary rollup of the products
  * @param {array} products array of product data
  * @param {array} categories a list of categories
  * @param {array} subAggregations a list of sub aggregations
  * @param {string} type name of the report
*/
export const getVisualSummaryData = (products, categories, subAggregations, type) => {
  const summaryData = categories?.map(category => createSummary(category, createSubAggregations(subAggregations)));

  if (products?.length > 0) {
    products.map(product => {
      const division = (type === 'inbound') ? product?.categoryInfo?.division : product?.division;
      const summary = summaryData.find(item => item?.name?.toLowerCase() === division?.toLowerCase());

      if (summary) {
        if (type === 'inbound') summary.count += product.unitCount;
        else summary.count += 1;
        subAggregations?.map(aggregation => incrementSubAggregation(summary, aggregation, product[aggregation], product?.unitCount));
      } else console.warn(`Division was not found, "${division}`);
    });
  }

  summaryData.map(summary => summary.subAggregations.map(subAggregation => subAggregation.subAggregations.sort(byProperty('name'))));
  return summaryData;
};

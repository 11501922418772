import React, { useContext } from 'react';
import { TranslationsContext } from '../../context/Translations.provider';

import './liveBadge.css';

export const LiveBadge = () => {
  const { getMessage } = useContext(TranslationsContext);

  return (
    <div className="live-badge" data-testid="live-badge">
      <div className="live-text">{getMessage('live')}</div>
      <div className="live-circle" />
    </div>
  );
};

export default LiveBadge;

import React, { useMemo, useContext } from 'react';
import {
  bool, string, func, number,
} from 'prop-types';
import Dialog from '@mui/material/Dialog';
import MUIDataTable from 'mui-datatables';
import { LoadingIndicator } from '../../assets/Svgs/index';
import DialogHeader from '../../components/dialogHeader/DialogHeader';
import ErrorAlert from '../../components/alerts/Error';
import { TranslationsContext } from '../../context/Translations.provider';

import './MissingStylesReport.css';
import { MissingStylesContext } from './MissingStyles.provider';

/**
 * MissingStylesDialog
 * @param {boolean} isDialogOpen - a value we toggle to open/close the dialog
 * @param {function} handleDialogClose - a function that handles changing the isDialogOpen value
 * @param {number} index - index of the current row selected in main table
 * @param {number} rowCount - number of rows in the main table
 * @param {function} setIndex - sets the index of the current row
 * @param {string} nodeTitle - title used for the row selector
*/
const MissingStylesDialog = ({
  isDialogOpen, handleDialogClose,
  index, rowCount, setIndex, nodeTitle,
}) => {
  const { getMessage } = useContext(TranslationsContext);
  const { sessionDetails, fetchingModalData, modalError } = useContext(MissingStylesContext);

  const dialogContent = useMemo(() => {
    const columns = [{
      name: 'bin',
      label: getMessage('bin'),
      options: {
        filter: false,
      },
    }, {
      name: 'productCode',
      label: getMessage('style-color'),
      options: {
        filterType: 'textField',
      },
    }, {
      name: 'genderAge',
      label: getMessage('gender'),
      options: {
        filterType: 'multiselect',
      },
    }, {
      name: 'division',
      label: getMessage('division'),
      options: {
        filterType: 'multiselect',
      },
    }, {
      name: 'category',
      label: getMessage('category'),
      options: {
        filterType: 'multiselect',
      },
    }, {
      name: 'description',
      label: getMessage('description'),
      options: {
        filterType: 'textField',
      },
    }, {
      name: 'currentStockOnHand',
      label: getMessage('stockOnHand'),
      options: {
        filter: false,
      },
    }, {
      name: 'daysUnrepresented',
      label: getMessage('daysMissing'),
      options: {
        filter: false,
      },
    }, {
      name: 'lastReceiptDate',
      label: getMessage('lastReceiveDate'),
      options: {
        filter: false,
      },
    }];

    return (
      <div data-testid="dialog-muiDataTable">
        <DialogHeader
          loading={fetchingModalData}
          index={index}
          setIsOpen={handleDialogClose}
          rowCount={rowCount}
          setIndex={setIndex}
          nodeTitle={nodeTitle}
        />
        { fetchingModalData && <LoadingIndicator /> }
        { modalError && !fetchingModalData
        && (
          <ErrorAlert
            errorObject={modalError?.message}
            apiName="Missing Styles API"
            pageCode=""
          />
        ) }
        {!fetchingModalData && sessionDetails?.length > 0 && (
          <MUIDataTable
            columns={columns}
            data={sessionDetails}
            options={{
              selectableRows: 'none',
              rowsPerPage: 500,
              rowsPerPageOptions: [500, 1000, 1500, 2000],
              print: false,
              downloadOptions: {
                filterOptions: {
                  useDisplayedColumnsOnly: true,
                  useDisplayedRowsOnly: true,
                },
              },
              // ignoring lint rule bc the data isn't rendering properly if i update to the perfered-template
              // eslint-disable-next-line prefer-template
              onDownload: (buildHead, buildBody, newColumns, newData) => '\uFEFF' + buildHead(newColumns) + buildBody(newData),
            }}
          />
        )}
      </div>
    );
  }, [getMessage, index, rowCount, sessionDetails, fetchingModalData, modalError]);

  return (
    <div className="details" data-testid="missingScanReportDialog-container">
      <Dialog
        open={isDialogOpen}
        onClose={() => handleDialogClose(false)}
        data-testid="missingScanReportDialog"
        PaperProps={{
          style: {
            minHeight: '90%',
            maxHeight: '90%',
            minWidth: '90%',
            maxWidth: '90%',
            padding: '20px',
          },
        }}
      >
        {dialogContent}
      </Dialog>
    </div>
  );
};

MissingStylesDialog.propTypes = {
  handleDialogClose: func.isRequired,
  isDialogOpen: bool.isRequired,
  nodeTitle: string,
  index: number,
  rowCount: number,
  setIndex: func,

};

MissingStylesDialog.defaultProps = {
  nodeTitle: null,
  index: 0,
  rowCount: 0,
  setIndex: null,
};

export default MissingStylesDialog;

/* eslint-disable no-useless-catch */
import { routerUrls, endpoints, baseUrl } from '../../axios/endpoints';
import { generalAxiosRequest } from '../../axios/axiosFunctions';

/**
 * Fetch Prcie Changes summary and details data
 * @param {string} storeId the unique store id
 * @param {boolean} rfidEnabled is rfid enabled in the store
 * @param {boolean} isOffsiteEnabled is isOffsiteEnabled in the store
 */
export const fetchPriceChangesData = async (storeId, rfidEnabled = false, isOffsiteEnabled = false) => {
  if (!storeId) return null;

  try {
    const urlParams = `?storeId=${storeId}&rfidEnabled=${rfidEnabled}&offsiteEnabled=${isOffsiteEnabled}`;
    const url = `${baseUrl(routerUrls.SIMWEB_BFF)}${endpoints.PRICE_CHANGES.url}${urlParams}`;
    const priceChangesResponse = await generalAxiosRequest('GET', url, endpoints.PRICE_CHANGES);

    return priceChangesResponse;
  } catch (error) {
    throw error;
  }
};

/**
 * Fetches live updates for the summary tables
 * @param {*} storeId the unique store id
 * @param {*} rfidEnabled is rfidEnabled in the store
 * @param {*} isOffsiteEnabled is isOffsiteEnabled in the store
 * @param {*} currentDate the date of the currently effective markdown
 */
export const fetchLiveUpdates = async (storeId, rfidEnabled = false, isOffsiteEnabled = false, currentDate) => {
  if (!storeId) return null;

  try {
    const url = `${baseUrl(routerUrls.SIMWEB_BFF)}${endpoints.PRICE_CHANGES.url}${endpoints.PRICE_CHANGES.actions.live}`
     + `?storeId=${storeId}&rfidEnabled=${rfidEnabled}&offsiteEnabled=${isOffsiteEnabled}&currentDate=${currentDate}`;
    const liveUpdateResponse = await generalAxiosRequest('GET', url, endpoints.PRICE_CHANGES);

    return liveUpdateResponse;
  } catch (error) {
    throw error;
  }
};

/**
  * The func takes in a callback to change the state of the modal to open
  * @param {function} setOpen callback that update the modal state
  */
export const handleModalOpen = (setOpen) => {
  setOpen(true);
};

/**
  * The func takes in a callback to change the state of the modal to close
  * @param {function} setOpen callback that update the modal state
*/
export const handleModalClose = (setOpen) => {
  setOpen(false);
};

/**
   * returns the current date time string
   * @param {string} locale the stores locale
  */
export const getLastUpdatedDate = (locale) => {
  const dateTime = new Date();
  const dateOptions = { month: 'long', day: 'numeric', year: 'numeric' };
  const timeOptions = { timeStyle: 'short' };
  const date1 = new Date(dateTime).toLocaleString(locale, dateOptions);
  const date2 = new Date(dateTime).toLocaleTimeString(locale, timeOptions);

  return `${date1} ${date2}`;
};

/**
 * A helper function to get the product division
 * @param {string} styleColor the style color of the product
 */
export const getDivision = (styleColor) => {
  if (styleColor?.class) {
    const className = styleColor.class.toLowerCase();
    if (className.includes('apparel')) return 'apparel';
    if (className.includes('footwear')) return 'footwear';
    if (className.includes('equipment')) return 'equipment';
  }

  return '';
};

import React from 'react';
import { string } from 'prop-types';

// eslint-disable-next-line import/prefer-default-export
export const Picked = ({ extraClasses }) => (
  <div className={`${extraClasses}`}>
    <svg width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.50642 18.4634V15.0488C9.50642 14.2 9.89836 13.9512 10.6009 13.9512H13.4335L14 16.878M14 16.878H4.65234M14 16.878V22H4.65234V16.878M4.65234 16.878L6.81547 13.9512C6.81547 13.9512 5.68242 12.7317 5.11587 12L2 12.9756L4.65234 16.6799" stroke="#111111" strokeWidth="1.25" strokeMiterlimit="10" />
      <path fillRule="evenodd" clipRule="evenodd" d="M16.75 7.5C16.75 9.571 15.071 11.25 13 11.25C10.929 11.25 9.25 9.571 9.25 7.5C9.25 5.429 10.929 3.75 13 3.75C15.071 3.75 16.75 5.429 16.75 7.5Z" stroke="#111111" strokeWidth="1.25" />
      <path d="M15 14C17.071 14 18.75 15.679 18.75 17.75V20.75" stroke="#111111" strokeWidth="1.25" />
    </svg>

  </div>
);

Picked.propTypes = {
  extraClasses: string,
};

Picked.defaultProps = {
  extraClasses: '',
};

import React, { useContext } from 'react';
import { Dialog } from '@mui/material';
import { InboundVisibilityTableContext } from '../InboundVisiblityTable.provider';
import PurchaseOrderDialogDetails from './PurchaseOrderDialogDetails';
import PrintDropZoneDialog from '../printDropZones/PrintDropZoneDialog';
import { mergeOrdersBasedOnStyleColors } from '../inboundVisibility.utils';

const InboundVisibilityPurchaseOrderDialog = () => {
  const {
    isOpen, setIsOpen, printDropZoneData, printDialogOpen, setPrintDialogOpen, useDeliveryNumber,
  } = useContext(InboundVisibilityTableContext);

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        fullScreen={false}
        maxWidth="xl"
        fullWidth
      >
        <PurchaseOrderDialogDetails />
      </Dialog>
      <PrintDropZoneDialog
        printDropZoneData={printDropZoneData}
        printDialogOpen={printDialogOpen}
        mergeOrdersBasedOnStyleColors={mergeOrdersBasedOnStyleColors}
        setPrintDialogOpen={setPrintDialogOpen}
        useDeliveryNumber={useDeliveryNumber}
      />
    </>
  );
};

export default InboundVisibilityPurchaseOrderDialog;

import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { object, string } from 'prop-types';
import {
  createTheme, ThemeProvider, StyledEngineProvider,
} from '@mui/material/styles';
import {
  Card, DialogContent, Grid, Paper, Zoom,
} from '@mui/material';
import { SalesFloor, StockRoom, Offsite } from '../../../../assets/Svgs/index';

import ScanProductImageCard from '../../../../components/ScanProductImageCard';
import ScanReportProductDetailTable from './ScanReportProductDetailTable';
import { getAggregationTotal, getImageURL } from './scanReportProducts.utils';
import { scanTypes } from '../../scanReportConstants';
import getFormattedPrice from '../../../../utils/getFormattedPrice';
import getTotalStockOnHand from '../../../../utils/getTotalStockOnHand';

import { SimWebContext } from '../../../../context/SimWeb.provider';
import { CPAContext } from '../../../../context/cpa/CPA.provider';

import './ScanReportProductCards.css';
import { ScanDetailsContext } from '../ScanDetails.provider';

const theme = createTheme(({
  typography: {
    useNextVariants: true,
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow: 'none',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        head: {
          height: '40px',
        },
        root: {
          height: '40px',
          width: '30px',
        },
      },
    },
    MuiTableCell: {
      root: {
        padding: '0 16px 0 16px',
      },
    },
    MUIDataTableHeadCell: {
      styleOverrides: {
        fixedHeader: {
          zIndex: 'auto',
          position: 'static',
        },
      },
    },
    MuiDialogContent: {
      root: {
        padding: '20px 24px 8px',
      },
    },
    MUIDataTablePagination: {
      root: {
        '&:lastChild': {
          padding: '0',
        },
      },
      toolbar: {
        height: '20px',
      },
    },
  },
}));

/**
 * @param {object} scan the product data of the selected scan
 */
const ScanReportProductCards = ({ scan, scanType, sortByValue }) => {
  const {
    currencyCode, isIOS, isOffsiteEnabled, isOffsiteInactive, locale, getMessage, storeConfig,
  } = useContext(SimWebContext);
  const { data, loading, getProducts } = useContext(CPAContext);
  const { filters } = useContext(ScanDetailsContext);

  const rfidEnabled = storeConfig?.rfidEnabled?.value;
  const offsiteType = storeConfig?.offsiteType?.value;

  const [location, setLocation] = useState('allGtins');
  const {
    allGtins, binInfo, description, division, genderAge, price, productCode, retailCategory,
  } = scan;

  useEffect(() => {
    if (productCode) getProducts(productCode);
  }, [productCode]);

  let includeItem = true;
  const gtinsTable = useMemo(() => (
    <ScanReportProductDetailTable
      scan={scan}
      scanArea={location}
      scanType={scanType}
      iPad={isIOS}
      sortBy={sortByValue}
      sizes={data?.[0]?.sizes}
      rfidEnabled={rfidEnabled}
      isOffsiteEnabled={isOffsiteEnabled}
      isOffsiteInactive={isOffsiteInactive}
    />
  ), [scan, scanType, location, isIOS, sortByValue, data]);

  const {
    accuracyTotal, expectedTotal, extraTotal, missingTotal, scannedTotal,
  } = getAggregationTotal(allGtins);

  filters.map(filter => {
    if (filter.name === 'missingCountRange') {
      const range = filter.value.split('_');
      includeItem = missingTotal >= range[0] && missingTotal <= range[2];
    } else if (filter.name === 'extraCountRange') {
      const range = filter.value.split('_');
      includeItem = extraTotal >= range[0] && extraTotal <= range[2];
    }
  });

  const handleLocationDataDisplayed = (locationType) => {
    if (locationType === location) {
      setLocation('allGtins');
    } else {
      setLocation(locationType);
    }
  };

  const getAreaButtons = () => ((scanTypes.isFullStore(scanType) || scanTypes.isCycleCount(scanType)) ? (
    <div className="scanProductDetails_sales-area-buttons">
      <div className="scanProductDetails_button-wrapper">
        <button
          data-testid="salesFloorGtinsButton"
          type="button"
          onClick={() => handleLocationDataDisplayed('salesFloorGtins')}
        >
          {SalesFloor}
          <span id={location === 'salesFloorGtins' ? 'scanTypeSelected' : null}>{getMessage('salesFloor')}</span>
        </button>
      </div>
      <div className="scanProductDetails_button-wrapper">
        <button
          data-testid="stockRoomGtinsButton"
          type="button"
          onClick={() => handleLocationDataDisplayed('stockRoomGtins')}
        >
          {StockRoom}
          <span id={location === 'stockRoomGtins' ? 'scanTypeSelected' : null}>{getMessage('stockRoom')}</span>
        </button>
      </div>
      {offsiteType === 'active' || offsiteType === 'inactive' ? (
        <div className="scanProductDetails_button-wrapper">
          <button
            data-testid="offsiteGtinsButton"
            type="button"
            onClick={() => handleLocationDataDisplayed('offsiteGtins')}
          >
            {Offsite}
            <span id={location === 'offsiteGtins' ? 'scanTypeSelected' : null}>{offsiteType === 'active' ? getMessage('offsite') : `${getMessage('offsite')} (${getMessage('inactive')})`}</span>
          </button>
        </div>
      ) : null}
    </div>
  ) : null);

  const productCard = useMemo(() => {
    if (loading || !scan || !allGtins || !includeItem) return null;

    return (
      <div
        className="scanProductDetails_product-detail-card"
        data-testid="varianceProductDetailCard"
        key={`${productCode} / ${description}`}
      >
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <Card className="scanProductDetails_product-detail-card" elevation={4}>
              <DialogContent
                data-testid="product-detail-dialog-content"
                className={`productDetails flex-row ${isIOS ? 'iPad' : ''}`}
              >
                <Grid container alignItems="flex-start">
                  <Grid item xs>
                    <Grid container direction="column" className="scanProductDetails_product-details">
                      <Grid item xs={12}>
                        <div className="scanProductDetails_details-header">
                          <div className="scanProductDetails_details-header-row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <h5 className="scanProductDetails_product-detail-title">
                              {`${productCode || ''} ${description ? `/ ${description}` : ''}`}
                            </h5>
                            <h5 className="scanProductDetails_product-detail-soh">{`${getMessage('rfidSoh')}: ${getTotalStockOnHand(data?.[0]?.sizes, rfidEnabled)}`}</h5>
                          </div>
                          <div className="scanProductDetails_details-gtin-totals">
                            <p>
                              {getMessage('scanned')}
                              <span>{scannedTotal}</span>
                            </p>
                            <p>
                              {getMessage('expected')}
                              <span>{expectedTotal}</span>
                            </p>
                            <p
                              className={sortByValue?.label === 'Missing Count' ? 'sort-missing-ui-styles' : ''}
                              data-testid="scan-report-product-card-missing-count"
                            >
                              {getMessage('missing')}
                              <span>{missingTotal}</span>
                            </p>
                            <p
                              className={sortByValue?.label === 'Extra Count' ? 'sort-extra-ui-styles' : ''}
                              data-testid="scan-report-product-card-extra-count"
                            >
                              {getMessage('extra')}
                              <span>{extraTotal}</span>
                            </p>
                            <p>
                              {getMessage('accuracy')}
                              <span>{`${accuracyTotal}%`}</span>
                            </p>
                          </div>
                        </div>
                        {getAreaButtons()}
                      </Grid>
                      <Grid item style={{ marginTop: 10 }}>
                        <Paper className="scanProductDetails_product-detail-body" style={{ width: 'auto' }}>
                          <ScanProductImageCard
                            keyProp={productCode ? `${productCode}-${retailCategory}` : ''}
                            gender={genderAge || ''}
                            division={division || ''}
                            category={retailCategory || ''}
                            binInfo={binInfo || ''}
                            price={price ? getFormattedPrice(price, locale, currencyCode) : ''}
                            gtins={allGtins.length || 0}
                            image={getImageURL(data)}
                          />
                          <Grid item xs={10} className="scanProductDetails_product-details-table">
                            <Zoom
                              in
                              key={location}
                              style={{ transitionDelay: '200ms' }}
                              timeout={200}
                            >
                              <span>
                                {gtinsTable}
                              </span>
                            </Zoom>
                          </Grid>
                        </Paper>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </DialogContent>
            </Card>
          </ThemeProvider>
        </StyledEngineProvider>
      </div>
    );
  }, [
    data,
    loading,
    scan,
    gtinsTable,
    getMessage,
  ]);

  return productCard;
};

ScanReportProductCards.propTypes = {
  scan: object.isRequired,
  scanType: string.isRequired,
};

export default ScanReportProductCards;

import React, { useContext, useMemo, useState } from 'react';
import { func, string } from 'prop-types';
import { Box, Grid, Paper } from '@mui/material';

import { updateScanStatus } from '../../../scanReport.axios';
import { getButtons, getContent, getSubmissionMessage } from './confirmationModal.utils';
import { newRelicAction } from '../../../../../utils/newRelicPageActions';

import EstimatedVariancesWidget from './EstimatedVariancesWidget';

import { SimWebContext } from '../../../../../context/SimWeb.provider';
import { ScanDetailsContext } from '../../ScanDetails.provider';

import './ConfirmationModal.css';

const ConfirmationModal = ({
  approvalAction, setOpenModal, setApprovalAction,
}) => {
  const {
    getMessage, formatMessage, storeConfig,
  } = useContext(SimWebContext);
  const { data } = useContext(ScanDetailsContext);

  const [isStatusUpdated, setIsStatusUpdated] = useState(false);
  const [updatedStatus, setUpdatedStatus] = useState('');
  const [error, setError] = useState(null);

  const {
    sessionId, resumeCount, scanDuration, totalVariances,
  } = data;
  const accuracyThreshold = storeConfig?.['rfid.scanReportAccuracyThreshold']?.value;

  const handleCloseModal = (resetApprovalAction = false) => {
    setOpenModal(false);
    setError(null);
    if (resetApprovalAction) setApprovalAction('');
  };

  const handleOnClick = async (newStatus) => {
    try {
      await updateScanStatus(sessionId, newStatus);
      setIsStatusUpdated(true);
      setUpdatedStatus(newStatus);
    } catch (error) {
      setError(error);
    }

    newRelicAction('scan-report-manager-approval', { action: newStatus, duration: scanDuration });
  };

  const buttons = useMemo(() => getButtons(
    resumeCount, approvalAction, isStatusUpdated, error, getMessage, handleCloseModal, handleOnClick,
  ), [approvalAction, resumeCount, isStatusUpdated, error, getMessage]);

  const content = useMemo(() => getContent(
    approvalAction, error, isStatusUpdated, updatedStatus, getMessage, formatMessage,
  ), [approvalAction, error, isStatusUpdated, updatedStatus, getMessage, formatMessage]);

  const submissionMessage = useMemo(() => getSubmissionMessage(
    accuracyThreshold, approvalAction, isStatusUpdated, formatMessage,
  ), [accuracyThreshold, approvalAction, isStatusUpdated, formatMessage]);

  const estimatedVariancesWidget = useMemo(() => (
    (!isStatusUpdated && (approvalAction === 'SUBMITTED' || approvalAction === 'SCANNING'))
      ? (
        <EstimatedVariancesWidget
          variances={totalVariances}
          getMessage={getMessage}
        />
      ) : null
  ), [data, approvalAction, isStatusUpdated, getMessage, formatMessage]);

  return (
    <Paper className="scan-details-confirmation-modal-container" data-testid="scan-confirmation-modal-container">
      <Grid container direction="column">
        <Box className="scan-details-confirmation-modal-title" data-testid="scan-modal-title">
          {submissionMessage}
        </Box>
        {estimatedVariancesWidget}
        <Box className="scan-details-confirmation-modal-content" data-testid="scan-modal-content">
          {content}
        </Box>
        <Grid className="confirmation-buttons" data-testid="scan-modal-buttons">
          {buttons}
        </Grid>
      </Grid>
    </Paper>
  );
};

ConfirmationModal.propTypes = {
  approvalAction: string.isRequired,
  setOpenModal: func.isRequired,
  setApprovalAction: func.isRequired,
};

export default ConfirmationModal;

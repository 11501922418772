import React, {
  useState, createContext, useEffect,
} from 'react';
import axios from 'axios';
import { node, object } from 'prop-types';
import supportedLanguages from '../utils/supportedTranslations';

export const TranslationsContext = createContext({});

// mockedValues for testing purposes
const TranslationsProvider = ({ children, mockedValue }) => {
  let defaultTranslations = [];
  const [language, setLanguage] = useState(sessionStorage.getItem('language'));
  const { Provider } = TranslationsContext;
  const [translations, setTranslations] = useState([]);
  const [isReady, setIsReady] = useState(false);

  const fetchTranslations = async () => {
    try {
      // eslint-disable-next-line max-len
      const isLanguageSupported = supportedLanguages.filter(lang => lang.tag === language)[0];
      const supportedSpanish = language === 'es-419';
      // eslint-disable-next-line no-nested-ternary
      const overrideLanguage = !isLanguageSupported && !supportedSpanish ? 'en-US' : supportedSpanish ? 'es-MX' : isLanguageSupported.bodega;
      if (supportedSpanish) {
        setLanguage(overrideLanguage);
        sessionStorage.setItem('language', overrideLanguage);
      }
      const url = `https://www.nike.com/assets/i18n/sim-web/${overrideLanguage}.json`;
      const bodegaResponse = await axios.get(url);
      if (bodegaResponse) {
        setTranslations(bodegaResponse.data);
        setIsReady(true);
      }
    } catch (err) {
      setIsReady(false);
      // eslint-disable-next-line no-console
      console.log(err?.response);
    }
  };

  const getDefaultTranslations = async () => {
    try {
      const url = 'https://www.nike.com/assets/i18n/sim-web/en-US.json';
      const bodegaResponse = await axios.get(url);
      if (bodegaResponse) {
        defaultTranslations = bodegaResponse.data;
        setTranslations(defaultTranslations);
        setIsReady(true);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console?.error(err.response);
    }
  };

  const getMessage = key => {
    if (!key || key === '-') {
      return '-';
    }
    return translations[key]?.value ?? (defaultTranslations[key]?.value ?? `<<${key}>>`);
  };

  const formatMessage = (key, params) => {
    const message = getMessage(key);
    if (message) {
      const formatted = message.replace(/\{(.*?)\}/gu, (_, token) => params[token]);
      return formatted;
    }
    return message;
  };

  useEffect(() => {
    getDefaultTranslations();
    return () => { defaultTranslations = []; };
  }, []);

  useEffect(() => {
    if (language === 'en-US' && defaultTranslations && defaultTranslations.length) {
      setTranslations(defaultTranslations);
      setIsReady(true);
    } else {
      fetchTranslations();
    }
  }, [language]);

  return (
    <Provider
      value={mockedValue ?? {
        language,
        translations,
        isReady,
        setLanguage,
        fetchTranslations,
        getMessage,
        formatMessage,
      }}
    >
      {children}
    </Provider>
  );
};

TranslationsProvider.defaultProps = {
  children: {},
  mockedValue: null,
};

TranslationsProvider.propTypes = {
  children: node,
  mockedValue: object,
};

export default TranslationsProvider;

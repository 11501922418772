/* eslint-disable no-nested-ternary */
import React, {
  useContext, useRef, useState, useMemo,
} from 'react';
import MUIDataTable, { ExpandButton } from 'mui-datatables';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import ReactToPrint from 'react-to-print';
import PrintIcon from '@mui/icons-material/Print';
import CloudDownload from '@mui/icons-material/CloudDownload';
import { CSVLink } from 'react-csv';
import { LoadingIndicator, Checkmark } from '../../assets/Svgs/index';
import TransfersTableDetailItem from './TransfersTableDetailItem';
import PrinterPage from './PrinterPage';
import {
  buildDownloadData, getHeaderForDownloadData, getTableColumnSpan, buildFiscalDownloadData, exportToCsv,
} from './transfers.utils';

import { endpoints } from '../../axios/endpoints';
import { performFiscalPrint } from '../../axios/fiscalAxiosFunctions';

import { TransfersContext } from './Transfers.provider';
import { tutorialContext } from '../../context/Tutorial.provider';
import { SimWebContext } from '../../context/SimWeb.provider';

import './TransfersPage.css';

const useStyles = makeStyles({
  printIcon: {
    position: 'absolute',
    right: '73px',
    top: '7px',
    fontSize: '37px',
    color: '#757575',
    cursor: 'pointer',
    '&:hover': {
      borderRadius: '50%',
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      color: '#1976d2',
    },
  },
  downloadIcon: {
    position: 'absolute',
    right: '123px',
    top: '7px',
    fontSize: '37px',
    color: '#757575',
    cursor: 'pointer',
    '&:hover': {
      borderRadius: '50%',
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      color: '#1976d2',
    },
  },
});

const theme = createTheme(({
  components: {
    MUIDataTableSelectCell: {
      styleOverrides: {
        expandDisabled: {
          // Soft hide the button.
          visibility: 'hidden',
        },
      },
    },
    MUIDataTableToolbarSelect: {
      styleOverrides: {
        root: {
          height: '50px',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        regular: {
          minHeight: '50px !important',
        },
      },
    },
  },
}));

export const tableDataKey = {
  creationDate: 0,
  targetType: 1,
  targetLocation: 2,
  purchaseOrderNumber: 3,
  totalCartons: 4,
  totalItems: 5,
  totalPrice: 6,
  status: 7,
  cartons: 8,
  governmentNumbers: 9,
  returnAuthorizationNumber: 10,
  opId: 11,
  transferId: 12,
  governmentNumbersData: 13,
};

const TransfersTable = () => {
  const {
    getMessage, currencyCode, data, documentType, displayGovernmentNumber,
    displayReturnAuthorizationNumber, locale, isFiscalDownloadDoor, isGreaterChinaDoor, isNorthAmerica,
  } = useContext(TransfersContext);
  const { country, isIOS } = useContext(SimWebContext);
  const { startTutorial } = useContext(tutorialContext);

  const [selectedItems, setSelectedItems] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [printFailure, setPrintFailure] = useState(null);
  const [loading, setLoading] = useState(null);

  const classes = useStyles();
  const printRef = useRef();
  const csvLink = useRef();

  const filterSelectedData = () => {
    if (selectedItems.length > 0) {
      return data.filter((item, i) => selectedItems.includes(i));
    }
    return data;
  };

  const handleCustomSearch = (searchQuery, currentRow) => {
    const cartonsDataInString = JSON.stringify(currentRow[8]);
    if (currentRow.indexOf(searchQuery) >= 0 || cartonsDataInString.indexOf(searchQuery) >= 0) return true;
    return false;
  };

  // Note: update the tableDataKey object if columns changed
  const columns = [
    {
      name: 'creationDate',
      label: getMessage('date'),
    },
    {
      name: 'targetType',
      label: getMessage('transfersType'),
    },
    {
      name: 'targetLocation',
      label: getMessage('shipTo'),
    },
    {
      name: isGreaterChinaDoor ? 'shipmentDocumentNumber' : 'purchaseOrderNumber',
      label: isGreaterChinaDoor ? getMessage('shipmentDocumentNumber') : isNorthAmerica ? 'STO/PO Number' : getMessage('poNumber'),
    },
    {
      name: 'totalCartons',
      label: getMessage('cartons'),
    },
    {
      name: 'totalItems',
      label: getMessage('units'),
    },
    {
      name: 'totalPrice',
      label: getMessage('totalPrice'),
    },
    {
      name: 'status',
      label: getMessage('status'),
    },
    {
      name: 'cartons',
      label: 'cartons',
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'governmentNumbers',
      label: getMessage('governmentNumber'),
      options: {
        display: !!displayGovernmentNumber || 'excluded',
      },
    },
    {
      name: 'returnAuthorizationNumber',
      label: getMessage('raNumber'),
      options: {
        display: !!displayReturnAuthorizationNumber || 'excluded',
      },
    },
    {
      name: 'opId',
      label: getMessage('opId'),
    },
    {
      name: 'transferId',
      label: 'transferId',
      options: {
        display: 'excluded',
      },
    },
    // this used in print and download because governmentNumber could be a link
    {
      name: 'governmentNumbersData',
      label: 'governmentNumbersData',
      options: {
        display: 'excluded',
      },
    },
    {
      name: '',
      options: {
        display: !!isFiscalDownloadDoor || 'excluded',
        customBodyRender: (value, tableMeta) => (
          <>
            <IconButton
              onClick={async () => {
                const fileName = 'Fiscal Trasfer Report.xlsx';
                const newCsvData = await buildFiscalDownloadData(tableMeta.rowIndex, data);
                exportToCsv(newCsvData, fileName);
              }}
              data-testid="fiscal-download"
              size="large"
            >
              <CloudDownload />
            </IconButton>
          </>
        ),
      },
    },
  ];

  const tableData = useMemo(() => (
    data.map((item) => {
      let governmentNumbers;
      let governmentNumbersData;

      if (item.governmentNumbers) {
        const joinedGovernmentNumber = item.governmentNumbers.join(', ');
        governmentNumbers = item.governmentDocumentURL ? (
          <a href={item.governmentDocumentURL} target="_blank" rel="noopener noreferrer">{joinedGovernmentNumber}</a>
        ) : joinedGovernmentNumber;
        governmentNumbersData = joinedGovernmentNumber;
      } else {
        governmentNumbers = '-';
        governmentNumbersData = '-';
      }
      return { ...item, governmentNumbers, governmentNumbersData };
    })
  ), [data, startTutorial]);

  const handleDispatchNotePrintButtonClick = async (selectedRows, displayData) => {
    setOpenDialog(true);
    const transferIdList = selectedRows?.data.map(index => displayData[index.dataIndex]?.data[tableDataKey.transferId]);
    const endpointObject = country === 'ARG' ? endpoints.TRANSFERS_ARG_FISCAL_PRINT : endpoints.TRANSFERS_FISCAL_PRINT;

    setLoading(true);
    await Promise.all(transferIdList?.map(async id => {
      const payload = { request: { transferId: id } };
      await performFiscalPrint(endpointObject, payload);
    })).then(() => {
      setLoading(false);
    }).catch(error => {
      setPrintFailure(error?.message);
      setLoading(false);
    });
  };

  const customToolbarSelect = (selectedRows, displayData) => (
    <>
      {data.length > 0 && (
        <div>
          {(documentType === 'packingSlip' || documentType === '') && (
            <>
              { (!isIOS) && (
                <ReactToPrint
                  trigger={() => (
                    <PrintIcon
                      className={classes.printIcon}
                      style={{ right: '100px', top: '12px', width: '25px' }}
                      data-testid="print-icon"
                    />
                  )}
                  content={() => printRef.current}
                />
              )}
              <IconButton
                onClick={() => csvLink.current.link.click()}
                className={(classes.downloadIcon)}
                style={{ right: (!isIOS ? '123px' : '20px') }}
                size="large"
              >
                <CloudDownload />
              </IconButton>
              <CSVLink
                data={buildDownloadData(
                  isGreaterChinaDoor, displayData, tableDataKey, locale, currencyCode, selectedRows,
                )}
                headers={getHeaderForDownloadData(
                  displayGovernmentNumber, displayReturnAuthorizationNumber, getMessage, isGreaterChinaDoor, isNorthAmerica,
                )}
                filename="Transfers Report"
                className="hidden"
                ref={csvLink}
                target="_blank"
              />
            </>
          )}
          {!isIOS && (documentType === 'fiscalDoc' || documentType === 'dispatchNote') && (
            <PrintIcon
              className={classes.printIcon}
              style={{ right: '100px', top: '12px', width: '25px' }}
              data-testid="print-icon"
              onClick={() => handleDispatchNotePrintButtonClick(selectedRows, displayData)}
            />
          )}
        </div>
      )}
    </>
  );

  const customToolbar = (displayData) => (
    <>
      {data.length > 0 && (
        <div>
          { (documentType === 'packingSlip' || documentType === '') && (
            <>
              { (!isIOS) && (
                <ReactToPrint
                  trigger={() => (
                    <PrintIcon
                      className={classes.printIcon}
                      data-testid="print-icon"
                      style={{ right: '100px', top: '12px', width: '25px' }}
                    />
                  )}
                  content={() => printRef.current}
                />
              )}
              { !isFiscalDownloadDoor && (
                <>
                  <IconButton
                    onClick={() => csvLink.current.link.click()}
                    className={(isIOS ? classes.printIcon : classes.downloadIcon)}
                    size="large"
                  >
                    <CloudDownload style={{ right: '40px' }} />
                  </IconButton>
                  <CSVLink
                    data={buildDownloadData(
                      isGreaterChinaDoor, displayData?.displayData, tableDataKey, locale, currencyCode,
                    )}
                    headers={getHeaderForDownloadData(
                      displayGovernmentNumber, displayReturnAuthorizationNumber, getMessage, isGreaterChinaDoor, isNorthAmerica,
                    )}
                    filename="Transfers Report"
                    className="hidden"
                    ref={csvLink}
                    target="_blank"
                  />
                </>
              )}
            </>
          )}
        </div>
      )}
    </>
  );

  const tableOptions = {
    filter: false,
    viewColumns: false,
    search: true,
    pagination: false,
    selectableRows: !isFiscalDownloadDoor && data?.length > 0 ? 'multiple' : 'none',
    print: false,
    download: false,
    fixedHeader: true,
    tableBodyMaxHeight: '900px',
    textLabels: {
      body: {
        noMatch: getMessage('noData'),
      },
    },
    onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
      setSelectedItems(rowsSelected);
    },
    rowsSelected: selectedItems,
    customToolbar: (displayData) => customToolbar(displayData),
    customToolbarSelect: (selectedRows, displayData) => customToolbarSelect(selectedRows, displayData),
    customSearch: (searchQuery, currentRow) => handleCustomSearch(searchQuery, currentRow),
    expandableRows: true,
    expandableRowsHeader: data?.length > 0,
    renderExpandableRow: (rowData, { dataIndex }) => ((data[dataIndex].cartons.length > 0) ? (
      <>
        <tr className="detail" data-testid="transfers-cartons-detail-table">
          <td
            colSpan={getTableColumnSpan(
              displayGovernmentNumber, displayReturnAuthorizationNumber, isFiscalDownloadDoor,
            )}
            aria-label="transfers cartons detail table"
          >
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell key="cartonId">{getMessage('cartonID')}</TableCell>
                    <TableCell key="style">{`${getMessage('style')}\xA0\xA0\xA0${getMessage('clr')}`}</TableCell>
                    <TableCell key="class">{getMessage('class')}</TableCell>
                    <TableCell key="description">{getMessage('description')}</TableCell>
                    <TableCell key="size">{getMessage('size')}</TableCell>
                    <TableCell key="upc">{getMessage('upc')}</TableCell>
                    <TableCell key="units">{getMessage('units')}</TableCell>
                    <TableCell key="price">{getMessage('price')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TransfersTableDetailItem data={data[dataIndex]} locale={locale} currencyCode={currencyCode} />
                </TableBody>
              </Table>
            </TableContainer>
          </td>
        </tr>
      </>
    ) : null),
  };

  const components = {
    ExpandButton(obj) {
      const { dataIndex } = obj;
      if (data[dataIndex]?.cartons.length === 0) return <div style={{ width: '24px' }} />;
      return <ExpandButton {...obj} />;
    },
  };

  const loadingSpinner = (
    <Dialog
      open={openDialog}
      fullScreen={false}
      onClose={() => setOpenDialog(false)}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        {getMessage('fiscalDocumentPrint')}
      </DialogTitle>
      <DialogContent className="transfer-print-header">
        {loading && (
          <div data-testid="transfer-print-loading-spinner" className="transfer-print-loading-spinner">
            <LoadingIndicator />
          </div>
        )}
        {!loading && (
          <div className="transfer-print-modal-innards">
            {!printFailure ? (
              <>
                <Checkmark fill="#5CDAA8" width="50px" height="50px" />
                <p className="transfer-print-text">{getMessage('successfulFiscalDocumentPrint')}</p>
              </>
            ) : (
              <>
                <ErrorOutlineIcon color="error" fontSize="large" className="transfer-print-error" />
                <p className="transfer-print-text">
                  {getMessage('failedToSendToPrinter')}
                </p>
                <p>{`Error: ${printFailure}`}</p>
              </>
            )}
          </div>
        )}
      </DialogContent>
    </Dialog>
  );

  return (
    <div className="transfers-report-table" data-testid="transfers-report-table">
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <MUIDataTable
            data={tableData}
            columns={columns}
            options={tableOptions}
            components={components}
          />
        </ThemeProvider>
      </StyledEngineProvider>
      {loadingSpinner}
      <div ref={printRef}>
        <PrinterPage
          columns={columns}
          columnSpan={getTableColumnSpan(
            displayGovernmentNumber, displayReturnAuthorizationNumber, isFiscalDownloadDoor,
          )}
          currencyCode={currencyCode}
          data={filterSelectedData()}
          locale={locale}
        />
      </div>
    </div>
  );
};

export default TransfersTable;

import React from 'react';
import { string, oneOfType, number } from 'prop-types';

import './svgs.css';

/**
   * An SVG used in the Scan Report to help display the status of a
   * scan to the  users in the summary table
   * @param {string} fill - adjustable color of SVG
   * @param {string | number} height - height of the SVG
   * @param {string | number} width - width of the SVG
   * @param {string} label - optional value that can be rendered with the SVG
   */
// eslint-disable-next-line import/prefer-default-export
export const Checkmark = ({
  fill, height, width, label,
}) => {
  const svg = (
    <svg
      enableBackground="new 0 0 541.038 541.038"
      viewBox="0 0 541.038 541.038"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fill}
    >
      <path d="m184.405 461.013-184.405-184.405 24.354-24.354 160.051 160.051 332.279-332.279 24.354 24.354z" />
    </svg>
  );

  return label ? (
    <div className="align-svg">
      {svg}
      {` ${label}`}
    </div>
  ) : svg;
};

Checkmark.propTypes = {
  fill: string,
  height: oneOfType([string, number]),
  width: oneOfType([string, number]),
  label: string,
};

Checkmark.defaultProps = {
  fill: '#000000',
  width: '30px',
  height: '30px',
  label: null,
};

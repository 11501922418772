import React, { useContext } from 'react';
import { ScanAccuracyWidget } from '../../../components/dashboardWidgets';
import { DashboardContext } from '../Dashboard.provider';
import { SimWebContext } from '../../../context/SimWeb.provider';
import useTutorial from '../../../hooks/useTutorial';

const ScanAccuracy = () => {
  const { scansLoading, data } = useContext(DashboardContext);
  const { locale, storeConfig, getMessage } = useContext(SimWebContext);
  const { Tutorial } = useTutorial('scanAccuracy');

  const rfidScanAccuracyWidgetEnabled = storeConfig?.rfidscanAccuracyWidget?.value;

  return (rfidScanAccuracyWidgetEnabled
    && (
      <div className="scanAccuracyWidget-container">
        <div className="scanAccuracyWidget-header">
          <Tutorial />
          <h4>{getMessage('accuracy')}</h4>
        </div>
        <ScanAccuracyWidget locale={locale} data={data?.scanReport} loading={scansLoading} />
      </div>
    )
  );
};

export default ScanAccuracy;

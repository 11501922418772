import React from 'react';
import { ProductRefillWidget } from '../../../components/dashboardWidgets';
import ProductRefillProvider from '../../productRefill/ProductRefill.provider';

const ProductRefill = () => (
  <ProductRefillProvider>
    <ProductRefillWidget />
  </ProductRefillProvider>
);

export default ProductRefill;

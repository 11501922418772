import React from 'react';
import MUIDataTable from 'mui-datatables';
import {
  array, bool, func, string,
} from 'prop-types';
import { getRfidTableHeader } from '../../../../components/RFIDTableHeader';
import { sortSizes } from '../../../../utils/sort/sortSizes';
import { defaultSort } from '../../../../utils/sort/defaultSort';

const GtinTable = ({
  scanAreaData, scanArea, scanType, rfidEnabled, isOffsiteEnabled, isOffsiteInactive, getMessage,
}) => {
  const rfidColumn = getRfidTableHeader('quantity', getMessage('rfidStock'), 'productCard-summary', rfidEnabled, isOffsiteEnabled, isOffsiteInactive, !rfidEnabled, true, null, null, null, scanArea);
  const columns = [
    {
      name: 'gtin',
      label: getMessage('upc'),
    },
    {
      name: 'size',
      label: getMessage('size'),
    },
    {
      name: 'scanned',
      label: getMessage('scanned'),
    },
    {
      name: 'expected',
      label: getMessage('expected'),
    },
    {
      name: 'missing',
      label: scanArea === 'fullStore' && scanType !== 'SALES_FLOOR_SCAN' ? getMessage('missing') : getMessage('lost'),
    },
    {
      name: 'extra',
      label: scanArea === 'fullStore' && scanType !== 'SALES_FLOOR_SCAN' ? getMessage('extra') : getMessage('found'),
    },
    {
      name: 'accuracy',
      label: getMessage('accuracy'),
    },
    rfidColumn,
  ];

  const options = {
    selectableRows: 'none',
    rowsPerPage: 10,
    rowsPerPageOptions: [10, 20, 50],
    filter: false,
    print: false,
    download: false,
    search: false,
    viewColumns: false,
    elevation: 0,
    customSort: (data, dataIndex, rowIndex) => {
      if (dataIndex === 1) {
        return data && sortSizes(data, rowIndex);
      }
      return defaultSort(data, dataIndex, rowIndex);
    },
  };

  return (
    <div
      className="scan-product-gtin-table"
      data-testid="scan-product-gtin-table"
    >
      <MUIDataTable
        data={scanAreaData}
        columns={columns}
        options={options}
      />
    </div>
  );
};

GtinTable.propTypes = {
  scanAreaData: array,
  scanArea: string,
  scanType: string,
  rfidEnabled: bool,
  isOffsiteEnabled: bool,
  isOffsiteInactive: bool,
  getMessage: func.isRequired,
};

GtinTable.defaultProps = {
  scanAreaData: [],
  scanArea: 'fullStore',
  scanType: '',
  rfidEnabled: false,
  isOffsiteEnabled: false,
  isOffsiteInactive: false,
};

export default GtinTable;

import React, { useContext } from 'react';
import { SimWebContext } from '../../../context/SimWeb.provider';
import { ScanTimerWidget } from '../../../components/dashboardWidgets';
import { ScanTimerContext } from '../../../context/ScanTimer.provider';

import useTutorial from '../../../hooks/useTutorial';

const ScanTimer = () => {
  const { getMessage } = useContext(SimWebContext);
  const { Tutorial } = useTutorial('scanTimer');
  const { hideScanTimer = true } = useContext(ScanTimerContext);

  return (!hideScanTimer
    && (
      <div className="scanTimerWidget-container">
        <div className="scanTimerWidget-header">
          <Tutorial />
          <h4>{getMessage('scanTimer')}</h4>
        </div>
        <ScanTimerWidget />
      </div>
    )
  );
};

export default ScanTimer;

import { mockPriceByStyle } from '../components/tutorial/mockTutorialData/index';

/**
 * Formats a number as a currency string.
 *
 * @param {number} value - The numeric value to format.
 * @param {string} [locale='en-US'] - The locale string to use for formatting.
 * @param {string} [currency='USD'] - The currency code to use for formatting.
 * @returns {string} The formatted currency string.
 */
export const formatCurrency = (price, locale = 'en-US', currencyCode = 'USD') => {
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  if (!price || (typeof price === 'string' && price.includes('-'))) {
    const currencySymbol = formatter.format(0.00).substring(0, 1);
    return `${currencySymbol} -`;
  }

  return formatter.format(price ? parseFloat(price) : 0);
};

/**
 * Formats a given price according to the specified locale and currency code.
 *
 * @param {number} price - The original price to be formatted.
 * @param {string} locale - The locale string to format the price (e.g., 'en-US').
 * @param {string} currencyCode - The currency code to format the price (e.g., 'USD').
 * @param {boolean} startTutorial - A flag indicating whether to use a mock price for tutorial purposes.
 * @returns {string} The formatted price string.
 */
export default (price, locale, currencyCode, startTutorial) => {
  const localPrice = startTutorial ? mockPriceByStyle?.data?.getPriceByStyle?.[0]?.prices?.[0]?.amount : price;
  return formatCurrency(localPrice, locale, currencyCode);
};

import ae from './ae.png';
import al from './al.png';
import am from './am.png';
import ar from './ar.png';
import at from './at.png';
import az from './az.png';
import au from './au.png';
import ba from './ba.png';
import be from './be.png';
import bh from './bh.png';
import bg from './bg.png';
import by from './by.png';
import ca from './ca.png';
import ch from './ch.png';
import cl from './cl.png';
import cs from './cs.png';
import cy from './cy.png';
import de from './de.png';
import dz from './dz.png';
import dk from './dk.png';
import el from './el.png';
import en from './en.png';
import enGB from './en-GB.png';
import esES from './es-ES.png';
import esMX from './es-MX.png';
import fr from './fr.png';
import he from './he.png';
import hk from './hk.png';
import hr from './hr.png';
import hu from './hu.png';
import id from './id.png';
import ie from './ie.png';
import ind from './ind.png';
import it from './it.png';
import ja from './ja.png';
import kr from './kr.png';
import mo from './mo.png';
import my from './my.png';
import nl from './nl.png';
import no from './no.png';
import nz from './nz.png';
import noFlag from './noFlag.png';
import ph from './ph.png';
import pl from './pl.png';
import ptBR from './pt-BR.png';
import ptPT from './pt-PT.png';
import ru from './ru.png';
import se from './se.png';
import sg from './sg.png';
import sk from './sk.png';
import th from './th.png';
import tr from './tr.png';
import uy from './uy.png';
import za from './za.png';
import zhHans from './zh-Hans.png';
import zhHant from './zh-Hant.png';

// Flags source:
// https://www.figma.com/file/EwdiBWXVAJTC1P6SCPK02O/Flags-of-325-countries-(Community)?node-id=0%3A2

// locale is used when the setFlag() is called in the language dropdown and changed we
// update the locale to display dates/times accordingly

const allFlags = {
  ALB: {
    name: 'English (US)',
    tag: 'en',
    image: al,
    locale: 'en-us',
  },
  ARE: {
    name: 'English (GB)',
    tag: 'en-GB',
    image: ae,
    locale: 'en-ae',
  },
  ARG: { // ARG-6505
    name: 'Spanish (Argentina)',
    tag: 'es-AR',
    image: ar,
    locale: 'es-AR', // not supported lang
  },
  ARM: {
    name: 'English (US)',
    tag: 'en',
    image: am,
    locale: 'en-us', // not supported lang
  },
  AUS: { // AUS-6176
    name: 'English (GB)',
    tag: 'en-GB',
    image: au,
    locale: 'en-au',
  },
  AUT: { // AUT-517
    name: 'German',
    tag: 'de',
    image: at,
    locale: 'de-AT',
  },
  AZE: {
    name: 'English (US)',
    tag: 'en',
    image: az,
    locale: 'en-us',
  },
  BEL: {
    name: 'Dutch',
    tag: 'nl',
    image: be,
    locale: 'nl-BE',
  },
  BGR: {
    name: 'English (GB)',
    tag: 'en-GB',
    image: bg,
    locale: 'en-US',
  },
  BHR: {
    name: 'English (US)',
    tag: 'en',
    image: bh,
    locale: 'en-BH',
  },
  BIH: {
    name: 'English (US)',
    tag: 'en',
    image: ba,
    locale: 'bs-BA',
  },
  BLR: {
    name: 'English (US)',
    tag: 'en',
    image: by,
    locale: 'ru-BY',
  },
  BRA: { // BRA-6324
    name: 'Portuguese (Brazilian)',
    tag: 'pt-BR',
    image: ptBR,
    locale: 'pt-BR',
  },
  CA: {
    name: 'Catalan',
    tag: 'en-US',
    image: esES,
    locale: 'ca-ES', // not supported lang
  },
  CAN: {
    name: 'Candian',
    tag: 'en-CA',
    image: ca,
    locale: 'en-CA', // not supported lang
  },
  CHE: {
    name: 'German',
    tag: 'de',
    image: ch,
    locale: 'de-CH',
  },
  CHL: { // CHL-6414
    name: 'Spanish (Chile)',
    tag: 'es-CL',
    image: cl,
    locale: 'es-CL', // not supported lang
  },
  CHN: {
    name: 'Chinese (Simplified)',
    tag: 'zh-Hans',
    image: zhHans,
    locale: 'zh-CN',
  },
  CYP: {
    name: 'English',
    tag: 'en',
    image: cy,
    locale: 'en-CY',
  },
  CZE: { // CZE-611
    name: 'Czech',
    tag: 'cs',
    image: cs,
    locale: 'cs-CZ',
  },
  DEU: {
    name: 'German',
    tag: 'de',
    image: de,
    locale: 'de-DE',
  },
  DNK: { // DNK-970
    name: 'German',
    tag: 'da',
    image: dk, //
    locale: 'da-DK',
  },
  DZA: {
    name: 'English (GB)',
    tag: 'en',
    image: dz,
    locale: 'en-us',
  },
  EGY: {}, // no stores
  ESP: {
    name: 'Spanish (Spain)',
    tag: 'es-ES',
    image: esES,
    locale: 'es-ES',
  },
  EST: {}, // no stores
  FRA: { // FRA-537
    name: 'French',
    tag: 'fr',
    image: fr,
    locale: 'fr-FR',
  },
  GBR: { // GBR-523
    name: 'English (GB)',
    tag: 'en-GB',
    image: enGB,
    locale: 'en-GB',
  },
  GEO: {}, // no stores
  GRC: { // GRC-661
    name: 'Greek',
    tag: 'el',
    image: el,
    locale: 'el-GR',
  },
  HKG: { // HKG-3270
    name: 'English (GB)',
    tag: 'en-GB',
    image: hk,
    locale: 'zh-HK',
  },
  HRV: { // HRV-682
    name: 'Croatian',
    tag: 'hr',
    image: hr,
    locale: 'hr-HR',
  },
  HUN: { // HUN-644
    name: 'Hungarian',
    tag: 'hu',
    image: hu,
    locale: 'hu-HU',
  },
  IDN: {
    name: 'English (GB)',
    tag: 'en-GB',
    image: id,
    locale: 'en-us',
  },
  IND: {
    name: 'English (GB)',
    tag: 'en-GB',
    image: ind,
    locale: 'en-us',
  },
  IRL: { // IRL-678
    name: 'English (GB)',
    tag: 'en-GB',
    image: ie,
    locale: 'en-IE',
  },
  ISR: { // ISR-791
    name: 'Hebrew',
    tag: 'he',
    image: he,
    locale: 'he-IL',
  },
  ITA: { // ITA-563
    name: 'Italian',
    tag: 'it',
    image: it,
    locale: 'it-IT',
  },
  JOR: {}, // no stores
  JPN: { // JPN-3192
    name: 'Japanese',
    tag: 'ja',
    image: ja,
    locale: 'ja-JP',
  },
  KAZ: {}, // no stores
  KEN: {}, // no stores
  KOR: {
    name: 'Korean',
    tag: 'ko',
    image: kr,
    locale: 'ko-KR',
  },
  KWT: {}, // no stores
  LBN: {}, // no stores
  LTU: {}, // no stores
  LVA: {}, // no stores
  MAC: { // MAC-4904
    name: 'English',
    tag: 'en',
    image: mo,
    locale: 'zh-MO',
  },
  MAR: {}, // no stores
  MDA: {}, // no stores
  MEX: { // MEX-6608
    name: 'Spanish (Mexico)',
    tag: 'es-MX',
    image: esMX,
    locale: 'es-MX',
  },
  MKD: {}, // no stores
  MNE: {}, // no stores
  MYS: { // MYS-6825
    name: 'English (GB)',
    tag: 'en-GB',
    image: my,
    locale: 'en-MY',
  },
  NLD: { // NLD-548
    name: 'Dutch',
    tag: 'nl',
    image: nl,
    locale: 'nl-NL',
  },
  NOR: { // NOR-2471
    name: 'Norwegian',
    tag: 'no',
    image: no,
    locale: 'nn-NO',
  },
  NZL: { // NZL-6070
    name: 'English (GB)',
    tag: 'en-NZ',
    image: nz,
    locale: 'en-GB',
  },
  OMN: {}, // no stores
  PHL: {
    name: 'English (GB)',
    tag: 'en-NZ',
    image: ph,
    locale: 'en-GB',
  },
  POL: { // POL-662
    name: 'English',
    tag: 'pl',
    image: pl,
    locale: 'pl-PL',
  },
  PRT: { // PRT-513
    name: 'Portuguese (European)',
    tag: 'pt-PT',
    image: ptPT,
    locale: 'pt-PT',
  },
  QAT: {}, // no stores
  ROU: {}, // no stores
  RUS: { // RUS-658
    name: 'Russian',
    tag: 'ru',
    image: ru,
    locale: 'ru-RU',
  },
  SAU: {}, // no stores
  SGP: {
    name: 'English (GB)',
    tag: 'en-GB',
    image: sg,
    locale: 'en-SG',
  },
  SRB: {}, // no stores
  SVK: {
    name: 'English (GB)',
    tag: 'en-GB',
    image: sk,
    locale: 'sk-SK',
  },
  SWE: { // SWE-2476
    name: 'English',
    tag: 'en',
    image: se,
    locale: 'sv-SE',
  },
  THA: { // THA-6811
    name: 'English',
    tag: 'en',
    image: th,
    locale: 'th-TH',
  },
  TKM: {}, // no stores
  TUR: { // TUR-672
    name: 'Turkish',
    tag: 'tr',
    image: tr,
    locale: 'tr-TR',
  },
  TWN: { // TWN-3306
    name: 'Chinese (Traditional)',
    tag: 'zh-Hant',
    image: zhHant,
    locale: 'zh-CN',
  },
  UKR: {}, // no stores
  URY: {
    name: 'English',
    tag: 'en',
    image: uy,
    locale: 'es-UY',
  },
  USA: { // USA-128
    name: 'English (US)',
    tag: 'en',
    image: en,
    locale: 'en-us',
  },
  UZB: {}, // no stores
  ZAF: { // ZAF-955
    name: 'English (GB)',
    tag: 'en-GB',
    image: za,
    locale: 'en-ZA',
  },
};

/**
 * The getFlag func takes the selected language and pulls the correct matching flag
  * @param {string} country - The language of the flag to be retrieved
  * @param {object} notFound - flag returned if not found by language
*/
export const getLanguageFlag = (country, notFound) => {
  const selectFlagForCountry = allFlags[country];
  return selectFlagForCountry && selectFlagForCountry.image ? selectFlagForCountry.image : notFound ?? noFlag;
};

export default allFlags;

/* eslint-disable import/prefer-default-export */
import { isEqual as equals, sortBy } from 'lodash';

/*
  *@param {array} first array containing string elements
  *@param {array} second array constaining string elements
  *@param {bool} make the arrays caseInsensitive
  *@returns {boolean} true if the arrays are equal, false otherwise
*/
export const isEqual = (a, b, caseInsensitive = true) => {
  const getValue = (item) => (caseInsensitive && typeof (item) === 'string' ? item.toLowerCase() : item);
  const list1 = a.map((item) => getValue(item));
  const list2 = b.map((item) => getValue(item));
  return equals(sortBy(list1), sortBy(list2));
};

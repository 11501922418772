import React from 'react';
import { string } from 'prop-types';

// eslint-disable-next-line import/prefer-default-export
export const ReadyForCarrier = ({ extraClasses }) => (
  <div className={`${extraClasses}`}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.425 11L11.652 15.773L9 13.122" stroke="#111111" strokeWidth="1.25" />
      <path fillRule="evenodd" clipRule="evenodd" d="M8.25 16.5C8.25 17.742 7.243 18.75 6 18.75C4.757 18.75 3.75 17.742 3.75 16.5C3.75 15.258 4.757 14.25 6 14.25C7.243 14.25 8.25 15.258 8.25 16.5Z" stroke="#111111" strokeWidth="1.25" />
      <path fillRule="evenodd" clipRule="evenodd" d="M20.25 16.5332C20.25 17.7752 19.242 18.7832 18 18.7832C16.758 18.7832 15.75 17.7752 15.75 16.5332C15.75 15.2902 16.758 14.2832 18 14.2832C19.242 14.2832 20.25 15.2902 20.25 16.5332Z" stroke="#111111" strokeWidth="1.25" />
      <path d="M0.75 11.5V14C0.75 15.242 1.757 16.25 3 16.25H3.25" stroke="#111111" strokeWidth="1.25" />
      <path d="M19.5 9.75H3C1.757 9.75 0.75 8.743 0.75 7.5V5.25H17.939C18.139 5.25 18.329 5.329 18.47 5.47L23.03 10.03C23.171 10.171 23.25 10.361 23.25 10.561V13.5V14.25C23.25 15.492 22.242 16.5 21 16.5H20.25" stroke="#111111" strokeWidth="1.25" />
    </svg>
  </div>
);

ReadyForCarrier.propTypes = {
  extraClasses: string,
};

ReadyForCarrier.defaultProps = {
  extraClasses: '',
};

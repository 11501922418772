/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React, { useMemo, useContext } from 'react';
import {
  object, bool, string,
} from 'prop-types';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import classNames from 'classnames';
import DonutChart from '../../visualSummaries/donutChart/DonutChart';
import { TranslationsContext } from '../../../context/Translations.provider';
import { tutorialContext } from '../../../context/Tutorial.provider';
import { LoadingIndicator } from '../../../assets/Svgs/index';
import { newRelicAction } from '../../../utils/newRelicPageActions';
import { handleToolTip } from '../dashboardWidgetUtils/complianceWidget.utils';
import { buildGraphData } from './styleComplianceWidget.utils';
import CustomSpinner from '../../CustomSpinner';

import mockedStyleComplianceData from '../../tutorial/mockTutorialData/dashboard/style-compliance-data.json';

import './StyleComplianceWidget.css';

/**
  * Creates a widget with data visuals for the Style Compliance
  * @param {String} customClass additional string to cusomize styles
  * @param {Object} data calculated details to display in the widget
  * @param {Boolean} loading loading state
  * @param {String} locale unique value to help us get the accurate date val
  * @return {Node} returns a new component with the correct data
*/
const StyleComplianceWidget = ({
  customClass,
  data,
  loading,
  locale,
}) => {
  const { getMessage } = useContext(TranslationsContext);
  const { activateTutorials, startTutorial } = useContext(tutorialContext);

  const styleComplianceData = activateTutorials ? mockedStyleComplianceData : data;

  const { apparelData, footwearData } = buildGraphData(styleComplianceData, activateTutorials, getMessage, newRelicAction);

  const apparelGraph = useMemo(() => (
    <DonutChart
      chartType="compliance"
      data={!isEmpty(styleComplianceData) ? apparelData : []}
      title={getMessage('apparel')}
      legendEnabled
      displayCenterText
      extraClass=""
      width="170px"
      customToolTip={handleToolTip}
      stopAnimation={startTutorial}
      isDashboardWidget
    />
  ), [apparelData, styleComplianceData, getMessage, startTutorial]);

  const footwearGraph = useMemo(() => (
    <DonutChart
      chartType="compliance"
      data={!isEmpty(styleComplianceData) ? footwearData : []}
      title={getMessage('footwear')}
      legendEnabled
      displayCenterText
      extraClass=""
      width="170px"
      customToolTip={handleToolTip}
      stopAnimation={startTutorial}
      isDashboardWidget
    />
  ), [footwearData, styleComplianceData, getMessage, startTutorial]);

  const ellipses = (
    <div id="scan-summary-ellipses" data-testid="scan-summary-ellipses">
      <CustomSpinner size="10px" color="#5CDAA8" loading margin="25px" />
    </div>
  );

  const sizeComplianceDetails = useMemo(() => {
    const noData = (
      <div className="styleComplianceWidget_no-data" data-testid="errorContainer_emptyData">
        <ErrorOutline color="error" fontSize="large" />
        <br />
        <p>{getMessage('noResultsStyleCompliance')}</p>
      </div>
    );

    if (!activateTutorials && loading) {
      return (<div className="styleComplianceWidget_card-loading"><LoadingIndicator width="45px" height="45px" fill="#FFFFFF" /></div>);
    }

    if (!activateTutorials && (!data || data.error)) {
      return (
        <div className="styleComplianceWidget_card-error" data-testid="errorContainer">
          <ErrorOutline color="error" fontSize="large" />
          <br />
          <p>{getMessage('fatalError')}</p>
        </div>
      );
    }

    return (
      <div className="styleComplianceWidget_card-details" data-testid="styleComplianceWidget_data">
        <div>
          <div className="styleComplianceWidget_card-body" data-testid="styleComplianceWidget_card-body">
            <p data-testid="styleComplianceWidget_date" className="styleComplianceWidget_date">
              {getMessage('lastFSScan:')}
              {(isEmpty(styleComplianceData) || styleComplianceData.date === '-' ? '-' : new Date(styleComplianceData.date).toLocaleString(locale))}
            </p>
            {data?.message ? (
              <div className="styleComplianceWidget_no-data">
                <p>{getMessage('missingReportGenerating')}</p>
                {ellipses}
              </div>
            ) : (
              <div data-testid="styleComplianceWidget_data_visuals" className="styleComplianceWidget_data_visuals">
                <div>
                  {footwearData && footwearGraph}
                </div>
                <div>
                  {apparelData && apparelGraph}
                </div>
              </div>
            )}

            {isEmpty(styleComplianceData) && noData}
          </div>
        </div>
      </div>
    );
  }, [
    data,
    styleComplianceData,
    loading,
    footwearData,
    apparelData,
    footwearGraph,
    apparelGraph,
    locale,
    getMessage,
  ]);

  return (
    <div
      className={classNames('styleComplianceWidget_dashboard', `${customClass}`, 'borderStyles')}
      data-testid="styleComplianceWidget_dashboard"
    >
      {activateTutorials ? (
        <div className="styleComplianceWidget_body">
          <p className="styleComplianceWidget_title">{getMessage('style')}</p>
          { sizeComplianceDetails }
        </div>
      )
        : (
          <Link to="/RFIDMissingScan" className="styleComplianceWidget_dashboard-widget">
            <div className="styleComplianceWidget_body">
              <p className="styleComplianceWidget_title">{getMessage('style')}</p>
              { sizeComplianceDetails }
            </div>
          </Link>
        )}
    </div>
  );
};

StyleComplianceWidget.propTypes = {
  customClass: string,
  data: object,
  loading: bool,
  locale: string,
};

StyleComplianceWidget.defaultProps = {
  customClass: '',
  data: {},
  loading: false,
  locale: 'en-us',
};

export default StyleComplianceWidget;

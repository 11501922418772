import React, { useContext } from 'react';
import { DashboardContext } from '../Dashboard.provider';
import { SimWebContext } from '../../../context/SimWeb.provider';
import { StyleComplianceWidget } from '../../../components/dashboardWidgets';
import useTutorial from '../../../hooks/useTutorial';

const StyleCompliance = () => {
  const { scansLoading, data } = useContext(DashboardContext);
  const { storeConfig } = useContext(SimWebContext);
  const { Tutorial } = useTutorial('styleCompliance');

  const rfidStyleComplianceWidgetEnabled = storeConfig?.rfidstyleComplianceWidget?.value;

  return (rfidStyleComplianceWidgetEnabled
    && (
      <div className="sizeComplianceWidget_dashboard_container">
        <div className="dashboard_tutorial_button_placeholder">
          <Tutorial />
        </div>
        <StyleComplianceWidget data={data?.styleCompliance} loading={scansLoading} />
      </div>
    )
  );
};

export default StyleCompliance;

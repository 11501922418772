import React, { useContext, useMemo } from 'react';
import { isEmpty } from 'lodash';
import InboundVisibilityVisualsTable from './InboundVisibilityVisualsTable';
import { InboundVisibilityContext } from '../InboundVisibility.provider';

const InboundVisibilityVisuals = () => {
  const { filters } = useContext(InboundVisibilityContext);

  const visuals = useMemo(() => (!isEmpty(filters) ? <InboundVisibilityVisualsTable /> : null), [filters]);

  return (
    <div className="inbound-visibility-table">
      {visuals}
    </div>
  );
};

export default InboundVisibilityVisuals;

/* eslint-disable no-await-in-loop */
/* eslint-disable max-len */
import { routerUrls, endpoints, baseUrl } from '../../axios/endpoints';
import { generalAxiosRequest } from '../../axios/axiosFunctions';

import { getDivision } from './asnTracking.utils';

/**
 * A function to fetch ASN Tracking details data
 * @param {string} jobId id of the completed job
 */
export const getASNTrackingDetails = async (jobId) => {
  let anchor = null;
  const results = [];

  let url = `${baseUrl(routerUrls.DELEDGE)}/sim/store_reports/v3/asn_tracking/${jobId}?count=6000`;

  do {
    if (anchor) url = `${baseUrl}${anchor}`;
    const response = await generalAxiosRequest('GET', url, endpoints.ASN_TRACKING, true);
    anchor = response?.pages && response?.pages?.next;
    if (response?.objects) results.push(response.objects);
  } while (anchor);

  return results;
};

/**
 * A function to get ASN Tracking report data
 * @param {string} store the store - ex. USA-128
 * @param {string} jobId id of the completed job
 */
export const getASNTrackingReport = async (store, jobId) => {
  const reportDetails = [];

  const result = await getASNTrackingDetails(jobId);
  result.forEach(objects => reportDetails.push(...objects));

  const aggregatedReportDetails = reportDetails.flatMap(detail => {
    const poCartonData = [];
    let apparelCount = 0;
    let footwearCount = 0;
    let equipmentCount = 0;

    detail.purchaseOrders.forEach(po => {
      po.cartons.forEach(carton => {
        carton.styleColors.forEach(styleColor => {
          const division = getDivision(styleColor);
          const { purchaseOrderNumber } = po;
          const {
            styleColor: color, class: colorClass, description, bins, stockOnHand, currentPrice, retailPrice, unitCount,
          } = styleColor;
          const { cartonNumber } = carton;

          poCartonData.push({
            purchaseOrderNumber, styleColor: color, class: colorClass, division, description, bins, cartonNumber, stockOnHand, currentPrice, retailPrice, unitCount,
          });

          if (division === 'apparel') apparelCount += unitCount;
          if (division === 'footwear') footwearCount += unitCount;
          if (division === 'equipment') equipmentCount += unitCount;
        });
      });
    });

    return {
      ...detail,
      purchaseOrders: poCartonData,
      apparelCount,
      footwearCount,
      equipmentCount,
    };
  });

  const response = {
    store,
    jobId,
    total: aggregatedReportDetails.length,
    details: aggregatedReportDetails,
  };

  return response;
};

/* eslint-disable */
export enum EStoreFeatureSet {
  FeatureSetNone,
  FeatureSetMVP,
  FeatureSetMVPPlus,
  FeatureSetPhaseTwo,
}

export default (featureSet: string) => {
  if (!featureSet || !featureSet.length) {
    return EStoreFeatureSet.FeatureSetNone;
  }

  switch (featureSet.toLowerCase()) {
    case 'mvp':
      return EStoreFeatureSet.FeatureSetMVP;
    case 'mvpplus':
      return EStoreFeatureSet.FeatureSetMVPPlus;
    case 'phasetwo':
      return EStoreFeatureSet.FeatureSetPhaseTwo;
    default:
      return EStoreFeatureSet.FeatureSetNone;
  }
};

/* eslint-disable max-len */
/* eslint-disable react/jsx-no-undef */
import React, {
  createContext, useContext, useEffect, useMemo, useState,
} from 'react';
import { node, object } from 'prop-types';
import { DateTime } from 'luxon';
import InfoTooltip from '../../components/InfoTooltip';
import {
  agedOrdersStatusToolTip, getAgedOrderStatusDetails, fetchTaskViewsData, getAgedOrdersData,
} from './agedOrders.utils';
import { agedOrderValues } from './agedOrders.constants';
import { SimWebContext } from '../../context/SimWeb.provider';
import { baseDetailsTableOptions, baseDialogWrapper as Dialog } from '../baseView/BaseViewPageUtils';
import AgedOrdersDialog from './AgedOrders.dialog';

export const AgedOrdersContext = createContext({});

const AgedOrdersProvider = ({ children, mockedContext }) => {
  const { Provider } = AgedOrdersContext;
  const { storeId, getMessage, SimDateTime } = useContext(SimWebContext);

  const id = 'aged-orders';
  const title = 'agedOrders';

  // parent
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [rows, setRows] = useState(null);
  const [columns, setColumns] = useState(null);
  const [options, setOptions] = useState(null);

  // modal
  const [isOpen, setIsOpen] = useState(false);
  const [orderId, setOrderId] = useState('');
  const [index, setIndex] = useState(0);

  useEffect(() => {
    setOrderId(rows?.[index]?.orderId);
  }, [index]);

  // NEEDS TRANSLATIONS
  const getColumns = () => [
    {
      name: 'creationDate',
      label: 'Creation Date', // getMessage('date'),
      options: {
        display: false,
      },
    },
    {
      name: 'localizedDate',
      label: 'Creation Date', // getMessage('date'),
      options: {
        filter: true,
      },
    },
    {
      name: 'journeyType',
      label: 'Journey Type', // getMessage('journeyType'),
      options: { filter: true },
    },
    {
      name: 'athleteId',
      label: getMessage('athlete'),
      options: {
        filter: true,
      },
    },
    {
      name: 'orderId',
      label: 'Order ID', // getMessage('poNumber'),
      options: {
        filter: true,
      },
    },
    {
      name: 'fulfillmentStatus',
      label: 'Fulfillment Status', // getMessage('fulfillmentStatus'),
      options: { filter: true },
    },
    {
      name: 'timeRange',
      label: 'Time in Queue', // getMessage('timeInQueue'),
      options: { filter: true },
    },
    {
      name: 'icon',
      label: 'icon',
      options: {
        filter: false,
        customHeadRender: () => (
          <th className="MuiTableCell-root MuiTableCell-head MuiTableCell-sizeMedium tss-10syd3x-MUIDataTableHeadCell-root tss-gm6zfk-MUIDataTableHeadCell-fixedHeader css-1ygcj2i-MuiTableCell-root" key="queueStatus" style={{ borderBottom: '1px solid #e0e0e0' }}>
            <span
              style={{
                display: 'flex',
                fontFamily: 'Helvetica',
                fontSize: '0.875rem',
                margin: '0 0 0 0px',
                paddingTop: '10.5px',
                lineHeight: '1.43',
                color: 'black',
                zIndex: 100,
              }}
            >
              <p style={{ paddingRight: '2px', paddingTop: '5px' }}>{getMessage('status')}</p>
              <InfoTooltip
                content={agedOrdersStatusToolTip()}
              />
            </span>
          </th>
        ),
      },
    },
  ];

  useEffect(() => {
    setData(null);
    setError(null);

    const fetchWidgetData = async () => {
      setLoading(true);
      try {
        const taskData = await fetchTaskViewsData(storeId);
        const agedOrdersData = getAgedOrdersData(taskData);
        setData(agedOrdersData);
        setRows(agedOrdersData?.map(journeyType => journeyType.orders)?.flat(1).map(order => ({
          ...order,
          fulfillmentStatus: agedOrderValues?.[order?.fulfillmentStatus]?.text,
          localizedDate: SimDateTime.toLocaleString(order?.creationDate, DateTime.DATETIME_SHORT),
          timeRange: agedOrderValues?.[order?.timeRange]?.value,
          icon: getAgedOrderStatusDetails(order?.fulfillmentStatus, order?.timeRange),
        })));
      } catch (error) {
        setError(error.message);
      }
      setLoading(false);
    };

    if (storeId) fetchWidgetData();
  }, [storeId]);

  useEffect(() => {
    if (!getMessage) return;
    const baseOptions = baseDetailsTableOptions(getMessage);
    baseOptions.sortOrder = {
      name: 'creationDate',
      direction: 'asc',
    };
    baseOptions.onRowClick = (rowData, { dataIndex }) => {
      const orderId = rowData?.[4];
      setOrderId(orderId);
      setIndex(dataIndex);
      setIsOpen(true);
    };

    setOptions(baseOptions);
    setColumns(getColumns());
  }, [getMessage]);

  const dialog = useMemo(() => {
    const content = !rows ? null : (
      <AgedOrdersDialog orderId={orderId} index={index} setIndex={setIndex} rows={rows} setOpenModal={setIsOpen} />
    );

    return <Dialog id={id} content={content} isOpen={isOpen} setIsOpen={setIsOpen} />;
  }, [data, index, rows, isOpen, orderId]);

  return (
    <Provider
      value={mockedContext ?? {
        id,
        title,
        data,
        dialog,
        error,
        loading,
        options,
        columns,
        rows,
      }}
    >
      {children}
    </Provider>
  );
};

AgedOrdersProvider.defaultProps = {
  children: {},
  mockedContext: null,
};

AgedOrdersProvider.propTypes = {
  children: node,
  mockedContext: object,
};

export default AgedOrdersProvider;

import React, { useState, useContext } from 'react';
import Dialog from '@mui/material/Dialog';
import DateRangeWithOptions from '../../components/date-range/DateRangeWithOptions';
import { Refresh } from '../../assets/Svgs/Refresh';
import { handleModalClose } from './asnTracking.utils';
import DialogDetails from './DialogDetails';
import ASNTrackingTable from './ASNTrackingTable';
import useAuthorization from '../../components/authorizer/Authorizer';

import './ASNTrackingReport.css';

import { SimWebContext } from '../../context/SimWeb.provider';
import { ASNTrackingContext } from './ASNTracking.provider';

/**
 * The ASN tracking report shows a table of incoming product to a store
 */
const ASNTrackingReport = () => {
  const { store, getMessage, formatMessage } = useContext(SimWebContext);
  const {
    rows,
    setRows,
    loading,
    error,
    refetch,
    jobFetchError,
    fetchingJobId,
    dateFetched,
    setRefresh,
    onGoClick,
    startDateState,
    endDateState,
    storeNumber,
  } = useContext(ASNTrackingContext);

  const title = 'asnTrackingReport';
  const { isAuthorized, unauthorizedView: UnauthorizedView } = useAuthorization(title, 'isASNTrackingEnabled');
  const [dialogData, setDialogData] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [proIndex, setPROIndex] = useState(0);

  if (!isAuthorized) {
    return UnauthorizedView;
  }

  return (
    <div className="asn-tracking-page">
      <div className="header flex-row">
        <div className="title-block">
          <div className="feature-title">{getMessage('asnTrackingReport')}</div>
          <span className="asn-tracking-date">
            <p>
              {dateFetched
                ? formatMessage('lastUpdated', { timestamp: dateFetched })
                : formatMessage('lastUpdated', { timestamp: '-' })}
            </p>
            <Refresh
              testId="asn-tracking-refresh"
              onClick={() => setRefresh(true)}
            />
          </span>
        </div>
        <div className="page-controls flex-row">
          <div className="date-picker-block">
            <DateRangeWithOptions
              optionsLabel="View"
              start={startDateState?.toISO()}
              end={endDateState?.toISO()}
              disabled={loading}
              onClick={onGoClick}
            />
          </div>
        </div>
      </div>
      <br />
      <ASNTrackingTable
        setDialogData={setDialogData}
        loading={loading || fetchingJobId}
        setOpen={setOpen}
        data={rows}
        refetch={refetch}
        error={jobFetchError || error}
        setPROIndex={setPROIndex}
        setRows={setRows}
        store={store}
      />
      <Dialog
        open={isOpen}
        onClose={() => handleModalClose(setOpen)}
        PaperProps={{
          style: {
            minHeight: '50%',
            maxHeight: '50%',
            minWidth: '80%',
            maxWidth: '80%',
          },
        }}
      >
        <DialogDetails
          loading={loading || fetchingJobId}
          dialogData={dialogData}
          proIndex={proIndex}
          setPROIndex={setPROIndex}
          rows={rows}
          setOpen={setOpen}
          setDialogData={setDialogData}
          storeNum={storeNumber}
        />
      </Dialog>
    </div>
  );
};

export default ASNTrackingReport;

import React from 'react';
import { func, string } from 'prop-types';
import { Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

const useStyles = makeStyles({
  downloadIcon: {
    color: '#757575',
    cursor: 'pointer',
    '&:hover': {
      borderRadius: '50%',
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      color: '#3f51b5',
    },
  },
  dropZonesIcon: {
    cursor: 'pointer',
    margin: '10px 25px 10px 0',
  },
});

const CloudDownloadLink = ({
  id, tooltip, onClick,
}) => {
  const classes = useStyles();

  return (
    <Tooltip title={tooltip}>
      <CloudDownloadIcon
        className={classes.downloadIcon}
        data-testid={id}
        style={{ marginRight: 13 }}
        onClick={onClick}
      />
    </Tooltip>
  );
};

export default CloudDownloadLink;

CloudDownloadLink.propTypes = {
  id: string.isRequired,
  tooltip: string.isRequired,
  onClick: func.isRequired,
};

/* eslint-disable max-len */
import React, { useMemo, useContext } from 'react';
import {
  bool, array, string,
} from 'prop-types';
import { isEmpty } from 'lodash';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import { LoadingIndicator } from '../../../assets/Svgs/index';
import { SimWebContext } from '../../../context/SimWeb.provider';
import { LiveBadge } from '../../liveBadge/LiveBadge';

import './SummaryWidget.css';

/**
 * A Widget component to display shipment's total PO number, carton and unit counts
 * @param {array} data the support filters data
 * @param {boolean} loading an indicator for if data is still loading
 * @param {string} locale the lanuage tag
 * @param {string} subMessage the sub title message
 * @param {string} title the title of the widget
 * @param {boolean} isLive show the live badge or not
 */
const SummaryWidget = ({
  data, loading, locale, subMessage, title, isLive,
}) => {
  const { getMessage } = useContext(SimWebContext);

  const noData = (
    <div className="summary-widget-error" data-testid="summary-widget-error" style={{ textAlign: 'center' }}>
      <ErrorOutline color="error" fontSize="large" />
      <br />
      <p>{getMessage('noResults')}</p>
    </div>
  );

  const widget = useMemo(() => {
    if (loading) {
      return (
        <div className="summary-widget-loading" data-testid="summary-widget-loading">
          <LoadingIndicator extraClass="loadingIndicator-width " width="65px" height="65px" fill="#FFFFFF" />
        </div>
      );
    }

    if (isEmpty(data)) {
      return noData;
    }

    return (
      <div className="summary-widget-container" data-testid="summary-widget-container">
        {isLive && <LiveBadge />}
        <div className="summary-widget-title" data-testid="summary-widget-title">
          {title}
        </div>
        {subMessage && (
          <div className="summary-widget-subMessage" data-testid="summary-widget-subMessage">
            {subMessage}
          </div>
        )}
        <div className="summary-widget-data" data-testid="summary-widget-data">
          {
            data.map(item => (
              <React.Fragment key={`summary-widget-data-${item.label}`}>
                <div>
                  <p>{`${item.label}:`}</p>
                </div>
                <div className={`summary-widget-data-value ${item.name}`}>
                  <p>{item?.value?.toLocaleString(locale)}</p>
                </div>
              </React.Fragment>
            ))
          }
        </div>
      </div>
    );
  }, [loading, data]);

  return (
    <>
      {widget}
    </>
  );
};

SummaryWidget.propTypes = {
  loading: bool.isRequired,
  data: array,
  locale: string.isRequired,
  subMessage: string,
  title: string,
  isLive: bool,
};

SummaryWidget.defaultProps = {
  data: null,
  subMessage: '',
  title: '',
  isLive: false,
};

export default SummaryWidget;

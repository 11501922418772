/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */
import getStockOnHand from '../../utils/getStockOnHand';
import { parseAsInt } from '../../utils/sort/sortByNumber';

/**
 * A helper function to build Item Activity rows data
 * @param {array} data the item activity data
 * @param {boolean} rfidEnabled is rfid enabled in the store
 * @param {boolean} isOffsiteEnabled is offsite enabled in the store
 */
export const buildRowsData = (data, rfidEnabled, isOffsiteEnabled) => {
  const tempRows = [];
  let segments;
  data && data?.forEach((_) => {
    const productRfidSoh = getStockOnHand(_.soh, true, isOffsiteEnabled);
    if (rfidEnabled) {
      const [salesfloor, stockroom, offsite] = productRfidSoh?.split('/').map(value => parseAsInt(value));
      segments = [salesfloor];
      if (rfidEnabled) segments.push(stockroom);
      if (isOffsiteEnabled) segments.push(offsite);
    }

    tempRows.push({
      styleColor: _?.styleColor,
      sale: _?.sale,
      received: _?.received,
      returned: _?.return,
      transfer: _?.transfer,
      adjustment: _?.adjustment,
      postVoidSale: _?.postVoidSale,
      postVoidReturn: _?.postVoidReturn,
      onlineSale: _?.onlineSale,
      shipFromStore: _?.shipFromStore,
      soh: _?.soh?.fiscalStock?.quantity,
      rfidSoh: segments?.join(' / ') || 0,
    });
  });

  return tempRows;
};

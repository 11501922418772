import React, {
  createContext, useState, useEffect, useContext,
} from 'react';
import { node, object } from 'prop-types';
import { fetchAllCountries, fetchStoreNumbers } from '../../login/retailLogin/countriesAndStoresAxios';
import { SimWebContext } from '../../../context/SimWeb.provider';

export const ProfileContext = createContext({});

const ProfileProvder = ({ children, mockedValue }) => {
  const { Provider } = ProfileContext;
  const { country, storeNumber } = useContext(SimWebContext);

  const [inputCountry, setInputCountry] = useState(country);
  const [inputStoreNum, setInputStoreNum] = useState(storeNumber);
  const [countries, setCountries] = useState([]);
  const [storeNumbers, setStoreNumbers] = useState([]);

  useEffect(() => {
    const getAllCountries = async () => {
      const countriesList = await fetchAllCountries();
      setCountries(countriesList);
    };
    getAllCountries();
  }, []);

  useEffect(() => {
    const getStoreNumbers = async (currentCountry) => {
      const storeList = await fetchStoreNumbers(currentCountry);
      setStoreNumbers(storeList);
    };

    if (inputCountry) getStoreNumbers(inputCountry);
  }, [inputCountry]);

  return (
    <Provider
      value={mockedValue ?? {
        inputStoreNum,
        inputCountry,
        countries,
        storeNumbers,
        setCountries,
        setStoreNumbers,
        setInputCountry,
        setInputStoreNum,
      }}
    >
      {children}
    </Provider>
  );
};

ProfileProvder.defaultProps = {
  children: {},
  mockedValue: null,
};

ProfileProvder.propTypes = {
  children: node,
  mockedValue: object,
};

export default ProfileProvder;

import { string } from 'prop-types';
import React from 'react';
import { version } from '../../../environment';

/**
 * Header for the login page form
 * @param {string} headerText - the string to display in the header
 */
const Header = ({ headerText }) => (
  <div className="header">
    <span className="g72-swoosh image-swoosh" />
    <div className="text">
      {headerText}
    </div>
    <p className="version-header">{version}</p>
  </div>
);

Header.propTypes = {
  headerText: string,
};

Header.defaultProps = {
  headerText: '',
};

export default Header;

import React, { useContext } from 'react';
import { string } from 'prop-types';
import { SimWebContext } from '../../../../context/SimWeb.provider';

const TooltipContent = ({ scanType }) => {
  const { getMessage } = useContext(SimWebContext);

  return (
    <div data-testid="scan-report-aggregations-tooltip">
      <p>
        <strong className="scanned-text">
          {`${getMessage('scanned')}: `}
        </strong>
        {getMessage('scannedDescription')}
      </p>
      <p>
        <strong className="expected-text">
          {`${getMessage('expected')}: `}
        </strong>
        {getMessage('expectedCountTooltip')}
      </p>
      <p>
        <strong className="missing-text">
          {scanType === 'SALES_FLOOR_SCAN' ? `${getMessage('lost')}:` : `${getMessage('missing')}: `}
        </strong>
        {scanType === 'SALES_FLOOR_SCAN' ? getMessage('lostDescription') : getMessage('missingDescription')}
      </p>
      <p>
        <strong className="extra-text">
          {scanType === 'SALES_FLOOR_SCAN' ? `${getMessage('found')}: ` : `${getMessage('extra')}: `}
        </strong>
        {scanType === 'SALES_FLOOR_SCAN' ? getMessage('foundDescription') : getMessage('extraDescription')}
      </p>
      <p>
        <strong className="accuracy-text">
          {`${getMessage('accuracy')}: `}
        </strong>
        {getMessage('accuracyDescription')}
      </p>
    </div>
  );
};

TooltipContent.propTypes = {
  scanType: string,
};

TooltipContent.defaultProps = {
  scanType: '',
};

export default TooltipContent;

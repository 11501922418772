/* eslint-disable */
import sortBy from 'lodash/sortBy';

type UserRole = {
  name: string;
  value: number;
};

export enum EUserFOHRoles {
  GeneralFOH = 10,
  Cashier = 20,
  CustomerService = 30,
  Admin = 40,
  AssistantManagerFOH = 50,
  ManagerFOH = 60,
  SupportFOH = 99,
}

export enum EUserBOHRoles {
  GeneralBOH = 10,
  ShippingReceivingAssociate = 20,
  InventoryAdjuster = 30,
  KeyHolder = 40,
  AssistantManagerBOH = 50,
  ManagerBOH = 60,
  SupportBOH = 99,
}

export type EUserRoles = EUserFOHRoles | EUserBOHRoles;

export enum EUserFeatures {
  ProductRefillMarkAllAsFilled = 40,
  StorePerformance = 40,
  Adjustments = 30,
}

const mapRolesToEnum = (roles: string[], enumerator: (typeof EUserBOHRoles | typeof EUserFOHRoles)): UserRole[] => {
  const unsorted = roles.map((roleStr: string) => {
    const roleVal = enumerator[roleStr as any] || null;

    if (!roleVal) {
      return null;
    }

    return { name: roleStr, value: roleVal };
  })
    .filter(Boolean) as unknown as UserRole[];

  return sortBy(unsorted, ['value']);
};

export const parseUserRoles = (roles: string[]) => {
  if (!roles || !roles.map || typeof roles !== 'object') { return null; }
  const fohRoles = mapRolesToEnum(roles, EUserFOHRoles);
  const bohRoles = mapRolesToEnum(roles, EUserBOHRoles);
  const highestBOHRole = bohRoles.slice(-1)[0];
  const highestFOHRole = fohRoles.slice(-1)[0];
  const bohMaxValue = highestBOHRole ? highestBOHRole.value : 0;
  const fohMaxValue = highestFOHRole ? highestFOHRole.value : 0;
  const allRoles = [...fohRoles, ...bohRoles];

  return {
    bohRoles,
    fohRoles,
    bohMaxValue,
    fohMaxValue,
    allRoles,
    maxValue: Math.max(bohMaxValue, fohMaxValue),
  };
};

/* eslint-disable no-alert */
/* eslint-disable no-console */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-no-literals */
import React, { useState, useEffect, useMemo } from 'react';
import ReactJson from '@microlink/react-json-view';
import {
  Button, Typography, TextField, MenuItem, Select, Box, Stack, Paper, Tab,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { TextareaAutosize } from '@material-ui/core';
import { generalAxiosRequest } from '../../axios/axiosFunctions';
import { SimDateTime } from '../../utils/datetime';
import ErrorAlert from '../../components/alerts/Error';
import CustomSpinner from '../../components/CustomSpinner';

const requestMethods = [
  {
    slug: 'get',
    method: 'GET',
  },
  {
    slug: 'post',
    method: 'POST',
  },
  // {
  //   slug: 'put',
  //   method: 'PUT',
  // },
  // {
  //   slug: 'patch',
  //   method: 'PATCH',
  // },
  // {
  //   slug: 'delete',
  //   method: 'DELETE',
  // },
];

/** **********
 * Postman Page is used to test URLs within the context of SIMWEB
 *
 * This is ENABLED only in Test
 * CORS ERROR URL: https://api-test.nikecloud.com/store/supervised-device-sessions/v1
 */

const Postman = () => {
  const [url, setUrl] = useState('https://deledge.test.commerce.nikecloud.com/store/configs/v2/store/USA-128?filter=key(true.disableEJ)');
  const [reqMethod, setReqMethod] = useState('GET');
  const [queryParams, setQueryParams] = useState([{ key: null, value: null }]);
  const [headers, setHeaders] = useState([{ key: null, value: null }]);
  const [body, setBody] = useState(null);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  const [responsePanel, setResponsePanel] = useState('responseBody');
  const [requestPanel, setRequestPanel] = useState('queryParams');

  const buttonLabel = 'send';
  const responseLabel = 'Response';
  const title = 'Postman';

  const onSend = async (e) => {
    setLoading(true);
    setResponse(null);
    setError(null);

    e.preventDefault();
    const requestBody = body?.toString();

    let filteredHeaders = headers.filter(header => header?.value !== null);
    filteredHeaders = filteredHeaders?.length === 0 ? null : filteredHeaders;

    let filteredParams = queryParams.filter(queryParams => queryParams?.value !== null);
    filteredParams = filteredParams?.length === 0 ? null : filteredParams;

    console.log('http method', reqMethod);
    console.log('headers', filteredHeaders);
    console.log('query params ', filteredParams);
    console.log('body ', requestBody);

    let data;
    try {
      data = requestBody && JSON.parse(requestBody);
    } catch (e) {
      alert('Something is wrong with the JSON data.');
    }

    try {
      const endpoint = { name: url };
      const response = await generalAxiosRequest(reqMethod, url, endpoint, true, filteredHeaders, data, filteredParams, true);
      setResponse(response);
    } catch (e) {
      setError(e);
      setResponse(e.response);
    }

    setLoading(false);
  };

  const handleMethodChange = (event, newValue) => {
    setResponsePanel(newValue);
  };

  const handleRequestChange = (event, newValue) => {
    setRequestPanel(newValue);
  };

  const onEdit = (e) => {
    setBody(e.target.value);
  };

  const addKeyValue = (prefix) => {
    const clone = prefix === 'queryParam' ? [...queryParams] : [...headers];
    const last = clone[clone.length - 1];
    last.key = document.getElementById(`${prefix}-key-${clone.length - 1}`).value;
    last.value = document.getElementById(`${prefix}-value-${clone.length - 1}`).value;
    clone.push({ key: null, value: null });
    if (prefix === 'queryParam') setQueryParams(clone);
    else setHeaders(clone);
    return clone;
  };

  const addQueryParam = (prefix) => {
    const params = addKeyValue(prefix);
    const filteredParams = params.filter(queryParam => queryParam?.key !== null);
    setUrl(`${url.split('?')[0]}?${filteredParams.map(keyValue => `${keyValue.key}=${keyValue.value}`).join('&')}`);
  };

  const onRemove = (prefix, index) => {
    const clone = prefix === 'queryParam' ? [...queryParams] : [...headers];
    clone.splice(index, 1);
    if (prefix === 'queryParam') {
      setQueryParams(clone);
      const filteredParams = clone.filter(queryParam => queryParam?.key !== null);
      setUrl(`${url.split('?')[0]}?${filteredParams.map(keyValue => `${keyValue.key}=${keyValue.value}`).join('&')}`);
    } else {
      setHeaders(clone);
    }
  };

  const onUrlChange = (e) => {
    setUrl(e.target.value);
    setQueryParams([]);
    setHeaders([]);
    setResponse(null);
    setError(null);
  };

  const KeyValueRow = ({
    prefix, index, keyValue, value,
  }) => (
    <Stack direction="row" spacing={2}>
      <TextField id={`${prefix}-key-${index}`} value={keyValue} fullWidth placeholder="Key" />
      <TextField id={`${prefix}-value-${index}`} value={value} fullWidth placeholder="Value" />
      <Button
        id={`${prefix}-remove-button-${index}`}
        style={{ minWidth: '100px' }}
        variant="outlined"
        onClick={(_) => onRemove(prefix, index)}
      >
        Remove
      </Button>
    </Stack>
  );

  const errorMessage = useMemo(() => {
    if (error && error?.response?.readyState === 4) {
      const message = 'A network error occurred. '
          + 'This could be a CORS issue or a dropped internet connection. '
          + 'Please check the network tab in the developer tools.';

      return <ErrorAlert errorObject={message} apiName="Network Error" pageCode="" />;
    }

    return null;
  }, [error]);

  const ellipses = (
    <div id="postman-ellipses">
      <CustomSpinner size="10px" color="#5CDAA8" loading margin="25px" />
    </div>
  );

  const getQueryParams = useMemo(() => {
    if (queryParams?.length === 0) return <KeyValueRow prefix="queryParam" index={0} />;
    return queryParams.map((item, index) => (<KeyValueRow prefix="queryParam" index={index} keyValue={item.key} value={item.value} />));
  }, [queryParams]);

  const getHeaderParams = useMemo(() => {
    if (headers?.length === 0) return <KeyValueRow prefix="headerParam" index={0} />;
    return headers.map((item, index) => (<KeyValueRow prefix="headerParam" index={index} keyValue={item.key} value={item.value} />));
  }, [headers]);

  const responseStatus = useMemo(() => {
    const getFormattedLabel = (text) => (
      <Typography variant="body1" style={{ marginRight: '25px' }}>{text}</Typography>
    );

    const { timeData } = { ...response?.config };
    const size = (response?.data ? JSON.stringify(response?.data).length : 0)
        + (response?.headers ? JSON.stringify(response.headers).length : 0);
    const time = timeData ? timeData?.endTime - timeData?.startTime : 0;

    return (
      <Stack direction="row" spacing={2}>
        {getFormattedLabel(`Status: ${response?.status ?? ''}`)}
        {getFormattedLabel(`Status Text: ${response?.statusText ?? ''}`)}
        {getFormattedLabel(`Time: ${time}`)}
        {getFormattedLabel(`Size: ${size}`)}
      </Stack>
    );
  }, [response]);

  const responseBodyPanel = useMemo(() => (<ReactJson src={response?.data ?? {}} displayDataTypes={false} name={false} />), [response]);

  const responseHeaderPanel = useMemo(() => {
    const responseHeaders = [];

    if (!(response == null)) {
      if ('headers' in response) {
        Object.entries(response.headers).forEach(([key, value]) => {
          responseHeaders.push({
            key,
            value,
          });
        });
      }
    }

    const renderedHeaders = responseHeaders.map(({ key, value, index }) => (
      <tr key={index}>
        <td><Typography variant="body1" style={{ minwidth: '200px', marginRight: '25px' }}>{key}</Typography></td>
        <td><Typography variant="body1" style={{ minwidth: '200px', marginRight: '25px' }}>{value}</Typography></td>
      </tr>
    ));

    return (
      <table>
        <thead>
          <tr>
            <th><Typography variant="h6" style={{ minwidth: '200px', marginRight: '25px' }}>Key</Typography></th>
            <th><Typography variant="h6" style={{ minwidth: '200px', marginRight: '25px' }}>Value</Typography></th>
          </tr>
        </thead>
        <tr>
          <td colSpan={2}><hr /></td>
        </tr>
        <tbody>{renderedHeaders}</tbody>
      </table>
    );
  }, [response]);

  const errorPanel = useMemo(() => (<ReactJson src={error ?? {}} displayDataTypes={false} name={false} />), [error]);

  return (
    <>
      <Paper style={{ padding: '25px' }}>
        {errorMessage}
        <span className="feature-title">{title}</span>
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <Stack direction="row" spacing={0}>
            <Select
              value={reqMethod}
              onChange={(e) => { setResponse(null); setReqMethod(e.target.value); }}
            >
              {requestMethods.map((option) => (
                <MenuItem key={option.slug} value={option.method}>
                  {option.method}
                </MenuItem>
              ))}
            </Select>
            <TextField
              id="textField-url"
              fullWidth
              value={url}
              onChange={(e) => onUrlChange(e)}
            />
            <Button
              variant="contained"
              size="large"
              onClick={(e) => onSend(e)}
              endIcon={<SendIcon />}
            >
              {buttonLabel}
            </Button>
          </Stack>
          <br />
          <TabContext value={requestPanel}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleRequestChange} aria-label="lab API tabs example">
                <Tab label="Query Params" value="queryParams" />
                <Tab label="Headers" value="headers" />
                <Tab label="Body" value="body" />
              </TabList>
            </Box>
            <TabPanel value="queryParams">
              {getQueryParams}
              <br />
              <Button id="add-queryParam-button" variant="outlined" size="medium" onClick={(_) => addQueryParam('queryParam')}>Add</Button>
            </TabPanel>
            <TabPanel value="headers">
              {getHeaderParams}
              <br />
              <Button id="add-headerParam-button" variant="outlined" size="medium" onClick={(_) => addKeyValue('headerParam')}>Add</Button>
            </TabPanel>
            <TabPanel value="body"><TextareaAutosize placeholder="Paste JSON object" onChange={e => onEdit(e)} style={{ width: '100%' }} minRows={5}>{body}</TextareaAutosize></TabPanel>
          </TabContext>
          <hr />
          <span className="feature-title">{responseLabel}</span>
          {loading ? ellipses : responseStatus}
          <br />
          <TabContext value={responsePanel}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleMethodChange} aria-label="API Methods">
                <Tab label="Response Body" value="responseBody" />
                <Tab label="Response Header" value="responseHeader" />
                <Tab label="Error" value="error" />
              </TabList>
            </Box>
            <TabPanel value="responseBody">{responseBodyPanel}</TabPanel>
            <TabPanel value="responseHeader">{responseHeaderPanel}</TabPanel>
            <TabPanel value="error">{errorPanel}</TabPanel>
          </TabContext>
        </Box>
      </Paper>
    </>
  );
};

export default Postman;

const RETAIL_AUTH = 'trueRetailAuth';
const OKTA_AUTH = 'trueOktaAuth';

const localStorageService = {
  login: (accessToken, refreshToken, tokenTimeout, prn) => {
    const authObject = {
      accessToken,
      refreshToken,
      tokenTimeout,
      prn,
    };
    localStorage.setItem(RETAIL_AUTH, JSON.stringify(authObject));
  },
  logout: () => {
    localStorage.removeItem(RETAIL_AUTH);
  },
  getAuthToken: () => {
    const authObjectString = localStorage.getItem(RETAIL_AUTH);
    if (authObjectString) {
      const authObject = JSON.parse(authObjectString);
      if (authObject.accessToken) return authObject.accessToken;
      return null;
    }
    return null;
  },
  getOktaToken: () => {
    const authObjectString = localStorage.getItem(OKTA_AUTH);
    if (authObjectString) {
      const authObject = JSON.parse(authObjectString);
      if (authObject.idToken) return authObject.idToken.value;
      return null;
    }
    return null;
  },
  getTokenTimeout: () => {
    const authObjectString = localStorage.getItem(RETAIL_AUTH);
    if (authObjectString) {
      const authObject = JSON.parse(authObjectString);
      if (authObject.tokenTimeout) return authObject.tokenTimeout;
      return null;
    }
    return null;
  },
  getStoreId: () => {
    const storeId = {
      country: localStorage.getItem('trueStoreId.country'),
      storeNumber: localStorage.getItem('trueStoreId.storeNumber'),
    };
    return storeId;
  },
  getParentUrl: () => localStorage.getItem('true.parentUrl'),
  setStoreId: (country, storeNumber) => {
    localStorage.setItem('trueStoreId.country', country);
    localStorage.setItem('trueStoreId.storeNumber', storeNumber);
  },
  setOktaToken: (token) => {
    localStorage.setItem(OKTA_AUTH, JSON.stringify(token));
  },
  setAuthToken: (token) => {
    localStorage.setItem(RETAIL_AUTH, JSON.stringify(token));
  },
  setParentUrl: (url) => {
    localStorage.setItem('true.parentUrl', JSON.stringify(url));
    sessionStorage.setItem('source', 'true');
    sessionStorage.setItem('loginSource', 'true');
  },
};

export default localStorageService;

import { useEffect } from 'react';

/**
 * Custom hook to manage session storage.
 *
 * @param {string} key - The key under which the value is stored in session storage.
 * @param {object} initialValue - The initial value to store in session storage.
 * @returns {[function, function]} - Returns an array with two functions:
 *   - getValue: A function to retrieve the value from session storage.
 *   - setValue: A function to store a new value in session storage.
 */

const useSessionStorage = (key, initialValue) => {
  const get = () => {
    const value = sessionStorage.getItem(key);
    return value ? JSON.parse(value) : null;
  };

  const set = (newValue) => {
    sessionStorage.setItem(key, JSON.stringify(newValue));
  };

  useEffect(() => {
    if (initialValue) {
      set(initialValue);
    }

    return () => {
      sessionStorage.removeItem(key);
    };
  }, []);

  return [get, set];
};

export default useSessionStorage;

import React from 'react';
import { string, bool, func } from 'prop-types';
import {
  FullStore, Offsite, SalesFloor, StockRoom,
} from '../../../../assets/Svgs';

const AreaButtons = ({
  location, isOffsiteEnabled, isOffsiteInactive, setLocation, getMessage,
}) => (
  <div className="scan-product-detail-area-buttons" data-testid="scan-product-detail-area-buttons">
    <div className={`area-button-wrapper${location === 'fullStore' ? ' selected' : ''}`}>
      <button
        type="button"
        data-testid="all-gtins-button"
        onClick={() => setLocation('fullStore')}
      >
        {FullStore}
        <span>{getMessage('fullStore')}</span>
      </button>
    </div>
    <div className={`area-button-wrapper${location === 'salesFloor' ? ' selected' : ''}`}>
      <button
        type="button"
        data-testid="salesFloor-gtins-button"
        onClick={() => setLocation('salesFloor')}
      >
        {SalesFloor}
        <span>{getMessage('salesFloor')}</span>
      </button>
    </div>
    <div className={`area-button-wrapper${location === 'stockRoom' ? ' selected' : ''}`}>
      <button
        type="button"
        data-testid="stockRoom-gtins-button"
        onClick={() => setLocation('stockRoom')}
      >
        {StockRoom}
        <span>{getMessage('stockRoom')}</span>
      </button>
    </div>
    {isOffsiteEnabled
      && (
        <div className={`area-button-wrapper${location === 'offsite' ? ' selected' : ''}`}>
          <button
            type="button"
            data-testid="offsite-gtins-button"
            onClick={() => setLocation('offsite')}
          >
            {Offsite}
            <span>{`${getMessage('offsite')}${isOffsiteInactive && ` (${getMessage('inactive')})`}`}</span>
          </button>
        </div>
      )}
  </div>
);

AreaButtons.propTypes = {
  location: string,
  isOffsiteEnabled: bool,
  isOffsiteInactive: bool,
  setLocation: func.isRequired,
  getMessage: func.isRequired,
};

AreaButtons.defaultProps = {
  isOffsiteEnabled: false,
  isOffsiteInactive: false,
  location: 'fullStore',
};

export default AreaButtons;

/* eslint-disable no-console */
/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
import writeXlsxFile from 'write-excel-file';
import React, { useContext, useMemo } from 'react';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import MUIDataTable from 'mui-datatables';
import { newRelicAction } from '../../../utils/newRelicPageActions';
import { buildDownloadHeaders, buildDownloadData } from './purchaseOrderDialogUtils';
import { SimWebContext } from '../../../context/SimWeb.provider';
import { InboundVisibilityContext } from '../InboundVisibility.provider';
import { PurchaseOrderDialogContext } from './InboundVisibilityPurchaseOrderDialog.provider';
import { SimDateTime } from '../../../utils/datetime';

export const baseOptions = {
  selectableRows: 'none',
  rowsPerPage: 100,
  rowsPerPageOptions: [],
  print: false,
  filter: false,
  viewColumns: false,
  tableBodyHeight: '400px',
};

/**
 * Generates CSV download options for the Purchase Order Dialog Table.
 *
 * @param {object} baseOptions - The base options for the CSV download.
 * @param {boolean} isEmea - Indicates whether the region is EMEA.
 * @param {string} title - The title of the CSV file.
 * @param {boolean} rfidEnabled - Indicates whether RFID is enabled.
 * @param {boolean} excelDownloadsEnabled - Indicates whether Excel downloads are enabled.
 * @returns {object} - The CSV download options.
 */
export const csvDownloads = (baseOptions, isEmea, title, rfidEnabled, excelDownloadsEnabled) => ({
  ...baseOptions,
  downloadOptions: {
    filename: `Purchase Order List-${title}.csv`,
    separator: isEmea ? ';' : ',',
    filterOptions: {
      useDisplayedRowsOnly: true,
    },
  },
  onDownload: (buildHead, buildBody, newColumns, newData) => {
    newRelicAction('purchase-order-download', { isDownloaded: true });
    const headers = buildHead(buildDownloadHeaders(newColumns, rfidEnabled, excelDownloadsEnabled));
    const rows = buildBody(buildDownloadData(newData, rfidEnabled));
    return `\uFEFF${headers}${rows}`;
  },
});

/**
 * Generates an Excel download configuration object.
 *
 * @param {object} baseOptions - The base options for the Excel download.
 * @param {string} title - The title of the Excel file.
 * @param {string} store - The store number.
 * @param {boolean} rfidEnabled - Indicates if RFID is enabled.
 * @param {boolean} excelDownloadsEnabled - Indicates if Excel downloads are enabled.
 * @returns {object} - The Excel download configuration object.
 */
export const excelDownloads = (baseOptions, title, store, rfidEnabled, excelDownloadsEnabled) => {
  /**
   * Downloads the Excel file.
   *
   * @param {function} buildHead - The function to build the Excel file header.
   * @param {function} buildBody - The function to build the Excel file body.
   * @param {array} newColumns - The new columns for the Excel file.
   * @param {array} newData - The new data for the Excel file.
   * @returns {boolean} - Indicates if the default datatable download logic should be canceled.
   */
  const onDownload = (buildHead, buildBody, newColumns, newData) => {
    const titleRow = [{ value: title, fontWeight: 'bold' }];
    const headerColumns = buildDownloadHeaders(newColumns, rfidEnabled, excelDownloadsEnabled);
    const headerRow = headerColumns.map(label => ({ value: label, type: String, fontWeight: 'bold' }));
    const data = buildDownloadData(newData, rfidEnabled);
    const dataRows = data.map(row => row.data.map(value => ({ value: value?.toString() ?? '', type: String })));

    try {
      writeXlsxFile([titleRow, headerRow, ...dataRows], { fileName: `InboundVisibility-PO-List-${store}-${SimDateTime.toUtcISO()}.xlsx` });
      newRelicAction('purchase-order-download', { isDownloaded: true });
    } catch (error) {
      console.error(error);
      newRelicAction('purchase-order-download', { isDownloaded: false });
    }

    return false; // cancels default datatable download logic
  };

  return {
    ...baseOptions,
    onDownload,
    downloadOptions: {
      filterOptions: {
        useDisplayedRowsOnly: true,
      },
    },
  };
};

/**
 * Displays datatable with Inbound Visibility Purchase Order details by sizes
 * */
const PurchaseOrderDialogTable = () => {
  const { store, storeInfo } = useContext(SimWebContext);
  const { rfidEnabled, excelDownloadsEnabled } = useContext(InboundVisibilityContext);
  const { theme, columns, rows, title } = useContext(PurchaseOrderDialogContext);
  const isEmea = storeInfo?.isEMEA;

  const options = useMemo(() => (excelDownloadsEnabled
    ? excelDownloads(baseOptions, title, store, rfidEnabled, excelDownloadsEnabled)
    : csvDownloads(baseOptions, isEmea, title, rfidEnabled, excelDownloadsEnabled)
  ), [store, rfidEnabled, isEmea, excelDownloadsEnabled]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <div data-testid="purchase-order-dialog-table">
          <MUIDataTable
            title={title}
            columns={columns}
            data={rows}
            options={options}
          />
        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default PurchaseOrderDialogTable;

import React, { useContext, useMemo } from 'react';

import { SimWebContext } from '../../context/SimWeb.provider';
import { StockOnHandContext } from './StockOnHand.provider';
import BaseViewPage from '../baseView/BaseViewPage';

import './StockOnHandPage.css';
/**
 * SOH Report that displays the SOH within the 4 walls of a store
 */
const StockOnHandPage = () => {
  const {
    data, loading, error, columns, tableOptions, id,
  } = useContext(StockOnHandContext);
  const { getMessage } = useContext(SimWebContext);

  const visuals = useMemo(() => {
    const summaryTable = data?.summary && Object.entries(data.summary).map(([key, value], idx) => (
      <div className="stock-on-hand-summary-table-row" key={`${key}-${idx}`}>
        <div className="key">{getMessage(key)}</div>
        {Object.values(value).map((elem) => (
          <div className={`value ${elem < 0 ? 'negative' : ''}`} key={`${elem}-${idx}`}>
            {elem < 0 ? -elem : elem}
          </div>
        ))}
      </div>
    ));
    return (
      <>
        {!loading && !error && (
          <div className="stock-on-hand-summary-container" data-testid="stockOnHandSummary">
            <div className="stock-on-hand-summary-table">
              {data && (
                <div className="stock-on-hand-summary-table-row labels">
                  <div className="key" />
                  <div className="value">{getMessage('positive')}</div>
                  <div className="value">{getMessage('negative')}</div>
                </div>
              )}
              {summaryTable}
            </div>
          </div>
        )}
      </>
    );
  }, [data, loading, error]);

  return (
    <>
      <BaseViewPage
        id={id}
        title={id}
        storeConfigValue="sohEnabled"
        loading={loading}
        error={error}
        visuals={visuals}
        details={{
          options: tableOptions,
          columns,
          rows: data?.details,
        }}
      />
    </>
  );
};

export default StockOnHandPage;

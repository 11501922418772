/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
/* eslint-disable object-curly-newline */
/* eslint-disable max-len */
/* eslint-disable no-await-in-loop */
import React, { createContext, useState } from 'react';
import { node, object } from 'prop-types';
import { updateSession, getUploadStatus as getStatus, getPhysicalInventoryUploadResults, uploadFile as piUploadFiles } from './physicalInventoryUpload.axios';
import { wait } from '../../axios/axiosFunctions';
import PIUploadStatus from './PIUploadStatus';
import { newRelicAction } from '../../utils/newRelicPageActions';
import { isDuplicate } from './physicalInventoryUpload.utils';
import { SimDateTime } from '../../utils/datetime';

export const PhysicalInventoryUploadContext = createContext({});
export const SESSION_KEY = 'piUploadFiles';

const PhysicalInventoryUploadProvider = ({ children, mockedValue }) => {
  const { Provider } = PhysicalInventoryUploadContext;
  const [isHistoricalFetch, setFetchingHistorical] = useState(false);
  const [historicalSession, setHistoricalSessions] = useState([]);
  const [sessionId, setSessionId] = useState(null);
  const [sessionData, setSessionData] = useState(null);
  const [varianceData, setVarianceData] = useState(null);
  const [paginatedVarianceData, setPaginatedVarianceData] = useState(null);
  const [uploadStatus, setUploadStatus] = useState(null);
  const [lastDeltaSessionId, setLastDeltaSessionId] = useState(null);
  const [lastSession, setLastSession] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [totalCounts, setTotalCounts] = useState(1);
  const [currentFiles, setCurrentFiles] = useState(JSON.parse(sessionStorage.getItem(SESSION_KEY)) ?? []);
  const [alertMessage, setAlertMessage] = useState(null);

  const isOneWeekkOld = (date) => SimDateTime.toDateTime(date) > SimDateTime.now().minus({ days: 7 }).endOf('day');

  const getSessionIds = async (storeId, onError) => {
    const sessions = await getStatus(storeId, null)
      .then(data => data?.objects)
      .catch(error => {
        if (onError) onError(error);
      });

    const filteredSessions = sessions?.filter(session => isOneWeekkOld(session.creationDate));

    if (filteredSessions && filteredSessions.length > 0) {
      const sessionId = filteredSessions[0]?.sessionId;
      const historicalSessions = filteredSessions.filter(x => PIUploadStatus.isFileCalculated(x.status));
      const lastestDeltaSession = historicalSessions?.[0];
      const lastDeltaSessionId = lastestDeltaSession?.sessionId ?? 0;
      setSessionId(sessionId);
      setLastSession(lastestDeltaSession);
      setLastDeltaSessionId(lastDeltaSessionId);
      setHistoricalSessions(historicalSessions);
    } else {
      setSessionId('new');
    }
  };

  const getUploadStatus = async (storeId, sessionId, runOnce = false) => {
    let response = null;
    let status = null;

    while (runOnce || !response || PIUploadStatus.isInProgress(response?.status) || PIUploadStatus.isFileUploadInProgress(status)) {
      // eslint-disable-next-line no-loop-func
      response = await getStatus(storeId, sessionId).then(data => data?.objects?.[0]);
      status = response?.status ?? PIUploadStatus.UNKNOWN.name;

      setSessionData(response);
      setUploadStatus(status);

      if (runOnce) break;
      if (PIUploadStatus.isFileUploadInProgress(status)) await wait(2000);
      if (PIUploadStatus.isInProgress(status)) await wait(15000);
    }

    if (!status) return;

    if (PIUploadStatus.isFileUploadComplete(status)) {
      setSessionId(response.sessionId);
      setSessionData(response);

      // Check for duplicates
      const { gtins, locations, quantity } = { ...response };
      if (isDuplicate(lastSession, { gtins, locations, quantity })) {
        setUploadStatus(PIUploadStatus.DUPLICATE_FILE_UPLOAD.name);
      }
    }
  };

  const getDelta = async (storeId, lastDeltaSessionId, onSuccess) => {
    const deltaData = await getPhysicalInventoryUploadResults(storeId, lastDeltaSessionId, setPaginatedVarianceData, setTotalCounts)
      .then(data => data);

    if (deltaData) {
      setVarianceData(deltaData);
      setTotalCounts(deltaData.length);
      if (onSuccess) onSuccess();
    }
  };

  const uploadFile = async (storeId, file, onFail, onError) => {
    await piUploadFiles(storeId, file)
      .then((data) => {
        const { error, errorCode, fileName, status, sessionId: currentSessionId } = { ...data };

        if (PIUploadStatus.isFileUploadFailed(status)) {
          if (onFail) onFail(error, errorCode, fileName, status);
        } else if (currentSessionId) {
          getUploadStatus(storeId, currentSessionId);
        } else {
          throw new Error('Sessiond id is null. Cannot retrieve current status');
        }
      })
      .catch(error => {
        console.error({ message: 'File upload error.', error, file: file?.name });
        if (onError) onError(error, file?.name);
      });
  };

  const addNewRelicAction = (actionName, attributes = {
    sessionId, sessionData, lastSession, status: lastSession?.status, lastDeltaSessionId,
  }) => {
    newRelicAction(`PIUploadView-${actionName}`, attributes);
  };

  const context = (
    {
      addNewRelicAction,
      alertMessage,
      setAlertMessage,
      currentFiles,
      setCurrentFiles,
      lastDeltaSessionId,
      setLastDeltaSessionId,
      getDelta,
      getSessionIds,
      getUploadStatus,
      lastSession,
      paginatedVarianceData,
      setPaginatedVarianceData,
      rowsPerPage,
      setRowsPerPage,
      totalCounts,
      setTotalCounts,
      sessionData,
      setSessionData,
      sessionId,
      setSessionId,
      uploadStatus,
      setUploadStatus,
      uploadFile,
      updateSession,
      varianceData,
      setVarianceData,
      historicalSession,
      setHistoricalSessions,
      setLastSession,
      isHistoricalFetch,
      setFetchingHistorical,
    }
  );

  return (
    <Provider value={mockedValue ? { ...mockedValue } : context}>
      {children}
    </Provider>
  );
};

PhysicalInventoryUploadProvider.defaultProps = {
  children: {},
  mockedValue: null,
};

PhysicalInventoryUploadProvider.propTypes = {
  children: node,
  mockedValue: object,
};

export default PhysicalInventoryUploadProvider;

import React from 'react';
import {
  any, string, array, func, bool, shape, oneOfType, number, object, arrayOf,
} from 'prop-types';
import Select, { components } from 'react-select';

import './CustomSelect.css';

const { Option, SingleValue } = components;

const IconOption = (props) => {
  const { data } = props;
  return (
    <Option {...props} className="custom-select-options">
      {data.label}
      {data.icon && data.icon}
    </Option>
  );
};

const ValueOption = (props) => {
  const { data } = props;
  return (
    <SingleValue {...props} className="custom-select-options">
      {data.label}
      {data.icon && data.icon}
    </SingleValue>
  );
};

IconOption.propTypes = {
  data: object.isRequired,
};

ValueOption.propTypes = {
  data: object.isRequired,
};

const CustomSelect = ({
  label,
  options,
  onChange,
  defaultValue,
  customClass,
  customSelectClass,
  customLabelClass,
  clearDefault,
  ...rest
}) => (
  <div
    className={`custom-select-container ${customClass}`}
    data-testid={`custom-select-${customSelectClass}`}
  >
    <div className={`custom-select-label ${customLabelClass}`}>{`${label}: `}</div>
    {/* undefined must be used so the properties are not used...nulls are a valid value */}
    <Select
      className={`custom-select-component ${customSelectClass}`}
      value={clearDefault ? null : undefined}
      defaultValue={clearDefault ? undefined : defaultValue}
      onChange={onChange}
      components={{ Option: IconOption, SingleValue: ValueOption }}
      options={options}
      aria-label="custom-select"
      id="custom-select"
      rest={rest}
    />
  </div>
);

CustomSelect.propTypes = {
  customClass: string,
  customLabelClass: string,
  customSelectClass: string,
  label: string,
  options: array.isRequired,
  onChange: func.isRequired,
  clearDefault: bool,
  defaultValue: arrayOf(shape({
    label: oneOfType([string, number]),
    value: oneOfType([string, number]),
    color: string,
    isFixed: bool,
  })),
  rest: any,
};

CustomSelect.defaultProps = {
  customClass: '',
  customLabelClass: '',
  customSelectClass: '',
  defaultValue: null,
  label: string,
  rest: null,
  clearDefault: false,
};

export default CustomSelect;

import React, { useEffect, useState, useContext } from 'react';
import { string, object } from 'prop-types';
import Select from 'react-select';
import Container from '@mui/material/Container';
import { Box } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import { Checkmark, LoadingIndicator } from '../../assets/Svgs/index';
import deviceData from '../../__test__/__mocks__/retail-managed-devices.json';
import { environment } from '../../environment';

import SubmitButton from './SubmitButton';
import TransferList from './TransferList';
import { TranslationsContext } from '../../context/Translations.provider';

const classStyles = {
  main: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '8px',
    textAlign: 'center',
    justifyContent: 'center',
  },
};

const AthletesDevices = ({
  auth,
  classes,
  country,
  daAdapter,
  storeId,
  storeNumber,
}) => {
  const { getMessage } = useContext(TranslationsContext);
  const [athletesList, setAthletesList] = useState([]);
  const [selectedAthlete, setAthlete] = useState({});
  const [athletesFetched, setAthletesFetched] = useState(false);

  const [fetchingDevices, setFetchingDevices] = useState(true);
  const [devicesList, setDevicesList] = useState([]);

  const [fetchingSessions, setFetchingSessions] = useState(false);
  const [devicesInSession, setDevicesInSession] = useState([]);
  const [devicesAvailable, setDevicesAvailable] = useState([]);
  const [devicesAssigned, setDevicesAssigned] = useState([]);

  const [newChanges, setNewChanges] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [changesSubmitted, setChangesSubmitted] = useState([]);
  const [errorMessage, setError] = useState('');
  const [successMessage, setSuccess] = useState('');

  const selfCheckoutOwnerId = (environment === 'prod')
    ? '7e842bd0-995a-4b78-a146-af8a24726f76'
    : 'ef16f49b-ba73-4d10-b837-973eed2bf8aa';

  const clear = () => {
    setError('');
    setSuccess('');
  };

  // GET to workforce/profiles/ to get an array of all athletes in that store.
  useEffect(() => {
    const getAthletes = async () => daAdapter.getProfiles({ auth, country, storeNumber })
      .then(res => setAthletesList(res))
      .catch(() => {
        setError(getMessage('messageFetchingAthletesError'));
      })
      .finally(() => setAthletesFetched(true));

    if (storeNumber && !athletesList.length) {
      getAthletes();
    }
    return () => null;
  }, [athletesList.length, auth, country, daAdapter, getMessage, storeNumber]);

  // GET to /store/retail_managed_devices/ to get an array of all devices in that store.
  useEffect(() => {
    if (storeId && !athletesList.length) {
      setFetchingDevices(true);
      const getDevices = async () => daAdapter.getDevices({ auth, storeId })
        .then((res) => {
          const devicesInStore = (environment === 'prod') ? res : res.concat(deviceData);
          return setDevicesList(devicesInStore.filter(device => (device.ownerId === selfCheckoutOwnerId)));
        })
        .catch(() => setError(getMessage('messageFetchingDevicesError')))
        .finally(() => setFetchingDevices(false));
      getDevices();
    }
    return () => null;
  }, [athletesList.length, auth, daAdapter, getMessage, storeId]);

  // GET to /store/supervised-device-sessions/ by athleteId & filter into two arrays of unassigned and assigned devices.
  useEffect(() => {
    if (selectedAthlete?.id) {
      clear();
      setFetchingSessions(true);
      const getSessions = async () => daAdapter.getSessions({ auth, storeId })
        .then((res) => {
          const now = new Date().toISOString();
          const sessions = res.filter(session => session.expirationDate > now);
          const athleteDevicesAssigned = sessions.filter(session => session.athleteId === selectedAthlete.id)
            .map(session => session.deviceId);
          const availableDevices = [];
          const selectedDevices = [];

          devicesList.forEach(device => (athleteDevicesAssigned.includes(device.id)
            ? selectedDevices.push({ ...device, isCurrentlyAssigned: true })
            : availableDevices.push(device)));

          setDevicesInSession(sessions);
          setDevicesAvailable(availableDevices);
          setDevicesAssigned(selectedDevices);
        })
        .catch(() => setError(getMessage('messageFetchingAssignmentsError')))
        .finally(() => setFetchingSessions(false));
      getSessions();
    }
    return () => null;
  }, [auth, changesSubmitted, daAdapter, devicesList, getMessage, selectedAthlete, storeId]);

  return (
    <Box className={classes.main} data-testid="athletesdevicesbox">
      <Container maxWidth="lg">
        {athletesList.length && athletesFetched && !fetchingDevices
          ? (
            <form
              style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'center',
                justifyContent: 'center',
              }}
            >
              <p data-testid="athleteSelectLabel" style={{ fontSize: '18px', marginBottom: '-1px' }}>{getMessage('athlete')}</p>
              <Select
                aria-label="Athlete Select"
                id="athleteSelect"
                options={athletesList.map(profile => ({
                  label: `${profile.athleteName.firstName} ${profile.athleteName.lastName}`,
                  value: profile,
                }))}
                styles={{
                  container: styles => ({ ...styles, marginBottom: '10px', zIndex: 110 }),
                  control: styles => ({ ...styles, width: 390 }),
                }}
                theme={theme => ({ ...theme, colors: { ...theme.colors, primary25: 'lightgray', primary: 'black' } })}
                value={(selectedAthlete && selectedAthlete.id)
                  ? {
                    label: `${selectedAthlete.athleteName.firstName} ${selectedAthlete.athleteName.lastName}`,
                    value: selectedAthlete,
                  }
                  : null}
                onChange={newOption => setAthlete(newOption.value)}
              />
              <TransferList
                assigned={devicesAssigned}
                available={!selectedAthlete.id ? devicesList : devicesAvailable}
                clear={clear}
                devicesInSession={devicesInSession}
                disabled={!selectedAthlete.id}
                fetching={fetchingSessions}
                setDevicesAssigned={setDevicesAssigned}
                setDevicesAvailable={setDevicesAvailable}
                setNewChanges={setNewChanges}
              />
              <SubmitButton
                auth={auth}
                clear={clear}
                daAdapter={daAdapter}
                disabled={submitting || !newChanges}
                devicesAssigned={devicesAssigned}
                devicesAvailable={devicesAvailable}
                devicesInSession={devicesInSession}
                isLoading={submitting}
                selectedAthlete={selectedAthlete}
                setChangesSubmitted={setChangesSubmitted}
                setDevicesAvailable={setDevicesAvailable}
                setDevicesAssigned={setDevicesAssigned}
                setError={setError}
                setNewChanges={setNewChanges}
                setSubmitting={setSubmitting}
                setSuccess={setSuccess}
                storeId={storeId}
              />
            </form>
          )
          : ((!athletesFetched || fetchingDevices) && <LoadingIndicator width="65px" height="65px" />)}
        {errorMessage && <p style={{ fontSize: '18px', marginBottom: '10px' }}>{errorMessage}</p>}
        {successMessage && (
          <p style={{ fontSize: '18px', marginBottom: '10px' }}>
            <Checkmark />
            &nbsp;&nbsp;
            {successMessage}
          </p>
        )}
      </Container>
    </Box>
  );
};

AthletesDevices.propTypes = {
  auth: string,
  classes: object.isRequired,
  country: string.isRequired,
  daAdapter: object.isRequired,
  storeNumber: string.isRequired,
  storeId: string.isRequired,
};

AthletesDevices.defaultProps = {
  auth: '',
};

export default (withStyles(classStyles)(AthletesDevices));

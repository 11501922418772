import React from 'react';
import { string } from 'prop-types';

// eslint-disable-next-line import/prefer-default-export
export const Missing = ({ extraClasses }) => (
  <div className={`${extraClasses}`}>
    <svg width="24" height="25" viewBox="0 0 24 25" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.5 18.9402L5.56004 5.00024L4.50004 6.06024L6.20004 7.76024C5.14004 9.05024 4.50004 10.7002 4.50004 12.5002C4.50004 16.6402 7.86004 20.0002 12 20.0002C13.8 20.0002 15.45 19.3602 16.74 18.3002L18.44 20.0002L19.5 18.9402ZM12 18.5002C8.69004 18.5002 6.00004 15.8102 6.00004 12.5002C6.00004 11.1202 6.48004 9.85024 7.27004 8.83024L15.67 17.2302C14.65 18.0202 13.38 18.5002 12 18.5002Z" fill="currentColor" />
      <path d="M12.0003 6.5C15.3103 6.5 18.0003 9.19 18.0003 12.5C18.0003 13.33 17.8303 14.12 17.5203 14.84L18.6403 15.96C19.1803 14.92 19.4903 13.75 19.4903 12.5C19.4903 8.36 16.1303 5 11.9903 5C10.7403 5 9.56033 5.31 8.53033 5.85L9.65033 6.97C10.3803 6.67 11.1703 6.5 12.0003 6.5Z" fill="currentColor" />
    </svg>
  </div>
);

Missing.propTypes = {
  extraClasses: string,
};

Missing.defaultProps = {
  extraClasses: '',
};

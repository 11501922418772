import React, { useContext, useMemo } from 'react';
import { isEmpty } from 'lodash';
import getFormattedPrice from '../../../utils/getFormattedPrice';

import { getQuantities } from '../../RFIDTableHeader';
import { SimWebContext } from '../../../context/SimWeb.provider';
import { CPAContext } from '../../../context/cpa/CPA.provider';
import { tutorialContext } from '../../../context/Tutorial.provider';

import mockedStyleColorData from '../../../__test__/__mocks__/cpa/cpa-merged.json';

/**
  * Item Inquiry Widget details - style color, price, bin, soh, etc...
*/
const ItemProductDetails = () => {
  const {
    currencyCode, isOffsiteEnabled, getMessage, locale, storeConfig,
  } = useContext(SimWebContext);
  const { data } = useContext(CPAContext);
  const { startTutorial } = useContext(tutorialContext);

  const productData = useMemo(() => {
    if (startTutorial) return mockedStyleColorData[0];
    if (data) {
      const product = data[0];
      return product;
    }
    return null;
  }, [startTutorial]);

  const rfidEnabled = storeConfig?.rfidEnabled?.value;
  let totalOffsite = 0;
  let totalSalesFloor = 0;
  let totalStockRoom = 0;
  let totalSoh = 0;

  const formattedMSRPPrice = getFormattedPrice(productData?.prices?.msrp, locale, currencyCode) || '-';
  const formattedCurrentPrice = getFormattedPrice(productData?.prices?.currentPrice, locale, currencyCode) || '-';

  const priceElement = useMemo(() => (
    <>
      <div data-testid="productCard_itemPrice-data">
        <span className="productSearchFragment-title">{`${getMessage('currentPrice')}: `}</span>
        {`${formattedCurrentPrice}, `}
        <span className="productSearchFragment-title">{`${getMessage('msrp').toUpperCase()}: `}</span>
        {`${formattedMSRPPrice}`}
      </div>
    </>
  ), [formattedMSRPPrice, formattedMSRPPrice]);

  const labelName = productData?.labelName;
  // eslint-disable-next-line no-unused-expressions
  productData && productData.sizes?.map(item => {
    if (!isEmpty(item?.quantity)) {
      totalSoh += item?.quantity.fiscalStock?.quantity;
      totalOffsite += item?.quantity?.physicalStock?.segmentation?.offSiteQuantity;
      totalSalesFloor += item?.quantity?.physicalStock?.segmentation?.salesFloorQuantity;
      totalStockRoom += item?.quantity?.physicalStock?.segmentation?.stockRoomQuantity;
    }
  });

  const rfidString = `${totalSalesFloor}/${totalStockRoom}${isOffsiteEnabled ? `/${totalOffsite}` : ''}`;

  return (
    <div data-testid="productSearchFragment" className="productSearchFragment">
      <div className="productSearchFragment-productDetails-container">
        <p className="productSearchFragment-name">{labelName}</p>
        <div className="productSearchFragment-productDetails">
          <div data-testid="productSearchFragment-styleColor">
            <span className="productSearchFragment-title">{`${getMessage('style-color')}: `}</span>
            {productData?.productCode}
          </div>
          <div>
            <span className="productSearchFragment-title">
              {`${getMessage('soh')}: `}
            </span>
            <span className="productSearchFragment-soh">
              {rfidEnabled ? getQuantities(rfidString, true, null, true) : totalSoh}
            </span>
          </div>
          {priceElement}
        </div>
      </div>
    </div>
  );
};

export default ItemProductDetails;

/* eslint-disable no-param-reassign */
import { adjustmentSteps, adjustmentsArray } from './adjustments';
import { dashboardSteps, dashboardArray } from './dashboard';
import { inboundVisibilitySteps, inboundStepArray } from './inboundVisibility';
import { itemInquirySteps, itemInquiryStepArray } from './itemInquiry';
import { oosAuditSteps } from './oosAudit';
import { physicalInventoryUploadSteps } from './physicalInventoryUpload';
import { priceChangesSteps } from './priceChanges';
import { sizeComplianceSteps, sizeComplianceStepsArray } from './sizeCompliance';
import { stockRoomOrgSteps } from './stockRoomOrganization';
import { styleManagementSteps, styleManagementStepsArray } from './styleManagement';
import { transfersStepsArray, transfersSteps } from './transfers';
// eslint-disable-next-line import/prefer-default-export
export const stepMap = new Map([
  ['adjustments', adjustmentSteps],
  ['dashboard', dashboardSteps],
  ['inboundVisibility', inboundVisibilitySteps],
  ['itemInquiry', itemInquirySteps],
  ['OOSAudits', oosAuditSteps],
  ['physicalInventoryUpload', physicalInventoryUploadSteps],
  ['priceChanges', priceChangesSteps],
  ['sizeCompliance', sizeComplianceSteps],
  ['stockroomOrganization', stockRoomOrgSteps],
  ['styleManagement', styleManagementSteps],
  ['transfers', transfersSteps],
]);

export const getSteps = new Map([
  ['adjustments', adjustmentsArray],
  ['dashboard', dashboardArray],
  ['inboundVisibility', inboundStepArray],
  ['itemInquiry', itemInquiryStepArray],
  ['OOSAudits', oosAuditSteps],
  ['physicalInventoryUpload', physicalInventoryUploadSteps],
  ['priceChanges', priceChangesSteps],
  ['sizeCompliance', sizeComplianceStepsArray],
  ['stockroomOrganization', stockRoomOrgSteps],
  ['styleManagement', styleManagementStepsArray],
  ['transfers', transfersStepsArray],
]);

/* eslint-disable max-len */
import React, {
  useState, createContext, useContext, useEffect,
  useCallback,
} from 'react';
import { node, object } from 'prop-types';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';

import { isWithinSearchableRange } from '../../../utils/isOutsideDateRange';
import {
  buildDownloadHeaders, buildDownloadData, tableDataKey, getColumns, getClassNames,
} from './scanReportSummaryTable.utils';
import { scanTypes } from '../scanReportConstants';
import { baseUrl, endpoints, routerUrls } from '../../../axios/endpoints';
import { generalAxiosRequest } from '../../../axios/axiosFunctions';

import { ScanTimerContext } from '../../../context/ScanTimer.provider';
import { SimWebContext } from '../../../context/SimWeb.provider';
import { defaultSort } from '../../../utils/sort/defaultSort';

export const ScanReportSummaryContext = createContext({});

/**
  * ScanReportSummaryProvider provides:
  *     - Columns and rows for both summary and detailed views
  *     - Base table options that can be extended
  *     - Product info modal declaration
  *     - Data
  *     - OnGoClick function for triggering data fetch
  *
  * @param {node} children - data that we iterate on and create data visuals
  * @param {object} mockedValue - mock values for testing
 */
const ScanReportSummaryProvider = ({ children, mockedValue }) => {
  const { Provider } = ScanReportSummaryContext;
  const {
    storeId, SimDateTime, getMessage, locale, region, isPrintingEnabled,
  } = useContext(SimWebContext);

  const { inReviewScanId, scanProgress } = useContext(ScanTimerContext);

  const today = SimDateTime.now();
  const previous28Days = today.minus({ days: 28 });

  const [startDate, setStartDate] = useState(SimDateTime.startOfDay(previous28Days).toISO());
  const [endDate, setEndDate] = useState(SimDateTime.endOfDay(today).toISO());
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedScan, setSelectedScan] = useState(null);
  const [tooManyDaysSelected, setTooManyDaysSelected] = useState(false);

  const id = 'scanReport';
  const maxDaysBack = 90;
  const maxDaysForward = 0;
  const searchableDays = 28;
  const history = useHistory();

  const isOutsideRange = useCallback((date, type) => isWithinSearchableRange(
    date, type, startDate, maxDaysBack, maxDaysForward, searchableDays, false, setTooManyDaysSelected,
  ), [startDate]);

  useEffect(() => {
    const getScanSummary = async () => {
      setData([]);
      setError(null);
      setLoading(true);

      const body = {
        region,
        variables: {
          storeId,
          startDateInclusive: SimDateTime.toUtcISO(startDate),
          endDateInclusive: SimDateTime.toUtcISO(endDate),
          filters: [],
          pageNumber: 1,
          count: 100,
          textQuery: '',
          sortBy: {
            field: 'MISSING_COUNT',
            order: 'DESC',
          },
          aggregateBy: 'MISSING,SCANNED,ACCURACY,EXTRA,EXPECTED',
        },
      };

      try {
        const url = `${baseUrl(routerUrls.SIMWEB_BFF)}${endpoints.SCAN_SUMMARY.url}`;
        const response = await generalAxiosRequest('POST', url, endpoints.SCAN_SUMMARY, true, null, body);
        setData(response);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    if (!storeId || !startDate || !endDate) return;

    if (isEmpty(data)) getScanSummary();
  }, [storeId, startDate, endDate]);

  useEffect(() => {
    if (!inReviewScanId) return;

    if (scanProgress && scanProgress <= 100) {
      const scan = data?.find((scan) => scan.id === inReviewScanId);
      if (scan) {
        scan.percentComplete = scanProgress;
        setData([...data]);
      }
    }
  }, [inReviewScanId, scanProgress, data]);

  const onRowClick = async (selected) => {
    const sessionId = selected[tableDataKey.id];
    const scanType = selected[tableDataKey.typeOfscanForFilter]?.toUpperCase();

    if (scanTypes.isDisplay(scanType)) {
      history.push(`/displayScan/${sessionId}/${endDate}`);
    } else {
      history.push(`/scan/${sessionId}/${endDate}`);
    }

    return false;
  };

  const tableOptions = {
    onRowClick: (selected) => onRowClick(selected),
    selectableRows: 'none',
    print: isPrintingEnabled,
    responsive: 'standard',
    rowsPerPage: 10,
    rowsPerPageOptions: [10, 20, 50, 100],
    filter: true,
    search: false,
    download: true,
    onDownload: (buildHead, buildBody, newColumns, newData) => `\uFEFF${ buildHead(buildDownloadHeaders(newColumns)) }${buildBody(buildDownloadData(newData))}`,
    downloadOptions: {
      filterOptions: {
        useDisplayedRowsOnly: true,
      },
    },
    textLabels: {
      body: {
        noMatch: getMessage('noData'),
      },
    },
    viewColumns: false,
    fixedHeader: true,
    tableBodyMaxHeight: '625px',
    customSort: (data, colIndex, order) => {
      if (colIndex === 0) {
        return data.sort((a, b) => {
          const aDate = a.split('-')[0].trim();
          const bDate = b.split('-')[0].trim();
          if (order === 'asc') {
            return aDate.localeCompare(bDate);
          }
          return bDate.localeCompare(aDate);
        });
      }
      return defaultSort(data, colIndex, order);
    },
  };

  const onDateChange = (options) => {
    const { startDate, endDate } = options;
    setStartDate(SimDateTime.toUtcISO(startDate));
    setEndDate(SimDateTime.toUtcISO(endDate));
    setData([]);
  };

  return (
    <Provider
      value={mockedValue ?? {
        id,
        data,
        columns: getColumns(locale, getMessage, getClassNames),
        selectedScan,
        tableOptions,
        error,
        loading,
        setSelectedScan,
        datePicker: {
          startDate,
          endDate,
          tooManyDaysSelected,
          maxSearchableDays: searchableDays,
          handleDateChange: onDateChange,
          isOutsideRange,
        },
        isOutsideRange,
      }}
    >
      {children}
    </Provider>
  );
};

ScanReportSummaryProvider.defaultProps = {
  children: {},
  mockedValue: null,
};

ScanReportSummaryProvider.propTypes = {
  children: node,
  mockedValue: object,
};

export default ScanReportSummaryProvider;

/* eslint-disable import/prefer-default-export */
/* eslint-disable max-len */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/no-array-index-key */
import classNames from 'classnames';
import TableHeader from './TableHeader';
import createSOHFilter from './customTableFilters/CustomSOHFilter';

import './RFIDTableHeader.css';

const getClassNames = (columnMeta, disabled, fixedHeader) => classNames(
  'rfid-summary-table-header',
  { 'rfid-summary-table-header-disabled': disabled },
  { 'rfid-summary-table-header-selected': columnMeta.sortDirection !== 'none' },
  { 'rfid-summary-table-header-fixedHeader': fixedHeader },
);

export const getQuantities = (quanities, rfidSelected, reportName, location = 'fullStore') => {
  if (!rfidSelected) return quanities;
  const values = quanities?.toString().split('/');
  let colors = null;

  if (location === 'salesFloor') colors = ['#3f51b5'];
  else if (location === 'stockRoom') colors = ['#17a2b8'];
  else if (location === 'offsite') colors = ['#c05850'];
  else colors = ['#3f51b5', '#17a2b8', '#c05850'];
  let i = 0;

  // eslint-disable-next-line no-plusplus
  const formattedValues = values?.map(value => ({ name: `${ i === 0 ? '' : ' /'} ${value}`, color: colors[i++] }));

  return (
    <div className="rfid-label" data-testid={`${reportName}-segment-values`}>
      {formattedValues?.map(formattedValue => (
        <div
          className="rfid-label-values"
          data-testid={`${reportName}-segment-values-${formattedValue?.name?.replace('/ ', '')?.trim()}`}
          style={{ color: formattedValue.color }}
          key={formattedValue.color}
        >
          {formattedValue.name}
        </div>
      ))}
    </div>
  );
};

const getAdditionalInfo = (isRfid, isOffsiteEnabled, isOffsiteInactive, reportName) => {
  if (!isRfid) return null;

  const additionalText = [
    { name: '(', color: '#000000' },
    { name: 'FOH / ', color: '#3f51b5' },
    { name: 'BOH', color: '#17a2b8' },
    { name: ')', color: '#000000' },
  ];

  if (isOffsiteEnabled && !isOffsiteInactive) {
    additionalText.splice(3, 0, { name: ' / Offsite', color: '#c05850' });
  }
  if (isOffsiteInactive) {
    additionalText.splice(3, 0, { name: ' / Offsite (Inactive)', color: '#c05850' });
  }

  return (
    <div className="rfid-label" data-testid={`${reportName}-rfid-label`}>
      {additionalText.map((aggregate, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div
          className="rfid-label-values"
          style={{ color: aggregate.color, fontSize: '.8em' }}
          key={`${aggregate.color}-${index}`}
        >
          {aggregate.name}
        </div>
      ))}
    </div>
  );
};

const getTableHeader = (name, columnMeta, handleToggleColumn, reportName, label, rfidEnabled, isOffsiteEnabled, isOffsiteInactive, sortOrder, fixedHeader) => (
  <TableHeader
    columnMeta={columnMeta}
    handleToggleColumn={handleToggleColumn}
    disabled={false}
    fixedHeader={fixedHeader}
    getClasses={getClassNames}
    dataTestId={`${reportName}-rfid-header`}
    key={label}
    sortDirection={name === sortOrder.name ? sortOrder.direction : 'none'}
    secondaryInfo={getAdditionalInfo(rfidEnabled, isOffsiteEnabled, isOffsiteInactive, reportName)}
  />
);

/**
    *Generates the color coded RFID header with segmented data
    * @param {string} name - name of the column used in MUI Datatable
    * @param {string} label - localized column name
    * @param {string} reportName - name of the parent report
    * @param {boolean} rfidEnabled - is rfid enabled in the store
    * @param {boolean} isOffsiteEnabled - is offsite enabled in the store
    * @param {boolean} isProductDetails - is function called from product details from non-rfid door
    * @param {boolean} fixedHeader - is fixed table header enabled
    * @param {boolean} customFilter - is custom table filter enabled
*/
export const getRfidTableHeader = (name, label, reportName, rfidEnabled, isOffsiteEnabled, isOffsiteInactive, isProductDetails = false, fixedHeader = false, rfidSohList, getMessage, customFilter = false, location) => ({
  name,
  label,
  options: {
    display: rfidEnabled || isProductDetails,
    filter: rfidEnabled,
    sort: rfidEnabled,
    viewColumns: rfidEnabled,
    customBodyRender: (value) => getQuantities(value, rfidEnabled, reportName, location),
    customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => getTableHeader(name, columnMeta, handleToggleColumn, reportName, label, rfidEnabled, isOffsiteEnabled, isOffsiteInactive, sortOrder, fixedHeader),
    ...(customFilter && {
      filterType: 'custom',
      ...createSOHFilter({ getMessage, sohRange: rfidSohList, filterType: 'rfidSoh' }),
    }),
  },
});

/* eslint-disable max-len */
import { SimDateTime } from '../../utils/datetime';
import { DATE_SHORT_WITH_APPENDED_ZEROS } from '../../constants/LocaleFormats';

/**
 * A helper function to filter the adjustment data by indexes
 * @param {array} data the adjustment detail data
 * @param {array} selectedItems a list of index of selected detail data
 */
export const filterSelectedData = (data, selectedItems) => {
  if (selectedItems.length === 0) return data;
  return data?.filter((item, i) => selectedItems.includes(i));
};

/**
 * A helper function to build filtered adjustment data for print or CSV download
 * @param {array} data the adjustment detail data
 * @param {array} selectedItems a list of index of selected detail data
 */
export const buildDownloadData = (data, selectedItems) => {
  const downloadData = filterSelectedData(data, selectedItems);
  const toDownload = [];

  downloadData?.forEach(item => {
    item?.tableData?.forEach(adjustment => {
      toDownload.push({
        creationDate: (SimDateTime.toLocaleString(adjustment?.creationDate, DATE_SHORT_WITH_APPENDED_ZEROS)),
        opId: adjustment?.opId,
        type: adjustment?.type,
        styleColor: adjustment?.styleColor,
        class: adjustment?.class,
        description: adjustment?.description,
        size: `${adjustment?.size}`,
        upc: `${adjustment?.upc}`,
        units: adjustment?.units,
        price: adjustment?.price,
      });
    });
    if (item?.totalPrice || item?.totalUnits) {
      toDownload.push({
        creationDate: '',
        opId: '',
        type: '',
        styleColor: '',
        class: '',
        description: '',
        size: '',
        upc: 'TOTALS',
        units: item?.totalUnits ?? '',
        price: item?.totalPrice ?? '',
      });
    }
  });

  return toDownload;
};

/**
 * Util that takes state/utils/configs & handles setting/changing steps in the turorial when clicking through the steps
 * @param {boolean} startTutorial - checks if we're in tutorial mode
 * @param {number} stepIndex - the current step index we are on for the tutorial
 * @param {boolean} restartTutorial - a value used to cancel out any state since the tutorial is no longer active
 * @param {boolean} isbackClicked - a value that handles back clicks within the steps in the tooltip
 * @param {func} setStepIndex - a function that handles setting the step index
 * @param {boolean} isAddressModalOpen - a value that handles open/closing the modal
 * @param {func} setAddressModalOpen - a function that handles setting the modal state
 */
export const setAdjustmentsTutorialSteps = (
  startTutorial, stepIndex, restartTutorial, isbackClicked, setStepIndex, isAddressModalOpen, setAddressModalOpen,
) => {
  const mainCheckbox = document.querySelectorAll('table thead tr input')[0];
  const listOfCheckboxes = document.querySelectorAll('table td input');
  const row = document.querySelectorAll('table td')[3];

  if (startTutorial) {
    if (stepIndex === 3 && isbackClicked) {
      mainCheckbox?.click(); // unselect all
      setStepIndex(3);
    }
    if (stepIndex === 4) {
      listOfCheckboxes?.[0]?.click(); // select single checkbox
    }
    if (stepIndex === 4 && isbackClicked && mainCheckbox?.checked) {
      mainCheckbox?.click(); // unselect all
      setTimeout(() => {
        listOfCheckboxes?.[0]?.click(); // select first checkbox
      }, 400);
    }
    if (stepIndex === 5) {
      if (!isbackClicked) {
        setTimeout(() => {
          setStepIndex(5);
          mainCheckbox?.click(); // select all
        }, 400);
      }
      mainCheckbox?.click(); // unselect all
    }
    if (stepIndex === 6) {
      if (!isbackClicked) mainCheckbox?.click(); // unselect all once again
      setTimeout(() => {
        row?.click(); // select row
      }, 400);
    }
    if (stepIndex === 7) {
      if (isbackClicked || isAddressModalOpen) {
        setAddressModalOpen(false);
      }
    }
    if (stepIndex === 8 && !isAddressModalOpen) {
      setTimeout(() => {
        setAddressModalOpen(true);
      }, 30);
      setTimeout(() => {
        setStepIndex(8);
      }, 85);
    }
  }
  if (restartTutorial) {
    if (mainCheckbox?.checked) mainCheckbox.click();
    if (listOfCheckboxes?.[0]?.checked && !mainCheckbox?.checked) listOfCheckboxes?.[0]?.click();
    if (stepIndex === 0 && isAddressModalOpen) setAddressModalOpen(false);
  }
};

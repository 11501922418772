import { newRelicAttribute } from './newRelicPageActions';
/**
 * Function setApplicationSource
 * allows us to check the device the user is using in order to save both the source and loginSource
 * to properly parse the tokens and display features based off of the device used
 */
const setApplicationSource = () => {
  const isIOS = typeof navigator.standalone === 'boolean';
  const searchParams = new URLSearchParams(window.location.search);
  const loginSource = searchParams.get('source') ?? sessionStorage.getItem('loginSource');
  // the user is using a desktop -- loged in VIA The retail launch pad
  if (window.location !== window.parent.location) {
    sessionStorage.setItem('source', 'true');
    sessionStorage.setItem('loginSource', 'true');
    newRelicAttribute('sim-web-source', 'true');
  } else if (isIOS && (loginSource !== 'iOS' || loginSource === null)) {
    // the user is on a device logging in via browser
    sessionStorage.setItem('source', 'iOS');
    sessionStorage.setItem('loginSource', 'retail');
    newRelicAttribute('sim-web-source', 'iOS');
  } else if (isIOS && loginSource === 'iOS') {
    // the user is using SIM via wrapper app
    sessionStorage.setItem('source', 'iOS');
    sessionStorage.setItem('loginSource', 'iOS');
    newRelicAttribute('sim-web-source', 'iOS');
  } else {
    // the user is using SIM via desktop
    sessionStorage.setItem('source', 'retail');
    sessionStorage.setItem('loginSource', 'retail');
    newRelicAttribute('sim-web-source', 'web');
  }
};

export default setApplicationSource;

/* eslint-disable object-curly-newline */
import React, { useContext, useEffect } from 'react';
import BaseFacetsViewPage from '../baseView/BaseViewPageWithFacets';
import { SimWebContext } from '../../context/SimWeb.provider';
import InboundVisibilityFacets from './facets/InboundVisibilityFacets';
import { InboundVisibilityContext } from './InboundVisibility.provider';
import { InboundVisibilityTableContext } from './InboundVisiblityTable.provider';
import InboundVisibilityVisuals from './visuals/InboundVisibilityVisuals';
//  import InboundVisibilityDialog from './InboundVisibility.dialog.onOrder';
import InboundVisibilityPurchaseOrderDialog from './dialog/InboundVisibilityPurchaseOrderDialog';
import InboundVisibilityTabs from './InboundVisibility.tabs';
import InboundVisibilitySearch from './search/InboundVisibilitySearch';

import './InboundVisibility.css';

const InboundVisibilityPage = () => {
  const { setInboundSearchDate } = useContext(SimWebContext);
  const {
    id, title, loading, datepicker, error, tabValue, setGetDataOnLoad, setFilters,
  } = useContext(InboundVisibilityContext);
  const { options, columns, rows, theme } = useContext(InboundVisibilityTableContext);

  useEffect(() => () => {
    setInboundSearchDate(null);
    setFilters([]);
  }, []);

  useEffect(() => {
    setGetDataOnLoad(true); // prevents auto loading data from non Inbound Visibility page
  }, []);

  return (
    <BaseFacetsViewPage
      id={id}
      title={title}
      storeConfigValue="isInboundVisibilityEnabled"
      loading={loading}
      datepicker={datepicker}
      error={error}
      tabValue={tabValue}
      tabs={<InboundVisibilityTabs />}
      searchToggle={<InboundVisibilitySearch />}
      visuals={<InboundVisibilityVisuals />}
      dialog={<InboundVisibilityPurchaseOrderDialog />}
      facets={<InboundVisibilityFacets />}
      details={options && columns && { options, columns, rows, theme }}
    />
  );
};

export default InboundVisibilityPage;

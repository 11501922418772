import React, {
  createContext, useContext, useEffect, useState,
} from 'react';
import { DateTime } from 'luxon';
import { node, object } from 'prop-types';
import {
  constructTableData, filterData, filterTransfersData, setTransfersTutorialSteps,
} from './transfers.utils';
import { routerUrls, endpoints } from '../../axios/endpoints';
import { createAndMonitorJob, retrieveJobData } from '../../axios/jobPollAndDataFetchAxiosFunctions';
import { isOutsideDateRange } from '../../utils/isOutsideDateRange';

import { tutorialContext } from '../../context/Tutorial.provider';
import { SimWebContext } from '../../context/SimWeb.provider';

import mockTransfersData from '../../components/tutorial/mockTutorialData/transfers.json';

export const TransfersContext = createContext({});

const TransfersProvider = ({ children, mockedValue }) => {
  const { Provider } = TransfersContext;

  const {
    currencyCode, locale, store, getMessage, country, SimDateTime, storeInfo,
  } = useContext(SimWebContext);
  const { startTutorial, stepIndex, isbackClicked } = useContext(tutorialContext);

  const [filter, setFilter] = useState('');
  const [startDate, setStartDate] = useState(SimDateTime.startOfDay());
  const [endDate, setEndDate] = useState(SimDateTime.endOfDay());
  const [transfersFilter, setTransfersFilter] = useState('');
  const [documentType, setDocumentType] = useState('');
  const [detailsData, setDetailsData] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [error, setError] = useState(null);
  const [displayGovernmentNumber, setDisplayGovernmentNumber] = useState(false);
  const [displayReturnAuthorizationNumber, setDisplayReturnAuthorizationNumber] = useState(false);
  const [isFetchingJobId, setFetchingJobId] = useState(false);

  // These are the doors that require special formatted csv downloads for nike.net import.
  const fiscalDownloadDoors = ['AUT', 'BEL', 'CHE', 'CZE', 'DEU', 'DNK', 'ESP', 'FRA', 'GBR', 'HRV', 'HUN', 'IRL', 'ISR', 'ITA', 'NLD', 'NOR', 'POL', 'PRT', 'SWE', 'ZAF'];
  const isFiscalDownloadDoor = fiscalDownloadDoors.includes(country);

  const isGreaterChinaDoor = storeInfo?.isGreaterChina;
  const isNorthAmerica = storeInfo?.isNorthAmerica;

  const printOptionsByCountry = {
    ARG: ['packingSlip', 'fiscalDoc'],
    RUS: ['packingSlip', 'fiscalDoc'],
    GRC: ['packingSlip', 'dispatchNote'],
  };

  const maxDate = SimDateTime.endOfDay();
  const isOutsideRange = date => isOutsideDateRange(date, null, maxDate);

  const refreshData = (options) => {
    const { startDate, endDate } = options;
    setStartDate(DateTime.fromISO(startDate));
    setEndDate(DateTime.fromISO(endDate));
  };

  useEffect(() => {
    setTransfersTutorialSteps(startTutorial, stepIndex, isbackClicked);
  }, [startTutorial, stepIndex]);

  useEffect(() => {
    setFetchingJobId(true);

    const detailsPayload = {
      creationDateAfterEq: startDate,
      creationDateBeforeEq: endDate,
      fromLocation: store,
    };

    const fetchDetailsData = async () => {
      setDetailsData([]);
      setError(null);
      try {
        const jobId = await createAndMonitorJob(routerUrls.DELEDGE,
          endpoints.TRANSFERS, detailsPayload);
        const detailsResponse = await retrieveJobData(jobId, routerUrls.DELEDGE, endpoints.TRANSFERS);
        setDetailsData(detailsResponse);
      } catch (error) {
        setError(error);
      }
      setFetchingJobId(false);
    };

    if (startDate && endDate && store) {
      fetchDetailsData();
    }
  }, [startDate, endDate, store]);

  useEffect(() => {
    if (detailsData) {
      const {
        newData, hasGovernmentNumber, hasReturnAuthorizationNumber,
      } = constructTableData(detailsData, locale, currencyCode);
      setDisplayData(filterTransfersData(filterData(newData, filter), transfersFilter));
      setDisplayGovernmentNumber(hasGovernmentNumber);
      setDisplayReturnAuthorizationNumber(hasReturnAuthorizationNumber);
    }
  }, [detailsData, filter, transfersFilter]);

  const onGoClick = (options) => {
    refreshData(options);
  };

  const onFilterChange = (newValue) => {
    setFilter(newValue);
  };

  const onTransferFilterChange = (newValue) => {
    setTransfersFilter(newValue);
  };

  const onPrintOptionChange = (newValue) => {
    setDocumentType(newValue);
  };

  return (
    <Provider
      value={mockedValue ?? {
        currencyCode,
        data: startTutorial ? mockTransfersData : displayData,
        printOptions: printOptionsByCountry[country] || null,
        documentType,
        displayGovernmentNumber,
        displayReturnAuthorizationNumber,
        getMessage,
        isFetchingJobId,
        locale,
        error,
        startDate,
        endDate,
        isFiscalDownloadDoor,
        isGreaterChinaDoor,
        isNorthAmerica,
        isOutsideRange,
        onFilterChange,
        onGoClick,
        onPrintOptionChange,
        onTransferFilterChange,
      }}
    >
      {children}
    </Provider>
  );
};

TransfersProvider.defaultProps = {
  children: {},
  mockedValue: null,
};

TransfersProvider.propTypes = {
  children: node,
  mockedValue: object,
};

export default TransfersProvider;

/* eslint-disable no-console */
/* eslint-disable max-len */
import React, {
  createContext, useContext, useEffect, useState,
} from 'react';
import { node, object } from 'prop-types';
import { SimWebContext } from '../../context/SimWeb.provider';
import { getDashboardData } from './dashboard.axios';
import removeDupes from '../../utils/dashboard/handle28DaysOfScans';

export const DashboardContext = createContext({});

const DashboardProvider = ({ children, mockedContext }) => {
  const [scansLoading, setScansLoading] = useState(false);
  const [scans, setScans] = useState({});
  const [scanError, setError] = useState(null);

  const { Provider } = DashboardContext;
  const { storeId, country, region } = useContext(SimWebContext);

  const fetchScans = async () => {
    setScansLoading(true);
    try {
      const scanData = await getDashboardData(storeId, region);
      setScans(scanData);
      setError(scanData?.error);
    } catch (error) {
      setError(error?.message);
    }

    setScansLoading(false);
  };

  useEffect(() => {
    if (!storeId || !country) return;
    fetchScans();
  }, [storeId, country, region]);

  const scanReportDashboardData = scans?.scanReportDashboardData;

  return (
    <Provider
      value={mockedContext ?? {
        storeId,
        country,
        refetch: fetchScans,
        scansLoading,
        scans,
        scanError,
        data: {
          styleCompliance: scans?.styleComplianceData,
          sizeCompliance: scans?.sizeComplianceData,
          scanReport: scanReportDashboardData,
          getScanCompliance: (locale, rfidFullStoreScanDate) => removeDupes(scanReportDashboardData?.twentyEightDaysOfScans, locale, rfidFullStoreScanDate),
        },
      }}
    >
      {children}
    </Provider>
  );
};

DashboardProvider.defaultProps = {
  children: {},
  mockedContext: null,
};

DashboardProvider.propTypes = {
  children: node,
  mockedContext: object,
};

export default DashboardProvider;

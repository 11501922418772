import { DateTime } from 'luxon';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import getFormattedPrice from '../../utils/getFormattedPrice';
import { DATE_SHORT_WITH_APPENDED_ZEROS } from '../../constants/LocaleFormats';
/**
 * Gets the operator ID from the athlete string
 * @param {string} athlete the ID of the athlete who initiated the transfer
 */
const getOperatorId = (athlete) => {
  if (!athlete) return '';
  if (!athlete.includes(';')) return athlete;
  return athlete.split(';').slice(-1)[0].replace(')', '');
};

/**
 * A helper function to formats price and gets info for table
 * @param {object} data the transfers data object
 * @param {string} locale unique value to help us get the accurate date val
 * @param {string} currencyCode unique value to help get the currency val
 */
// we replace purchaseOrderNumber w/ shipmentDocumentNumber per carton if GC door
export const constructTableData = (data, locale, currencyCode) => {
  let hasGovernmentNumber = false;
  let hasReturnAuthorizationNumber = false;
  const newData = data?.map(item => {
    if (item?.returnAuthorizationNumber) hasReturnAuthorizationNumber = true;
    if (item?.governmentNumbers) hasGovernmentNumber = true;
    return {
      ...item,
      status: item?.status || '-',
      purchaseOrderNumber: item?.purchaseOrderNumber || '-', // not populated from API if PO number is not exist.
      shipmentDocumentNumber: item?.shipmentDocumentNumber || '-',
      returnAuthorizationNumber: item?.returnAuthorizationNumber || '-',
      creationDate: DateTime.fromISO(item?.creationDate).toLocaleString(DATE_SHORT_WITH_APPENDED_ZEROS),
      totalPrice: getFormattedPrice(item?.totalPrice, locale, currencyCode),
      opId: getOperatorId(item?.athlete),
    };
  });

  return { newData, hasGovernmentNumber, hasReturnAuthorizationNumber };
};

/**
 * a helper function to filter data by status
 * @param {object} data the transfers data object
 * @param {string} filter the filter option that used for filter the data
 */
export const filterData = (data, filter) => {
  if (!data) { return null; }
  if (!filter || filter === 'all') { return data; }

  return data.filter(obj => obj.status && obj.status.toLowerCase().includes(filter));
};

/**
 * A helper function to filter data by transfer type
 * @param {object} data the transfers data object
 * @param {string} transfersFilter the transfer option that used for filter the data
 */
export const filterTransfersData = (data, transfersFilter) => {
  if (!data) { return null; }
  if (!transfersFilter || transfersFilter === 'all') { return data; }
  if (typeof data === typeof [] && data.length > 0 && !('targetType' in data[0])) { return []; }

  return data.filter(obj => obj.targetType && obj.targetType.toLowerCase().includes(transfersFilter));
};

/**
 * Get the table column span if extran columns are needed
 * @param {boolean} displayGovernmentNumber option to add government number column
 * @param {boolean} displayReturnAuthorizationNumber option to add return authorization number column
 */
export const getTableColumnSpan = (displayGovernmentNumber, displayReturnAuthorizationNumber, isFiscalDownloadDoor) => {
  let columnSpan = 10;
  if (displayGovernmentNumber) columnSpan += 1;
  if (displayReturnAuthorizationNumber) columnSpan += 1;
  if (isFiscalDownloadDoor) columnSpan += 1;
  return columnSpan;
};

/**
 * Get a list of headers for CSV download
 * @param {boolean} displayGovernmentNumber option to add government number column
 * @param {boolean} displayReturnAuthorizationNumber option to add return authorization number column
 * @param {function} getMessage a function to get translated message
 * @param {boolean} isGreaterChinaDoor bool that toggles btwn shipment # and PO #
 * @param {boolean} isNorthAmerica bool that toggles btwn delivery/PO # and PO # label
 */
export const getHeaderForDownloadData = (
  displayGovernmentNumber, displayReturnAuthorizationNumber, getMessage, isGreaterChinaDoor, isNorthAmerica,
) => {
  const headers = [
    { label: getMessage('date'), key: 'creationDate' },
    { label: getMessage('transfersType'), key: 'targetType' },
    { label: getMessage('shipTo'), key: 'targetLocation' },
    isGreaterChinaDoor ? { label: getMessage('shipmentDocumentNumber'), key: 'shipmentDocumentNumber' } : { label: isNorthAmerica ? 'STO/PO Number' : getMessage('poNumber'), key: 'purchaseOrderNumber' },
    { label: getMessage('cartons'), key: 'totalCartons' },
    { label: getMessage('totalUnits'), key: 'totalItems' },
    { label: getMessage('totalPrice'), key: 'totalPrice' },
    { label: getMessage('status'), key: 'status' },
    { label: getMessage('opId'), key: 'opId' },
    { label: getMessage('cartonID'), key: 'cartonNumber' },
    { label: `${getMessage('style')}\xA0\xA0\xA0${getMessage('clr')}`, key: 'styleColor' },
    { label: getMessage('class'), key: 'class' },
    { label: getMessage('description'), key: 'description' },
    { label: getMessage('size'), key: 'size' },
    { label: getMessage('upc'), key: 'gtin' },
    { label: getMessage('units'), key: 'quantity' },
    { label: getMessage('price'), key: 'price' },
  ];

  if (displayReturnAuthorizationNumber) headers.splice(8, 0, { label: getMessage('raNumber'), key: 'returnAuthorizationNumber' });
  if (displayGovernmentNumber) headers.splice(8, 0, { label: getMessage('governmentNumber'), key: 'governmentNumbers' });

  return headers;
};

/**
 * Build data for CSV download. return selected data if rows are selected, else return all data.
 * @param {boolean} isGreaterChinaDoor bool that toggles btwn shipment # and PO #
 * @param {array} data An array of data displays in the table
 * @param {object} dataKey A table columns index mapping
 * @param {string} locale unique value to help us get the accurate date val
 * @param {string} currencyCode unique value to help get the currency val
 * @param {object} selectedRows an object with indexes of selected rows
 */
export const buildDownloadData = (isGreaterChinaDoor, data, dataKey, locale, currencyCode, selectedRows) => {
  const csvFormat = sessionStorage.getItem('source') !== 'iOS' ? '"="' : ''; // forces csv value to string
  const dataToDownload = selectedRows ? selectedRows?.data?.map(index => data?.[index.dataIndex]) : data;
  const download = [];
  dataToDownload.forEach(transfer => {
    transfer?.data?.[dataKey.cartons].forEach(carton => {
      carton.styleColors.forEach(styleColor => {
        styleColor.items.forEach(item => {
          const orderNumber = isGreaterChinaDoor
            ? { shipmentDocumentNumber: transfer?.data?.[dataKey?.shipmentDocumentNumber] ? `${csvFormat}${transfer?.data?.[dataKey?.shipmentDocumentNumber]}` : '-' }
            : { purchaseOrderNumber: transfer?.data?.[dataKey?.purchaseOrderNumber] ? `${csvFormat}${transfer?.data?.[dataKey?.purchaseOrderNumber]}` : '-' };

          download.push({
            creationDate: transfer.data[dataKey.creationDate],
            targetType: transfer.data[dataKey.targetType],
            targetLocation: transfer.data[dataKey.targetLocation],
            ...orderNumber,
            totalCartons: transfer.data[dataKey.totalCartons],
            totalItems: transfer.data[dataKey.totalItems],
            totalPrice: transfer.data[dataKey.totalPrice],
            status: transfer.data[dataKey.status],
            governmentNumbers: `${csvFormat}${transfer.data[dataKey.governmentNumbersData]}`,
            returnAuthorizationNumber: `${csvFormat}${transfer.data[dataKey.returnAuthorizationNumber]}`,
            opId: transfer.data[dataKey.opId],
            cartonNumber: `${csvFormat}${carton.cartonNumber}`,
            styleColor: styleColor.styleColor,
            class: styleColor.class,
            description: styleColor.description,
            size: item.size,
            gtin: `${csvFormat}${item.gtin}`,
            quantity: item.quantity,
            price: getFormattedPrice(item.price, locale, currencyCode),
          });
        });
      });
    });
  });
  return download;
};

/**
 * Finds the selected row and converts its innards into a material and quantity format suitable for
 * fiscal compliance downloads.
 * @param {*} selectedRowIndex index of the row the user has selected
 * @param {*} data all of the data in the table to use the index to find the appropriate row of data.
 * @returns
 */
export const buildFiscalDownloadData = (selectedRowIndex, data) => {
  const toDownload = [];

  const selectedItem = data?.find((item, idx) => idx === selectedRowIndex);

  selectedItem?.cartons?.map(carton => carton?.styleColors?.map(styleColor => {
    const materialQuantity = styleColor?.items?.reduce((a, b) => a + (b.quantity || 0), 0);
    const sameStyleColorIndex = toDownload.findIndex(_ => _.material === styleColor?.styleColor);
    if (sameStyleColorIndex !== -1) {
      toDownload[sameStyleColorIndex].quantity += materialQuantity;
    } else {
      toDownload.push({
        material: styleColor?.styleColor,
        quantity: materialQuantity,
      });
    }
  }));
  return toDownload;
};

/**
 * Creates and downloads an excel sheet
 * @param {*} csvData data to convert into excel format
 * @param {*} fileName name of the file that will be downloaded
 */
export const exportToCsv = (csvData, fileName) => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const ws = XLSX.utils.json_to_sheet(csvData);
  const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};

/**
 * Utils that handles setting/changing steps in the tutorial whe clicking through the steps
 * @param {boolean} startTutorial - checks if we're in tutorial mode
 * @param {number} stepIndex - the current step index we are on for the tutorial
 * @param {boolean} isbackClicked - a value that handles back clicks within the steps in the tooltip
 */
export const setTransfersTutorialSteps = (startTutorial, stepIndex, isbackClicked) => {
  if (startTutorial) {
    if (stepIndex === 5 || (stepIndex === 4 && isbackClicked)) {
      document.querySelector('[data-testid="MUIDataTableBodyRow-0"] button')?.click();
    }
  }
};

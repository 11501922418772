import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { createTheme } from '@mui/material';
import BaseViewPage from '../../baseView/BaseViewPage';
import ChevronButton from '../../../components/ChevronButton';

import { DisplayScanContext } from './DisplayScan.provider';
import { SimWebContext } from '../../../context/SimWeb.provider';
import { baseMuiTheme } from '../../baseView/BaseViewPageUtils';

import './displayScan.css';

const theme = createTheme({
  ...baseMuiTheme,
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          '&:first-of-type': {
            paddingLeft: '70px',
          },
        },
      },
    },
  },
});

const DisplayScan = () => {
  const { getMessage } = useContext(SimWebContext);
  const {
    id, tableTitle, tableOptions, columns, data, loading, error, productModal,
  } = useContext(DisplayScanContext);

  const history = useHistory();

  return (
    <>
      <BaseViewPage
        id={id}
        loading={false}
        error={error}
        theme={theme}
        storeConfigValue="isRfidScanReportEnabled"
        dialog={productModal}
        details={{
          title: tableTitle,
          options: tableOptions,
          columns,
          rows: loading || !data?.items ? [] : data?.items,
        }}
      />
      <div className="display-scan-table-view-footer">
        <ChevronButton
          type="button"
          onClick={() => { history.push('/scanSummary'); }}
          buttonText={getMessage('back')}
          data-testid="display-scan-back-button"
          extraClasses="display-scan-back-button"
          chevron="left"
        />
      </div>
    </>
  );
};

export default DisplayScan;

import sortByObject from './sortByObject';

/**
 * will sort by accuracy, will put footwear division first
 * @param {array} facetsArray - the array of strings to be sorted
 * @param {object} facets - needs to conatain accuracy for each string in facet array
 */
export function sortFacetsDivision(facetsArray, facets) {
  const sortFunc = (a, b) => {
    if (a === 'footwear division' || b === 'footwear division') return -1;
    if (facets[a].accuracy > facets[b].accuracy) return 1;
    if (facets[a].accuracy < facets[b].accuracy) return -1;
    return 0;
  };
  return facetsArray.sort(sortFunc);
}

const priceOrder = {
  '0_TO_49': 0,
  '50_TO_99': 1,
  '100_TO_149': 2,
  '150_TO_500000': 3,
};

/**
 * will sort based on the priceOrder object
 * @param {array} facetsArray - the array of strings to be sorted
 */
export function sortFacetsPrice(facetsArray) {
  return sortByObject(facetsArray, priceOrder);
}

/**
 * will sort based on accuracy
 * @param {array} facetsArray - array of strings to be sorted
 * @param {object} facets - needs to contain accuracy for each string in facet array
 */
export function sortFacetsDefault(facetsArray, facets) {
  const sortFunc = (a, b) => {
    if (facets[a].accuracy > facets[b].accuracy) return 1;
    if (facets[a].accuracy < facets[b].accuracy) return -1;
    return 0;
  };
  return facetsArray.sort(sortFunc);
}

export function sortFacetsDate(facetsArray) {
  return facetsArray.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
}

/**
 * Routes to sorting functions based on the facet group
 * @param {array} facetsArray - the array of strings to be sorted
 * @param {string} facetGroup - string that routes to differant sorting functions
 * @param {object} facets - needs to contain accuracy for each string in facet array
 */
export function sortFacets(facetsArray, facetGroup, facets) {
  switch (facetGroup) {
    case 'division':
      return sortFacetsDivision(facetsArray, facets);
    case 'price':
      return sortFacetsPrice(facetsArray);
    case 'shipDate':
    case 'edd':
      return sortFacetsDate(facetsArray);
    default:
      return sortFacetsDefault(facetsArray, facets);
  }
}

export const sizeOrder = [
  /^One Size$/,

  /^\w{0}[2X]S-S/, // 2XS-S
  /^2XS$/i,
  /^\w{0}[2X]S-T/, // 2XS-T
  /^\w{0}[2X]STT/, // 2XSTT

  /^\w{0}[X]S-S/, // XS-S
  /^[X/S]+S$/i,
  /^\w{0}[X]S-T/, // XS-T
  /^\w{0}[X]STT/, // XSTT

  /^\w{0}[S]-S/, // S-S
  /^S$/i,
  /^\w{0}[S]-T/, // S-T
  /^\w{0}[S]TT/, // STT

  /^\w{0}[M]-S/, // M-S
  /^M$/i,
  /^\w{0}[M]-T/, // M-T
  /^\w{0}[M]TT/, // MTT

  /^\w{0}[L]-S/, // L-S
  /^L$/i,
  /^\w{0}[L]-T/, // L-T
  /^\w{0}[L]TT/, // LTT

  /^\w{0}[X]L-S/, // XL-S
  /^XL$/i,
  /^\w{0}[X]L-T/, // XL-T
  /^\w{0}[X]LTT/, // XLTT

  /^\w{0}[2]XL-S/, // 2XL-S
  /^\w{0}2XL$/,
  /^\w{0}[2]XL-T/, // 2XL-T
  /^\w{0}[2]XLTT/, // 2XL-TT

  /^\w{0}[3]XL-S/, // 3XL-S
  /^\w{0}3XL$/,
  /^\w{0}[3]XL-T/, // 3XL-T
  /^\w{0}[3]XLTT/, // 3XL-TT

  /^\w{0}[4]XL-S/, // 4XL-S
  /^\w{0}4XL$/, // 4XL
  /^\w{0}[4]XL-T/, // 4XL-T
  /^\w{0}[4]XLTT/, // 4XL-TT

  /^[0-9]+/, // numbers
  /[a-zA-Z0-9-]+$/,

  /^[0-9]+/, // numbers
  /^-/i, // -
];

/**
  * given an array of sizes this func will help sort the sizes in the correct order
  * @param {Array} data the array of data passed in with size info
  * @param {String} order the order to sort the sizes example: desc/asc
  * @param {number} sortIndex the index of the array to sort on
  * @return {Array} of newly sorted data
*/
// eslint-disable-next-line import/prefer-default-export
export const sortSizes = (data, order, sortIndex = 1) => {
  const ascOrder = [...sizeOrder];
  const desOrder = [...sizeOrder].reverse();

  const patternMap = order === 'asc' ? new Map(ascOrder.map(pattern => [pattern, []])) : new Map(desOrder.map(pattern => [pattern, []]));
  let matchingPattern;

  // eslint-disable-next-line no-unused-expressions
  data && data.forEach((str) => {
    matchingPattern = sizeOrder.find(pattern => pattern.test(str.data[sortIndex]));
    // eslint-disable-next-line no-unused-expressions
    if (matchingPattern) return patternMap.get(matchingPattern).push(str);
    return null;
  });

  const valuesArr = [...patternMap.values()];

  valuesArr.map(arr => arr.sort((a, b) => {
    // this is sepcifically handling sorting string number values
    if (typeof Number(b.data[sortIndex]) === 'number' && typeof Number(a.data[sortIndex]) === 'number') {
      if (order === 'desc') {
        return Number(a.data[sortIndex]) < Number(b.data[sortIndex]) ? 1 : -1;
      }
      if (order === 'asc') {
        return Number(a.data[sortIndex]) > Number(b.data[sortIndex]) ? 1 : -1;
      }
      return Number(b.data[sortIndex]) < Number(a.data[sortIndex]) ? 1 : -1;
    }
    return b.data[1].localeCompare(a.data[sortIndex]);
  }));

  const sortedValues = valuesArr.flat();

  return sortedValues;
};

/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-no-literals */
/* eslint-disable max-len */
import React, {
  useMemo, useState, useContext, createContext, useEffect,
} from 'react';
import { node, object } from 'prop-types';
import DonutChartWithLegend from '../../components/visualSummaries/donutChart/DonutChartWithLegend';
import ProductDetailContainer from '../../components/productDetails/ProductDetailContainer';
import { baseDialogWrapper as Dialog } from '../baseView/BaseViewPageUtils';
import { filterButtonTypes } from './styleManagementConstants';
import RefreshButton from '../../components/refresh/Refresh';
import LoadingAnnimation from '../../components/loadingAnnimation/LoadingAnnimation';
import { newRelicAction } from '../../utils/newRelicPageActions';
import { createProductRefillPickList } from '../../axios/taskService.axios';
import GenderCategoryToggle, { getToggles, getAggregatedBy } from '../../components/toggle/GenderCategoryToggle';
import {
  getColumns, getTableOptions,
} from './StyleManagement.utils';

import { SimWebContext } from '../../context/SimWeb.provider';
// import { tutorialContext } from '../../context/Tutorial.provider';
import { StyleManagementDataContext } from './StyleManagementData.provider';
import { CPAContext } from '../../context/cpa/CPA.provider';

export const StyleManagementContext = createContext({});
const id = 'style-management';
const title = 'styleManagement';

const StyleManagementProvider = ({ children, mockedContext }) => {
  const { Provider } = StyleManagementContext;
  const {
    loading,
    summary,
    error,
    rows,
    sohList,
    rfidSohList,
    refetch,
    buttonFilters,
    setButtonFilters,
    rfidEnabled,
    isOffsiteEnabled,
    isOffsiteInactive,
    tableFilters,
    tableFilteredData,
    setTableFilters,
    setTableFilteredData,
  } = useContext(StyleManagementDataContext);

  const {
    getMessage, isIOS, currencySymbol, isPrintingEnabled, storeConfig, storeInfo, locale, currencyCode, isAdmin, storeId, athleteId, country,
  } = useContext(SimWebContext);

  // const {
  //   stepIndex, setStepIndex, startTutorial, isbackClicked, restartTutorial,
  // } = useContext(tutorialContext);

  const { getProducts, setLoading } = useContext(CPAContext);

  const [selectedToggle, setSelectedToggle] = useState('genderAge');
  const [isOpen, setIsOpen] = useState(false);
  const [tableIndex, setTableIndex] = useState(null);
  const [modalStyleColor, setModalStyleColor] = useState('');
  const [selectedStyles, setStylesSelected] = useState([]);

  const taskService = storeConfig?.tasksVersion?.value;
  const picklistEnabled = storeConfig?.isStyleMgmtPicklistEnabled?.value || isAdmin;
  // picklist state
  const [selectedRows, setSelectedRows] = useState([]);
  const [pickListLoading, setPickListLoading] = useState(false);
  const [isCreated, setCreated] = useState(false);
  const [isNameSet, setNameSet] = useState(false);
  const [isFormOpen, setFormOpen] = useState(false);
  const [isCreatingPicklist, setCreatingPicklist] = useState(false);
  const [picklistItems, setPicklistItems] = useState([]);
  const [isPicklistComplete, setPicklistComplete] = useState(false); // note: this state is reset on row click in getTableOptions()
  const [styleColorIndex, setStyleColorIndex] = useState(0);

  const styleManagementButtons = useMemo(() => {
    const buttons = { ...filterButtonTypes() };

    Object.values(buttons).forEach((button) => {
      if (button?.config) button.enabled = storeConfig?.[button?.config]?.value || isAdmin;
    });

    return buttons.enabled();
  }, [storeConfig, isAdmin]);

  const setModals = (status) => {
    if (status === 'SUCCESS') {
      setCreated(true);
      setIsOpen(false);
    }
    if (status === 'FAILED') {
      setCreated(false);
      setIsOpen(true);
    }
  };

  const setSelectedPicklistItems = async (gtin, quantity) => {
    const items = {
      gtin,
      quantityRequested: parseInt(quantity, 10),
      status: 'REQUESTED',
      ...(taskService?.toLowerCase() === 'v3' ? { itemType: 'REGULAR_PRODUCT' } : { type: 'REGULAR_PRODUCT' }),
      ...(taskService?.toLowerCase() === 'v3' && {
        fulfillments: [
          {
            status: 'REQUESTED',
            quantity: 1,
          },
        ],
      }),
    };

    if (picklistItems?.some(item => item.gtin === gtin)) {
      setPicklistItems(picklistItems.map(item => (item.gtin === items.gtin ? items : item)));
    } else {
      if (picklistItems?.length > 0) setPicklistItems([...picklistItems, items]);
      if (picklistItems?.length === 0) setPicklistItems([items]);
    }
  };

  const createPicklist = async (name) => {
    const variables = {
      storeId,
      name,
      items: picklistItems,
      athleteId,
    };
    setPickListLoading(true);

    try {
      const productRefill = await createProductRefillPickList(variables, taskService, 'Style Management');
      if (productRefill) {
        newRelicAction('style-compliance-picklist', {
          picklistName: name,
          units: picklistItems.length,
          user: athleteId,
          country,
        });
        setModals('SUCCESS');
      } else {
        setModals('FAILED');
      }
    } catch (error) {
      setModals('FAILED');
    }
    setPickListLoading(false);
    setNameSet(true);
  };

  const setPicklistToCreate = async (selectedRows) => {
    const styles = [];
    const productCodes = [];

    await selectedRows?.data?.map((row) => {
      styles.push(rows[row?.dataIndex]);
      productCodes.push(rows[row?.dataIndex]?.productCode);
    });

    if (productCodes.length) getProducts(productCodes, false, true);

    setCreatingPicklist(true);
    setStylesSelected(styles);
    setIsOpen(true);
  };

  useEffect(() => {
    const productCode = tableFilteredData?.[tableIndex]?.productCode || tableFilteredData?.[tableIndex]?.data[0];
    if (!productCode) return;

    setModalStyleColor(productCode);
    if (!isCreatingPicklist) getProducts(productCode, false, true);

    if (!isOpen && !isNameSet && !isFormOpen) setPicklistItems([]);
    if (!isOpen && isCreatingPicklist) setCreatingPicklist(false);
  }, [tableFilteredData, tableIndex, isOpen, isNameSet, isFormOpen, isCreatingPicklist]);

  const columns = useMemo(() => getColumns(
    getMessage, isIOS, rfidEnabled, isOffsiteEnabled, isOffsiteInactive, storeInfo?.isEMEA, sohList, rfidSohList, tableFilters, locale, currencyCode, isAdmin,
  ), [getMessage, isIOS, rfidEnabled, isOffsiteEnabled, isOffsiteInactive, storeInfo?.isEMEA, sohList, rfidSohList, tableFilters, locale, currencyCode, isAdmin]);

  const visuals = useMemo(() => {
    const getChart = (aggregatedBy, data, toggle) => (
      <DonutChartWithLegend
        id={`${id}-${aggregatedBy}`}
        data={data}
        aggregatedBy={aggregatedBy}
        toggle={toggle}
        centerTextLabel={getMessage('styles')}
      />
    );

    if (loading || summary?.loading) return (<div />);

    const toggles = getToggles(getMessage);
    const toggle = <GenderCategoryToggle selected={selectedToggle} setSelected={setSelectedToggle} />;
    const aggregatedBy = getAggregatedBy(toggles, selectedToggle);
    const chart = getChart(aggregatedBy, summary?.data, toggle);

    return (
      <div>
        <div data-testid={`${id}-toggleGroup`}>
          {chart}
        </div>
      </div>
    );
  }, [loading, summary, selectedToggle, getMessage]);

  const dialogModal = useMemo(() => {
    const isPicklistDialog = picklistEnabled && isCreatingPicklist;
    const content = (
      <ProductDetailContainer
        index={isPicklistDialog ? styleColorIndex : tableIndex}
        setIsOpen={setIsOpen}
        rowCount={tableFilteredData?.length}
        setIndex={isPicklistDialog ? setStyleColorIndex : setTableIndex}
        styleColor={isPicklistDialog ? picklistItems?.[styleColorIndex]?.productCode : modalStyleColor}
        modal
        optionalColumns={['dotcomAvailability']}
        isUnitCountEnabled
        selectedStyles={selectedStyles}
        isCreatingPicklist={isCreatingPicklist}
        setSelectedPicklistItems={setSelectedPicklistItems}
        picklistItems={picklistItems}
        setFormOpen={setFormOpen}
      />
    );
    return <Dialog id={id} content={content} isOpen={isOpen} setIsOpen={setIsOpen} />;
  }, [isOpen, tableIndex, modalStyleColor, tableFilteredData?.length,
    picklistEnabled, isCreatingPicklist, styleColorIndex, selectedStyles, picklistItems]);

  const detailsTitle = useMemo(() => (loading ? <LoadingAnnimation /> : <RefreshButton id={id} classes="style-management-date" refetch={refetch} />), [id, refetch, loading]);

  const options = useMemo(() => getTableOptions(
    columns,
    currencySymbol,
    rows,
    setModalStyleColor,
    setIsOpen,
    setTableIndex,
    getMessage,
    isPrintingEnabled,
    tableFilters,
    setTableFilters,
    setTableFilteredData,
    isOffsiteEnabled,
    setPicklistToCreate,
    selectedRows,
    setSelectedRows,
    isPicklistComplete,
    isCreated,
    setPicklistComplete,
    setLoading,
    picklistEnabled,
    buttonFilters,
  ),
  [loading, columns, currencySymbol, rows, setModalStyleColor, setIsOpen, setTableIndex, isPrintingEnabled, tableFilters, isOffsiteEnabled, isPicklistComplete, isCreated, setPicklistComplete, picklistEnabled, buttonFilters]);

  // useEffect(() => {
  //   setStyleManagementTutorialSteps(startTutorial, stepIndex, restartTutorial, isbackClicked, isOpen, setIsOpen, setStepIndex);
  // }, [stepIndex, startTutorial, styleManagementButtons, isOpen, isbackClicked, restartTutorial]);

  const wrapperStyle = {
    paddingLeft: '5px',
    display: 'grid',
    gridTemplateColumns: '110px 625px',
    gridGap: '5px',
  };

  const i18nInfoKeys = useMemo(() => (
    <div style={wrapperStyle}>
      {
        Object.values(styleManagementButtons).map(button => button?.i18nKeyInfo
            && (
              <>
                <div>{`${getMessage(button.i18nKey)}:`}</div>
                <div>{getMessage(button?.i18nKeyInfo)}</div>
              </>
            ))
      }
    </div>
  ), [styleManagementButtons]);

  return (
    <Provider
      value={mockedContext ?? {
        id,
        title,
        pageInfo: {
          title: `${getMessage('styleManagementFilterDescriptions')}:`,
          content: i18nInfoKeys,
          placement: 'right',
        },
        buttons: {
          list: styleManagementButtons,
          setButtonFilters,
        },
        isCreatingPicklist,
        setSelectedPicklistItems,
        getProducts,
        isOpen,
        setIsOpen,
        dialog: dialogModal,
        loading,
        visuals,
        detailsTitle,
        options,
        columns,
        rows,
        error,
        setPicklistItems,
        picklistItems,
        setCreatingPicklist,
        createPicklist,
        setCreated,
        pickListLoading,
        setNameSet,
        isNameSet,
        setFormOpen,
        isFormOpen,
        setPicklistComplete,
        isCreated,
        selectedStyles,
      }}

    >
      {children}
    </Provider>
  );
};

StyleManagementProvider.defaultProps = {
  children: {},
  mockedContext: null,
};

StyleManagementProvider.propTypes = {
  children: node,
  mockedContext: object,
};

export default StyleManagementProvider;

/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable max-len */
import React, { useMemo, useContext } from 'react';
import { string, func } from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Tooltip, Switch } from '@mui/material';
import { closeSnackbar } from 'notistack';
import LanguageDropdown from '../languageDropdown/LanguageDropdown';
import { User, Settings } from '../../../assets/Svgs';
import { navigationBadge, environment } from '../../../environment';
import CustomSelect from '../../../components/customSelect/CustomSelect';
import Button from '../../../components/Button';
import ViewAsGroup from './components/ViewAs';
import { capitalizeWords } from '../../../utils/stringExt';
import { changeFavicon } from '../../../utils/setChromeTabTitle';

import { SimWebContext } from '../../../context/SimWeb.provider';
import { LoginContext } from '../../../context/Login.provider';
import { tutorialContext } from '../../../context/Tutorial.provider';
import { ProfileContext } from './Profile.provider';

import favIcon from '../../../assets/images/favicon-small.png';

import './Profile.css';

const Profile = ({ logout, userName, storeNumber }) => {
  const history = useHistory();
  const {
    isAdmin, isAthleteAdmin, setIsAdmin, setCountry, setStoreNumber, country,
  } = useContext(LoginContext);
  const {
    getMessage, language, flag, storeInfo, nikeStoreRoles, isIOS, isTrue,
  } = useContext(SimWebContext);
  const { activateTutorials, setActivateTutorials, isTutorialEnabled } = useContext(tutorialContext);
  const {
    countries, storeNumbers, inputCountry, inputStoreNum, setInputCountry, setInputStoreNum,
  } = useContext(ProfileContext);

  const handleStartTutorial = () => {
    setActivateTutorials(!activateTutorials);
    sessionStorage.setItem('isTutorialActivated', !activateTutorials);
  };

  const concept = storeInfo?.businessConcept;
  const facilityType = storeInfo?.facilityType;

  const adminSettings = useMemo(() => environment === 'test' && nikeStoreRoles?.maxValue >= 40 && (
    <div className="admin-settings" data-testid="admin-settings">
      <div className="admin-hover-styles" />
      <button
        data-testid="admin-settings-button"
        type="button"
        onClick={() => history.push('/admin')}
        className="header-admin-settings"
        aria-label="admin settings button"
      >
        <Settings stroke="white" />
      </button>
    </div>
  ), [nikeStoreRoles, environment]);

  const tutorial = useMemo(() => (isTutorialEnabled ? (
    <div data-testid="reportTutorial" style={{ marginTop: '10px' }}>
      <Tooltip title={getMessage('activateReportTutorials')}>
        <Switch
          checked={activateTutorials}
          onChange={handleStartTutorial}
          name="reportTutorial"
          color="primary"
        />
      </Tooltip>
    </div>
  ) : null
  ), [isTutorialEnabled, activateTutorials, getMessage]);

  const countriesSelect = useMemo(() => {
    if (!countries?.length > 0) return null;

    return (
      <CustomSelect
        label={`${getMessage('country')}`}
        options={countries}
        defaultValue={countries.filter(option => option.value === country)}
        customLabelClass="profile-label"
        customClass="flex-row"
        customSelectClass="profile-country"
        onChange={(event) => {
          setInputStoreNum(null);
          setInputCountry(event.value.toUpperCase());
        }}
      />
    );
  }, [countries, getMessage]);

  const storeNumberSelect = useMemo(() => {
    if (!storeNumbers?.length > 0) return null;
    const defaultValue = !inputStoreNum ? null : storeNumbers.filter(option => option.value === inputStoreNum);

    return (
      <CustomSelect
        label={`${getMessage('store')}`}
        options={storeNumbers}
        defaultValue={defaultValue}
        clearDefault={!inputStoreNum}
        customClass="flex-row"
        customLabelClass="profile-label"
        customSelectClass="profile-storeNumber"
        onChange={(event) => {
          sessionStorage.setItem('showToast', false);
          const storeNumber = event.value;
          setInputStoreNum(storeNumber);

          // values to force store view update
          setCountry(inputCountry);
          setStoreNumber(storeNumber);
          sessionStorage.setItem('showToast', true);

          // force reload to update store view
          window.location.reload(true);
        }}
      />
    );
  }, [storeNumbers, inputCountry, inputStoreNum, getMessage]);

  const profileDropdown = useMemo(() => {
    const storeFacilityType = facilityType ? capitalizeWords(facilityType.replace(/_/g, ' ')) : '-';
    const storeConcept = concept ? capitalizeWords(concept.replace(/_/g, ' ')) : '-';
    const userInfo = userName ? `${userName} - ${storeNumber}` : getMessage('loading');

    changeFavicon(isAdmin ? flag : favIcon);

    return (
      <div className="accountMenu-dropdown-content" id="accountMenu-dropdown-list" data-testid="accountMenu-dropdown">
        <div style={{ paddingRight: '25px' }}>
          <ul>
            <li className="navDropdownItem-label" key={getMessage('profile')}>
              {getMessage('profile')}
              <img src={flag} alt={`${language} is the language used for the SIM WEB application`} height="100%" />
            </li>
            <p>
              <span style={{ fontWeight: '700', fontSize: '14px' }}>{storeInfo?.name}</span>
            </p>
            <p>
              <span data-testid="profile-user-info" style={{ fontWeight: '700' }}>{userInfo}</span>
              <span data-testid="showAppVersion">
                <span className="badge badge-dark">{`${navigationBadge}`}</span>
              </span>
            </p>
            <p>
              <span data-testid="timezone" style={{ fontWeight: '700' }}>{`${getMessage('timezone') }: `}</span>
              <span>{storeInfo?.timezone}</span>
            </p>
            <p>
              <span data-testid="facilityType" style={{ fontWeight: '700' }}>{`${getMessage('facilityType') }: `}</span>
              <span>{storeFacilityType}</span>
            </p>
            <p style={{ marginBottom: '3px' }}>
              <span data-testid="businessConcept" style={{ fontWeight: '700' }}>{`${getMessage('businessConcept') }: `}</span>
              <span>{storeConcept}</span>
            </p>
            <li>
              <LanguageDropdown />
            </li>
            { isAthleteAdmin() && !isIOS && !isTrue && (
              <>
                <li>
                  <span data-testid="view-as-admin-label" style={{ fontWeight: '700' }}>{ 'View As: ' }</span>
                  <ViewAsGroup isAdmin={isAdmin} setIsAdmin={setIsAdmin} />
                </li>
                <li>
                  <div data-testid="login-country-store">
                    {countriesSelect}
                    {storeNumberSelect}
                  </div>
                </li>
              </>
            )}
            <li>
              <div>
                <Button
                  data-testid="profile-logout"
                  type="submit"
                  buttonText={getMessage('signout')}
                  onClick={() => {
                    sessionStorage.setItem('showToast', false);
                    closeSnackbar();
                    logout();
                  }}
                  style={{ width: '300px' }}
                />
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  }, [userName, country, storeNumber, storeInfo, getMessage, navigationBadge, language, flag, isAdmin, isIOS, isTrue, countriesSelect, storeNumberSelect, inputStoreNum]);

  return (
    <div className="header-profile-container" data-testid="header-profile-container">
      <div>
        {tutorial}
      </div>
      <div>
        <img src={flag} alt={`${language} is the language used for the SIM WEB application`} height="100%" />
      </div>
      {adminSettings}
      <div className="header-dropdown" data-testid="header-dropdown">
        <div className="user-hover-styles" />
        <User
          className="profile-icon pr12-sm"
          testId="header-profile-tab"
          fill="white"
        />
        {profileDropdown}
      </div>
    </div>
  );
};

Profile.propTypes = {
  userName: string,
  logout: func.isRequired,
  storeNumber: string.isRequired,
};

Profile.defaultProps = {
  userName: null,
};

export default Profile;

/* eslint-disable max-len */
/* eslint-disable react/jsx-no-literals */
import React from 'react';
import { func, object } from 'prop-types';
import { Grid, Box, Typography } from '@mui/material';

const EstimatedVariancesWidget = ({ getMessage, variances }) => (
  <Grid container data-testid="estimated-variances-widget-container" direction="column" style={{ padding: '10px', margin: '20px 0' }}>
    <Grid container justifyContent="space-evenly">
      <Typography variant="h5"><strong>{getMessage('missing')}</strong></Typography>
      <Typography variant="h5"><strong>{getMessage('extra')}</strong></Typography>
    </Grid>
    <Box className="widget" style={{ padding: '10px', margin: '0 20px 30px' }}>
      <Grid container justifyContent="space-evenly">
        <Grid item>
          <Grid container direction="column">
            <Typography>{getMessage('value')}</Typography>
            <Typography data-testid="estimated-variances-missing-value">{variances?.missing?.value || '-'}</Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="column">
            <Typography>{getMessage('units')}</Typography>
            <Typography data-testid="estimated-variances-missing-units">{variances?.missing?.units || '-'}</Typography>
          </Grid>
        </Grid>
        <Box sx={{ borderLeft: 1, borderColor: 'white', height: 'auto' }} />
        <Grid item>
          <Grid container direction="column">
            <Typography>{getMessage('value')}</Typography>
            <Typography data-testid="estimated-variances-extra-value">{variances?.extra?.units || '-'}</Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="column">
            <Typography>{getMessage('units')}</Typography>
            <Typography data-testid="estimated-variances-extra-units">{variances?.extra?.units || '-'}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
    <Box component="p" sx={{ color: '#5f5f5f' }}>
      Note: The above values are only estimates based off Missing and Extra units and their price. They will not be updated or reflected in SAP if you submit your scan.
    </Box>
  </Grid>
);

EstimatedVariancesWidget.propTypes = {
  getMessage: func.isRequired,
  variances: object,
};

EstimatedVariancesWidget.defaultProps = {
  variances: null,
};

export default EstimatedVariancesWidget;

import React, { useState, useContext, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { Button } from '@mui/material';
import { version } from '../environment';
import * as serviceWorker from '../serviceWorker';
import { newRelicAction } from '../utils/newRelicPageActions';

import { SimWebContext } from '../context/SimWeb.provider';
import { ErrorContext } from '../context/Error.provider';

/**
 * Listen to service worker on updates and render a toast to notify user for an update available.
 */
const AppUpdateNotification = () => {
  const { isReady, getMessage } = useContext(SimWebContext);
  const { versionError } = useContext(ErrorContext);
  const [waitingWorker, setWaitingWorker] = useState(null);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const onSWUpdate = (registration) => {
    setWaitingWorker(registration.waiting);
  };

  useEffect(() => {
    serviceWorker.register({ onUpdate: onSWUpdate });
  }, []);

  const reloadPage = (snackbarId) => {
    newRelicAction('application-update-notification-reload', { action: 'reload', 'sim-version': version });
    waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
    closeSnackbar(snackbarId);
    window.location.reload(true);
  };

  const action = snackbarId => (
    <div>
      <Button
        data-testid="user-toast-notification-reload-button"
        color="inherit"
        size="small"
        onClick={() => reloadPage(snackbarId)}
      >
        {getMessage('reload')}
      </Button>
    </div>
  );

  if (isReady && versionError && window.performance.getEntriesByType('navigation')[0].type === 'reload') {
    enqueueSnackbar(getMessage('newVersionAvailable'), { variant: 'info', persist: true, action });
  }
};

export default AppUpdateNotification;

/**
   * Returns an array of distinct bin names
   * @param {array} taskItems array of ProductRefillItem
 */
export const getBinNames = (taskItems) => {
  const binNames = [];

  taskItems?.map(item => {
    item?.bins?.map(bin => binNames.push(bin?.name));
  });
  return Array.from(new Set(binNames)).sort();
};

/**
* Returns the description of the pick list item
* @param {array} taskItems array of ProductRefillItem
*/
export const getDescription = (taskItems) => {
  const descriptions = [];

  taskItems?.map((item) => {
    descriptions.push(item?.name || item?.gtin);
  });

  return Array.from(new Set(descriptions)).join(', ');
};

/**
 * Returns the first and last bin names
 * @param {object} task the ProductRefill task object
 * @param {function} getMessage translations helper func
 */
export const generateBinName = (task, getMessage) => {
  if (!task) {
    return '';
  }

  const binNames = getBinNames(task?.items);
  let heading = task?.name?.trim().toUpperCase();

  if (heading && task.classification?.type !== 'PICK_LIST') {
    return heading;
  }
  if (!heading && !binNames.length) {
    return getMessage('nonBinProducts');
  }

  const { 0: first, length: l, [l - 1]: last } = binNames;
  if (first === last) {
    heading = first;
  } else {
    heading = `${first} - ${last}`;
  }
  return heading?.toUpperCase();
};

/**
 * Returns the picklist name
 * @param {object} task the ProductRefill task object
 * @param {boolean} istaskV3Service the version of the task service is V3
 */
export const getPicklistName = (task, isTaskV3Service) => {
  if (!task) {
    return '';
  }

  if (isTaskV3Service) {
    const heading = task?.description?.trim().toUpperCase();
    if (heading && task?.fulfillmentType === 'PICK_LIST') {
      return heading;
    }
  } else {
    const heading = task?.name?.trim().toUpperCase();
    if (heading && task.classification?.type === 'PICK_LIST') {
      return heading;
    }
  }

  return '-';
};

/**
* Returns a number of unit
* @param {array} taskItems array of ProductRefillItem
*/
export const getUnits = (taskItems) => taskItems.reduce((total, item) => item.quantityRequested + total, 0);

/**
 * Sorts data by creation date
 * @param {array} data array of Product Refill data
 */
export function sortByCreationDate(data) {
  if (!data) return null;
  const sortedData = data.slice().sort((a, b) => {
    const dateA = new Date(a.createdDateUtc);
    const dateB = new Date(b.createdDateUtc);
    // eslint-disable-next-line no-nested-ternary
    return dateA > dateB ? -1 : (dateA < dateB ? 1 : 0);
  });
  return sortedData;
}

/**
 * Returns the product refill summay
 * @param {array} tasks array of ProductRefill tasks
 * @param {number} count the refill count
 */
export const createProductRefillSummaryData = (tasks, count) => {
  const summary = {
    unitsInQueue: 0,
    refills: 0,
    pickList: 0,
    unbinned: 0,
    lastReportDateTime: '',
  };

  tasks?.map(task => {
    switch (task?.type.toUpperCase()) {
      case 'REFILL':
        if (task?.binNames.length > 0) {
          summary.refills += 1;
        } else {
          summary.unbinned += 1;
        }
        break;
      case 'PICK_LIST':
        summary.pickList += 1;
        break;
      default:
        break;
    }
  });

  summary.unitsInQueue = count;

  if (tasks?.length > 0 && tasks?.[0].date) {
    summary.lastReportDateTime = tasks[0].date;
  }

  return summary;
};

/**
 * Returns dialog data from selected Product Refill tasks
 * @param {object} selectedTask the selected product refill tasks data
 * @param {boolean} taskService the version of the task service
 */
export const buildDialogRowsData = (selectedTask, taskService) => {
  if (selectedTask?.items) {
    if (taskService?.toLowerCase() !== 'v3') {
      return selectedTask?.items?.map(item => ({
        bins: item?.bins?.map(bin => bin.name).join(', ') || '-',
        product: item?.name || '-',
        styleColor: (item?.style && item?.color) ? `${item?.style}-${item?.color}` : '-',
        size: item?.size || '-',
        pull: item?.quantityRequested?.toString(),
      }));
    }
    return selectedTask?.items?.map(item => ({
      bins: item?.bins?.map(bin => bin.name).join(', ') || '-',
      product: item?.itemDetails?.name || '-',
      styleColor: (item?.itemDetails?.style && item?.itemDetails?.color) ? `${item?.itemDetails?.style}-${item?.itemDetails?.color}` : '-',
      size: item?.itemDetails?.size || '-',
      pull: item?.quantityRequested?.toString(),
    }));
  }
  return [];
};

/**
 * Returns an ordered product refill tasks
 * @param {array} data the product refill task data
 */
export const filterTasksData = (data) => {
  const singleBinArray = data && data.filter(item => ((item.binNames ? item.binNames.length === 1 : 0) && item.type === 'REFILL'));
  const binArray = data && data.filter(item => ((item.binNames ? item.binNames.length > 1 : 0) && item.type === 'REFILL'));
  const hashArray = data && data.filter(item => ((item.binNames ? item.binNames.length === 0 : 0) && item.type === 'REFILL'));
  const pickListArray = data && data.filter(item => item.type === 'PICK_LIST');
  return singleBinArray && singleBinArray.concat(binArray).concat(hashArray).concat(pickListArray);
};

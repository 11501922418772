import React, { useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import ChevronButton from '../../../components/ChevronButton';
import Button from '../../../components/Button';
import Search from '../../../components/search/Search';

import BaseFacetsViewPage from '../../baseView/BaseViewPageWithFacets';
import ScanReportFacets from './facets/ScanReportFacets';
import ScanReportProducts from './products/ScanReportProducts';
import ScanReportManagerApproval from './managerApproval/ScanReportManagerApproval';
import searchTooltipContents from './facets/search-tooltip-contents.json';

import { ScanDetailsContext } from './ScanDetails.provider';
import { SimWebContext } from '../../../context/SimWeb.provider';

import './ScanReportDetailsPage.css';

const ScanDetails = () => {
  const { getMessage } = useContext(SimWebContext);
  const {
    id, title, loading, error, clearFilters, reportInfo, searchText, setSearchText, setPageSearchText, scans, scanType,
  } = useContext(ScanDetailsContext);
  const history = useHistory();

  const scanReportSearch = useMemo(() => (
    <div className="scan-report-sidebar" data-testid="scan-report-filters">
      <Search
        type="scan-report"
        searchTooltipContents={searchTooltipContents}
        searchText={searchText}
        style={{ marginBottom: '40px' }}
        setSearchTextValue={setSearchText}
        setPageSearchTextValue={setPageSearchText}
      />
    </div>
  ), [searchText, searchText, setPageSearchText]);

  const pageContent = useMemo(() => {
    if (!scans || !scanType) return null;

    return (
      <div className="scan-report-main-body">
        <div className="scan-report-product-data">
          <div data-testid="scan-report-header">
            <div className="scan-report-main-header">
              <ScanReportManagerApproval />
            </div>
          </div>
          <ScanReportProducts />
        </div>
      </div>
    );
  }, [scans, scanType]);

  return (
    <>
      <BaseFacetsViewPage
        id={id}
        title={title}
        reportInfo={reportInfo}
        loading={loading}
        error={error}
        searchToggle={scanReportSearch}
        facets={<ScanReportFacets />}
        pageContent={pageContent}
      />
      <div className="scan-report-footer" data-testid="scan-report-footer">
        <div className="clear-button-container">
          <ChevronButton
            type="button"
            onClick={() => { history.push('/scanSummary'); }}
            buttonText={getMessage('back')}
            data-testid="display-scan-back-button"
            extraClasses="clear-scan-report-filters"
            chevron="left"
            style={{ padding: '0 24px' }}
          />
          <Button
            type="button"
            onClick={clearFilters}
            disabled={false}
            buttonText={getMessage('clear')}
            dataTestId="clear-scan-report-filters"
            extraClasses="clear-scan-report-filters"
            style={{ padding: '0 24px' }}
          />
        </div>
      </div>
    </>

  );
};

export default ScanDetails;

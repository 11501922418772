export const pageCodes = {
  APPLICATION: 1,
  IOSWRAPPER: 2,
  INBOUND_VISIBILITY: 7,
};

export const applicationErrors = {
  storeViewsFail: {
    code: pageCodes.APPLICATION,
    message: 'Unable to retrieve store information. Please try again later.',
  },
  iOSWrapperLoginFail: {
    code: pageCodes.IOSWRAPPER,
    message: 'Unable to login into SIMWEB via iOS Wrapper.',
  },
};

export const inboundVisibilityErrors = {
  graphqlMissingData: {
    code: pageCodes.INBOUND_VISIBILITY,
    message: 'InboundVisbility API is returning a null dataset',
  },
};

import React from 'react';
import { string, func } from 'prop-types';
import Typography from '@mui/material/Typography';

const AdditionalProductDetails = ({
  genderAge, retailDivision, retailCategory, getMessage,
}) => (
  <div style={{ margin: '10px 0' }} data-testid="additionalProductDetails">
    <Typography gutterBottom variant="h6" className="productDetails_catalogDetails_title">
      {`${getMessage('catalogInformation')}:`}
    </Typography>
    <Typography component="div" variant="body1" style={{ fontSize: '1.25em', marginTop: '20px' }}>
      <div>
        {`${getMessage('gender')}:
          ${genderAge ?? ''}`}
      </div>
      <div>
        {`${getMessage('division')}:
          ${retailDivision ?? ''}`}
      </div>
      <div>
        {`${getMessage('category')}:
          ${retailCategory ?? ''}`}
      </div>
    </Typography>
  </div>
);

AdditionalProductDetails.propTypes = {
  genderAge: string,
  retailDivision: string,
  retailCategory: string,
  getMessage: func.isRequired,
};

AdditionalProductDetails.defaultProps = {
  genderAge: null,
  retailDivision: null,
  retailCategory: null,
};

export default AdditionalProductDetails;

import React from 'react';
import { string, number, oneOfType } from 'prop-types';

import './svgs.css';
/**
   * An SVG used in the dashboard welcome banner
   * scan to the  users in the summary table
   * @param {string} fill - adjustable color of SVG
   * @param {string | number} height - height of the SVG
   * @param {string | number} width - width of the SVG
   */
// eslint-disable-next-line import/prefer-default-export
export const Location = ({
  fill, height, width,
}) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
  >
    <path
      d="M12 21.7471L11.5323 22.3334C11.8059 22.5517 12.1941 22.5517 12.4677 22.3334L12 21.7471ZM18 9C18 9.92052 17.6971 11.081 17.1763 12.362C16.6605 13.6308 15.9544 14.9616 15.2021 16.2051C14.4506 17.4472 13.6608 18.5892 12.9849 19.4786C12.2899 20.393 11.7625 20.9771 11.5323 21.1608L12.4677 22.3334C12.8625 22.0185 13.4913 21.2912 14.1792 20.3862C14.886 19.4561 15.7057 18.2704 16.4854 16.9816C17.2643 15.6943 18.0114 14.2909 18.5659 12.9269C19.1154 11.5752 19.5 10.2045 19.5 9H18ZM12.4677 21.1608C12.2375 20.9771 11.7101 20.393 11.0151 19.4786C10.3392 18.5892 9.54943 17.4472 8.79794 16.2051C8.04555 14.9616 7.3395 13.6308 6.82369 12.362C6.30289 11.081 6 9.92052 6 9H4.5C4.5 10.2045 4.88461 11.5752 5.43413 12.9269C5.98863 14.2909 6.7357 15.6943 7.51456 16.9816C8.29432 18.2704 9.11397 19.4561 9.82085 20.3862C10.5087 21.2912 11.1375 22.0185 11.5323 22.3334L12.4677 21.1608ZM6 9C6 5.68621 8.68621 3 12 3V1.5C7.85779 1.5 4.5 4.85779 4.5 9H6ZM12 3C15.3138 3 18 5.68621 18 9H19.5C19.5 4.85779 16.1422 1.5 12 1.5V3Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.25 9C15.25 10.795 13.795 12.25 12 12.25C10.205 12.25 8.75 10.795 8.75 9C8.75 7.205 10.205 5.75 12 5.75C13.795 5.75 15.25 7.205 15.25 9Z"
      stroke={fill}
      strokeWidth="1.5"
    />
  </svg>
);

Location.propTypes = {
  fill: string,
  height: oneOfType([string, number]),
  width: oneOfType([string, number]),
};

Location.defaultProps = {
  fill: 'white',
  width: '24',
  height: '24',
};

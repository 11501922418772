import React, { useContext } from 'react';
import { Card, CardContent, Grid } from '@mui/material';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import LinkWithDisable from '../../LinkWithDisable';
import { LoadingIndicator } from '../../../assets/Svgs/index';
import { ProductRefillContext } from '../../../containers/productRefill/ProductRefill.provider';
import { SimWebContext } from '../../../context/SimWeb.provider';
import useTutorial from '../../../hooks/useTutorial';

import mockedProductRefillSummaryData from '../../tutorial/mockTutorialData/dashboard/product-refill-data.json';

import './ProductRefillWidget.css';

/**
  * Creates a widget with data visuals for the Product Refill
*/
const ProductRefillWidget = () => {
  const { getMessage } = useContext(SimWebContext);
  const { summary: productRefillSummary, isFetching, error } = useContext(ProductRefillContext);
  const { activateTutorials, Tutorial } = useTutorial('productRefill');
  const summary = activateTutorials ? mockedProductRefillSummaryData : productRefillSummary;

  return (
    <LinkWithDisable disabled={activateTutorials} to="/productRefill" style={{ textDecoration: 'none', color: 'inherit' }} className="productRefillWidget_dashboard-link">
      <Card className="productRefillWidget_dashboard-card widget" elevation={8} data-testid={`productRefillWidget${activateTutorials ? '-tutorial' : ''}`}>
        <CardContent>
          <Grid container direction="column" justifyContent="center">
            <Tutorial />
            <Grid item style={{ paddingBottom: '50px' }}>
              <p className="productRefillWidget_title">{getMessage('refills')}</p>
            </Grid>
            { (!activateTutorials && isFetching) && (<Grid item><div className="productRefill_card-loading"><LoadingIndicator width="100px" height="100px" fill="#FFFFFF" /></div></Grid>)}
            { (!activateTutorials && (!productRefillSummary || error))
              && (
                <Grid item>
                  <div className="productRefill_error" data-testid="errorContainer">
                    <ErrorOutline color="error" fontSize="large" />
                    <br />
                    <p>{getMessage('fatalError')}</p>
                  </div>
                </Grid>
              )}
            <Grid item>
              <Grid container direction="column" className="productRefillWidget_dashboard-card-details" data-testid="productRefillWidgetData">
                <Grid item>
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      <p>{`${getMessage('unitsInQueue')}: `}</p>
                    </Grid>
                    <Grid item>
                      <p>{summary?.unitsInQueue}</p>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      <p>{`${getMessage('openRefills')}: `}</p>
                    </Grid>
                    <Grid item>
                      <p>{summary?.refills}</p>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      <p>{`${getMessage('openPicklists')}: `}</p>
                    </Grid>
                    <Grid item>
                      <p>{summary?.pickList}</p>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      <p>{`${getMessage('openUnbinned')}: `}</p>
                    </Grid>
                    <Grid item>
                      <p>{summary?.unbinned && summary.unbinned.toLocaleString()}</p>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </LinkWithDisable>
  );
};

export default ProductRefillWidget;

/* eslint-disable no-unused-expressions */
/* eslint-disable object-curly-newline */
/* eslint-disable max-len */
import React, { useState, useEffect, useContext } from 'react';
import jwtDecode from 'jwt-decode';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { useMedia } from 'use-media';
import classNames from 'classnames';
import PrivateRoute from './components/PrivateRoute';
import localStorageService from './services/localStorage';
import { Messages, AuthTypes, getTargetOrigin } from './services/iframeMessaging';
import env from './environment';
import Postman from './containers/postman/PostmanPage';

import StackedNotifications from './userNotifications/StackedNotifications';
import ScanTimerProvider from './context/ScanTimer.provider';
// reports
import ItemInquiry from './containers/itemInquiry/ItemInquiry';
import SelfCheckoutDeviceAssignment from './containers/selfCheckoutDeviceAssignment/SelfCheckoutDevice';
// components
import loginFactory from './containers/login/loginFactory';
import NavMenu from './containers/navigation/navMenu/NavMenu';
import ErrorAlert from './components/alerts/Error';
// utils
import { ReactComponent as DeviceRotation } from './assets/images/device-rotate.svg';
import ScrollToTop from './components/ScrollToTop';
import { updateChromeTab } from './utils/setChromeTabTitle';
import wrappedPages from './wrappedPages';

// contexts
import { SimWebContext } from './context/SimWeb.provider';
import { ErrorContext } from './context/Error.provider';

import './App.css';
import { version } from '../package.json';
import { generalAxiosRequest } from './axios/axiosFunctions';
import { baseUrl, endpoints, routerUrls } from './axios/endpoints';

// eslint-disable-next-line max-len
const setToken = (token, source, haveRetried, initialLogin, setRetailLogin, setInitialLogin, setHaveRetried, callGetAccessData, logout) => {
  if (!source) return;
  let decodedToken;
  if (token.accessToken) {
    try {
      decodedToken = jwtDecode(token.accessToken);
    } catch (e) {
      // if token decoding fails then logout
      logout();
    }

    // // TODO: for OKTA login
    // if (source === AuthTypes.OktaAuth) {
    //   setOktaLogin(decodedToken, token, initialLogin);
    // }
    if (source === AuthTypes.RetailAuth) setRetailLogin(decodedToken, token, initialLogin);

    setInitialLogin(false);
    setHaveRetried(false);
  } else if (!haveRetried && callGetAccessData) {
    // retry fetching token after 30 seconds to give a chance for wrapper to refresh token
    setHaveRetried(true);
    setTimeout(callGetAccessData, 5000);
  } else {
    // if we have attempted to grab token twice and failed, logout
    setHaveRetried(false);
    // logout();
  }
};

const requestTokens = () => {
  const source = sessionStorage.getItem('loginSource');
  const targetOrigins = getTargetOrigin();
  const isTrue = window.location !== window.parent.location;
  const docRef = document.referrer.replace(/\/$/, '');

  if (source === 'iOS') window.webkit.messageHandlers.getAccessData.postMessage('');
  if (isTrue && docRef !== '' && targetOrigins.includes(docRef)) {
    window.parent.postMessage({ name: Messages.RequestAccessToken }, docRef);
  }
};

export const App = () => {
  const location = useLocation();
  const {
    isIOS, isTrue, getMessage, setCountry, setStoreNumber, storeConfig, isLoggedIn, setTrueOrIOSLogin,
    logout, setInitialCall, setOidcAccessToken, isAdmin,
  } = useContext(SimWebContext);
  const { setVersionError, versionError } = useContext(ErrorContext);

  const [currentRoute, setCurrentRoute] = useState('');
  const [haveRetried, setHaveRetried] = useState(false);
  const [initialLogin, setInitialLogin] = useState(true);
  const [topLocation, setTopLocation] = useState('');

  const rfidEnabled = storeConfig?.rfidEnabled?.value;
  const isSelfcheckoutForTrue = isTrue && currentRoute === '/selfCheckoutDeviceAssignment';

  const isLoggedIOS = sessionStorage.getItem('loginSource') === 'iOS';
  const targetOrigins = getTargetOrigin();
  const page = window.location.href.split('/')[3];

  const userAgent = navigator?.userAgent;
  const isMobile = userAgent.includes('iPad') || userAgent.includes('iPhone');

  updateChromeTab(page, getMessage);

  useEffect(() => {
    const checkVersion = async () => {
      const url = `${baseUrl(routerUrls.SIMWEB_BFF)}${endpoints.VERSION_CHECK.url}?version=${version}`;
      generalAxiosRequest('GET', url, endpoints.VERSION_CHECK, false).catch(error => setVersionError(error));
    };
    checkVersion();
  }, []);

  // setup communication between true and simweb and pass security tokens
  useEffect(() => {
    const setTokens = () => {
      try {
        const docRef = document?.referrer?.replace(/\/$/, '') ?? '';

        if (!targetOrigins.includes(docRef)) return;

        window.parent.postMessage({ name: Messages.RequestAccessToken }, docRef);
        window.parent.postMessage({ name: Messages.SetTitle, title: 'Store Inventory Management' }, docRef);
        window.parent.postMessage({ name: Messages.GetURL }, docRef);
        window.parent.postMessage({ name: Messages.SendStore }, docRef);

        // TODO: delete once verified the build mode is set properly
        /* eslint-disable no-console */
        console.log('IFRAME: messages', Messages, 'docRef', docRef);

        window.onmessage = (e) => {
          if (e.data.authType === AuthTypes.RetailAuth) {
            localStorageService.setAuthToken(e.data.token);
            // eslint-disable-next-line max-len
            setToken(e.data.token, e.data.authType, haveRetried,
              initialLogin, setTrueOrIOSLogin, setInitialLogin, setHaveRetried, requestTokens, () => logout());
          }
          if (e.data.url) {
            setTopLocation(e.data.url);
            if (e.data.url.includes('selfCheckoutDevice')) {
              setCurrentRoute(`/${e.data.url.split('/').pop()}`);
            }
            localStorageService.setParentUrl(e.data.url);
          }
          if (e.data.country && e.data.storeNumber) {
            localStorageService.setStoreId(e.data.country, e.data.storeNumber);
            setCountry(e.data.country);
            setStoreNumber(e.data.storeNumber);
          }
        };
      } catch (error) {
        console.error('Error setting tokens', error); // eslint-disable-line no-console
        setHaveRetried(false);
        // logout();
      }
    };

    if (isTrue && document?.referrer !== '') setTokens();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // add function to window that the wrapper application can use to return accessToken if
  // the auth source is iOS
  useEffect(() => {
    if (!isLoggedIOS) return;

    document.body.classList.add('mobile');

    window.action = {
      returnAccessData: (token) => {
        const parsedToken = JSON.parse(token);
        let decodedToken;
        const thirtySeconds = 30000; // 30 seconds in ms
        if (parsedToken.accessToken) {
          try {
            decodedToken = jwtDecode(parsedToken.accessToken);
          } catch (e) {
            // if token decoding fails then logout
            logout();
          }
          setOidcAccessToken(decodedToken, parsedToken, initialLogin);
          setInitialLogin(false);
          setInitialCall(false);
          setHaveRetried(false);
        } else if (!haveRetried) {
          // retry fetching token after 30 seconds to give a chance for wrapper to refresh token
          setHaveRetried(true);
          setTimeout(requestTokens, thirtySeconds);
        } else {
          // if we have attempted to grab token twice and failed, logout
          setHaveRetried(false);
          logout();
        }
      },
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isMobile) document.body.classList.add('mobile');
  }, [isMobile]);

  // udpate the navigation bar in the parent window to reflect current route and allow browser button navigation
  useEffect(() => {
    const pathName = location?.pathname;
    const docRef = document.referrer.replace(/\/$/, '');

    if (!isTrue || docRef === '' || !topLocation) return;

    try {
      const newurl = `${docRef}/SIMWeb${pathName}`;
      window.parent.postMessage({ name: Messages.Addressbar, newurl }, docRef);

      // if users navigates to the login page, close the iframe
      if (pathName === '/login') {
        window.parent.postMessage({ name: Messages.Close }, docRef);
      }
      sessionStorage.setItem('lastPage', pathName);
      setCurrentRoute(pathName);
    } catch (error) {
      console.error('Error updating navigation bar', error);
    }
  }, [location]);

  // CSS Media Queries
  const landscape = useMedia('only screen and (orientation: landscape)');
  sessionStorage.setItem('deviceSize', landscape ? 'lg' : 'sm');

  const reducedAnimation = useMedia('only screen and (prefers-reduced-motion: reduce)');

  const containerClasses = classNames(
    'sim-container', { isLoggedIn }, { isIOS }, { landscape },
    { portrait: !landscape }, { reducedAnimation },
  );

  const app = (
    <>
      <StackedNotifications page={page} isAdmin={isAdmin} isScanTimerEnabled={rfidEnabled} />
      <div data-testid="sim-containter" className={containerClasses} style={{ paddingTop: '60px' }}>
        <div className="iPad-portrait-overlay">
          <DeviceRotation />
          <span className="message">{getMessage('rotateDeviceMessage')}</span>
        </div>
        {isLoggedIn && !isSelfcheckoutForTrue ? <NavMenu currentRoute={currentRoute} /> : null}
        {versionError && (
          <ErrorAlert
            errorObject={versionError?.message}
            apiName="Version Alert"
            pageCode=""
          />
        )}
        <div className="sim-content" id="sim-content">
          <ScrollToTop />
          <Switch>
            <PrivateRoute exact path="/admin" render={() => wrappedPages('admin')} />
            <PrivateRoute exact path="/Adjustments" render={() => wrappedPages('adjustments')} />
            <PrivateRoute exact path="/agedOrders" render={() => wrappedPages('agedOrders')} />
            <PrivateRoute exact path="/asnTracking" render={() => wrappedPages('asnTracking')} />
            <PrivateRoute exact path="/stockroomBin" render={() => wrappedPages('binAudit')} />
            <PrivateRoute exact path="/dashboard" render={() => wrappedPages('dashboard')} />
            <PrivateRoute exact path="/inboundVisibility" render={() => wrappedPages('inboundVisibility')} />
            <PrivateRoute exact path="/itemActivity" render={() => wrappedPages('itemActivity')} />
            <PrivateRoute exact path="/RFIDMissingScan" render={() => wrappedPages('missingStyles')} />
            <PrivateRoute exact path="/OOSAudits" render={() => wrappedPages('oosAudit')} />
            <PrivateRoute exect path="/physicalInventoryUpload" render={() => wrappedPages('physicalInventoryUpload')} />
            <PrivateRoute exact path="/priceChanges" render={() => wrappedPages('priceChanges')} />
            <PrivateRoute exact path="/productMovement" render={() => wrappedPages('productMovement')} />
            <PrivateRoute exact path="/productRefill" render={() => wrappedPages('productRefill')} />
            <PrivateRoute exact path="/ReceivingLog/:searchDate?" render={() => wrappedPages('receivingLog')} />
            <PrivateRoute exact path="/scanSummary" render={() => wrappedPages('scanReportSummary')} />
            <PrivateRoute path="/displayScan/:sessionId" render={() => wrappedPages('displayScan')} />
            <PrivateRoute path="/scan/:sessionId/:endDate" render={() => wrappedPages('scan')} />
            <PrivateRoute path="/scanV2/:sessionId/:endDate" render={() => wrappedPages('scanDetails')} />
            <PrivateRoute exact path="/sizeCompliance" render={() => wrappedPages('sizeCompliance')} />
            <PrivateRoute exact path="/StockOnHand" render={() => wrappedPages('stockOnHand')} />
            <PrivateRoute exact path="/stockroomOrganization" render={() => wrappedPages('stockroomOrganization')} />
            <PrivateRoute exact path="/UPHReport" render={() => wrappedPages('storePerformance')} />
            <PrivateRoute exact path="/styleManagement" render={() => wrappedPages('styleManagement')} />
            <PrivateRoute exact path="/transfers" render={() => wrappedPages('transfers')} />

            {/* old format pages */}
            <PrivateRoute exact path="/itemInquiry/:searchValue?" component={ItemInquiry} />
            <PrivateRoute exact path="/selfCheckoutDeviceAssignment" component={SelfCheckoutDeviceAssignment} />
            {env.isLocalHost() || env.isTest() ? <PrivateRoute exact path="/postman" component={Postman} /> : null}

            <Route exact path="/login" component={loginFactory()} />
            <Route exact path="/service-worker.js" render={() => <Redirect to="/service-worker.js" />} />
            <Route path="*" component={loginFactory()} />
          </Switch>
        </div>
      </div>
    </>
  );

  const renderApplication = () => {
    if (!isLoggedIn) {
      return app;
    }
    return rfidEnabled ? <ScanTimerProvider>{app}</ScanTimerProvider> : app;
  };

  return renderApplication();
};

export default App;

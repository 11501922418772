import React from 'react';
import { string, func, object } from 'prop-types';
import { Button } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';

import './svgs.css';

/**
 * Refresh is an reusable icon button that takes an onclick event
 * Used in the SOH Report
 * @param {string} className - a class name value to allow you to customize the component if needed
 * @param {function} onClick - func to handle an event passed in
 * @param {string} testId - value to tag the element with an id for testing
 * @param {string} color - color of the svg
 */
// eslint-disable-next-line import/prefer-default-export
export const Refresh = ({
  className, onClick, testId, color, style,
}) => (
  <div className="refreshIcon-container">
    <Button
      onClick={onClick}
      disableRipple
      data-testid={testId}
    >
      <RefreshIcon className={className} fontSize="medium" color={color} style={{ ...style }} />
    </Button>
  </div>
);

Refresh.propTypes = {
  className: string,
  onClick: func,
  testId: string,
  color: string,
  style: object,
};

Refresh.defaultProps = {
  className: 'refreshIcon',
  onClick: null,
  testId: '',
  color: 'action',
  style: null,
};

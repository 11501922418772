/* eslint-disable no-return-assign */
/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable no-case-declarations */
/* eslint-disable quote-props */
/* eslint-disable object-curly-newline */
import React, { useContext, useMemo, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Typography } from '@mui/material';
import Select from '@mui/material/Select';
import { array, bool, string, func, number } from 'prop-types';
import MUIDataTable from 'mui-datatables';
import CloudDownloadLink from '../../components/cloudDownloadLink/CloudDownloadLink';
import { LoadingIndicator } from '../../assets/Svgs/index';
import { toExcel, getPaginatedVarianceData } from './physicalInventoryUpload.utils';
import PIUploadStatus from './PIUploadStatus';
import getFormattedPrice from '../../utils/getFormattedPrice';

import { DATE_TIME_SHORT_WITH_APPENDED_ZEROS } from '../../constants/LocaleFormats';

import { PhysicalInventoryUploadContext } from './PhysicalInventoryUpload.provider';
import { SimWebContext } from '../../context/SimWeb.provider';
import { SimDateTime } from '../../utils/datetime';

/**
 * The Physical Inventory Upload Summary Table
 * @param {string} title - value of the current sessionId displayed in the title of the MUI Table
 * @param {object} data - the variance report data after the Physical Inventory Upload is submitted
 * @param {object} showLoading - show spinner when retrieving data from the parent
 * @param {object} viewClick - click action to grab the last good variance report
 */
const PhysicalInventoryUploadVarianceTable = ({
  title, paginatedData, allData, showLoading, viewClick, rowsPerPage, totalCounts,
}) => {
  const { getMessage, isPrintingEnabled, locale, storeNumber, storeConfig, currencyCode } = useContext(SimWebContext);
  const {
    setPaginatedVarianceData,
    setRowsPerPage,
    setTotalCounts,
    historicalSession,
    lastSession,
    setLastDeltaSessionId,
    setSessionId,
    setLastSession,
    setUploadStatus,
    setFetchingHistorical,
  } = useContext(PhysicalInventoryUploadContext);

  const rfidEnabled = storeConfig?.rfidEnabled?.value;
  const overrideRfid = storeConfig?.varianceReportSOH?.value;

  const [filterBy, setFilterBy] = useState(null);
  const [searchText, setSearchText] = useState(null);
  const [sortBy, setSortBy] = useState(null);

  const getValue = (value) => value || '-';
  const onClick = () => toExcel(storeNumber, allData, sortBy, searchText, filterBy);

  // Platform changing SOH to be either RFID or Fiscal but using the same variable name.
  // For consistency, we want to make sure stores know which one is being used through configs.
  const sohLabel = () => {
    if (rfidEnabled && !overrideRfid) {
      return getMessage('rfidSoh');
    }
    if (overrideRfid) {
      return getMessage('soh');
    }
    return getMessage('soh');
  };

  const customToolbar = () => (
    <CloudDownloadLink
      id="pi-upload-download"
      tooltip="Download XLS"
      onClick={onClick}
    />
  );

  const columns = [
    {
      name: 'productCode',
      label: getMessage('style-color'),
      options: {
        filter: true,
        customBodyRender: getValue,
      },
    },
    {
      name: 'division',
      label: getMessage('division'),
      options: {
        filter: true,
        customBodyRender: getValue,
      },
    },
    {
      name: 'description',
      label: getMessage('description'),
      options: {
        filter: true,
        customBodyRender: getValue,
      },
    },
    {
      name: 'gtin',
      label: getMessage('upc'),
      options: {
        filter: true,
        customBodyRender: getValue,
      },
    },
    {
      name: 'size',
      label: getMessage('size'),
      options: {
        filter: true,
        customBodyRender: getValue,
      },
    },
    {
      name: 'oldStock',
      label: sohLabel(),
      options: {
        filter: true,
      },
    },
    {
      name: 'newStock',
      label: 'PI SOH',
      options: {
        filter: true,
      },
    },
    {
      name: 'currentPrice',
      label: getMessage('currentPrice'),
      options: {
        customBodyRender: value => (
          <span>{getFormattedPrice(value, locale, currencyCode)}</span>
        ),
      },

    },
    {
      name: 'variance',
      label: getMessage('variance'),
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          'data-testid': 'columnStep',
        }),
      },
    },
    {
      name: 'valueVariance',
      label: getMessage('valueVariance'),
      options: {
        customBodyRender: value => {
          let className = '';
          if (value > 0) { className = 'positive-value-variance-value'; }
          if (value < 0) { className = 'negative-value-variance-value'; }
          return (
            <span className={className}>{getFormattedPrice(value, locale, currencyCode)}</span>
          );
        },
      },
    },
  ];

  const muiDataTableOptions = {
    selectableRows: 'none',
    rowsPerPage,
    rowsPerPageOptions: [100, 500, 1000, 5000],
    count: totalCounts,
    print: !showLoading && isPrintingEnabled,
    responsive: 'standard',
    download: false,
    customToolbar: !showLoading && (() => customToolbar()),
    customToolbarSelect: () => customToolbar(),
    filter: !showLoading,
    search: !showLoading,
    viewColumns: !showLoading,
    textLabels: {
      body: { noMatch: getMessage('noResults') },
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
        case 'sort':
          const sortedData = getPaginatedVarianceData(allData, tableState.page, rowsPerPage, setTotalCounts, tableState.sortOrder, searchText, filterBy);
          setPaginatedVarianceData(sortedData);
          setSortBy(tableState.sortOrder);
          break;
        case 'filterChange':
          const filteredData = getPaginatedVarianceData(allData, tableState.page, rowsPerPage, setTotalCounts, sortBy, searchText, tableState.filterList);
          setPaginatedVarianceData(filteredData);
          setFilterBy(tableState.filterList);
          break;
        case 'search':
        case 'onSearchClose':
          const searchedData = getPaginatedVarianceData(allData, 0, rowsPerPage, setTotalCounts, sortBy, tableState.searchText, filterBy);
          setPaginatedVarianceData(searchedData);
          setSearchText(tableState.searchText);
          break;
        case 'changeRowsPerPage':
          const pageData = getPaginatedVarianceData(allData, 0, tableState.rowsPerPage, setTotalCounts, sortBy, searchText, filterBy);
          setPaginatedVarianceData(pageData);
          setRowsPerPage(tableState.rowsPerPage);
          break;
        default:
          break;
      }
    },
  };

  const buttonStyle = { position: 'relative', top: '0px', paddingLeft: '10px' };
  const handleChange = (event) => {
    setFetchingHistorical(true);
    setUploadStatus(PIUploadStatus.HISTORICAL_REPORT.name);
    setSessionId(event.target.value);
    setLastSession(historicalSession.find(session => session.sessionId === event.target.value));
    setLastDeltaSessionId(event.target.value);
  };

  const renderDate = (date) => (date && new Date(date).toLocaleString(locale, DATE_TIME_SHORT_WITH_APPENDED_ZEROS)) || '-';
  const isOneWeekkOld = (date) => SimDateTime.toDateTime(date) > SimDateTime.now().minus({ days: 7 }).endOf('day');
  const isLast = (array, index) => index === array.length - 1;
  const filteredItems = historicalSession?.filter((session) => isOneWeekkOld(session.creationDate) || session.sessionId === lastSession?.sessionId);
  const menuItems = filteredItems?.map((session, index) => <MenuItem value={session.sessionId} selected={isLast(filteredItems, index)}><div>{`${session.sessionId}   (${renderDate(session.creationDate)})`}</div></MenuItem>);

  const select = (
    <FormControl sx={{ m: 1, minWidth: 175 }} size="small">
      <Select
        labelId="session-lablel"
        data-testid="historical-sessions"
        value={title}
        onChange={handleChange}
        disabled={filteredItems?.length === 0}
      >
        {menuItems}
      </Select>
    </FormControl>
  );

  const reportLabel = 'Variance Report';
  const sessionsLabel = 'Sessions';

  const getTitle = useMemo(() => (
    <div style={{ paddingTop: '20px', paddingRight: '20px' }}>
      <h4>{reportLabel}</h4>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Typography variant="h6">
          {sessionsLabel}
        </Typography>
        {select}
        { showLoading ? <LoadingIndicator extraClass="pi-variance-upload-loading-spinner" dataTestId="pi-variance-upload-loading-spinner" height="50px" width="50px" /> : null }
        <div className="physical-inventory-upload-button-wrapper" style={!showLoading ? buttonStyle : null}>
          {!showLoading
            ? (
              <button type="button" className="sim-button" data-testid="pi-upload-view-button" onClick={viewClick} disabled={filteredItems?.length === 0}>
                {getMessage('view')}
              </button>
            ) : null}
        </div>
      </div>
    </div>
  ), [showLoading, title, viewClick, paginatedData, historicalSession]);

  return (
    <div data-testid="physical-inventory-upload-variance-table">
      <MUIDataTable
        title={getTitle}
        data={paginatedData || []}
        columns={columns}
        options={muiDataTableOptions}
      />
    </div>
  );
};

PhysicalInventoryUploadVarianceTable.propTypes = {
  paginatedData: array,
  allData: array,
  title: string,
  rowsPerPage: number,
  totalCounts: number,
  showLoading: bool,
  viewClick: func,
};

PhysicalInventoryUploadVarianceTable.defaultProps = {
  paginatedData: null,
  allData: null,
  title: null,
  rowsPerPage: 100,
  totalCounts: 0,
  showLoading: false,
  viewClick: null,
};

export default PhysicalInventoryUploadVarianceTable;

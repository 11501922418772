/* eslint-disable no-constant-condition */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-useless-catch */
/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import { generalAxiosRequest } from '../../axios/axiosFunctions';
import { baseUrl, chooseTaskService, routerUrls } from '../../axios/endpoints';

export const fetchStorePerformanceData = async (storeId, startDate, endDate, appId, taskService) => {
  if (!storeId || !startDate || !endDate) return [];

  const headers = { appId };

  const tasksEndpoint = chooseTaskService(taskService, true);
  const typeFilter = `&filter=${taskService?.toLowerCase() === 'v3' ? 'fulfillmentType' : 'type'}(REFILL,UNBINNED_REFILL)`;
  const statusFilter = '&filter=status(COMPLETED_SUCCESSFULLY,COMPLETED_UNSUCCESSFULLY)';
  const dateFilter = `&filter=modificationDateAfterEq(${startDate.toUTC()})&filter=modificationDateBeforeEq(${endDate.toUTC()})`;
  const filters = `?filter=storeId(${storeId})${typeFilter}${statusFilter}${dateFilter}&count=100`;

  const fetchedPerformance = [];
  let anchor;
  let url = `${baseUrl(routerUrls.NIKE_API)}${tasksEndpoint.url}${filters}`;

  try {
    while (true) {
      if (anchor) url = `${baseUrl(routerUrls.NIKE_API)}${anchor}`;

      const performancePage = await generalAxiosRequest('GET', url, tasksEndpoint, true, headers);
      if (performancePage?.objects?.length > 0) {
        performancePage.objects.map(task => fetchedPerformance.push(task));
      }

      anchor = performancePage?.pages?.next || null;

      if (!anchor) {
        break;
      }
    }

    return fetchedPerformance;
  } catch (error) {
    throw error;
  }
};

import React from 'react';
import { string } from 'prop-types';

import './svgs.css';

// eslint-disable-next-line import/prefer-default-export
export const NoDevice = ({ extraClass, width, height }) => (
  <div className={`noDevices ${extraClass}`} data-testid="noDevices">
    <svg className="no-devices" width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 512" preserveAspectRatio="xMidYMid" style={{ background: 'none' }}>
      <g>
        <path d="M350.351,69.215c4.2,0,7.604-3.405,7.604-7.604v-9.949C357.955,23.175,334.78,0,306.293,0H63.889
          C35.403,0,12.228,23.175,12.228,51.662v408.676c0,28.486,23.175,51.661,51.661,51.661h242.404
          c28.487,0,51.661-23.175,51.661-51.661v-66.181c0-4.199-3.405-7.604-7.604-7.604c-4.2,0-7.604,3.405-7.604,7.604v66.181
          c0,20.101-16.354,36.453-36.453,36.453H63.889c-20.1,0-36.453-16.353-36.453-36.453V51.662c0-20.102,16.354-36.454,36.453-36.454
          h242.404c20.1,0,36.453,16.353,36.453,36.454v9.949C342.747,65.811,346.15,69.215,350.351,69.215z"
        />
      </g>
      <g>
        <path d="M260.119,82.123H48.22c-4.2,0-7.604,3.405-7.604,7.604v238.529c0,4.199,3.404,7.604,7.604,7.604s7.604-3.405,7.604-7.604
          V97.331h204.295c4.199,0,7.604-3.405,7.604-7.604S264.32,82.123,260.119,82.123z"
        />
      </g>
      <g>
        <path d="M321.963,382.498c-4.2,0-7.604,3.405-7.604,7.604v24.567H55.824v-61.066c0-4.199-3.404-7.604-7.604-7.604
          s-7.604,3.405-7.604,7.604v68.67c0,4.199,3.404,7.604,7.604,7.604h273.743c4.199,0,7.604-3.405,7.604-7.604v-32.171
          C329.567,385.903,326.163,382.498,321.963,382.498z"
        />
      </g>
      <g>
        <path d="M185.091,432.519c-16.881,0-30.615,13.734-30.615,30.616c0,16.881,13.734,30.615,30.615,30.615
          s30.615-13.734,30.615-30.615C215.706,446.254,201.972,432.519,185.091,432.519z M185.091,478.542
          c-8.495,0-15.407-6.911-15.407-15.407s6.911-15.408,15.407-15.408c8.495,0,15.407,6.911,15.407,15.408
          C200.498,471.63,193.587,478.542,185.091,478.542z"
        />
      </g>
      <g>
        <path d="M479.16,151.231c-41.656-70.522-132.917-94.01-203.443-52.354c-16.056,9.484-29.979,21.671-41.384,36.225
          c-2.59,3.305-2.01,8.086,1.295,10.675s8.085,2.011,10.675-1.295c10.232-13.057,22.73-23.995,37.149-32.511
          c63.303-37.391,145.224-16.308,182.614,46.995c18.113,30.665,23.2,66.549,14.324,101.04
          c-8.876,34.492-30.653,63.462-61.318,81.574s-66.549,23.2-101.038,14.323c-34.493-8.876-63.463-30.652-81.574-61.318
          c-23.677-40.083-24.872-88.651-3.202-129.922c1.952-3.718,0.521-8.315-3.198-10.267c-3.716-1.952-8.314-0.521-10.267,3.197
          c-11.558,22.013-17.464,46.782-17.076,71.626c0.399,25.628,7.54,50.905,20.648,73.1c20.179,34.163,52.453,58.424,90.878,68.312
          c12.349,3.177,24.852,4.749,37.277,4.749c26.237-0.001,52.104-7.012,75.288-20.706
          C497.328,313.019,520.814,221.754,479.16,151.231z"
        />
      </g>
      <g>
        <path d="M398.576,226.774l40.862-40.862c2.97-2.97,2.97-7.784,0-10.753l-36.561-36.561c-1.427-1.426-3.36-2.227-5.378-2.227
          s-3.95,0.801-5.378,2.227l-40.861,40.862l-40.862-40.862c-2.971-2.97-7.784-2.97-10.754,0l-36.561,36.561
          c-1.425,1.426-2.226,3.36-2.226,5.377c0,2.017,0.801,3.951,2.226,5.377l40.863,40.862l-40.863,40.862
          c-1.426,1.425-2.227,3.36-2.227,5.376c0,2.017,0.801,3.951,2.226,5.377l36.561,36.561c1.485,1.485,3.431,2.227,5.378,2.227
          c1.946,0,3.892-0.742,5.378-2.227l40.862-40.862l40.861,40.862c1.427,1.427,3.36,2.227,5.378,2.227s3.95-0.801,5.378-2.227
          l36.561-36.561c2.97-2.97,2.97-7.784,0-10.753L398.576,226.774z M397.499,298.82l-40.861-40.862
          c-1.427-1.426-3.36-2.227-5.378-2.227s-3.95,0.801-5.378,2.227l-40.862,40.862l-25.807-25.807l40.863-40.862
          c1.425-1.426,2.226-3.36,2.226-5.377c0-2.017-0.801-3.951-2.226-5.377l-40.863-40.862l25.807-25.807l40.862,40.862
          c1.427,1.427,3.36,2.227,5.378,2.227s3.95-0.801,5.378-2.227l40.861-40.862l25.807,25.807l-40.862,40.862
          c-2.97,2.97-2.97,7.784,0,10.753l40.862,40.862L397.499,298.82z"
        />
      </g>
      <g>
        <path d="M242.881,39.813h-115.58c-4.2,0-7.604,3.405-7.604,7.604s3.404,7.604,7.604,7.604h115.58c4.2,0,7.604-3.405,7.604-7.604
          S247.082,39.813,242.881,39.813z"
        />
      </g>
    </svg>
  </div>
);

NoDevice.defaultProps = {
  extraClass: null,
  width: '180px',
  height: '180px',
};

NoDevice.propTypes = {
  extraClass: string,
  width: string,
  height: string,
};

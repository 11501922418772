/**
 * Will call the given function at the given time
 * @param {function} func - function to call
 * @param {dateTime} dateTo - time to call the function at
 */
const setToHappen = (func, dateTo) => {
  const t = dateTo.getTime() - (new Date()).getTime();
  return setTimeout(func, t);
};

export default setToHappen;

import React, {
  useContext, useEffect, useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';

import Header from './Header';
import Form from './Form';
import { LoadingIndicator } from '../../../assets/Svgs/index';
import { fetchAllCountries, fetchStoreNumbers } from './countriesAndStoresAxios';

import { LoginContext } from '../../../context/Login.provider';
import { TranslationsContext } from '../../../context/Translations.provider';

import '../LoginPage.css';

// only needs to be english since this is done on the return from storeviews
export const sortAlphaNum = (a, b) => a.localeCompare(b, 'en', { numeric: true });
export const getInitialStore = (location) => {
  // get store number from query parameter
  const query = qs.parse(location.search);

  // set store number to either query param, cached store number, or blank.
  const store = (query.store && query.store.split('-'))
    || [localStorage.getItem('country'), localStorage.getItem('storeNumber')];
  if (store.length === 2) {
    store[0] = store[0]?.toUpperCase();
    return store;
  }
  return [];
};

/**
 * The retail login page
 */
const LoginPage = () => {
  const { getMessage, isReady } = useContext(TranslationsContext);
  const { error, performLogIn } = useContext(LoginContext);

  const [countries, setCountries] = useState([]);
  const [storeNumbers, setStoreNumbers] = useState([]);
  const [isLogginIn, setLoggingIn] = useState(false);
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const [defaultCountry, defaultStoreNumber] = getInitialStore(location);

  useEffect(() => {
    const getAllCountries = async () => {
      setLoading(true);
      const countriesList = await fetchAllCountries();
      setCountries(countriesList);
      setLoading(false);
    };
    getAllCountries();
  }, []);

  const getStoreNumbers = async (country) => {
    setLoading(true);
    const storeList = await fetchStoreNumbers(country);
    setStoreNumbers(storeList);
    setLoading(false);
  };

  useEffect(() => {
    getStoreNumbers(defaultCountry);
  }, [defaultCountry]);

  const submit = ({
    employeeId, password, country, storeNumber,
  }) => {
    setLoggingIn(true);
    performLogIn({
      athleteLogin: employeeId,
      password,
      store: `${country}-${storeNumber}`,
    });
  };

  const missingTranslations = (
    // eslint-disable-next-line react/jsx-curly-brace-presence
    <div data-testid="missingTranslations" style={{ display: 'none' }}>{'Translations are not ready.'}</div>
  );

  return isReady ? (
    <div className="sim-login-container">
      <Header headerText={getMessage('retailWebTitle')} />
      <LoadingIndicator extraClass={`${!error && (loading || isLogginIn) ? 'sim-login-loading-show' : 'sim-login-loading '}`} />
      {error ? (
        <div className="alert alert-danger login-error">
          {error === 'generalError' ? getMessage('invalidEmployeeNumberPassword') : error}
        </div>
      ) : null}
      <Form
        className="sim-login-container-form"
        defaultCountry={defaultCountry}
        defaultStoreNumber={defaultStoreNumber}
        loading={false}
        storeNumbers={storeNumbers}
        countries={countries}
        fetchStoreNumbers={getStoreNumbers}
        submit={submit}
        setLoading={setLoading}
      />
    </div>
  ) : missingTranslations;
};

export default LoginPage;

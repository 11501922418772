import React from 'react';
import { string } from 'prop-types';

import './LoadingAnnimation.css';

/**
   * A loading component used in the Scan Report to help display the
   * Scanning status of scan to the users in the summary table
   * @param {string} label - optional value that can be rendered with the SVG
   */
// eslint-disable-next-line import/prefer-default-export
export const LoadingAnnimation = ({
  label,
}) => {
  const loadingIndicator = (
    <div className="dot-flashing" data-testid="loading-annimation" data-title="dot-flashing" />
  );

  return label ? (
    <div className="loading-flex" data-testid="loading-annimation-with-label">
      {loadingIndicator}
      {` ${label}`}
    </div>
  ) : loadingIndicator;
};

LoadingAnnimation.propTypes = {
  label: string,
};

LoadingAnnimation.defaultProps = {
  label: null,
};

export default LoadingAnnimation;

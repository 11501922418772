import React, { useContext } from 'react';
import {
  array, bool, func, string,
} from 'prop-types';
import Typography from '@mui/material/Typography';
import Toggle from '../toggle/Toggle';
import getTotalStockOnHand from '../../utils/getTotalStockOnHand';
import { TranslationsContext } from '../../context/Translations.provider';

/**
 * A Toggle component, to display RFID SOH and SOH
 * @param {bool} rfidEnabled - rfidEnabled config coming from storeConfig
 * @param {string} sohTypeSelected - the type of SOH the user is toggling
 * @param {func} setSohTypeSelected  - what update the SOH toggle
 * @param {array} sizes - array of sizes for a product to be calculated
 * @param {bool} darkMode - changes the color to support dark mode on dashboard
 */
const ProductDetailRfidToggle = ({
  rfidEnabled, sohTypeSelected, setSohTypeSelected, sizes, darkMode,
}) => {
  const { getMessage } = useContext(TranslationsContext);

  if (rfidEnabled) {
    return (
      <Toggle
        leftLabel={getMessage('rfidSoh')}
        leftQuantity={getTotalStockOnHand(sizes, true)}
        leftName="RFID SOH"
        rightLabel={getMessage('soh')}
        rightQuantity={getTotalStockOnHand(sizes, false)}
        rightName="SOH"
        selected={sohTypeSelected}
        setSelected={setSohTypeSelected}
        darkMode={darkMode}
      />
    );
  }
  return (
    <div data-testid="product-details-non-rfid-soh-header">
      <Typography gutterBottom variant="h5">
        {getMessage('stockOnHand')}
      </Typography>
      <Typography gutterBottom variant="h3">
        {getTotalStockOnHand(sizes, false, false)}
      </Typography>
    </div>
  );
};

ProductDetailRfidToggle.propTypes = {
  rfidEnabled: bool.isRequired,
  sizes: array,
  sohTypeSelected: string.isRequired,
  setSohTypeSelected: func.isRequired,
  darkMode: bool,
};

ProductDetailRfidToggle.defaultProps = {
  sizes: [],
  darkMode: false,
};

export default ProductDetailRfidToggle;

/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { func, number, object } from 'prop-types';
import MUIDataTable from 'mui-datatables';
import { orderBy } from 'lodash';
import { DateTime } from 'luxon';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { sortByFirstValue, sortRowsByFirstValue } from './binAudits.utils';
import { DATE_SHORT_WITH_APPENDED_ZEROS } from '../../constants/LocaleFormats';
import { defaultSort } from '../../utils/sort/defaultSort';

// context
import { SimWebContext } from '../../context/SimWeb.provider';

const BinAuditsTable = ({
  setOpen, setDialogData, dataForRender, setAuditIndex, auditIndex,
}) => {
  const { isPrintingEnabled, getMessage } = useContext(SimWebContext);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (dataForRender?.audits) {
      setRows(dataForRender?.audits);
    }
  }, [dataForRender?.audits]);

  useEffect(() => {
    const newAudit = rows?.[auditIndex];
    const newDialogData = [
      {
        ...newAudit,
        documentNumber: DateTime.fromISO(newAudit?.date).toLocaleString(DATE_SHORT_WITH_APPENDED_ZEROS),
      },
    ];
    setDialogData(newDialogData);
  }, [auditIndex]);

  const columns = [
    getMessage('date'),
    getMessage('auditScore'),
    getMessage('passed'),
    getMessage('updated'),
    getMessage('auditNumber'),
    getMessage('opId'),
  ];

  const createRows = useMemo(() => rows?.map(audit => ([
    DateTime.fromISO(audit?.date).toLocaleString(DATE_SHORT_WITH_APPENDED_ZEROS),
      `${audit.score}%`,
      `${audit.numPassed} / ${audit.numAudits}`,
      `${audit.numUpdated} / ${audit.numFailed}`,
      audit.auditNumber,
      audit.athlete,
  ])), [rows]);

  const theme = createTheme(({
    components: {
      MuiTableRow: {
        styleOverrides: {
          root: {
            cursor: 'pointer',
            alignItems: 'middle',
          },
        },
      },
    },
  }));

  return (
    <div data-testid="bin-audits-report-table" className="bin-audits-table">
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <MUIDataTable
            data={createRows}
            columns={columns}
            options={{
              selectableRows: false,
              rowsPerPage: 100,
              selectableRowsHeader: dataForRender?.audits?.length > 0,
              rowsPerPageOptions: [15, 25, 50, 100],
              filter: false,
              viewColumns: false,
              print: isPrintingEnabled,
              responsive: 'standard',
              download: false,
              search: false,
              textLabels: {
                body: {
                  noMatch: getMessage('noData'),
                },
              },
              selectToolbarPlacement: 'replace',
              onColumnSortChange: (changedColumn, direction) => {
                const sortBy = {
                  Date: 'date',
                  'Audit Score': 'score',
                  Passed: 'numPassed',
                  Updated: 'numUpdated',
                  'Audit Number': 'auditNumber',
                  'Op Id': 'athlete',
                };
                const orderDataBy = sortBy[changedColumn];
                const customSort = (orderDataBy === 'numPassed' || orderDataBy === 'numUpdated');
                customSort
                  ? setRows(sortByFirstValue(rows, orderDataBy, direction))
                  : setRows(orderBy(rows, orderDataBy, [`${direction}`]));
              },
              customSort: (data, dataIndex, rowIndex) => {
                if (dataIndex === 1 || dataIndex === 2 || dataIndex === 3 || dataIndex === 5) {
                  return data && sortRowsByFirstValue(data, dataIndex, rowIndex);
                }
                return defaultSort(data, dataIndex, rowIndex);
              },
              onRowClick: (rowData, { dataIndex }) => {
                const newDialogData = [
                  {
                    ...rows?.[dataIndex],
                    documentNumber: rowData[0],
                  },
                ];
                setAuditIndex(dataIndex);
                setDialogData(newDialogData);
                setOpen(true);
              },
            }}
          />
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
};

BinAuditsTable.defaultProps = {
  dataForRender: {},
  setDialogData: () => {},
  setOpen: () => {},
};

BinAuditsTable.propTypes = {
  dataForRender: object,
  setDialogData: func,
  setOpen: func,
  setAuditIndex: func.isRequired,
  auditIndex: number.isRequired,
};

export default BinAuditsTable;

/**
  * gets the css color for the mapped value in App.css
  * @param {string} value - value of the string being mapped
*/
// eslint-disable-next-line import/prefer-default-export
export const getCssColorString = (value) => {
  if (!value || typeof (value) !== 'string') return '';

  const chars = ['/', ' '];
  chars.forEach((character) => {
    // eslint-disable-next-line no-param-reassign
    value = value.replace(new RegExp(character, 'gi'), '');
  });
  return value;
};
